// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"

// Formik
import { useFormik } from "formik"

// Theme
import FullAddressForm from "../../../../../../../../full-address-form"

import { getAddressFormat, isRequiredField } from "../../../../../../../../../services/address-form"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../../../../services/address-form/slice"
import { useUpdateCompanyMutation } from "../../../../../../../../../services/company/slice"

function CompanyContactInformationDrawer({ company, onSubmitEvent, setShowCompanyContactInformationDrawer, refetchCompany }) {
  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()
  const [setCompanyDetails] = useUpdateCompanyMutation()

  const { t } = useTranslation("companies")

  const formik = useFormik({
    initialValues: {
      companyName: company?.clientAddress?.companyName || "",
      personName: company?.clientAddress?.personName || "",
      addressLine1: company?.clientAddress?.addressLine1 || "",
      addressLine2: company?.clientAddress?.addressLine2 || "",
      city: company?.clientAddress?.city || "",
      provinceName: company?.clientAddress?.provinceName || "",
      provinceCode: company?.clientAddress?.provinceCode || "",
      countryCode: company?.clientAddress?.countryCode || "CA",
      postalCode: company?.clientAddress?.postalCode || "",
      phoneNumber: company?.clientAddress?.phoneNumber || "",
    },
    validate: (formValues) => {
      const errors = {}

      if (!formValues.personName) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.personName) {
        errors.personName = t("fields.personName.error.required")
      }

      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      await setCompanyDetails({ companyId: company.id, clientAddress: formValues })
      formik.resetForm()
      setShowCompanyContactInformationDrawer(false)
      setTimeout(() => refetchCompany(), 200)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <FullAddressForm formik={formik} displaySearchAddressBar displaySaveAddressCheckbox={false} displaySaveDefaultAddressCheckbox={false} displayResidentialToggle={false} displayEmailAddressField={false} />
    </Box>
  )
}

CompanyContactInformationDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

CompanyContactInformationDrawer.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    clientAddress: PropTypes.shape({
      personName: PropTypes.string,
      companyName: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      provinceName: PropTypes.string,
      provinceCode: PropTypes.string,
      countryCode: PropTypes.string,
      postalCode: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    emailAddress: PropTypes.string,
    billing: PropTypes.shape({
      type: PropTypes.string,
    }),
    pricingGroupCode: PropTypes.string,
  }).isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowCompanyContactInformationDrawer: PropTypes.func.isRequired,
  refetchCompany: PropTypes.func.isRequired,
}

export default CompanyContactInformationDrawer
