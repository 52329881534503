// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { FormikProvider, FieldArray } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import EmptyCarrierService from "../../../../services/empty-carrier"
import SoeDeleteOutlineIcon from "../../../../soe-theme/src/icons/SoeDeleteOutlineIcon"

function CarrierChipsForm({ formik, languages }) {
  const customTheme = useTheme()
  const { t } = useTranslation("carriers")

  const emptyChip = EmptyCarrierService.getFieldsWithLanguages("chip", languages)

  const { values } = formik

  return (
    <Box component="div">
      <FormikProvider value={formik}>
        <FieldArray
          name="chips"
          render={(chipsArray) => (
            <>
              {values.chips &&
                values.chips.map((_, indexChip) => (
                  <Box component="div" py={1} key={"chip".concat(indexChip.toString())}>
                    <Box
                      component="div"
                      mb={customTheme.utils.pxToThemeSpacing(16)}
                      sx={{
                        height: customTheme.utils.pxToRem(40),
                        borderRadius: customTheme.utils.pxToRem(4),
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography variant="bodyMedium600" sx={(theme) => ({ fontWeight: "bold", color: theme.palette.common.black, paddingLeft: theme.utils.pxToRem(14), marginBottom: 0 })} gutterBottom>
                        {`${t("carrierDetails.carrierChips.label")} - ${indexChip + 1}`}
                      </Typography>
                      <Box component="div" sx={{ flexGrow: 1 }} />
                      <IconButton color="primary" onClick={() => chipsArray.remove(indexChip)}>
                        <SoeDeleteOutlineIcon sx={{ color: customTheme.palette.common.black }} />
                      </IconButton>
                    </Box>
                    <Box component="div">
                      {values.languages &&
                        values.languages.length &&
                        values.languages.map((language, indexLanguage) => (
                          <Box component="div" mb={customTheme.utils.pxToThemeSpacing(16)} key={indexLanguage.toString().concat(language)}>
                            <TextField
                              key={indexLanguage.toString().concat("chips").concat([language.code])}
                              name={"chips".concat(indexChip.toString()).concat([language.code.toLocaleUpperCase()])}
                              id={"chips".concat(indexChip.toString()).concat([language.code.toLocaleUpperCase()])}
                              inputProps={{ maxLength: 100, style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular } }}
                              InputLabelProps={{ style: { lineHeight: customTheme.utils.pxToLineHeight(16, 24) } }}
                              variant="outlined"
                              size="small"
                              label={language.name}
                              onChange={(event) => {
                                formik.setFieldValue(`${"chips"}[${indexChip}][${language.code}]`, event.target.value)
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.chips[indexChip][language.code] || ""}
                              fullWidth
                            />
                          </Box>
                        ))}
                      {values && values.chips && values.chips.length > 1 && indexChip < values.chips.length - 1 && (
                        <>
                          <Box component="div" p={1} />
                          <Divider orientation="horizontal" style={{ minHeight: 0 }} />
                        </>
                      )}
                    </Box>
                  </Box>
                ))}
              <Box component="div" p={1} />
              <Button
                variant="outlined"
                onClick={() => {
                  chipsArray.push(emptyChip)
                }}
              >
                {t("carrierDetails.carrierChips.actions.add.label")}
              </Button>
              <Box component="div" p={2} />
            </>
          )}
        />
      </FormikProvider>
    </Box>
  )
}

CarrierChipsForm.defaultProps = {
  languages: undefined,
}

CarrierChipsForm.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  languages: PropTypes.instanceOf(Object),
}

export default CarrierChipsForm
