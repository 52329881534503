import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import React from "react"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"

import { useNavigate } from "react-router-dom"

import AuditItemAccountCard from "./components/audit-item-account-card"
import AuditItemCarrierCard from "./components/audit-item-carrier-card"
import AuditItemClientCard from "./components/audit-item-client-card"
import CustomChip from "./style"
import NoMatchCard from "./components/no-match-card"

import { AuditItemProps } from "../../../../../../models/audit"
import { pxToThemeSpacing } from "../../../../../../soe-theme/src/utils"
import { ROUTES } from "../../../../../../constant"
import { AuditStatusEnum } from "../../../../constant"

function AuditItem({ auditItem, auditId, handleSavePageYOffset }) {
  const navigate = useNavigate()

  const handleAuditItemClick = () => {
    handleSavePageYOffset()
    navigate(`${ROUTES.AUDITS}/${auditId}/items/${auditItem.id}`)
  }

  const getMatchPercentage = (rate) => {
    const carrierRate = rate.totals?.total?.carrierInvoice?.actual?.amount
    const accountRate = rate.totals?.total?.account?.declared?.amount

    if (!carrierRate || !accountRate) return 0

    if (carrierRate < accountRate) {
      return ((carrierRate / accountRate) * 100).toFixed(0)
    }

    return ((accountRate / carrierRate) * 100).toFixed(0)
  }

  const getMatchColor = (rate) => {
    const percentage = getMatchPercentage(rate)

    if (percentage >= 95) return "success"
    if (percentage >= 80) return "warning"
    return "error"
  }

  return (
    <TableRow hover component="tr" onClick={handleAuditItemClick}>
      <TableCell sx={{ border: "none" }}>
        <Grid container spacing={pxToThemeSpacing(10)}>
          <Grid item xs={3}>
            <AuditItemCarrierCard
              auditItemType={auditItem.auditItemType}
              tracking={auditItem.tracking.carrierInvoice.actual}
              shippingOptions={auditItem.shippingOptions.carrierInvoice.actual}
              rate={auditItem.rate.totals.total.carrierInvoice.actual}
            />
          </Grid>
          <Grid item xs={1}>
            <Card variant="outlined" square sx={{ height: 1 }}>
              <Box display="flex" justifyContent="center" alignItems="center" height={1}>
                {auditItem.order?.id && <CustomChip color={getMatchColor(auditItem.rate)} label={`${getMatchPercentage(auditItem.rate)}%`} />}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={3}>
            {auditItem.order?.id ? <AuditItemAccountCard rate={auditItem.rate.totals.total.account.declared} auditItem={auditItem} /> : <NoMatchCard />}
          </Grid> 
          <Grid item xs={3}>
            {auditItem.order?.id ? <AuditItemClientCard rate={auditItem.rate.totals.total.client?.actual || auditItem.rate.totals.total.client.declared} user={auditItem.user || auditItem.company} /> : <NoMatchCard />}
          </Grid>
          <Grid item xs={2}>
            <Card variant="outlined" square sx={{ height: 1 }}>
              {(auditItem.auditStatus === AuditStatusEnum.VALIDATED || auditItem.auditStatus === AuditStatusEnum.INVOICED) && (
                <Box display="flex" justifyContent="center" alignItems="center" height={1}>
                  <CustomChip color="success" label={auditItem.auditStatus} sx={{ textTransform: "capitalize" }} />
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

AuditItem.propTypes = {
  auditItem: AuditItemProps.isRequired,
  auditId: PropTypes.string.isRequired,
  handleSavePageYOffset: PropTypes.func,
}

AuditItem.defaultProps = {
  handleSavePageYOffset: () => {},
}

export default AuditItem
