/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/tables/
 * @link https://mui.com/api/table/
 *
 * @link https://mui.com/api/table-body/
 *
 * @link https://mui.com/api/table-cell/
 *
 * @link https://mui.com/api/table-container/
 *
 * @link https://mui.com/api/table-footer/
 *
 * @link https://mui.com/api/table-head/
 *
 * @link https://mui.com/api/table-pagination/
 *
 * @link https://mui.com/api/table-row/
 *
 * @linl https://mui.com/api/table-sort-label/
 */

export default function Table(theme) {
  return {
    MuiTable: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiTableBody: {
      defaultProps: {},
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.backgroundNeutralUnderground1.main,
          borderBottom: "1px solid", // @todo: Optimize this line.
          borderColor: theme.palette.backgroundNeutralUnderground2.main,
        },
      },
    },
    MuiTableCell: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderBottom: "1px solid", // @todo: Optimize this line.
          borderColor: theme.palette.backgroundNeutralUnderground2.main,
        },
      },
    },
    MuiTableContainer: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiTableFooter: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiTableHead: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiTablePagination: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiTableRow: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiTableSortLabel: {
      defaultProps: {},
      styleOverrides: {},
    },
  }
}
