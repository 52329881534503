const descendingComparator = (a, b, orderBy) => {
  const attributes = orderBy.split(".")

  const firstAttribute = attributes.shift()

  let aProperty = a[firstAttribute]
  let bProperty = b[firstAttribute]

  attributes.forEach((element) => {
    aProperty = aProperty ? aProperty[element] : undefined
    bProperty = bProperty ? bProperty[element] : undefined
  })

  if (!aProperty) return -1
  if (!bProperty) return 1

  if (typeof aProperty === "string") aProperty = aProperty.toLowerCase()
  if (typeof bProperty === "string") bProperty = bProperty.toLowerCase()

  if (bProperty < aProperty) {
    return -1
  }
  if (bProperty > aProperty) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => (order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy))

export default getComparator
