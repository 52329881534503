// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"

import { useTranslation } from "react-i18next"

// Custom
import CardContentNoPaddingBottom from "../../../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import DateTimeService from "../../../../../../services/date-time"

function TrackingEventsCard({ trackingData }) {
  const { t } = useTranslation("orders")

  return (
    <Card
      sx={(theme) => ({
        mb: theme.spacing(1),
      })}
    >
      <CardContentNoPaddingBottom
        sx={(theme) => ({
          "&:last-child": {
            pt: 0,
            my: theme.utils.pxToRem(12),
          },
        })}
      >
        <Box sx={(theme) => ({ my: theme.spacing(1) })}>
          <Typography variant="bodyMedium600">{t("trackingDrawer.trackingEventsCard.title")}</Typography>
        </Box>
        {trackingData.trackingEvents?.length > 0 ? (
          trackingData.trackingEvents.map((trackingEvent, trackingEventIndex) => {
            return (
              <Card key={"".concat(trackingEventIndex)} sx={(theme) => ({ mb: theme.spacing(1) })}>
                <CardContentNoPaddingBottom
                  sx={(theme) => ({
                    "&:last-child": {
                      pt: 0,
                      my: theme.utils.pxToRem(12),
                    },
                  })}
                >
                  <Box>
                    {trackingEvent?.statusDateTime && (
                      <Typography sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
                        {t("trackingDrawer.trackingEventsCard.statusDateTime")}: <b>{DateTimeService.toLocaleDateString(trackingEvent.statusDateTime)}</b> <b>{DateTimeService.toLocaleTimeString(trackingEvent.statusDateTime)}</b>
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    {trackingEvent?.statusCode && (
                      <Typography sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
                        {t("trackingDrawer.trackingEventsCard.statusCode")}: <b>{trackingEvent.statusCode}</b>
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    {trackingEvent?.statusDescription && (
                      <Typography sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
                        {t("trackingDrawer.trackingEventsCard.statusDescription")}: <b>{trackingEvent.statusDescription}</b>
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    {trackingEvent?.location && (
                      <Typography sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
                        {t("trackingDrawer.trackingEventsCard.location")}: <b>{trackingEvent.location}</b>
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    {trackingEvent?.notes && (
                      <Typography sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
                        {t("trackingDrawer.trackingEventsCard.notes")}: <b>{trackingEvent.notes}</b>
                      </Typography>
                    )}
                  </Box>
                </CardContentNoPaddingBottom>
              </Card>
            )
          })
        ) : (
          <Box>
            <Typography sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("trackingDrawer.trackingEventsCard.noEvents")}
            </Typography>
          </Box>
        )}
      </CardContentNoPaddingBottom>
    </Card>
  )
}

TrackingEventsCard.propTypes = {
  trackingData: PropTypes.instanceOf(Object).isRequired,
}

export default TrackingEventsCard
