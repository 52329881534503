// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import CardContent from "@mui/material/CardContent"

// Theme
import { styled } from "@mui/material/styles"

const StyledCardContentNoPaddingBottom = styled(CardContent, { label: "StyledCardContentNoPaddingBottom", slot: "Root" })(() => ({
  "&:last-child": {
    paddingBottom: 0,
  },
}))

function CardContentNoPaddingBottom(props) {
  const { sx, ...others } = props

  return <StyledCardContentNoPaddingBottom sx={sx} {...others} />
}

CardContentNoPaddingBottom.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]),
}

CardContentNoPaddingBottom.defaultProps = {
  sx: {},
}
export default CardContentNoPaddingBottom
