import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Card from "@mui/material/Card"
import Chip from "@mui/material/Chip"

import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "RateRequestInformationCard", slot: "Root" })(() => ({}))

export const CustomCard = styled(Card, { name: "CustomCard", slot: "Root" })(({ theme, disabled }) => ({
  color: disabled && theme.palette.contentNeutralTertiary.main,
  backgroundColor: disabled && "rgba(0, 0, 0, .003)",
  margin: theme.spacing(1),
  cursor: "pointer",
}))

export const CustomChipIsTest = styled(Chip, { name: "CustomChipIsTest", slot: "Root" })(({ theme }) => ({
  color: theme.palette.contentNeutralPrimaryReverse.main,
  backgroundColor: theme.palette.contentNeutralTertiary.main,
  borderRadius: 2,
  padding: 0,
  marginRight: theme.spacing(2),
  textTransform: "Uppercase",
}))

export const CustomCardContent = styled(CardContent, { name: "CustomCardContent", slot: "Root" })(({ theme }) => ({
  "&:last-child": {
    padding: theme.spacing(2),
  },
}))
