// react
import React, { useMemo } from "react"
import PropTypes from "prop-types"

// material
import { CssBaseline } from "@mui/material"
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material/styles"

// theme
import breakpoints from "./breakpoints" // Force MUI default
import componentsOverride from "./overrides"
import createCustomShadows from "./shadows" // Custom
import palette from "./palette" // Custom and MUI default
import shape from "./shape" // Force MUI default
import spacing from "./spacing" // Force MUI default
import stripe from "./stripe"
import typography from "./typography" // Custom and MUI default
import { pxToLineHeight, pxToThemeSpacing, pxToRem, responsiveFontSizes } from "./utils" // Custom utils

import GlobalStyles from "./globalStyles"
import "./scss/_base.scss"

/* eslint-disable react/jsx-filename-extension */

export default function ThemeConfig({ children }) {
  const customShadows = createCustomShadows()
  const themeOptions = useMemo(
    () => ({
      breakpoints,
      customShadows,
      palette,
      shape,
      spacing,
      stripe,
      typography,
      utils: { pxToLineHeight, pxToRem, pxToThemeSpacing, responsiveFontSizes },
    }),
    [customShadows]
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeConfig.propTypes = {
  children: PropTypes.node.isRequired,
}
