import React from "react"

import { useSelector } from "react-redux"

import ForgotPassword from "./components/forgot-password"
import ForgotPasswordSubmit from "./components/forgot-password-submit"
import SignIn from "./components/sign-in"

function Authenticator() {
  const { formState } = useSelector((state) => state.authenticator)

  if (formState === "signIn") {
    return <SignIn />
  }
  if (formState === "forgotPassword") {
    return <ForgotPassword />
  }
  if (formState === "forgotPasswordSubmit") {
    return <ForgotPasswordSubmit />
  }
  if (formState === "signedIn") {
    return <div />
  }
}

export default Authenticator
