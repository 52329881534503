/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/paper/
 * @link https://mui.com/api/paper/
 */
export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        square: false,
      },
      styleOverrides: {
        root: {
          borderColor: theme.palette.strokeDefault,
          backgroundColor: theme.palette.backgroundNeutralGround,
        },
      },
    },
  }
}
