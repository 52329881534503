/**
 * @link https://www.figma.com/file/NEkxr0jcaiX1LKu4if6FOE/SaveONexpress-(DS)?node-id=178%3A37
 * @link https://mui.com/components/material-icons/
 */
import * as React from "react"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import SoeAccessTimeIcon from "../../icons/SoeAccessTimeIcon"
import SoeAccountCircleIcon from "../../icons/SoeAccountCircleIcon"
import SoeAddCircleIcon from "../../icons/SoeAddCircleIcon"
import SoeAddIcon from "../../icons/SoeAddIcon"
import SoeRemoveIcon from "../../icons/SoeRemoveIcon"
import SoeBarChartIcon from "../../icons/SoeBarChartIcon"
import SoeBorderAllIcon from "../../icons/SoeBorderAllIcon"
import SoeAttachFileIcon from "../../icons/SoeAttachFileIcon"
import SoeArrowBackIcon from "../../icons/SoeArrowBackIcon"
import SoeArrowDownwardIcon from "../../icons/SoeArrowDownwardIcon"
import SoeArrowForwardIcon from "../../icons/SoeArrowForwardIcon"
import SoeArrowUpwardIcon from "../../icons/SoeArrowUpwardIcon"
import SoeArrowDropDownIcon from "../../icons/SoeArrowDropDownIcon"
import SoeArrowDropUpIcon from "../../icons/SoeArrowDropUpIcon"
import SoeArrowLeftIcon from "../../icons/SoeArrowLeftIcon"
import SoeArrowRightIcon from "../../icons/SoeArrowRightIcon"
import SoeCalendarTodayIcon from "../../icons/SoeCalendarTodayIcon"
import SoeCallIcon from "../../icons/SoeCallIcon"
import SoeCancelIcon from "../../icons/SoeCancelIcon"
import SoeCheckCircleIcon from "../../icons/SoeCheckCircleIcon"
import SoeCheckIcon from "../../icons/SoeCheckIcon"
import SoeCloseIcon from "../../icons/SoeCloseIcon"
import SoeCropSquareIcon from "../../icons/SoeCropSquareIcon"
import SoeDeleteOutlineIcon from "../../icons/SoeDeleteOutlineIcon"
import SoeEditIcon from "../../icons/SoeEditIcon"
import SoeFlightOutlinedIcon from "../../icons/SoeFlightOutlinedIcon"
import SoeErrorOutlineIcon from "../../icons/SoeErrorOutlineIcon"
import SoeWarningAmberIcon from "../../icons/SoeWarningAmberIcon"
import SoeInfoOutlinedIcon from "../../icons/SoeInfoOutlinedIcon"
import SoeTaskAltIcon from "../../icons/SoeTaskAltIcon"
import SoeReportGmailerrorredIcon from "../../icons/SoeReportGmailerrorredIcon"
import SoeKeyboardArrowDownIcon from "../../icons/SoeKeyboardArrowDownIcon"
import SoeKeyboardArrowUpIcon from "../../icons/SoeKeyboardArrowUpIcon"
import SoeKeyboardArrowLeftIcon from "../../icons/SoeKeyboardArrowLeftIcon"
import SoeKeyboardArrowRightIcon from "../../icons/SoeKeyboardArrowRightIcon"
import SoeLinkIcon from "../../icons/SoeLinkIcon"
import SoeLocationOffIcon from "../../icons/SoeLocationOffIcon"
import SoeLocationOnIcon from "../../icons/SoeLocationOnIcon"
import SoeLocalShippingSharpIcon from "../../icons/SoeLocalShippingSharpIcon"
import SoeMenuBookIcon from "../../icons/SoeMenuBookIcon"
import SoeMenuIcon from "../../icons/SoeMenuIcon"
import SoeMoreVertIcon from "../../icons/SoeMoreVertIcon"
import SoeMoreHorizIcon from "../../icons/SoeMoreHorizIcon"
import SoePrintOutlinedIcon from "../../icons/SoePrintOutlinedIcon"
import SoeSearchIcon from "../../icons/SoeSearchIcon"
import SoeVisibilityIcon from "../../icons/SoeVisibilityIcon"
import SoeVisibilityOffIcon from "../../icons/SoeVisibilityOffIcon"
import SoeOpenInNewIcon from "../../icons/SoeOpenInNew"
import SoeBusinessCenterIcon from "../../icons/SoeBusinessCenterIcon"
import SoeGroupIcon from "../../icons/SoeGroupIcon"
import SoeHomeIcon from "../../icons/SoeHomeIcon"
import SoeLocalShippingIcon from "../../icons/SoeLocalShippingIcon"
import SoePeopleAltIcon from "../../icons/SoePeopleAltIcon"
import SoeSettingsIcon from "../../icons/SoeSettingsIcon"
import SoeShoppingCartIcon from "../../icons/SoeShoppingCartIcon"
import SoeStyleIcon from "../../icons/SoeStyleIcon"
import SoeVerifiedUserIcon from "../../icons/SoeVerifiedUserIcon"
import SoeReceiptIcon from "../../icons/SoeReceiptIcon"

export default function SoeIcons() {
  return (
    <Container maxWidth="lg">
      <Grid container sx={{ color: "text.primary" }}>
        <Grid item xs={4}>
          <Typography>SoeOpenInNewIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeOpenInNewIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeAccessTimeIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeAccessTimeIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeAccountCircleIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeAccountCircleIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeAddCircleIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeAddCircleIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeAddIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeAddIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeRemoveIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeRemoveIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeBarChartIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeBarChartIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeBorderAllIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeBorderAllIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeAttachFileIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeAttachFileIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeArrowBackIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeArrowBackIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeArrowDownwardIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeArrowDownwardIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeArrowForwardIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeArrowForwardIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeArrowUpwardIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeArrowUpwardIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeArrowDropDownIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeArrowDropDownIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeArrowDropUpIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeArrowDropUpIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeArrowLeftIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeArrowLeftIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeArrowRightIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeArrowRightIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeCalendarTodayIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeCalendarTodayIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeCallIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeCallIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeCancelIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeCancelIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeCheckCircleIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeCheckCircleIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeCheckIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeCheckIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeCloseIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeCloseIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeCropSquareIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeCropSquareIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeDeleteOutlineIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeDeleteOutlineIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeEditIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeEditIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeFlightOutlinedIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeFlightOutlinedIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeErrorOutlineIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeErrorOutlineIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeWarningAmberIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeWarningAmberIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeInfoOutlinedIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeInfoOutlinedIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeTaskAltIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeTaskAltIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeReportGmailerrorredIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeReportGmailerrorredIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeWarningAmberIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeWarningAmberIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeKeyboardArrowDownIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeKeyboardArrowDownIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeKeyboardArrowUpIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeKeyboardArrowUpIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeKeyboardArrowLeftIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeKeyboardArrowLeftIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeKeyboardArrowRightIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeKeyboardArrowRightIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeLinkIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeLinkIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeLocationOffIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeLocationOffIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeLocationOnIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeLocationOnIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeLocalShippingSharpIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeLocalShippingSharpIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeMenuBookIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeMenuBookIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeMenuIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeMenuIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeMoreVertIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeMoreVertIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeMoreHorizIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeMoreHorizIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoePrintOutlinedIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoePrintOutlinedIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeSearchIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeSearchIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeVisibilityIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeVisibilityIcon />
        </Grid>

        <Grid item xs={4}>
          <Typography>SoeVisibilityOffIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeVisibilityOffIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeBusinessCenterIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeBusinessCenterIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeGroupIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeGroupIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeHomeIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeHomeIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeLocalShippingIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeLocalShippingIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoePeopleAltIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoePeopleAltIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeSettingsIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeSettingsIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeShoppingCartIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeShoppingCartIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeStyleIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeStyleIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeVerifiedUserIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeVerifiedUserIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography>SoeReceiptIcon</Typography>
        </Grid>
        <Grid item xs={8}>
          <SoeReceiptIcon />
        </Grid>
      </Grid>
    </Container>
  )
}
