// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

function LanguageSelector({ i18n, languages }) {
  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value)
  }

  return (
    <Box component="div" my={1} sx={{ textAlign: "right" }}>
      {languages && (
        <Select id="language" value={i18n.resolvedLanguage} variant="standard" onChange={handleLanguageChange} disableUnderline>
          {languages.map((language, index) => {
            return (
              <MenuItem key={`${index.toString()}`} value={language.code}>
                {language.name}
              </MenuItem>
            )
          })}
        </Select>
      )}
    </Box>
  )
}

LanguageSelector.defaultProps = {
  languages: undefined,
}

LanguageSelector.propTypes = {
  i18n: PropTypes.instanceOf(Object).isRequired,
  languages: PropTypes.instanceOf(Object),
}

export default LanguageSelector
