// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CompanyDetailsSummaryCard from "../../../company-details-summary-card"
import { SummaryStatusVariantsEnum } from "../../../company-details-summary-card/components/summary-status"

function CompanyFeatureFlagsCard({ company, defaultToIconSuccessStatus, handleCompanyFeatureFlagsClick }) {
  const theme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToIconSuccessStatus) return SummaryStatusVariantsEnum.icon
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <CompanyDetailsSummaryCard title="Feature Flags" handleClick={handleCompanyFeatureFlagsClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={theme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {company && company.featureFlags && Object.entries(company.featureFlags).map(([key, value]) => (
          <Typography
            key={key}
            variant="bodySmall400"
            gutterBottom
            sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}
          >
            {key} - {value.toString()}
          </Typography>
        ))}
      </Box>
    </CompanyDetailsSummaryCard>
  )
}

CompanyFeatureFlagsCard.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    featureFlags: PropTypes.objectOf(PropTypes.bool)
  }),
  defaultToIconSuccessStatus: PropTypes.bool,
  handleCompanyFeatureFlagsClick: PropTypes.func,
}

CompanyFeatureFlagsCard.defaultProps = {
  handleCompanyFeatureFlagsClick: () => {},
  defaultToIconSuccessStatus: false,
  company: undefined,
}

export default CompanyFeatureFlagsCard
