import Box from "@mui/material/Box"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Link from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import React, { useState } from "react"
import Typography from "@mui/material/Typography"

import { useNavigate, useParams } from "react-router-dom"
import { useTheme } from "@emotion/react"
import { useTranslation } from "react-i18next"

import ClientDetailsActivities from "./components/client-details-activities"
import ClientDetailsInformations from "./components/client-details-informations"
import ClientDetailsStatistics from "./components/client-details-statistics"

import { pxToThemeSpacing } from "../../../../soe-theme/src/utils"
import { ROUTES } from "../../../../constant"
import { useGetClientDetailsQuery } from "../../slice"

function ClientDetails() {
  const { t } = useTranslation("clients")
  const navigate = useNavigate()
  const theme = useTheme()

  const { clientId } = useParams()

  const [dataRangeFilterValues, setDataRangeFilterValues] = useState()

  const { data: client } = useGetClientDetailsQuery(clientId, { skip: !clientId })

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(ROUTES.CLIENTS)}>
      {t("clientDetails.breadcrumbs.clients")}
    </Link>,
    <Typography key="3" color="text.primary">
      {t("clientDetails.breadcrumbs.details")}
    </Typography>,
  ]

  return (
    <Box flexDirection="column" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1 }}>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <CardHeader title={t("clientDetails.title")} style={{ paddingLeft: theme.spacing(4), paddingBottom: theme.spacing(2) }} />
      </Card>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <Box mb={pxToThemeSpacing(10)} ml={pxToThemeSpacing(34)}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
      </Card>
      <Card variant="outlined" />
      <Box p={pxToThemeSpacing(20)} pt={0}>
        {client && <ClientDetailsStatistics client={client} dataRangeFilterValues={dataRangeFilterValues} setDataRangeFilterValues={setDataRangeFilterValues} />}
        {client && <ClientDetailsInformations client={client} />}
        {client && <ClientDetailsActivities client={client} dataRangeFilterValues={dataRangeFilterValues} />}
      </Box>
    </Box>
  )
}

export default ClientDetails
