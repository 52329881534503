import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import englishTranslations from "./assets/locales/en/translation.json"
import enAuditInfoCardTranslations from "./assets/locales/en/audit-info-card.json"
import enAppBarTranslations from "./assets/locales/en/app-bar.json"
import enAuditsTranslations from "./assets/locales/en/audits.json"
import enAuthenticatorTranslations from "./assets/locales/en/authenticator.json"
import enBrokerCardTranslations from "./assets/locales/en/broker-card.json"
import enBuyerCardTranslations from "./assets/locales/en/buyer-card.json"
import enCarriersTranslations from "./assets/locales/en/carriers.json"
import enClientSelectionTranslations from "./assets/locales/en/client-selection.json"
import enClientsTranslations from "./assets/locales/en/clients.json"
import enCompaniesTranslations from "./assets/locales/en/companies.json"
import enCommercialInvoiceCardTranslations from "./assets/locales/en/commercial-invoice-card.json"
import enCostSummaryCardTranslations from "./assets/locales/en/cost-summary-card.json"
import enCustomPaginationTranslations from "./assets/locales/en/custom-pagination.json"
import enDateRangeFilterTranslations from "./assets/locales/en/date-range-filter.json"
import enFileUploaderTranslations from "./assets/locales/en/file-uploader.json"
import enFreightOptionsCardTranslations from "./assets/locales/en/freight-options-card.json"
import enInternationalOptionsCardTranslations from "./assets/locales/en/international-options-card.json"
import enInvoiceNumberCardTranslations from "./assets/locales/en/invoice-number-card.json"
import enInvoicesTranslation from "./assets/locales/en/invoices.json"
import enMenuTranslations from "./assets/locales/en/menu.json"
import enOrderNumberCardTranslations from "./assets/locales/en/order-number-card.json"
import enOrderPaymentsCardTranslations from "./assets/locales/en/order-payments-card.json"
import enOrdersTranslations from "./assets/locales/en/orders.json"
import enPackagesCardTranslations from "./assets/locales/en/packages-card.json"
import enPriceSummaryCardTranslations from "./assets/locales/en/price-summary-card.json"
import enPricingTranslations from "./assets/locales/en/pricing.json"
import enReadyToInvoiceTranslations from "./assets/locales/en/ready-to-invoice.json"
import enRecipientCardTranslations from "./assets/locales/en/recipient-card.json"
import enSearchInputTranslations from "./assets/locales/en/search-input.json"
import enShipperCardTranslations from "./assets/locales/en/shipper-card.json"
import enShippingOptionsCardTranslations from "./assets/locales/en/shipping-options-card.json"
import enTrackingNumberCardTranslations from "./assets/locales/en/tracking-number-card.json"
import enUsersTranslations from "./assets/locales/en/users.json"
import enSettings from "./assets/locales/en/settings.json"
import enSummaryCard from "./assets/locales/en/summary-card.json"
import enUserProfileTranslations from "./assets/locales/en/user-profile.json"
import enFullAddressFormTranslations from "./assets/locales/en/full-address-form.json"
import enClientsAutoCompleteTranslations from "./assets/locales/en/clients-auto-complete.json"

import frenchTranslations from "./assets/locales/fr/translation.json"
import frAuditInfoCardTranslations from "./assets/locales/fr/audit-info-card.json"
import frAppBarTranslations from "./assets/locales/fr/app-bar.json"
import frAuditsTranslations from "./assets/locales/fr/audits.json"
import frAuthenticatorTranslations from "./assets/locales/fr/authenticator.json"
import frBrokerCardTranslations from "./assets/locales/fr/broker-card.json"
import frBuyerCardTranslations from "./assets/locales/fr/buyer-card.json"
import frCarriersTranslations from "./assets/locales/fr/carriers.json"
import frClientSelectionTranslations from "./assets/locales/fr/client-selection.json"
import frClientsTranslations from "./assets/locales/fr/clients.json"
import frCompaniesTranslations from "./assets/locales/fr/companies.json"
import frCommercialInvoiceCardTranslations from "./assets/locales/fr/commercial-invoice-card.json"
import frCostSummaryCardTranslations from "./assets/locales/fr/cost-summary-card.json"
import frCustomPaginationTranslations from "./assets/locales/fr/custom-pagination.json"
import frDateRangeFilterTranslations from "./assets/locales/fr/date-range-filter.json"
import frFileUploaderTranslations from "./assets/locales/fr/file-uploader.json"
import frFreightOptionsCardTranslations from "./assets/locales/fr/freight-options-card.json"
import frInternationalOptionsCardTranslations from "./assets/locales/fr/international-options-card.json"
import frInvoiceNumberCardTranslations from "./assets/locales/fr/invoice-number-card.json"
import frInvoicesTranslation from "./assets/locales/fr/invoices.json"
import frMenuTranslations from "./assets/locales/fr/menu.json"
import frOrderNumberCardTranslations from "./assets/locales/fr/order-number-card.json"
import frOrdersTranslations from "./assets/locales/fr/orders.json"
import frOrderPaymentsCardTranslations from "./assets/locales/fr/order-payments-card.json"
import frPackagesCardTranslations from "./assets/locales/fr/packages-card.json"
import frPriceSummaryCardTranslations from "./assets/locales/fr/price-summary-card.json"
import frPricingTranslations from "./assets/locales/fr/pricing.json"
import frReadyToInvoiceTranslations from "./assets/locales/fr/ready-to-invoice.json"
import frRecipientCardTranslations from "./assets/locales/fr/recipient-card.json"
import frSearchInputTranslations from "./assets/locales/fr/search-input.json"
import frShipperCardTranslations from "./assets/locales/fr/shipper-card.json"
import frShippingOptionsCardTranslations from "./assets/locales/fr/shipping-options-card.json"
import frTrackingNumberCardTranslations from "./assets/locales/fr/tracking-number-card.json"
import frUsersTranslations from "./assets/locales/fr/users.json"
import frSettings from "./assets/locales/fr/setting.json"
import frSummaryCard from "./assets/locales/fr/summary-card.json"
import frUserProfileTranslations from "./assets/locales/fr/user-profile.json"
import frFullAddressFormTranslations from "./assets/locales/fr/full-address-form.json"
import frClientsAutoCompleteTranslations from "./assets/locales/fr/clients-auto-complete.json"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: "languageOnly",
    ns: [
      "appBar",
      "auditInfoCard",
      "audits",
      "authenticator",
      "brokerCard",
      "buyerCard",
      "carriers",
      "clientSelection",
      "clients",
      "companies",
      "commercialInvoiceCard",
      "costSummaryCard",
      "customPagination",
      "dateRangeFilter",
      "fileUploader",
      "freightOptionsCard",
      "internationalOptionsCard",
      "invoiceNumberCard",
      "invoices",
      "menu",
      "orderNumberCard",
      "orderPaymentsCard",
      "orders",
      "packagesCard",
      "priceSummaryCard",
      "pricing",
      "readyToInvoice",
      "recipientCard",
      "shipperCard",
      "shippingOptionsCard",
      "trackingNumberCard",
      "translation",
      "users",
      "settings",
      "summaryCard",
      "userProfile",
      "fullAddressForm",
      "clientsAutoComplete",
    ],
    defaultNS: "translation",
    resources: {
      en: {
        appBar: enAppBarTranslations,
        auditInfoCard: enAuditInfoCardTranslations,
        audits: enAuditsTranslations,
        authenticator: enAuthenticatorTranslations,
        brokerCard: enBrokerCardTranslations,
        buyerCard: enBuyerCardTranslations,
        carriers: enCarriersTranslations,
        clientSelection: enClientSelectionTranslations,
        clients: enClientsTranslations,
        companies: enCompaniesTranslations,
        commercialInvoiceCard: enCommercialInvoiceCardTranslations,
        costSummaryCard: enCostSummaryCardTranslations,
        customPagination: enCustomPaginationTranslations,
        dateRangeFilter: enDateRangeFilterTranslations,
        fileUploader: enFileUploaderTranslations,
        freightOptionsCard: enFreightOptionsCardTranslations,
        internationalOptionsCard: enInternationalOptionsCardTranslations,
        invoiceNumberCard: enInvoiceNumberCardTranslations,
        invoices: enInvoicesTranslation,
        menu: enMenuTranslations,
        orderNumberCard: enOrderNumberCardTranslations,
        orderPaymentsCard: enOrderPaymentsCardTranslations,
        orders: enOrdersTranslations,
        packagesCard: enPackagesCardTranslations,
        priceSummaryCard: enPriceSummaryCardTranslations,
        pricing: enPricingTranslations,
        readyToInvoice: enReadyToInvoiceTranslations,
        recipientCard: enRecipientCardTranslations,
        searchInput: enSearchInputTranslations,
        shipperCard: enShipperCardTranslations,
        shippingOptionsCard: enShippingOptionsCardTranslations,
        trackingNumberCard: enTrackingNumberCardTranslations,
        translation: englishTranslations,
        users: enUsersTranslations,
        settings: enSettings,
        summaryCard: enSummaryCard,
        userProfile: enUserProfileTranslations,
        fullAddressForm: enFullAddressFormTranslations,
        clientsAutoComplete: enClientsAutoCompleteTranslations,
      },
      fr: {
        appBar: frAppBarTranslations,
        auditInfoCard: frAuditInfoCardTranslations,
        audits: frAuditsTranslations,
        authenticator: frAuthenticatorTranslations,
        brokerCard: frBrokerCardTranslations,
        buyerCard: frBuyerCardTranslations,
        carriers: frCarriersTranslations,
        clientSelection: frClientSelectionTranslations,
        clients: frClientsTranslations,
        companies: frCompaniesTranslations,
        commercialInvoiceCard: frCommercialInvoiceCardTranslations,
        costSummaryCard: frCostSummaryCardTranslations,
        customPagination: frCustomPaginationTranslations,
        dateRangeFilter: frDateRangeFilterTranslations,
        fileUploader: frFileUploaderTranslations,
        freightOptionsCard: frFreightOptionsCardTranslations,
        internationalOptionsCard: frInternationalOptionsCardTranslations,
        invoiceNumberCard: frInvoiceNumberCardTranslations,
        invoices: frInvoicesTranslation,
        menu: frMenuTranslations,
        orderNumberCard: frOrderNumberCardTranslations,
        orderPaymentsCard: frOrderPaymentsCardTranslations,
        orders: frOrdersTranslations,
        packagesCard: frPackagesCardTranslations,
        priceSummaryCard: frPriceSummaryCardTranslations,
        pricing: frPricingTranslations,
        readyToInvoice: frReadyToInvoiceTranslations,
        recipientCard: frRecipientCardTranslations,
        searchInput: frSearchInputTranslations,
        shipperCard: frShipperCardTranslations,
        shippingOptionsCard: frShippingOptionsCardTranslations,
        trackingNumberCard: frTrackingNumberCardTranslations,
        translation: frenchTranslations,
        users: frUsersTranslations,
        settings: frSettings,
        summaryCard: frSummaryCard,
        userProfile: frUserProfileTranslations,
        fullAddressForm: frFullAddressFormTranslations,
        clientsAutoComplete: frClientsAutoCompleteTranslations,
      },
    },
    detection: {
      order: ["sessionStorage"],
      caches: ["sessionStorage"],
    },
  })

export default i18n
