const htmlFontSize = 16 // Browser default - do not change it
const baseFontSize = 14 // Real "base" fontSize - "0.875rem" - do not change it

const coef = baseFontSize / 14
export const pxToRem = (size) => `${(size / htmlFontSize) * coef}rem` // from MUI code

/**
 * The "lineHeight" values can be found here:
 * @link https://github.com/mui-org/material-ui/blob/master/packages/mui-joy/src/styles/defaultTheme.ts
 * file: packages/mui-joy/src/styles/defaultTheme.ts
 */
const muiLineHeight = {
  sm: 1.25,
  md: 1.5,
  lg: 1.7,
}

/**
 * The "letterSpacing" values can be found here:
 * @link https://github.com/mui-org/material-ui/blob/master/packages/mui-joy/src/styles/defaultTheme.ts
 * file: packages/mui-joy/src/styles/defaultTheme.ts
 */
const muiLetterSpacing = {
  sm: "-0.01em",
  md: "0.083em",
  lg: "0.125em",
}

/**
 * fontWeight from figma
 */
const fontWeight = {
  light: 300, // Use in MUI default
  normal: 400, // Use in MUI default - html normal standard 400
  medium: 500, // Use in MUI default
  bold: 700, // Use in MUI default - html bold standard 700
}

/**
 * fontSize from figma
 */
const fontSize = {
  rem96px: pxToRem(96), // "6rem", 96px
  rem60px: pxToRem(60), // "3.75rem", 60px
  rem48px: pxToRem(48), // "3rem", 48px
  rem34px: pxToRem(34), // "2.125rem", 34px
  rem32px: pxToRem(32), // "2rem", 32px
  rem24px: pxToRem(24), // "1.5rem", 24px
  rem20px: pxToRem(20), // "1.25rem", 20px
  rem16px: pxToRem(16), // "1rem", 16px
  rem14px: pxToRem(14), // "0.875rem", 14px
  rem12px: pxToRem(12), // "0.75rem", 12px
  rem10px: pxToRem(10), // "0.625rem", 10px
}

/**
 * Typography from figma
 */
export const figmaTypography = {
  fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  fontWeight,
  heading: {
    h1: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem96px,
    },
    h2: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem60px,
    },
    h3: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem48px,
    },
    h4: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem34px,
    },
    h5: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem24px,
    },
    h6: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem20px,
    },
  },
  body: {
    medium600: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem16px,
    },
    medium400: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem14px,
    },
    small600: {
      fontWeight: fontWeight.bold,
      fontSize: fontSize.rem12px,
    },
    small400: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem12px,
    },
    small200: {
      fontWeight: fontWeight.normal,
      fontSize: fontSize.rem10px,
    },
  },
}

/**
 * Theme Custom Typography, use same name as Figma
 *
 * The "lineHeight" and "letterSpacing" function value can be found here:
 * @link https://github.com/mui-org/material-ui/blob/master/packages/mui-joy/src/styles/defaultTheme.ts
 * file: packages/mui-joy/src/styles/defaultTheme.ts
 */
const themeCustomTypography = {
  headingH1: {
    fontWeight: figmaTypography.heading.h1.fontWeight,
    fontSize: figmaTypography.heading.h1.fontSize,
    lineHeight: muiLineHeight.sm,
    letterSpacing: muiLetterSpacing.sm,
  },
  headingH2: {
    fontWeight: figmaTypography.heading.h2.fontWeight,
    fontSize: figmaTypography.heading.h2.fontSize,
    lineHeight: muiLineHeight.sm,
    letterSpacing: muiLetterSpacing.sm,
  },
  headingH3: {
    fontWeight: figmaTypography.heading.h3.fontWeight,
    fontSize: figmaTypography.heading.h3.fontSize,
    lineHeight: muiLineHeight.sm,
  },
  headingH4: {
    fontWeight: figmaTypography.heading.h4.fontWeight,
    fontSize: figmaTypography.heading.h4.fontSize,
    lineHeight: muiLineHeight.sm,
  },
  headingH5: {
    fontWeight: figmaTypography.heading.h5.fontWeight,
    fontSize: figmaTypography.heading.h5.fontSize,
    lineHeight: muiLineHeight.md,
  },
  headingH6: {
    fontWeight: figmaTypography.heading.h6.fontWeight,
    fontSize: figmaTypography.heading.h6.fontSize,
    lineHeight: muiLineHeight.md,
  },
  bodyMedium600: {
    fontWeight: figmaTypography.body.medium600.fontWeight,
    fontSize: figmaTypography.body.medium600.fontSize,
    lineHeight: muiLineHeight.md,
  },
  bodyMedium400: {
    fontWeight: figmaTypography.body.medium400.fontWeight,
    fontSize: figmaTypography.body.medium400.fontSize,
    lineHeight: muiLineHeight.md,
  },
  bodySmall600: {
    fontWeight: figmaTypography.body.small600.fontWeight,
    fontSize: figmaTypography.body.small600.fontSize,
    lineHeight: muiLineHeight.md,
  },
  bodySmall400: {
    fontWeight: figmaTypography.body.small400.fontWeight,
    fontSize: figmaTypography.body.small400.fontSize,
    lineHeight: muiLineHeight.md,
  },
  bodySmall200: {
    fontWeight: figmaTypography.body.small200.fontWeight,
    fontSize: figmaTypography.body.small200.fontSize,
    lineHeight: muiLineHeight.md,
  },
}

/**
 * MUI Default, set for compatibility purpose, set to look like the Figma
 */
const muiTypography = {
  fontFamily: figmaTypography.fontFamily,
  htmlFontSize,
  fontSize: baseFontSize,
  fontWeightLight: figmaTypography.fontWeight.light,
  fontWeightRegular: figmaTypography.fontWeight.normal,
  fontWeightMedium: figmaTypography.fontWeight.medium,
  fontWeightBold: figmaTypography.fontWeight.bold,
  h1: {
    fontWeight: figmaTypography.heading.h1.fontWeight,
    fontSize: figmaTypography.heading.h1.fontSize,
  },
  h2: {
    fontWeight: figmaTypography.heading.h2.fontWeight,
    fontSize: figmaTypography.heading.h2.fontSize,
  },
  h3: {
    fontWeight: figmaTypography.heading.h3.fontWeight,
    fontSize: figmaTypography.heading.h3.fontSize,
  },
  h4: {
    fontWeight: figmaTypography.heading.h4.fontWeight,
    fontSize: figmaTypography.heading.h4.fontSize,
  },
  h5: {
    fontWeight: figmaTypography.heading.h5.fontWeight,
    fontSize: figmaTypography.heading.h5.fontSize,
  },
  h6: {
    fontWeight: figmaTypography.heading.h6.fontWeight,
    fontSize: figmaTypography.heading.h6.fontSize,
  },
  body1: {
    // This will be applied as default on html body tag
    fontWeight: figmaTypography.body.medium400.fontWeight,
    fontSize: figmaTypography.body.medium400.fontSize,
  },
  body2: {
    // @todo: validate this
    fontWeight: figmaTypography.body.small400.fontWeight,
    fontSize: figmaTypography.body.small400.fontSize,
  },
  subtitle1: {
    // @todo: validate this
    fontWeight: figmaTypography.body.medium400.fontWeight,
    fontSize: figmaTypography.body.medium400.fontSize,
  },
  subtitle2: {
    // @todo: validate this
    fontWeight: figmaTypography.body.small600.fontWeight,
    fontSize: figmaTypography.body.small600.fontSize,
  },
  button: {
    // @todo: validate this
    fontWeight: figmaTypography.body.medium600.fontWeight,
    fontSize: figmaTypography.body.medium600.fontSize,
  },
  caption: {
    // @todo: validate this
    fontWeight: figmaTypography.body.small400.fontWeight,
    fontSize: figmaTypography.body.small400.fontSize,
  },
  overline: {
    // @todo: validate this
    fontWeight: figmaTypography.body.small400.fontWeight,
    fontSize: figmaTypography.body.small400.fontSize,
  },
}

const typography = { ...muiTypography, ...themeCustomTypography, lineHeight: muiLineHeight, letterSpacing: muiLetterSpacing, pxToRem }

export default typography
