import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"

function FreightOptionsCard({ freightOptions, disable }) {
  const { t } = useTranslation("freightOptionsCard")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard title={t("title")} disable={disable}>
      <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
        {freightOptions?.appointment && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {t("appointment.label")}
          </Typography>
        )}
        {freightOptions?.prebookedAppointment && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {t("prebookedAppointment.label")}
          </Typography>
        )}
        {freightOptions?.afterHours && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {t("afterHours.label")} : {t(`afterHours.options.${freightOptions.afterHours}`) || ""}
          </Typography>
        )}
        {freightOptions?.twoPersons && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {t("twoPersons.label")} : {t(`twoPersons.options.${freightOptions.twoPersons}`) || ""}
          </Typography>
        )}
        {freightOptions?.tailgate && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {t("tailgate.label")} : {t(`tailgate.options.${freightOptions.tailgate}`) || ""}
          </Typography>
        )}
      </Box>
    </RateRequestSummaryCard>
  )
}

FreightOptionsCard.propTypes = {
  freightOptions: PropTypes.instanceOf(Object),
  disable: PropTypes.bool,
}

FreightOptionsCard.defaultProps = {
  freightOptions: undefined,
  disable: false,
}

export default FreightOptionsCard
