// REACT
import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Skeleton from "@mui/material/Skeleton"

// Others
import PricingGroupAdd from "./components/pricing-group-add"
import PricingGroupEdit from "./components/pricing-group-edit"
import PricingGroup from "./components/pricing-group"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../soe-theme/src/components"
import { useGetPricingGroupsQuery } from "./slice"

function Pricing() {
  const { t } = useTranslation("pricing")

  const { data: pricingGroups, isFetching } = useGetPricingGroupsQuery()

  const [selectedPricingGroup, setSelectedPricingGroup] = useState()
  const [showPricingGroupAddDrawer, setShowPricingGroupAddDrawer] = useState(false)
  const [showPricingGroupEditDrawer, setShowPricingGroupEditDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()

  useEffect(() => {
    if (!showPricingGroupAddDrawer) setSelectedPricingGroup(undefined)
  }, [showPricingGroupAddDrawer])

  useEffect(() => {
    if (!showPricingGroupEditDrawer) setSelectedPricingGroup(undefined)
  }, [showPricingGroupEditDrawer])

  const handleAddPricingGroupClick = () => {
    setShowPricingGroupAddDrawer(true)
  }

  return (
    <>
      <Card variant="elevation" square>
        <CardHeader
          title={t("pricingManagement.title")}
          action={
            <Button variant="contained" onClick={handleAddPricingGroupClick} sx={(theme) => ({ mr: theme.spacing(1) })}>
              {t("pricingManagement.actions.add.label")}
            </Button>
          }
        />
      </Card>
      <Card variant="elevation" square sx={{ border: 0 }}>
        {isFetching ? (
          <Skeleton variant="rectangular" width="100%" height={100} />
        ) : (
          <>
            {pricingGroups.map((pricingGroup, index) => {
              return (
                <PricingGroup
                  key={"".concat(index)}
                  pricingGroup={pricingGroup}
                  setSelectedPricingGroup={setSelectedPricingGroup}
                  setShowPricingGroupAddDrawer={setShowPricingGroupAddDrawer}
                  setShowPricingGroupEditDrawer={setShowPricingGroupEditDrawer}
                />
              )
            })}
          </>
        )}
      </Card>
      <SoeDrawer showDrawer={showPricingGroupAddDrawer} setShowDrawer={setShowPricingGroupAddDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("pricingGroupDrawer.title.add.label")}
            setShowDrawer={() => {
              setShowPricingGroupAddDrawer(false)
            }}
          />
          <SoeDrawerContent>
            {pricingGroups && <PricingGroupAdd selectedPricingGroup={selectedPricingGroup} pricingGroups={pricingGroups} onSubmitEvent={onSubmitEvent} setSubmitEvent={setSubmitEvent} setShowPricingGroupAddDrawer={setShowPricingGroupAddDrawer} />}
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("pricingManagement.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <SoeDrawer showDrawer={showPricingGroupEditDrawer} setShowDrawer={setShowPricingGroupEditDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("pricingGroupDrawer.title.edit.label")}
            setShowDrawer={() => {
              setShowPricingGroupEditDrawer(false)
            }}
          />
          <SoeDrawerContent>
            {selectedPricingGroup && <PricingGroupEdit selectedPricingGroup={selectedPricingGroup} onSubmitEvent={onSubmitEvent} setSubmitEvent={setSubmitEvent} setShowPricingGroupEditDrawer={setShowPricingGroupEditDrawer} />}
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("pricingManagement.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </>
  )
}

export default Pricing
