import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CarrierDetailsCard from "../../../carrier-details-card"
import TrackingEventsCard from "./components/tracking-events-card"
import TrackingCard from "./components/tracking-card"
import TrackingNumberCard from "../../../tracking-number-card"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useGetOrderQuery, useGetTrackingInfoQuery } from "../../../../services/order/slice"

function TrackingDrawer({ selectedOrderId, showTrackingDrawer, setShowTrackingDrawer }) {
  const { t } = useTranslation("orders")
  const theme = useTheme()

  const { data: selectedOrder } = useGetOrderQuery(selectedOrderId)
  const { data: trackingData } = useGetTrackingInfoQuery(selectedOrderId)

  return (
    <SoeDrawer showDrawer={showTrackingDrawer} setShowDrawer={setShowTrackingDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("trackingDrawer.title")}
          setShowDrawer={() => {
            setShowTrackingDrawer(false)
          }}
        />
        <SoeDrawerContent>
          {selectedOrder && trackingData ? (
            <Stack direction="column">
              <CarrierDetailsCard
                service={selectedOrder.quotation.response.service}
                carrier={selectedOrder.quotation.response.carrier}
                commitment={selectedOrder.quotation.response.commitment}
                charges={selectedOrder.quotation.response.rate.charges}
              />
              <Box sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
                <TrackingNumberCard trackingNumber={selectedOrder?.tracking?.masterId} />
              </Box>
              <TrackingCard trackingData={trackingData} />
              <TrackingEventsCard trackingData={trackingData} />
            </Stack>
          ) : (
            <Skeleton variant="rectangular" height={50} width="100%" />
          )}
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: () => setShowTrackingDrawer(false),
              label: t("trackingDrawer.actions.close"),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

TrackingDrawer.propTypes = {
  selectedOrderId: PropTypes.string.isRequired,
  showTrackingDrawer: PropTypes.bool.isRequired,
  setShowTrackingDrawer: PropTypes.func.isRequired,
}

export default TrackingDrawer
