/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/api/switch/
 */

export default function Switch() {
  return {
    MuiSwitch: {
      defaultProps: {},
      styleOverrides: {},
    },
  }
}
