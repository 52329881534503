import React, { useContext, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"

// MUI
import Box from "@mui/material/Box"
import { FormControl, InputLabel, MenuItem, Select, TextField, Checkbox, FormHelperText } from "@mui/material"
import { useTranslation } from "react-i18next"
import CustomDatePicker from "../../../date-picker"
import { useGetClientsQuery, useGetCurrenciesQuery } from "../../../clients/slice"
import { useCreateCreditsMutation, useUpdateCreditMutation } from "../../slice"
import ClientsAutocomplete from "../../../clients-autocomplete"
import UserContext from "../../../../services/user/context"

function AddCreditDrawer({ onSubmitEvent, setShowCreditDrawer, setIsCreditCreating, credit, clientEmailAddress }) {
  const { t } = useTranslation("invoices")
  const { currentUser } = useContext(UserContext)
  const { data: clients, refetch: refetchClients } = useGetClientsQuery()
  const { data: currencies } = useGetCurrenciesQuery()
  const [createCredits] = useCreateCreditsMutation()
  const [updateCredit] = useUpdateCreditMutation()

  const clientForInvoice = credit?.client || clientEmailAddress ? clients?.find((c) => c.emailAddress === (credit?.client || clientEmailAddress)) : ""
  const formik = useFormik({
    initialValues: {
      creditDate: useMemo(() => new Date(credit?.creditDate || new Date()), []),
      client: clientForInvoice,
      orderNumber: credit?.orderNumber || "",
      title: credit?.title || "",
      description: credit?.description || "",
      amount: credit?.creditAmount?.amount || "",
      currency: credit?.creditAmount?.currency || "CAD",
      gst: credit?.taxGst?.amount || "",
      pst: credit?.taxPst?.amount || "",
      isAppliedFromBalance: false,
      total: "",
      privateNote: credit?.privateNote || "",
    },
    validate: (formvalues) => {
      const errors = {}
      if (!formvalues.creditDate) {
        errors.creditDate = true
      }
      if (!formvalues.client) {
        errors.client = true
      }
      if (!formvalues.title) {
        errors.title = true
      }
      if (!formvalues.amount) {
        errors.amount = true
      }
      if (!formvalues.currency) {
        errors.currency = true
      }
      if (formvalues.isAppliedFromBalance && (Number(formik.values.gst) + Number(formik.values.pst) + Number(formik.values.amount)).toFixed(2) > formik.values.client?.company?.credit?.total?.amount) {
        errors.isAppliedFromBalance = true
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formvalues) => {
      setIsCreditCreating(true)
      const companyId = formvalues.client.companyId || formvalues.client?.sk?.replace("org#", "") || formvalues.client?.company?.id
      let temporaryCredit = {
        creditDate: new Date(formvalues.creditDate).toISOString(),
        client: formvalues.client.emailAddress,
        title: formvalues.title,
        description: formvalues.description || undefined,
        isAppliedFromBalance: formvalues.isAppliedFromBalance,
        companyId,
        creditAmount: {
          amount: formvalues.amount,
          currency: formvalues.currency,
        },
        privateNote: formvalues.privateNote || undefined,
        orderNumber: formvalues.orderNumber || undefined,
      }

      if (temporaryCredit.isAppliedFromBalance) {
        const companyCreditEvents = formvalues.client?.company?.credit?.historyEvents
        const timestamp = new Date(new Date().toISOString()).toLocaleString()

        const newEvent = `${currentUser?.clientAddress?.personName} applied ${temporaryCredit.creditAmount.amount} ${temporaryCredit.creditAmount.currency} | ${timestamp}`

        temporaryCredit = {
          ...temporaryCredit,
          historyEvents: companyCreditEvents.length > 0 ? companyCreditEvents.concat(newEvent) : [newEvent],
        }
      }

      if (formvalues.gst)
        temporaryCredit = {
          ...temporaryCredit,
          taxGst: {
            amount: formvalues.gst,
            currency: formvalues.currency,
          },
        }

      if (formvalues.pst)
        temporaryCredit = {
          ...temporaryCredit,
          taxPst: {
            amount: formvalues.pst,
            currency: formvalues.currency,
          },
        }

      // Update credit information
      if (credit?.id) {
        await updateCredit({ ...temporaryCredit, creditId: credit.id })
      } else {
        await createCredits(temporaryCredit)
      }

      setIsCreditCreating(false)
      setShowCreditDrawer(false)
    },
  })

  useEffect(() => {
    formik.handleReset()
    if (clients?.length > 0) refetchClients()
  }, [])

  useEffect(() => {
    if (formik.values.isAppliedFromBalance) {
      const event = {
        target: {
          value: formik.values.client?.company?.credit?.total?.amount,
          name: "amount",
        },
      }
      formik.handleChange(event)
    } else if (formik.touched.isAppliedFromBalance) {
      const event = {
        target: {
          value: 0,
          name: "amount",
        },
      }
      formik.handleChange(event)
    }
  }, [formik.values.isAppliedFromBalance])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <CustomDatePicker
            label={t("addCreditDrawer.form.fields.creditDate.label")}
            name="creditDate"
            value={formik.values.creditDate}
            disablePast
            error={formik.touched.creditDate && formik.errors.creditDate}
            onChange={(value) => formik.setFieldValue("creditDate", value)}
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <ClientsAutocomplete formik={formik} clients={clients} />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField id="orderNumber" variant="outlined" size="small" value={formik.values.orderNumber || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} label={t("addCreditDrawer.form.fields.orderNumber.label")} />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            id="title"
            variant="outlined"
            label={t("addCreditDrawer.form.fields.title.label")}
            size="small"
            value={formik.values.title || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && formik.errors.title !== undefined}
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField id="description" variant="outlined" label={t("addCreditDrawer.form.fields.description.label")} size="small" value={formik.values.description || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        </Box>
        {formik.values.client?.company?.credit?.total?.amount > 0 && formik.values.currency === formik.values.client?.company?.credit?.total?.currency && (
          <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
            {t("addCreditDrawer.form.fields.applyFromBalance.label")}
            <Checkbox
              id="isAppliedFromBalance"
              error={formik.touched.isAppliedFromBalance && formik.errors.isAppliedFromBalance !== undefined}
              variant="outlined"
              size="small"
              value={formik.values.isAppliedFromBalance || false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.isAppliedFromBalance && formik.errors.isAppliedFromBalance && <FormHelperText style={{ color: "red" }}>{t("addCreditDrawer.form.errors.balanceExceeds")}</FormHelperText>}
          </Box>
        )}
        <Box component="div" sx={(theme) => ({ display: "flex", marginBottom: theme.spacing(1) })}>
          <TextField
            sx={(theme) => ({ marginRight: theme.spacing(1) })}
            id="amount"
            variant="outlined"
            label={t("addCreditDrawer.form.fields.amount.label")}
            size="small"
            value={formik.values.amount || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.amount && formik.errors.amount !== undefined}
            inputMode="numeric"
            type="number"
            inputProps={{ min: 0 }}
          />
          <FormControl fullWidth error={formik.touched?.declaredValue?.currency && formik.errors?.currency}>
            <InputLabel />
            {currencies && (
              <Select name="currency" onChange={formik.handleChange} value={formik.values?.currency || "CAD"} size="small" error={formik.touched.currency && formik.errors.currency !== undefined}>
                {currencies &&
                  currencies.map((currency, index) => (
                    <MenuItem key={currency.code.concat(index)} value={currency.code}>
                      {currency.code}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            id="gst"
            inputMode="numeric"
            type="number"
            inputProps={{ min: 0 }}
            variant="outlined"
            label={t("addCreditDrawer.form.fields.gst.label")}
            size="small"
            value={formik.values.gst || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            id="pst"
            inputMode="numeric"
            type="number"
            inputProps={{ min: 0 }}
            variant="outlined"
            label={t("addCreditDrawer.form.fields.pst.label")}
            size="small"
            value={formik.values.pst || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            id="total"
            inputMode="numeric"
            type="number"
            inputProps={{ min: 0 }}
            variant="outlined"
            label={t("addCreditDrawer.form.fields.total.label")}
            size="small"
            value={(Number(formik.values.gst) + Number(formik.values.pst) + Number(formik.values.amount)).toFixed(2) || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField id="privateNote" variant="outlined" label={t("addCreditDrawer.form.fields.privateNote.label")} size="small" value={formik.values.privateNote || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        </Box>
      </form>
    </Box>
  )
}

AddCreditDrawer.defaultProps = {
  onSubmitEvent: undefined,
  clientEmailAddress: undefined,
  setIsCreditCreating: () => {},
  credit: {},
}

AddCreditDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setIsCreditCreating: PropTypes.instanceOf(Object),
  setShowCreditDrawer: PropTypes.func.isRequired,
  clientEmailAddress: PropTypes.string,
  credit: PropTypes.shape({
    id: PropTypes.string,
    creditDate: PropTypes.string,
    client: PropTypes.string,
    orderNumber: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    privateNote: PropTypes.string,
    creditAmount: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
    taxGst: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
    taxPst: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
  }),
}

export default AddCreditDrawer
