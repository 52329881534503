// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import ClientDetailsSummaryCard from "../../../client-details-summary-card"
import { SummaryStatusVariantsEnum } from "../../../client-details-summary-card/components/summary-status"

function ClientPricingGroupCard({ pricingGroupName, defaultToSuccessStatus, handleClientPricingGroupCardClick }) {
  const { t } = useTranslation("clients")
  const customTheme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToSuccessStatus) return SummaryStatusVariantsEnum.success
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <ClientDetailsSummaryCard title={t("clientDetails.clientDetailsInformations.clientPricingGroupCard.title")} handleClick={handleClientPricingGroupCardClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={customTheme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
          {pricingGroupName}
        </Typography>
      </Box>
    </ClientDetailsSummaryCard>
  )
}

ClientPricingGroupCard.propTypes = {
  pricingGroupName: PropTypes.instanceOf(Object),
  defaultToSuccessStatus: PropTypes.bool,
  handleClientPricingGroupCardClick: PropTypes.func,
}

ClientPricingGroupCard.defaultProps = {
  handleClientPricingGroupCardClick: () => {},
  defaultToSuccessStatus: false,
  pricingGroupName: undefined,
}

export default ClientPricingGroupCard
