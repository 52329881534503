// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

// MUI
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"

function MoreMenu({ optionsList }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    if (event) event.stopPropagation()
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event, index) => {
    if (optionsList[index]) optionsList[index].handleClick(event)
    event.stopPropagation()
    handleClose(event)
  }

  return (
    <Box component="span">
      <IconButton onClick={handleClick}>
        <MoreVertIcon color="black" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "auto",
            width: "auto",
            position: "absolute",
          },
        }}
      >
        {optionsList.map((option, index) => (
          <Box key={option.label} onClick={(event) => handleMenuItemClick(event, index)}>
            <MenuItem disabled={!!option.disabled}>{option.label}</MenuItem>
          </Box>
        ))}
      </Menu>
    </Box>
  )
}
MoreMenu.propTypes = {
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      handleClick: PropTypes.func,
    })
  ).isRequired,
}

export default MoreMenu
