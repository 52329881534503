import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React, { useState } from "react"
// import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"

import { useTranslation } from "react-i18next"

import Attachments from "./components/attachments"
import FileUploader from "../../../file-uploader"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useAddAttachmentFileMutation } from "./slice"
import SummaryCard from "../../../summary-card"
import { pxToThemeSpacing } from "../../../../soe-theme/src/utils"

function AttachmentsDrawer({ selectedOrderId, showAttachmentsDrawer, setShowAttachmentsDrawer }) {
  const { t } = useTranslation("orders")

  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  const [addAttachmentFile] = useAddAttachmentFileMutation()

  return (
    <SoeDrawer showDrawer={showAttachmentsDrawer} setShowDrawer={setShowAttachmentsDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("attachmentsDrawer.title")}
          setShowDrawer={() => {
            setShowAttachmentsDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <Stack direction="column">
            <SummaryCard
              sx={() => ({
                cursor: "pointer",
              })}
              summaryStatusProps={undefined}
              title={t("attachmentsDrawer.addAttachment.title")}
              handleClick={() => {}}
            >
              <Box sx={{ pt: pxToThemeSpacing(10) }}>
                <FileUploader name="attachmentFile" files={files} setFiles={setFiles} signUrlMutation={addAttachmentFile} trigger={setIsUploading} invalidateTags={["Attachments"]} orderId={selectedOrderId} />
              </Box>
            </SummaryCard>
            <SummaryCard
              sx={() => ({
                cursor: "pointer",
              })}
              summaryStatusProps={undefined}
              title={t("attachmentsDrawer.attachments.title")}
              handleClick={() => {}}
            >
              <Attachments selectedOrderId={selectedOrderId} />
            </SummaryCard>
          </Stack>
          {/* <Skeleton variant="rectangular" height={50} width="100%" /> */}
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: () => setShowAttachmentsDrawer(false),
              label: t("attachmentsDrawer.actions.close"),
              variant: "contained",
              loading: isUploading,
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

AttachmentsDrawer.propTypes = {
  selectedOrderId: PropTypes.string.isRequired,
  showAttachmentsDrawer: PropTypes.bool.isRequired,
  setShowAttachmentsDrawer: PropTypes.func.isRequired,
}

export default AttachmentsDrawer
