// REACT
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"
import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"

// Others
import Currency from "../../../../../../../currency"
import RateRequestSummaryCard from "../../../../../../../rate-request-summary-card"
import TranslationService from "../../../../../../../../services/translation"

import { AuditItemSectionProps } from "../../../../../../../../models/audit"

function CarrierDetailsInvoiceItem({ rate }) {
  const { i18n, t } = useTranslation("audits")
  const customTheme = useTheme()

  const sanitizeFloat = (value) => (Number.isNaN(parseFloat(value)) ? 0 : parseFloat(value).toFixed(2))

  return (
    <RateRequestSummaryCard title={t("auditDetails.auditItemDetails.carrierDetails.invoiceItem.title")} sx={{ height: 1 }}>
      <Box mb={pxToThemeSpacing(10)}>
        <Stack direction="column">
          <Box>
            {rate && (
              <Box>
                <Grid container>
                  <Grid item xs={4}>
                    <Box />
                  </Grid>
                  <Grid item xs={4} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.carrierDetails.invoiceItem.headers.invoice")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.carrierDetails.invoiceItem.headers.difference")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Box />
                  </Grid>
                  <Grid item xs={4} pb={pxToThemeSpacing(6)} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", borderBottom: 1 }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {`(${rate.totals.total.carrierInvoice.actual.currency})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", borderBottom: 1 }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {`(${rate.totals.total.carrierInvoice.actual.currency})`}
                    </Typography>
                  </Grid>
                </Grid>
                {rate.charges?.map((charge, index) => {
                  return (
                    <Box key={"".concat(index)} mt={pxToThemeSpacing(6)} mb={pxToThemeSpacing(6)} sx={(theme) => ({ height: theme.utils.pxToRem(22) })}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography noWrap variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                            {TranslationService.getLabelFromLocale(i18n.language, charge.name)} {charge.type === "tax" && `(${(charge.percentage * 100).toFixed(3)}%)`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                          {charge.carrierInvoice?.actual?.amount !== undefined && (
                            <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                              <Currency amount={sanitizeFloat(charge.carrierInvoice.actual.amount)} currency={charge.carrierInvoice.actual.currency} currencyPosition="none" showSymbol={false} />
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={4} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                          <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                            <Currency
                              amount={sanitizeFloat(charge.carrierInvoice?.actual?.amount) - sanitizeFloat(charge.account?.declared?.amount)}
                              currency="CAD"
                              currencyPosition="none"
                              color={sanitizeFloat(charge.carrierInvoice?.actual?.amount) - sanitizeFloat(charge.account?.declared?.amount) >= 0 ? "inherit" : customTheme.palette.contentAlertError.main}
                              showSymbol={false}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )
                })}
                <Grid container mt={pxToThemeSpacing(6)}>
                  <Grid item xs={4}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.carrierDetails.invoiceItem.carrierFx", { currency: rate.totals.total.carrierInvoice.actual.currency })}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} pr={pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                    {rate.totals?.total?.carrierInvoice?.actual?.amount !== undefined && (
                      <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                        <Currency amount={sanitizeFloat(rate.totals.total.carrierInvoice.actual.amount)} currency={rate.totals.total.carrierInvoice.actual.currency} currencyPosition="none" showSymbol={false} />
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} pr={pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                    {rate.totals?.total?.carrierInvoice?.actual?.amount !== undefined && rate.totals?.total?.account?.declared?.amount !== undefined && (
                      <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                        <Currency
                          amount={sanitizeFloat(rate.totals.total.carrierInvoice.actual.amount) - sanitizeFloat(rate.totals.total.account.declared.amoun)}
                          currency="CAD"
                          currencyPosition="none"
                          color={sanitizeFloat(rate.totals.total.carrierInvoice.actual.amount) - sanitizeFloat(rate.totals.total.account.declared.amount) >= 0 ? "inherit" : customTheme.palette.contentAlertError.main}
                          showSymbol={false}
                        />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container mt={pxToThemeSpacing(6)}>
                  <Grid item xs={4}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })} />
                  </Grid>
                  <Grid item xs={4} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })} />
                  </Grid>
                  <Grid item xs={4} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })} />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </RateRequestSummaryCard>
  )
}

CarrierDetailsInvoiceItem.propTypes = {
  rate: AuditItemSectionProps.isRequired,
}

export default CarrierDetailsInvoiceItem
