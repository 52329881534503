import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "carrierServiceLogo", slot: "Root" })(() => ({}))

export const CustomCard = styled(Card, { name: "CustomCard", slot: "Root" })(() => ({
  backgroundColor: "transparent",
  border: "none",
  boxShadow: "none",
}))

export const CustomCardContent = styled(Card, { name: "CustomCardContent", slot: "Root" })(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.backgroundNeutralTransparent.main,
  border: 0,
  display: "flex",
  flexDirection: "column",
}))

export const CustomLogoBox = styled(Box, { label: "CustomBox", slot: "Root" })(({ theme }) => ({
  "& svg": {
    verticalAlign: "middle",
    height: theme.utils.pxToRem(36),
    width: "auto",
    maxWidth: theme.utils.pxToRem(150),
  },
}))
