// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Others
import CarrierDetailsSummaryCard from "../carrier-details-summary-card"
import { SummaryStatusVariantsEnum } from "../carrier-details-summary-card/components/summary-status"

function CarrierAccountCard({ carrierAccount, carrierGeneralInfoSorted, handleCarrierAccountCardClick }) {
  const getSummaryStatus = () => {
    if (carrierAccount && carrierAccount.isDisabled === true) return SummaryStatusVariantsEnum.disabled
    if (carrierAccount) return SummaryStatusVariantsEnum.success
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  const getAccountFieldDescription = (account, carrier) => {
    const accountField = carrier.accountFields.find((field) => field.name === account.name)
    return accountField ? accountField.description : undefined
  }

  return (
    <CarrierDetailsSummaryCard
      title={carrierAccount.name}
      handleClick={handleCarrierAccountCardClick}
      summaryStatusProps={summaryStatusProps}
      isDisabled={carrierAccount.isDisabled && carrierAccount.isDisabled === true}
      isTest={carrierAccount.isTest && carrierAccount.isTest === true}
    >
      {carrierAccount && (
        <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
          {carrierAccount.accountDetails &&
            carrierAccount.accountDetails.map(
              (accDetails, accDetailsIndex) =>
                accDetails.isDisplayed &&
                getAccountFieldDescription(accDetails, carrierGeneralInfoSorted) && (
                  <Typography key={`${accDetailsIndex.toString()}`} noWrap sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
                    {getAccountFieldDescription(accDetails, carrierGeneralInfoSorted)}: {accDetails.value}
                  </Typography>
                )
            )}
        </Box>
      )}
    </CarrierDetailsSummaryCard>
  )
}

CarrierAccountCard.propTypes = {
  carrierAccount: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    accountDetails: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        isDisplayed: PropTypes.bool,
        isSecret: PropTypes.bool,
      })
    ),
    isDisabled: PropTypes.bool,
    isTest: PropTypes.bool,
  }).isRequired,
  carrierGeneralInfoSorted: PropTypes.instanceOf(Object).isRequired,
  handleCarrierAccountCardClick: PropTypes.func,
}

CarrierAccountCard.defaultProps = {
  handleCarrierAccountCardClick: () => {},
}

export default CarrierAccountCard
