/* eslint-disable react/forbid-prop-types */
// REACT
import PropTypes from "prop-types"
import React from "react"
// MUI
import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"

function BulkUpload({ bulkUpload }) {
  const bulkUploadStatus = {
    processed: <Chip label="processed" size="medium" color="primary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />,
    processing: <Chip label="processing" size="medium" color="secondary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />,
  }

  return (
    <TableRow hover component="tr" sx={(theme) => ({ backgroundColor: theme.palette.contentNeutralPrimaryReverse.main })}>
      <TableCell component="td" scope="row">
        {bulkUpload.filename}
      </TableCell>
			<TableCell component="td" scope="row">
				{bulkUpload.createdDate}
			</TableCell>
			<TableCell component="td" scope="row">
        {bulkUploadStatus[bulkUpload.status]}
      </TableCell>
			<TableCell component="td" scope="row">
				{bulkUpload.processedItems}
			</TableCell>
			<TableCell component="td" scope="row">
				{bulkUpload.failedItems}
			</TableCell>
			<TableCell component="td" scope="row">
				<a href = {bulkUpload.url} target="_blank" rel="noreferrer">Download</a>
			</TableCell>
    </TableRow>
  )
}

BulkUpload.propTypes = {
  bulkUpload: PropTypes.shape({
    pk: PropTypes.string,
    status: PropTypes.string,
    createdDate: PropTypes.string,
    companyId: PropTypes.string,
    filename: PropTypes.string,
		processedItems: PropTypes.string,
		failedItems: PropTypes.string,
		url: PropTypes.string,
  }).isRequired,
}

export default BulkUpload
