// REACT
import React from "react"

// MUI
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

// Others
import AppConfig from "../../../../utils/app-config"

import { isProd } from "../../../../utils/get-environment"

import "./style.scss"

function AppInfo() {
  const CustomChip = styled(Chip, { label: "CustomChip", slot: "Root" })(({ theme }) => ({
    marginLeft: theme.spacing(1),
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
  }))

  return (
    <Box component="div">
      {!isProd() && <CustomChip label={`v${AppConfig.app.version}`} size="small" variant="outlined" sx={{ textTransform: "none" }} />}
      <CustomChip label={AppConfig.app.environment} size="small" variant="outlined" />
    </Box>
  )
}

export default AppInfo
