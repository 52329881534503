import PropTypes from "prop-types"

export const PackagingTypeProps = PropTypes.shape({
  id: PropTypes.string,
  isEnvelope: PropTypes.bool,
  name: PropTypes.string,
  code: PropTypes.string,
  dimensions: PropTypes.shape({
    length: PropTypes.shape({ isFixed: PropTypes.bool, value: PropTypes.shape({ metric: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), imperial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }) }),
    width: PropTypes.shape({ isFixed: PropTypes.bool, value: PropTypes.shape({ metric: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), imperial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }) }),
    height: PropTypes.shape({ isFixed: PropTypes.bool, value: PropTypes.shape({ metric: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), imperial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }) }),
  }),
})

export const PieceProps = PropTypes.shape({
  packagingType: PackagingTypeProps,
  quantity: PropTypes.number,
  length: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  weight: PropTypes.number,
})
