// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"

import { CustomBox, MuiWrapper } from "./style"

function SoeDrawerHeader({ title, setShowDrawer, preventClosing, ...muiBoxProps }) {
  return (
    <MuiWrapper component="header" {...muiBoxProps}>
      <CustomBox>
        <Typography variant="headingH6" sx={(theme) => ({ color: theme.palette.contentNeutralPrimary.main, fontWeight: theme.typography.fontWeightBold })}>
          {title}
        </Typography>
        {!preventClosing && (
          <IconButton
            onClick={() => {
              setShowDrawer(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </CustomBox>
    </MuiWrapper>
  )
}

SoeDrawerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  setShowDrawer: PropTypes.func,
  preventClosing: PropTypes.bool,
}

SoeDrawerHeader.defaultProps = {
  setShowDrawer: () => {},
  preventClosing: false,
}

export default SoeDrawerHeader
