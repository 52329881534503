const AppConfig = {
  app: {
    version: "1.1.3",
    environment: "PROD",
    nodeEnv: "production",
  },
  aws: {
    cognito: {
      userPoolId: "ca-central-1_Qh87G1vny",
      clientId: "1htev00tp3v8sv6lmkd6u57fhh",
      hostedUiUrl: "https://saveonexpress.auth.ca-central-1.amazoncognito.com",
    },
    restApi: {
      apiUrl: "https://api.saveonexpress.ca",
    },
    graphql: {
      apiUrl: process.env.REACT_APP_GRAPHQL_API_URL,
      apiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
      region: process.env.REACT_APP_GRAPHQL_API_REGION,
    },
  },
  features: {
    showOrderTypeColumn: "false" === "true",
    emailInvoiceCCList: "finance@inex.ca",
  },
  logRocket: {
    id: "ylvrtg/admin-zl7as",
  },
}

export default AppConfig
