const TranslationService = {
  getLabelFromLocale(locale, labels) {
    if (labels) {
      if (labels[locale]) return labels[locale]

      // Fallbacks
      if (labels.en) return labels.en
      if (labels.fr) return labels.fr
    }
    // @todo: Manage Exceptions
    return undefined
  },
}

export default TranslationService
