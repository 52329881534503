import restApiSlice from "../rest-api/slice"

export const versionCheckRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVersionCheck: builder.query({
      query: (currentVersion) => ({ url: `/version/admin/${currentVersion}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetVersionCheckQuery } = versionCheckRestApiSlice

export default versionCheckRestApiSlice.reducer
