// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import ClientDetailsSummaryCard from "../../../client-details-summary-card"
import DateTimeService from "../../../../../../../../services/date-time"

import { SummaryStatusVariantsEnum } from "../../../client-details-summary-card/components/summary-status"

function ClientContactInformationCard({ clientAddress, clientCreatedDate, defaultToSuccessStatus, handleClientContactInformationCardClick }) {
  const { t } = useTranslation("clients")
  const customTheme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToSuccessStatus) return SummaryStatusVariantsEnum.success
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <ClientDetailsSummaryCard title={t("clientDetails.clientDetailsInformations.contactInformationCardTitle")} handleClick={handleClientContactInformationCardClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={customTheme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {clientAddress?.companyName && (
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {clientAddress.companyName}
          </Typography>
        )}
        {clientAddress?.personName && (
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {clientAddress.personName}
          </Typography>
        )}
        {clientAddress?.addressLine1 && (
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {clientAddress.addressLine1}
          </Typography>
        )}
        {clientAddress?.addressLine2 && (
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {clientAddress.addressLine2}
          </Typography>
        )}
        {(clientAddress?.city || clientAddress?.provinceCode || clientAddress?.countryCode) && (
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {`${clientAddress?.city || ""}${clientAddress?.city && clientAddress?.provinceCode ? ", " : ""}${clientAddress?.provinceCode || ""}${(clientAddress?.city || clientAddress?.provinceCode) && clientAddress?.countryCode ? ", " : ""}${clientAddress?.countryCode || ""
              }`}
          </Typography>
        )}
        {clientAddress?.postalCode && (
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {clientAddress.postalCode}
          </Typography>
        )}
        <Box
          mt={customTheme.spacing(1)}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {clientAddress?.phoneNumber && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {clientAddress.phoneNumber}
            </Typography>
          )}
          {clientAddress?.emailAddress && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {clientAddress.emailAddress}
            </Typography>
          )}
          {clientCreatedDate && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {DateTimeService.toLocaleDateString(clientCreatedDate)}
            </Typography>
          )}
        </Box>
      </Box>
    </ClientDetailsSummaryCard>
  )
}

ClientContactInformationCard.propTypes = {
  clientAddress: PropTypes.shape({
    personName: PropTypes.string,
    companyName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    provinceCode: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    phoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
  }).isRequired,
  clientCreatedDate: PropTypes.string,
  defaultToSuccessStatus: PropTypes.bool,
  handleClientContactInformationCardClick: PropTypes.func,
}

ClientContactInformationCard.defaultProps = {
  handleClientContactInformationCardClick: () => { },
  defaultToSuccessStatus: false,
  clientCreatedDate: "",
}

export default ClientContactInformationCard
