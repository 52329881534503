// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Masonry from "@mui/lab/Masonry"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom theme
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"

// Others
import CarrierServiceCard from "../carrier-service-card"
import CarrierServiceDrawer from "../carrier-service-drawer"
import { useGetCarrierServicesQuery } from "../../slice"
import { useGetLanguagesQuery } from "../../../../services/rest-api/slice"

function CarrierServices({ carrier }) {
  const customTheme = useTheme()
  const { t } = useTranslation("carriers")

  const masonryNumberColumns = 4

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  const [showCarrierServicesDrawer, setShowCarrierServicesDrawer] = useState(false)
  const [carrierServiceIndex, setCarrierServiceIndex] = useState()
  const [onSubmitEvent, setSubmitEvent] = useState()
  const [onDisableEvent, setDisableEvent] = useState()

  const { data: languages } = useGetLanguagesQuery()
  const { data: carrierServices, isFetching } = useGetCarrierServicesQuery({ code: carrier.code }, { skip: !isAccordionExpanded })

  const carrierGeneralInfoSorted = { ...carrier }
  if (carrierGeneralInfoSorted && carrierGeneralInfoSorted.accountFields) carrierGeneralInfoSorted.accountFields = carrier.accountFields.slice().sort((a, b) => a.ordinal - b.ordinal)

  const handleAccordionChange = (_, expanded) => {
    setIsAccordionExpanded(expanded)
  }

  const handleAddCarrierServiceClick = (event) => {
    event.stopPropagation()
    setSubmitEvent()
    setCarrierServiceIndex()
    setShowCarrierServicesDrawer(true)
  }

  const handleCarrierServiceCardClick = () => {
    setSubmitEvent()
    setShowCarrierServicesDrawer(true)
  }

  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary>
        <Grid container>
          <Grid container item xs={11} alignItems="center">
            <Typography component="div" ml={customTheme.utils.pxToThemeSpacing(14)}>
              <Box fontWeight="fontWeightBold" display="inline">
                {t("carrierDetails.carrierServices.title")}
              </Box>
            </Typography>
            {carrier.services.new > 0 && (
              <Chip label={`${carrier.services.new} ${t("carriersManagement.chipStatus.newItems.label")}`} size="small" color="error" sx={(theme) => ({ ml: theme.utils.pxToThemeSpacing(14), textTransform: "lowercase" })} />
            )}
          </Grid>
          <Grid container item justifyContent="flex-end" alignItems="center" xs={1}>
            <Button variant="outlined" size="medium" color="primary" onClick={handleAddCarrierServiceClick}>
              {t("carrierDetails.carrierServices.actions.add.label")}
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {isFetching ? (
          <Masonry columns={masonryNumberColumns} sx={{ alignContent: "flex-start" }}>
            {Array(masonryNumberColumns)
              .fill()
              .map((_, index) => {
                return <Skeleton key={"".concat(index)} variant="rectangular" height={75} />
              })}
          </Masonry>
        ) : (
          carrierServices && (
            <Masonry columns={masonryNumberColumns} sx={{ alignContent: "flex-start" }}>
              {carrierServices.map((carrierService, index) => {
                return <CarrierServiceCard key={"".concat(index)} carrierService={carrierService} handleCarrierServiceCardClick={() => handleCarrierServiceCardClick(setCarrierServiceIndex(index))} />
              })}
            </Masonry>
          )
        )}
      </AccordionDetails>
      <SoeDrawer showDrawer={showCarrierServicesDrawer} setShowDrawer={setShowCarrierServicesDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("carrierDetails.carrierServicesDrawer.title")}
            setShowDrawer={() => {
              setShowCarrierServicesDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CarrierServiceDrawer
              carrierGeneralInfoCode={carrier.code}
              carrierServices={carrierServices}
              carrierServiceIndex={carrierServiceIndex}
              languages={languages}
              onSubmitEvent={onSubmitEvent}
              onDisableEvent={onDisableEvent}
              setShowCarrierServicesDrawer={setShowCarrierServicesDrawer}
              setDisableEvent={setDisableEvent}
            />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setDisableEvent,
                label:
                  carrierServices && carrierServices[carrierServiceIndex] && carrierServices[carrierServiceIndex].isDisabled && carrierServices[carrierServiceIndex].isDisabled === true
                    ? t("carrierDetails.carrierServicesDrawer.actions.enable.label")
                    : t("carrierDetails.carrierServicesDrawer.actions.disable.label"),
                variant: "outlined",
                color: "error",
              },
              {
                action: setSubmitEvent,
                label: t("carrierDetails.carrierServicesDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Accordion>
  )
}

CarrierServices.propTypes = {
  carrier: PropTypes.shape({
    accountFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    charges: PropTypes.shape({
      count: PropTypes.number,
      new: PropTypes.number,
    }),
    code: PropTypes.string,
    fullName: PropTypes.instanceOf(Object),

    logo: PropTypes.string,
    services: PropTypes.shape({
      count: PropTypes.number,
      new: PropTypes.number,
    }),
    shortName: PropTypes.instanceOf(Object),
    isDisabled: PropTypes.bool,
  }).isRequired,
}

export default CarrierServices
