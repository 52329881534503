// React
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import { Route, Routes } from "react-router-dom"
import { useDispatch } from "react-redux"

// Components
import AlertsExample from "./soe-theme/src/demo/alert"
import App from "./components/app"
import Audits from "./components/audits"
import AuditDetails from "./components/audits/components/audit-details"
import AuditItemDetails from "./components/audits/components/audit-details/components/audit-item-details"
import ButtonExample from "./soe-theme/src/demo/button"
import Carriers from "./components/carriers"
import ClientDetails from "./components/clients/components/client-details"
import Clients from "./components/clients"
import Companies from "./components/pages/companies"
import CompanyDetails from "./components/pages/companies/components/company-details"
import DataDisplayExample from "./soe-theme/src/demo/data-display"
import DataGridExample from "./soe-theme/src/demo/data-grid"
import Home from "./components/home"
import InputsExample from "./soe-theme/src/demo/inputs"
import Invoices from "./components/invoices"
import ReadyToInvoice from "./components/ready-to-invoice"
import Orders from "./components/orders"
import Pricing from "./components/pricing"
import NotFound from "./components/not-found"
import SoeIcons from "./soe-theme/src/demo/icons"
import SurfaceExample from "./soe-theme/src/demo/surface"
import TypographyExample from "./soe-theme/src/demo/typography"
import UserContext from "./services/user/context"
import Users from "./components/users"
import Settings from "./components/settings"
import UserProfile from "./components/user-profile"
import Bulk from "./components/bulk"

import { ROUTES } from "./constant"
import { setShowAuthenticator } from "./reducers/authenticatorReducer"

function AppRoutes() {
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<App />}>
        <Route index element={<Home />} />
        <Route path={ROUTES.DEMO}>
          <Route index element={<TypographyExample />} />
          <Route path={ROUTES.DEMO_BUTTON} element={<ButtonExample />} />
          <Route path={ROUTES.DEMO_ICONS} element={<SoeIcons />} />
          <Route path={ROUTES.DEMO_TYPOGRAPHY} element={<TypographyExample />} />
          <Route path={ROUTES.DEMO_INPUTS} element={<InputsExample />} />
          <Route path={ROUTES.DEMO_ALERTS} element={<AlertsExample />} />
          <Route path={ROUTES.DEMO_SURFACE} element={<SurfaceExample />} />
          <Route path={ROUTES.DEMO_DATA_DISPLAY} element={<DataDisplayExample />} />
          <Route path={ROUTES.DEMO_DATA_GRID} element={<DataGridExample />} />
        </Route>
        <Route path={ROUTES.SETTINGS}>
          <Route
            index
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.USER_PROFILE}>
          <Route
            index
            element={
              <RequireAuth>
                <UserProfile />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.USERS}>
          <Route
            index
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.CLIENTS}>
          <Route
            index
            element={
              <RequireAuth>
                <Clients />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.CLIENTS.concat("/:clientId")}
            element={
              <RequireAuth>
                <ClientDetails />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.COMPANIES}>
          <Route
            index
            element={
              <RequireAuth>
                <Companies />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.COMPANIES.concat("/:companyId")}
            element={
              <RequireAuth>
                <CompanyDetails />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.ORDERS}>
          <Route
            index
            element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.INVOICES}>
          <Route
            index
            element={
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.READY_TO_INVOICE}>
          <Route
            index
            element={
              <RequireAuth>
                <ReadyToInvoice />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.AUDITS}>
          <Route
            index
            element={
              <RequireAuth>
                <Audits />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.AUDITS.concat("/:auditId")}
            element={
              <RequireAuth>
                <AuditDetails />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.AUDITS.concat("/:auditId/items/:auditItemId")}
            element={
              <RequireAuth>
                <AuditItemDetails />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.CARRIERS}>
          <Route
            index
            element={
              <RequireAuth>
                <Carriers />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.PRICING}>
          <Route
            index
            element={
              <RequireAuth>
                <Pricing />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ROUTES.BULK}>
          <Route
            index
            element={
              <RequireAuth>
                <Bulk />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

function RequireAuth({ children }) {
  const dispatch = useDispatch()

  const { currentUser } = useContext(UserContext)

  useEffect(() => {
    if (!currentUser) dispatch(setShowAuthenticator(true))
  }, [currentUser])

  return currentUser ? children : <div />
}

RequireAuth.propTypes = {
  children: PropTypes.node,
}

RequireAuth.defaultProps = {
  children: [],
}

export default AppRoutes
