// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

// Others
import PricingMargins from "../pricing-margins"
import TranslationService from "../../../../services/translation"
import { useGetMarginsQuery } from "../../slice"

function ChargeType({ pricingGroupId, pricingPlanId, serviceId, chargeType }) {
  const { t, i18n } = useTranslation("pricing")

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  const { data: margins, isFetching } = useGetMarginsQuery({ pricingGroupId, pricingPlanId, serviceId, chargeId: chargeType.chargeType }, { skip: !isAccordionExpanded })

  const handleAccordionChange = (_, expanded) => {
    setIsAccordionExpanded(expanded)
  }

  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary>
        <Grid container alignItems="center">
          <Grid container item xs={12} alignItems="center">
            <Typography component="div">
              {`${t("chargeType.fields.label")}: `}
              <Box fontWeight="fontWeightBold" display="inline">
                {TranslationService.getLabelFromLocale(i18n.language, chargeType.fullName)}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {!margins || isFetching ? (
          <Skeleton variant="rectangular" width="100%" height={100} />
        ) : (
          <PricingMargins pricingGroupId={pricingGroupId} pricingPlanId={pricingPlanId} serviceId={serviceId} chargeTypeId={chargeType.chargeType} pricingMargins={margins} />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

ChargeType.propTypes = {
  pricingGroupId: PropTypes.string.isRequired,
  pricingPlanId: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
  chargeType: PropTypes.shape({
    chargeType: PropTypes.string,
    fullName: PropTypes.shape({}),
    shortName: PropTypes.shape({}),
    pricingMargins: PropTypes.arrayOf(Object),
  }).isRequired,
}

export default ChargeType
