import Chip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

const CustomChip = styled(Chip, { name: "Chip", slot: "Root" })(({ theme }) => ({
  color: theme.palette.backgroundNeutralGround.main,
  textTransform: "none",
  borderRadius: 6,
}))

export default CustomChip
