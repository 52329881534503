import FormControlLabel from "@mui/material/FormControlLabel"
import PropTypes from "prop-types"
import Radio from "@mui/material/Radio"
import React from "react"

import { useTranslation } from "react-i18next"

import SummaryCard from "../../../../../summary-card"
import CreditCard from "../credit-card"

function CreditCardList({ activePaymentMethods }) {
  const { t } = useTranslation("invoices")

  return (
    <SummaryCard title={t("creditCardList.title")} handleClick={() => {}}>
      {activePaymentMethods.map((paymentMethod) => {
        return <FormControlLabel key={paymentMethod.id} value={paymentMethod?.id} control={<Radio />} label={<CreditCard paymentMethod={paymentMethod} />} />
      })}
    </SummaryCard>
  )
}

CreditCardList.propTypes = {
  activePaymentMethods: PropTypes.arrayOf(Object).isRequired,
}

export default CreditCardList
