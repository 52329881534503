import Chip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

const CustomChip = styled(Chip, { name: "Chip", slot: "Root" })(({ theme }) => ({
  textTransform: "none",
  borderRadius: 12,
  marginRight: theme.spacing(theme.utils.pxToThemeSpacing(8)),
}))

export default CustomChip
