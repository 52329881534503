import React, { useState } from "react"

import Autocomplete from "@mui/material/Autocomplete"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined" // @todo: Currently not in our SOE icons, the one in Figma is not even the same as feathericons source
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Checkbox from "@mui/material/Checkbox"
import Container from "@mui/material/Container"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import FormLabel from "@mui/material/FormLabel"
import Grid from "@mui/material/Grid"
import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import SoeErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import MenuItem from "@mui/material/MenuItem"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import TextField from "@mui/material/TextField"

import "./styles.scss"

export default function InputsExample() {
  const autoCompleteListItemsExample = [
    { label: "Item1", size: 1 },
    { label: "Item2", size: 2 },
    { label: "Item3", size: 3 },
    { label: "Item4", size: 4 },
  ]
  const [age, setAge] = useState("")

  const handleChange = (event) => {
    setAge(event.target.value)
  }

  return (
    <Container maxWidth="fit-content">
      <List>
        <ListItem>
          <Link href="https://mui.com/components/TextField/" target="_blank">
            {"MUI component <TextField />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/FilledInput/" target="_blank">
            {"MUI API <FilledInput />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/FormControl/" target="_blank">
            {"MUI API <FormControl />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/FormControlUnstyled/" target="_blank">
            {"MUI API <FormControlUnstyled />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/FormHelperText/" target="_blank">
            {"MUI API <FormHelperText />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/Input/" target="_blank">
            {"MUI API <Input />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/InputAdornment/" target="_blank">
            {"MUI API <InputAdornment />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/InputBase/" target="_blank">
            {"MUI API <InputBase />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/InputLabel/" target="_blank">
            {"MUI API <InputLabel />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/InputUnstyled/" target="_blank">
            {"MUI API <InputUnstyled />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/OutlinedInput/" target="_blank">
            {"MUI API <OutlinedInput />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/material-ui/react-select/" target="_blank">
            {"MUI component <Select />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/material-ui/api/select/" target="_blank">
            {"MUI API <Select />"}
          </Link>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Material UI Input components" />
            <Box sx={{ width: "100%" }}>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - without icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <TextField label="outlined" variant="outlined" size="medium" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="outlined" variant="outlined" size="medium" disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="outlined" variant="outlined" size="medium" error />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" disabled={true} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" error />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="filled - medium - without icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <TextField label="filled" variant="filled" size="medium" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="filled" variant="filled" size="medium" disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="filled" variant="filled" size="medium" error />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" disabled={true} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" error/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="standard - medium - without icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <TextField label="standard" variant="standard" size="medium" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="standard" variant="standard" size="medium" disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="standard" variant="standard" size="medium" error />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" disabled={true} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" error />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - icon at start" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="medium"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="medium"
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="medium"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="medium"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" disabled={true} InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="filled - medium - icon at start" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="medium"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="medium"
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="medium"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="medium"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" disabled={true} InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="standard - medium - icon at start" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="medium"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="medium"
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="medium"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="medium"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" disabled={true} InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - icon at the end" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="medium"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="medium"
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="medium"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="medium"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" disabled={true} InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>

                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="filled - medium - icon at the end" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="medium"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="medium"
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="medium"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="medium"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" disabled={true} InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="medium" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="standard - medium - icon at the end" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="medium"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="medium"
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="medium"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="medium"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" InputProps={{
    startAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" disabled={true} InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="medium" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - without icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <TextField label="outlined" variant="outlined" size="small" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="outlined" variant="outlined" size="small" disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="outlined" variant="outlined" size="small" error />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" disabled={true} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" error />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="filled - small - without icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <TextField id="filled-basic" label="filled" variant="filled" size="small" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField id="filled-basic" label="filled" variant="filled" size="small" disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField id="filled-basic" label="filled" variant="filled" size="small" error />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField id="filled-basic" label="filled" variant="filled" size="small" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField id="filled-basic" label="filled" variant="filled" size="small" disabled={true} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField id="filled-basic" label="filled" variant="filled" size="small" error />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="standard - small - without icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <TextField id="standard-basic" label="standard" variant="standard" size="small" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField id="standard-basic" label="standard" variant="standard" size="small" disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField id="standard-basic" label="standard" variant="standard" size="small" error />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField id="standard-basic" label="standard" variant="standard" size="small" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField id="standard-basic" label="standard" variant="standard" size="small" disabled={true} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField id="standard-basic" label="standard" variant="standard" size="small" error />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - icon at start" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="small"
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="small"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="small"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" disabled={true} InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="filled - small - icon at start" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="small"
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="small"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="small"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="small" InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="small" disabled={true} InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="small" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="small" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="standard - small - icon at start" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="small"
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="small"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="small"
                          error
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="small" InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="small" disabled={true} InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="small" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="small" error InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined -small - icon at the end" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="small"
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="small"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="outlined"
                          variant="outlined"
                          size="small"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" disabled={true} InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
  />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
  />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="small" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
  />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="filled - small - icon at the end" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="small"
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="small"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="filled"
                          variant="filled"
                          size="small"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="small" InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="small" disabled={true} InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="small" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="filled" variant="filled" size="small" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="standard - small - icon at the end" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="small"
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="small"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="standard"
                          variant="standard"
                          size="small"
                          error
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SoeErrorOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="small" InputProps={{
    startAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="small" disabled={true} InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="small" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <AccountCircleOutlinedIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        <pre>
                          <code>{`<TextField label="standard" variant="standard" size="small" error InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <SoeErrorOutlineIcon />
      </InputAdornment>
    ),
  }}
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Box>
          </Card>
          <CardContent />
          <CardContent />
          <CardContent />
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Material UI Input components - AUTOCOMPLETE (medium size), CHECKBOX, RADIOBUTTON (all without icon, error or disabled)" />
            <Box sx={{ width: "100%" }}>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="Autocomplete, Checkbox, Radio" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <Autocomplete options={autoCompleteListItemsExample} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="autocomplete" />} />
                      </Grid>
                      <Grid item xs={4}>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox defaultChecked />} label="checkbox" />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl>
                          <FormLabel>radiobutton</FormLabel>
                          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="radio-1" name="radio-buttons-group">
                            <FormControlLabel value="radio-1" control={<Radio />} label="radio-1" />
                            <FormControlLabel value="radio-2" control={<Radio />} label="radio-2" />
                            <FormControlLabel value="radio-other" control={<Radio />} label="radio-other" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<Autocomplete
  options={autoCompleteListItemsExample}
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="autocomplete" />}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<FormGroup>
  <FormControlLabel control={<Checkbox defaultChecked />} label="checkbox" />
</FormGroup>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<FormControl>
  <FormLabel>radiobutton</FormLabel>
  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="radio-1" name="radio-buttons-group">
    <FormControlLabel value="radio-1" control={<Radio />} label="radio-1" />
    <FormControlLabel value="radio-2" control={<Radio />} label="radio-2" />
    <FormControlLabel value="radio-other" control={<Radio />} label="radio-other" />
  </RadioGroup>
</FormControl>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Box>
          </Card>
          <CardContent />
          <CardContent />
          <CardContent />
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Material UI Input components - TEXTFIELD with text-helper (without icon, error or disabled)" />
            <Box sx={{ width: "100%" }}>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - text-helper" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <TextField label="outlined" variant="outlined" size="medium" helperText="Help Text" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="outlined" variant="outlined" size="medium" helperText="Help Text" disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label="outlined" variant="outlined" size="medium" helperText="Help Text" error />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" helperText="Help Text" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" helperText="Help Text" disabled={true} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField label="outlined" variant="outlined" size="medium" helperText="Help Text" error />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Box>
          </Card>
          <CardContent />
          <CardContent />
          <CardContent />
          <Card my={2}>
            <CardHeader title="Material UI Input components - SELECT with FormControl and InputLabel (outlined, fullWidth)" />
            <Box sx={{ width: "100%" }}>
              <CardContent>
                <Card my={2}>
                  <CardHeader title="medium - small - disabled" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <FormControl variant="outlined" size="medium" fullWidth>
                          <InputLabel id="demo-simple-select-label-medium">Age</InputLabel>
                          <Select labelId="demo-simple-select-label-medium" id="demo-simple-select-medium" value={age} label="Age" onChange={handleChange}>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel id="demo-simple-select-label-disabled">Age</InputLabel>
                          <Select labelId="demo-simple-select-label-disabled" id="demo-simple-select-disabled" value={age} label="Age" onChange={handleChange}>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl variant="outlined" size="medium" fullWidth disabled>
                          <InputLabel id="demo-simple-select-label-small">Age</InputLabel>
                          <Select labelId="demo-simple-select-label-small" id="demo-simple-select-small" value={age} label="Age" onChange={handleChange}>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<FormControl variant="outlined" size="medium" fullWidth>
  <InputLabel id="demo-simple-select-label-medium">Age</InputLabel>
    <Select labelId="demo-simple-select-label-medium" id="demo-simple-select-medium" value={age} label="Age" onChange={handleChange}>
      <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem>
    </Select>
</FormControl>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<FormControl variant="outlined" size="small" fullWidth>
  <InputLabel id="demo-simple-select-label-small">Age</InputLabel>
    <Select labelId="demo-simple-select-label-small" id="demo-simple-select-small" value={age} label="Age" onChange={handleChange}>
      <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem>
    </Select>
</FormControl>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<FormControl variant="outlined" size="medium" fullWidth disabled>
  <InputLabel id="demo-simple-select-label-disabled">Age</InputLabel>
    <Select labelId="demo-simple-select-label-disabled" id="demo-simple-select-disabled" value={age} label="Age" onChange={handleChange}>
      <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem>
    </Select>
</FormControl>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Box>
          </Card>
          <CardContent />
          <CardContent />
          <CardContent />
          <Card my={2}>
            <CardHeader title="Material UI Input components - TEXTFIELD SELECT (outlined, fullWidth)" />
            <Box sx={{ width: "100%" }}>
              <CardContent>
                <Card my={2}>
                  <CardHeader title="medium - small - disabled" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <TextField id="selectMedium" select variant="outlined" size="medium" value={age} label="Age" onChange={handleChange}>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField id="selectSmall" select variant="outlined" size="small" value={age} label="Age" onChange={handleChange}>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField id="selectDisabled" select variant="outlined" size="medium" value={age} label="Age" onChange={handleChange} disabled>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField id="selectMedium" select variant="outlined" size="medium" value={age} label="Age" onChange={handleChange}>
  <MenuItem value={10}>Ten</MenuItem>
  <MenuItem value={20}>Twenty</MenuItem>
  <MenuItem value={30}>Thirty</MenuItem>
</TextField>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField id="selectSmall" select variant="outlined" size="small" value={age} label="Age" onChange={handleChange}>
  <MenuItem value={10}>Ten</MenuItem>
  <MenuItem value={20}>Twenty</MenuItem>
  <MenuItem value={30}>Thirty</MenuItem>
</TextField>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={4}>
                        <pre>
                          <code>{`<TextField id="selectDisabled" select variant="outlined" size="medium" value={age} label="Age" onChange={handleChange} disabled>
  <MenuItem value={10}>Ten</MenuItem>
  <MenuItem value={20}>Twenty</MenuItem>
  <MenuItem value={30}>Thirty</MenuItem>
</TextField>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Box>
          </Card>
          <CardContent />
          <CardContent />
          <CardContent />
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Material UI Input components - INPUT (DO NOT USE - AS DEMO ONLY)" />
            <Box sx={{ width: "100%" }}>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="input (demo)" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={4}>
                        <Input label="input" />
                      </Grid>
                      <Grid item xs={4}>
                        <Input label="input" disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <Input label="input" error />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
