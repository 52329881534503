// REACT
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"

// Others
import Country from "./components/country"
import Province from "./components/province"
import Input from "./components/input"
import UserContext from "../../../../services/user/context"

import { getAddressFormat } from "../../../../services/address-form"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../services/address-form/slice"

const componentsEnum = {
  Country,
  Province,
  Input,
}

function AddressForm({ formik }) {
  const [components, setComponents] = useState(null)

  const { currentUser } = useContext(UserContext)

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()

  const countryCode = formik?.values?.countryCode || currentUser?.clientAddress?.countryCode || "CA"
  const addressFormat = getAddressFormat({ addressFormats, countries, countryCode })

  useEffect(() => {
    if (addressFormat) {
      const newComponents = addressFormat.map((fieldFormat, index) => {
        const ComponentName = componentsEnum[fieldFormat.form?.element]
        return <ComponentName key={"".concat(index)} fieldFormat={fieldFormat} formik={formik} />
      })
      setComponents(newComponents)
    }
  }, [addressFormat, formik])

  return components
}

AddressForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({ countryCode: PropTypes.string }),
  }).isRequired,
}

export default AddressForm
