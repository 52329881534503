// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CarrierNamesLanguagesForm from "../carrier-names-languages-form"

import { useEditCarrierDetailsMutation } from "../../slice"

function CarrierGeneralInfo({ carrierGeneralInfo, languages, onSubmitEvent, setShowCarrierGeneralInfoDrawer }) {
  const { t } = useTranslation("carriers")

  const customTheme = useTheme()

  const [editCarrierDetails] = useEditCarrierDetailsMutation()

  const formik = useFormik({
    initialValues: {
      fullName: (carrierGeneralInfo && carrierGeneralInfo.fullName) || undefined,
      shortName: (carrierGeneralInfo && carrierGeneralInfo.shortName) || undefined,
      logo: (carrierGeneralInfo && carrierGeneralInfo.logo) || "",
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      if (carrierGeneralInfo) {
        await editCarrierDetails({ code: carrierGeneralInfo.code, payload: formValues })
      }
      formik.resetForm()
      setShowCarrierGeneralInfoDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <CarrierNamesLanguagesForm formik={formik} nameLabel={t("carrierDetails.carrierGeneralInfoDrawer.fullName.label")} nameLabelPrefix="fullName" languages={languages} />
        <Divider orientation="horizontal" style={{ minHeight: customTheme.utils.pxToRem(24) }} />
        <Box component="div" p={1} />
        <CarrierNamesLanguagesForm formik={formik} nameLabel={t("carrierDetails.carrierGeneralInfoDrawer.shortName.label")} nameLabelPrefix="shortName" languages={languages} />
        <Divider orientation="horizontal" style={{ minHeight: customTheme.utils.pxToRem(24) }} />
        <Box component="div" p={1} />
        <Box component="div">
          <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
            {t("carrierDetails.carrierGeneralInfoDrawer.logo.title")}
          </Typography>
          <Box component="div" p={1} />
          <TextField
            id="logo"
            inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular } }}
            InputLabelProps={{ style: { lineHeight: customTheme.utils.pxToLineHeight(16, 24) } }}
            label={t("carrierDetails.carrierGeneralInfoDrawer.logo.label")}
            variant="outlined"
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.logo}
            fullWidth
            multiline
            sx={{ m: 1 }}
          />
        </Box>
      </form>
    </Box>
  )
}

CarrierGeneralInfo.defaultProps = {
  carrierGeneralInfo: undefined,
  languages: undefined,
  onSubmitEvent: undefined,
}

CarrierGeneralInfo.propTypes = {
  carrierGeneralInfo: PropTypes.shape({
    accountFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    code: PropTypes.string,
    fullName: PropTypes.instanceOf(Object),
    shortName: PropTypes.instanceOf(Object),
    logo: PropTypes.string,
  }),
  languages: PropTypes.instanceOf(Object),
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowCarrierGeneralInfoDrawer: PropTypes.func.isRequired,
}

export default CarrierGeneralInfo
