/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/api/radio/
 * @link https://mui.com/api/radio-group/
 */

export default function Radio(theme) {
  return {
    MuiRadio: {
      defaultProps: { color: "contentActionDefault" },
      styleOverrides: {
        root: {
          color: theme.palette.contentActionDefault.main,
          borderColor: theme.palette.contentActionDefault.main,
          backgroundColor: "inherit",
          "&.Mui-disabled": {
            color: theme.palette.contentNeutralTertiary.main,
            borderColor: "inherit",
            backgroundColor: "inherit",
          },
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: theme.palette.contentActionDefault.main,
            borderColor: theme.palette.contentActionDefault.main,
            backgroundColor: "inherit",
          },
        },
      },
    },
    MuiRadioGroup: {
      defaultProps: {},
      styleOverrides: {},
    },
  }
}
