// REACT
import React from "react"
import PropTypes from "prop-types"

import MuiWrapper from "./style"

function SoeConfirmationDialogContainer({ children, ...muiBoxProps }) {
  return (
    <MuiWrapper component="section" {...muiBoxProps}>
      {children}
    </MuiWrapper>
  )
}

SoeConfirmationDialogContainer.propTypes = {
  children: PropTypes.node,
}

SoeConfirmationDialogContainer.defaultProps = {
  children: undefined,
}

export default SoeConfirmationDialogContainer
