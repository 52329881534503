import React from "react"

import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Container from "@mui/material/Container"
import { DataGrid } from "@mui/x-data-grid"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"

import "./styles.scss"

export default function DataGridExample() {
  // Example for DataGrid
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 90,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ]

  const rows = [
    { id: 1, lastName: "UserLastName1", firstName: "UserFirstName1", quantity: 35 },
    { id: 2, lastName: "UserLastName2", firstName: "UserFirstName2", quantity: 42 },
    { id: 3, lastName: "UserLastName3", firstName: "UserFirstName3", quantity: 45 },
    { id: 4, lastName: "UserLastName4", firstName: "UserFirstName4", quantity: 16 },
    { id: 5, lastName: "UserLastName5", firstName: "UserFirstName5", quantity: null },
    { id: 6, lastName: "UserLastName6", firstName: null, quantity: 150 },
    { id: 7, lastName: "UserLastName7", firstName: "UserFirstName7", quantity: 44 },
    { id: 8, lastName: "UserLastName8", firstName: "UserFirstName8", quantity: 36 },
    { id: 9, lastName: "UserLastName9", firstName: "UserFirstName9", quantity: 65 },
  ]

  return (
    <Container maxWidth="fit-content">
      <List>
        <ListItem>
          <Link href="https://mui.com/components/data-grid/" target="_blank">
            {"MUI component <DataGrid />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/data-grid/data-grid/" target="_blank">
            {"MUI API <DataGrid />"}
          </Link>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Material UI Input components - DATAGRID" />
            <Box sx={{ width: "100%" }}>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="DataGrid" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12} style={{ height: 400, width: "100%" }}>
                        <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} checkboxSelection />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12}>
                        <pre>
                          <code>{`/* Example for DataGrid
const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 90,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => \`\${params.row.firstName || ""} \`\${params.row.lastName || ""}\`,
  },
]

const rows = [
  { id: 1, lastName: "UserLastName1", firstName: "UserFirstName1", quantity: 35 },
  { id: 2, lastName: "UserLastName2", firstName: "UserFirstName2", quantity: 42 },
  { id: 3, lastName: "UserLastName3", firstName: "UserFirstName3", quantity: 45 },
  { id: 4, lastName: "UserLastName4", firstName: "UserFirstName4", quantity: 16 },
  { id: 5, lastName: "UserLastName5", firstName: "UserFirstName5", quantity: null },
  { id: 6, lastName: "UserLastName6", firstName: null, quantity: 150 },
  { id: 7, lastName: "UserLastName7", firstName: "UserFirstName7", quantity: 44 },
  { id: 8, lastName: "UserLastName8", firstName: "UserFirstName8", quantity: 36 },
  { id: 9, lastName: "UserLastName9", firstName: "UserFirstName9", quantity: 65 },
]
*/
<Grid item xs={12} style={{ height: 400, width: "100%" }}>
  <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} checkboxSelection />
</Grid>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
      {/* --------------------------------------------- */}
    </Container>
  )
}
