/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/api/button-base/
 *
 * @link https://mui.com/components/buttons/
 * @link https://mui.com/api/button/
 *
 * @link https://mui.com/components/button-group/
 * @link https://mui.com/api/button-group/
 */

export default function Button(theme) {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        focusRipple: false,
      },
      styleOverrides: {
        style: {},
      },
    },
    MuiButton: {
      defaultProps: { color: "contentActionDefault", fullWidth: false, size: "medium", variant: "contained" },
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          "&:focus": {
            boxShadow: theme.customShadows.contentActionFocusShadow,
          },
          textTransform: "none",
        },
        contained: {
          backgroundColor: theme.palette.contentActionDefault.main,
          color: theme.palette.contentNeutralPrimaryReverse.main,
          "&:hover": {
            backgroundColor: theme.palette.contentActionDarker.main,
            color: theme.palette.contentNeutralPrimaryReverse.main,
          },
          "&:disabled": {
            backgroundColor: theme.palette.contentNeutralTertiary.main,
            borderColor: theme.palette.strokeDefault.main,
            color: theme.palette.contentNeutralPrimaryReverse.main,
          },
        },
        containedError: {
          backgroundColor: theme.palette.contentAlertError.main,
          color: theme.palette.contentNeutralPrimaryReverse.main,
          "&:hover": {
            backgroundColor: theme.palette.contentAlertErrorDarker.main,
            color: theme.palette.contentNeutralPrimaryReverse.main,
          },
        },
        outlined: {
          borderColor: theme.palette.contentActionLighter.main,
          color: theme.palette.contentActionDefault.main,
          "&:hover": {
            backgroundColor: theme.palette.backgroundNeutralHightlightLight.main,
            borderColor: theme.palette.backgroundNeutralTransparent,
            color: theme.palette.contentActionDefault.main,
          },
          "&:disabled": {
            backgroundColor: theme.palette.backgroundNeutralTransparent,
            borderColor: theme.palette.strokeDefault.main,
            color: theme.palette.contentNeutralTertiary.main,
          },
        },
        outlinedError: {
          borderColor: theme.palette.contentAlertError.main,
          color: theme.palette.contentAlertError.main,
          "&:hover": {
            backgroundColor: theme.palette.backgroundAlertError.main,
            borderColor: theme.palette.contentAlertErrorDarker.main,
            color: theme.palette.contentAlertError.main,
          },
        },
        text: {
          border: "none",
          borderColor: "none",
          color: theme.palette.contentActionDefault.main,
          "&:hover": {
            backgroundColor: theme.palette.backgroundNeutralHightlightLight.main,
            color: theme.palette.contentActionDefault.main,
          },
          "&:disabled": {
            backgroundColor: theme.palette.backgroundNeutralTransparent,
            color: theme.palette.contentNeutralTertiary.main,
          },
        },
        // @todo: Icon need to be bold on sizeSmall ? It doesn't seem to be an easy fix to change the font size of an svg, this one risk to cost some money to fix.
        sizeSmall: {
          fontSize: theme.typography.bodySmall600.fontSize,
          fontWeight: theme.typography.bodySmall600.fontWeight,
          lineHeight: theme.typography.bodySmall600.lineHeight,
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
        sizeMedium: {
          fontSize: theme.typography.bodyMedium400.fontSize,
          fontWeight: theme.typography.bodyMedium400.fontWeight,
          lineHeight: theme.typography.bodyMedium400.lineHeight,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        /* @todo: To theme accordingly with Figma (no example available).
           Below, sizeLarge doesn't exist in theme ...
           Do not use size large or if eventually used, accordingly modify de values. */
        sizeLarge: {
          fontSize: theme.typography.bodyMedium600.fontSize,
          fontWeight: theme.typography.bodyMedium600.fontWeight,
          lineHeight: theme.typography.bodyMedium600.lineHeight,
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
      },
    },
    // @todo: To theme accordingly with Figma (no example available).
    MuiButtonGroup: {
      defaultProps: { color: "contentActionDefault", fullWidth: false, size: "medium", variant: "contained" },
      styleOverrides: {},
    },
    MuiFab: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiToggleButton: {
      defaultProps: { color: "standard", fullWidth: false, size: "small" },
      styleOverrides: {
        root: {
          fontSize: theme.typography.bodyMedium600.fontSize,
          fontWeight: theme.typography.fontWeightBold,
          textTransform: "none",
          "&.Mui-selected": {
            backgroundColor: theme.palette.backgroundNeutralHightlightStrong.main,
            color: theme.palette.contentNeutralPrimary.main,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: { color: "standard", fullWidth: false, orientation: "horizontal", size: "small" },
      styleOverrides: {},
    },
  }
}
