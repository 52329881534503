/* eslint-disable react/forbid-prop-types */
// REACT
import PropTypes from "prop-types"
import React from "react"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

// MUI
import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"

// Others
import MoreMenu from "../../../../../../more-menu"
import { ROUTES } from "../../../../../../../constant"
import DateTimeService from "../../../../../../../services/date-time"
import BILLING_TYPE_ENUM from "../../../../../../../utils/billing"

function Company({ company, externalHandleCompanyClick, showMoreMenu }) {
  const { t } = useTranslation("companies")
  const navigate = useNavigate()

  const handleCompanyClick = () => {
    navigate(`${ROUTES.COMPANIES}/${company.pk.slice(4)}`)
  }

  const handleAssignCompanyClick = () => {
    externalHandleCompanyClick(company)
  }

  const sideMenuOptionList = [
    {
      label: t("sideMenu.details"),
      handleClick: handleCompanyClick,
    },
  ]

  const companyBillingType = {
    account: <Chip label={t(`headers.billingTypes.${company?.billing?.type}`)} size="medium" color="primary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />,
    creditcard: <Chip label={t(`headers.billingTypes.${company?.billing?.type}`)} size="medium" color="secondary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />,
    undefined: <Chip label={t(`headers.billingTypes.${BILLING_TYPE_ENUM.CREDIT_CARD}`)} size="medium" color="secondary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />,
  }

  return (
    <TableRow hover component="tr" onClick={!externalHandleCompanyClick ? handleCompanyClick : handleAssignCompanyClick} data-company={company} sx={(theme) => ({ backgroundColor: theme.palette.contentNeutralPrimaryReverse.main })}>
      <TableCell component="td" scope="row">
        {company.companyName || company.billing?.address?.companyName}
      </TableCell>
      <TableCell component="td" scope="row">
        {company.accountId}
      </TableCell>
      <TableCell component="td" scope="row">
        {company.creatorEmail}
      </TableCell>
      <TableCell component="td" scope="row">
        {company.billing?.address?.addressLine1}
        {company.billing?.address?.addressLine2 && ", ".concat(company.billing?.address?.addressLine2)}
        {company.billing?.address?.city && ", ".concat(company.billing?.address?.city)}
        {company.billing?.address?.provinceCode && ", ".concat(company.billing?.address?.provinceCode)}
        {company.billing?.address?.countryCode && ", ".concat(company.billing?.address?.countryCode)}
        {company.billing?.address?.postalCode && ", ".concat(company.billing?.address?.postalCode)}
      </TableCell>
      <TableCell component="td" scope="row">
        {company.pricingGroupName}
      </TableCell>
      <TableCell component="td" scope="row">
        {company.billing?.currency || "CAD"}
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography>{DateTimeService.toLocaleDateString(company.createdDate)}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        {companyBillingType[company.billing?.type]}
      </TableCell>
      <TableCell component="td" scope="row">
        <Checkbox checked={company.paymentMethods?.length > 0 || company?.paymentMethods?.length > 0} readOnly />
      </TableCell>
      {showMoreMenu && (
        <TableCell component="td" scope="row" align="right">
          <MoreMenu optionsList={sideMenuOptionList} />
        </TableCell>
      )}
    </TableRow>
  )
}

Company.propTypes = {
  company: PropTypes.shape({
    pk: PropTypes.string,
    disabled: PropTypes.bool,
    createdDate: PropTypes.string,
    companyId: PropTypes.string,
    personName: PropTypes.string,
    accountId: PropTypes.string,
    companyName: PropTypes.string,
    paymentMethods: PropTypes.arrayOf(PropTypes.object),
    creatorEmail: PropTypes.string,
    billing: PropTypes.shape({
      type: PropTypes.string,
      currency: PropTypes.string,
      address: PropTypes.shape({
        personName: PropTypes.string,
        companyName: PropTypes.string,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        provinceCode: PropTypes.string,
        countryCode: PropTypes.string,
        postalCode: PropTypes.string,
      }),
    }),
    pricingGroupName: PropTypes.string,
  }).isRequired,
  externalHandleCompanyClick: PropTypes.func,
  showMoreMenu: PropTypes.bool.isRequired,
}

Company.defaultProps = {
  externalHandleCompanyClick: undefined,
}

export default Company
