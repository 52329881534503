/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/app-bar/
 * @link https://mui.com/api/app-bar/
 * @link https://mui.com/api/toolbar/
 */
export default function AppBar() {
  return {
    MuiAppBar: {
      defaultProps: {
        color: "contentNeutralPrimaryReverse",
        position: "fixed",
        elevation: 3,
      },
      styleOverrides: {},
    },
    MuiToolbar: {
      defaultProps: {
        variant: "regular",
      },
      styleOverrides: {},
    },
  }
}
