import React, { useContext, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

// MUI
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography, Card, Box } from "@mui/material"

import { useGetCurrenciesQuery } from "../../../../../../../../clients/slice"
import { useUpdateCompanyMutation } from "../../../../../../../../../services/company/slice"
import UserContext from "../../../../../../../../../services/user/context"

function AddCreditToCompanyDrawer({ company, onSubmitEvent, setShowCreditDrawer, refetchCompany }) {
  const { t } = useTranslation("companies")
  const { currentUser } = useContext(UserContext)
  const { data: currencies } = useGetCurrenciesQuery()
  const [setCompanyDetails] = useUpdateCompanyMutation()

  const updatedHistoryEvents = (formvalues) => {
    let historyEvents = company.credit?.historyEvents?.length > 0 ? company.credit?.historyEvents : []
    if (formvalues.amount !== company.credit?.total?.amount) {
      const timestamp = new Date(new Date().toISOString()).toLocaleString()

      if (company.credit?.total?.amount > 0) {
        historyEvents = historyEvents.concat(
          `${currentUser?.clientAddress?.personName} updated from ${company.credit?.total?.amount} ${company.credit?.total?.currency} to ${formvalues.amount} ${formvalues.currency} | ${timestamp} | Note: ${formvalues.privateNote}`
        )
      } else {
        historyEvents = historyEvents.concat(`${currentUser?.clientAddress?.personName} added ${formvalues.amount} ${formvalues.currency} | ${timestamp} | Note: ${formvalues.privateNote}`)
      }
    }

    return historyEvents
  }

  const formik = useFormik({
    initialValues: {
      updatedDate: useMemo(() => new Date(new Date()), []),
      amount: company.credit?.total?.amount || "",
      currency: company.credit?.total?.currency || "CAD",
      privateNote: company.credit?.privateNote || "",
    },
    validate: (formvalues) => {
      const errors = {}
      if (!formvalues.privateNote) {
        errors.privateNote = true
      }
      if (!formvalues.amount) {
        errors.amount = true
      }
      if (formvalues.amount < 0) {
        errors.amount = true
      }
      if (!formvalues.currency) {
        errors.currency = true
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formvalues) => {
      const tempCredit = {
        updatedDate: new Date(formvalues.updatedDate).toISOString(),
        total: {
          amount: formvalues.amount,
          currency: formvalues.currency,
        },
        privateNote: formvalues.privateNote || undefined,
        historyEvents: updatedHistoryEvents(formvalues),
      }

      await setCompanyDetails({ companyId: company.id, credit: tempCredit })
      setShowCreditDrawer(false)
      setTimeout(() => refetchCompany(), 200)
    },
  })

  useEffect(() => {
    formik.handleReset()
  }, [])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" sx={(theme) => ({ display: "flex", marginBottom: theme.spacing(1) })}>
          <TextField
            sx={(theme) => ({ marginRight: theme.spacing(1) })}
            id="amount"
            variant="outlined"
            label={t("companyDetails.companyDetailsInformations.companyCreditDrawer.fields.amount.label")}
            size="small"
            value={formik.values.amount || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.amount && formik.errors.amount !== undefined}
            inputMode="numeric"
            type="number"
            InputProps={{ inputProps: { type: "number", min: 0 } }}
          />
          <FormControl fullWidth error={formik.touched?.declaredValue?.currency && formik.errors?.currency}>
            <InputLabel />
            {currencies && (
              <Select name="currency" onChange={formik.handleChange} value={formik.values?.currency} size="small" error={formik.touched.currency && formik.errors.currency !== undefined}>
                {currencies &&
                  currencies.map((currency, index) => (
                    <MenuItem key={currency.code.concat(index)} value={currency.code}>
                      {currency.code}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            id="privateNote"
            variant="outlined"
            label={t("companyDetails.companyDetailsInformations.companyCreditDrawer.fields.privateNote.label")}
            size="small"
            value={formik.values.privateNote || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.privateNote && formik.errors.privateNote !== undefined}
          />
        </Box>
      </form>

      {company.credit?.historyEvents?.length > 0 && (
        <Card variant="elevation" square sx={(theme) => ({ border: `1px solid ${theme.palette.strokeDefault.main}`, padding: 1, maxHeight: 600, overflowY: "auto" })}>
          {[...company.credit.historyEvents].reverse().map((item) => (
            <Card key={item} variant="elevation" square sx={(theme) => ({ border: `1px solid ${theme.palette.strokeDefault.main}`, padding: 1, marginBottom: 1 })}>
              <Typography component="p" key={item} variant="bodySmall400" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                {item}
              </Typography>
            </Card>
          ))}
        </Card>
      )}
    </Box>
  )
}

AddCreditToCompanyDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

AddCreditToCompanyDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  company: PropTypes.shape({
    id: PropTypes.string,
    credit: PropTypes.shape({
      updatedDate: PropTypes.string,
      total: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      privateNote: PropTypes.string,
      historyEvents: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  setShowCreditDrawer: PropTypes.func.isRequired,
  refetchCompany: PropTypes.func.isRequired,
}

export default AddCreditToCompanyDrawer
