// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CompanyDetailsSummaryCard from "../../../company-details-summary-card"
import { SummaryStatusVariantsEnum } from "../../../company-details-summary-card/components/summary-status"

function CompanyBillingContactCard({ address, defaultToIconSuccessStatus, handleCompanyBillingContactCardClick }) {
  const { t } = useTranslation("companies")
  const theme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToIconSuccessStatus) return SummaryStatusVariantsEnum.icon
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <CompanyDetailsSummaryCard title={t("companyDetails.companyDetailsInformations.companyBillingContactCardTitle")} handleClick={handleCompanyBillingContactCardClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={theme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
          {address?.companyName ?? ""} {address?.addressLine1 ?? ""}
        </Typography>
        <Box
          mt={theme.spacing(1)}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        />
      </Box>
    </CompanyDetailsSummaryCard>
  )
}

CompanyBillingContactCard.propTypes = {
  address: PropTypes.shape({
    personName: PropTypes.string,
    companyName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    provinceCode: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    emailAddress: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  defaultToIconSuccessStatus: PropTypes.bool,
  handleCompanyBillingContactCardClick: PropTypes.func,
}

CompanyBillingContactCard.defaultProps = {
  handleCompanyBillingContactCardClick: () => {},
  defaultToIconSuccessStatus: false,
  address: undefined,
}

export default CompanyBillingContactCard
