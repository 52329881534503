// REACT
import React, { useState, useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

// Theme
import { useTheme } from "@mui/material"

// Others
import CustomPagination from "../custom-pagination"
import EnhancedTableHead from "../enhanced-table-head"
import getComparator from "../../utils/sorting"
import SearchInput from "../search-input"
import User from "./components/user"
import { useGetUsersQuery } from "../../services/user/slice"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../soe-theme/src/components"
import SetAdminDrawer from "./components/set-admin-drawer"

function Users() {
  const { t } = useTranslation("users")
  const customTheme = useTheme()

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("personName")
  const [filteredUsers, setFilteredUsers] = useState()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(15)

  const { data: users, isFetching } = useGetUsersQuery()
  const [showSetAdminDrawer, setShowSetAdminDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()

  useEffect(() => {
    setFilteredUsers(users)
  }, [users])

  const headCells = [
    {
      id: "personName",
      disablePadding: false,
      label: t("headers.name"),
    },
    {
      id: "emailAddress",
      disablePadding: false,
      label: t("headers.email"),
    },
    {
      id: "space1",
    },
    {
      id: "space2",
    },
    {
      id: "space3",
    },
    {
      id: "space4",
    },
    {
      id: "space5",
    },
    {
      id: "space6",
    },
    {
      id: "disabled",
      disablePadding: false,
      label: t("headers.status"),
    },
    {
      id: "menu",
    },
  ]

  const handleSetAdminClick = () => {
    setSubmitEvent(undefined)
    setShowSetAdminDrawer(true)
  }

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <>
      {showSetAdminDrawer && (
        <SoeDrawer showDrawer={showSetAdminDrawer} setShowDrawer={setShowSetAdminDrawer} anchor="right" keepMounted={false}>
          <SoeDrawerContainer>
            <SoeDrawerHeader
              title={t("setAsAdminDrawer.title")}
              setShowDrawer={() => {
                setShowSetAdminDrawer(false)
              }}
            />
            <SoeDrawerContent>
              <SetAdminDrawer onSubmitEvent={onSubmitEvent} setShowSetAdminDrawer={setShowSetAdminDrawer} />
            </SoeDrawerContent>
            <SoeDrawerActions
              buttons={[
                {
                  action: setSubmitEvent,
                  label: t("setAsAdminDrawer.action.save.label"),
                  variant: "contained",
                },
              ]}
            />
          </SoeDrawerContainer>
        </SoeDrawer>
      )}
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <CardHeader
          title={t("title")}
          action={
            <Button variant="contained" onClick={handleSetAdminClick} sx={(theme) => ({ mr: theme.spacing(1) })}>
              {t("newUser.actions.add.label")}
            </Button>
          }
        />
      </Card>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <Box my={customTheme.utils.pxToThemeSpacing(20)} mx={customTheme.spacing(2)}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <SearchInput
                data={users}
                setFilteredData={setFilteredUsers}
                placeHolder={t("fields.search.placeHolder.label")}
                excludeFields={["pk", "sk", "createdDate", "defaultLanguage", "entityType", "modifiedDate", "personName", "stripeCustomerId", "termsAcceptedDate", "pricingGroupCode", "defaultShipperId", "defaultBrokerId", "defaultShipper"]}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <Box sx={{ width: "auto" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table size="small" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1.main })}>
                <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {!filteredUsers || isFetching ? (
                    <>
                      {Array(3)
                        .fill()
                        .map((_, index) => {
                          return (
                            <TableRow key={"".concat(index)}>
                              <TableCell colSpan={12}>
                                <Skeleton variant="rectangular" height={50} width="100%" />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </>
                  ) : (
                    filteredUsers
                      .slice()
                      .sort(getComparator(order, orderBy))
                      .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                      .map((user, index) => {
                        return <User key={"".concat(index)} user={user} />
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination count={filteredUsers ? Math.ceil(filteredUsers.length / rowsPerPage) : 1} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />
          </Paper>
        </Box>
      </Card>
    </>
  )
}

export default Users
