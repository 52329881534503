import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invoices: [],
  selectedInvoiceIds: [],
}

const selectedInvoicesSlice = createSlice({
  name: 'selectedInvoices',
  initialState,
  reducers: {
    setInvoices(state, action) {
      state.invoices = action.payload
    },
    selectInvoice(state, action) {
      if (!state.selectedInvoiceIds.includes(action.payload)) {
        state.selectedInvoiceIds.push(action.payload)
      }
    },
    deselectInvoice(state, action) {
      state.selectedInvoiceIds = state.selectedInvoiceIds.filter((id) => id !== action.payload)
    },
    clearSelection(state) {
      state.selectedInvoiceIds = []
    },
    selectAll(state, action) {
      state.selectedInvoiceIds = action.payload.map((invoice) => invoice.id)
    },
  },
})

export const selectSelectedInvoices = (state) => {
  const { invoices, selectedInvoiceIds } = state.selectedInvoices
  return invoices.filter((invoice) => selectedInvoiceIds.includes(invoice.id))
}

export const { setInvoices, selectInvoice, deselectInvoice, clearSelection, selectAll } = selectedInvoicesSlice.actions;
export default selectedInvoicesSlice.reducer
