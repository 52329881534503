/* eslint-disable no-console */
import PropTypes from "prop-types"
import React, { useState } from "react"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"
import { useTranslation } from "react-i18next"

import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useCreateCompanyMutation, useGetCompaniesQuery } from "../../../../services/company/slice"
import { useEditUserProfileMutation } from "../../../../services/user/slice"

function ManageCompanyDrawer({ client, showManageCompanyDrawer, setShowManageCompanyDrawer, refetchClients }) {
  const { t } = useTranslation("clients")
  const [isNewOrgForm, setIsNewOrgForm] = useState(!client?.companyId)

  const [creationError, setCreationError] = useState("")
  const [companyName, setCompanyName] = useState(client.clientAddress?.companyName || "")
  const [selectedCompanyId, setSelectedCompanyId] = useState(client.companyId || "")
  const [selectedCompanyRole, setSelectedCompanyRole] = useState(client.companyRole || "ADMIN")

  const { data: companies, isLoading } = useGetCompaniesQuery()
  const [editUserProfile] = useEditUserProfileMutation()
  const [createCompany] = useCreateCompanyMutation()

  const handleChangeCompany = (event) => {
    setSelectedCompanyId(event.target.value)
  }

  const handleTypeCompanyName = (event) => {
    setCompanyName(event.target.value)
    if (creationError) setCreationError("")
  }

  const handleChangeMode = (_, value) => {
    setIsNewOrgForm(value === "true")
    if (value === "false") {
      const existingOrg = companies.find((org) => org.companyName === companyName)
      if (existingOrg?.id) setSelectedCompanyId(existingOrg.id)
    }
  }

  const handleCreateOrUpdateCompanyForUser = () => {
    const criteria = (o) => (isNewOrgForm ? o.companyName === companyName : o.id === selectedCompanyId)
    const company = companies?.find((org) => criteria(org))

    const userId = client.pk?.replace("u#", "") === client.emailAddress ? client.userId : client.pk?.replace("u#", "")
    if (isNewOrgForm) {
      if (company) {
        setCreationError("Company with this name already exists! try to add user with existing one option")
        return
      }

      // Create new company and append user to it
      createCompany({
        companyName,
        userEmail: client.emailAddress,
        userId,
      })
    } else if (company?.id) {
      editUserProfile({
        companyId: company.id,
        companyRole: selectedCompanyRole,
        userId,
        emailAddress: client.emailAddress,
      })
    }

    setShowManageCompanyDrawer(false)
    setTimeout(() => {
      refetchClients()
    }, 700)
  }

  return (
    <SoeDrawer showDrawer={showManageCompanyDrawer} setShowDrawer={setShowManageCompanyDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("companyDrawer.title")}
          setShowDrawer={() => {
            setShowManageCompanyDrawer(false)
          }}
        />
        <SoeDrawerContent>
          {!isLoading ? (
            <Stack direction="column">
              {!client.companyId && (
                <FormControl>
                  <RadioGroup row aria-labelledby="company-group-label" name="company" value={isNewOrgForm} onChange={handleChangeMode}>
                    <FormControlLabel
                      value
                      control={
                        <Radio
                          sx={(theme) => ({
                            color: theme.palette.contentActionDefault.main,
                            "&.Mui-checked": {
                              color: theme.palette.contentActionDefault.main,
                            },
                          })}
                        />
                      }
                      label="Create new Company"
                    />
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          sx={(theme) => ({
                            color: theme.palette.contentActionDefault.main,
                            "&.Mui-checked": {
                              color: theme.palette.contentActionDefault.main,
                            },
                          })}
                        />
                      }
                      label="Add to Existing Company"
                      sx={{ mr: 1 }}
                    />
                  </RadioGroup>
                </FormControl>
              )}

              {isNewOrgForm ? (
                <Box component="div" marginTop={5}>
                  <TextField id="companyName" label="Company Name" variant="outlined" size="small" onChange={handleTypeCompanyName} value={companyName} error={!!creationError} helperText={creationError || ""} required autoFocus />
                </Box>
              ) : (
                <Box component="div" marginTop={5}>
                  <Typography variant="bodyMedium600" component="span" sx={(theme) => ({ color: theme.palette.contentNeutralPrimary.main })}>
                    {client.companyId ? "Update" : "Add"} User Company
                  </Typography>
                  {companies?.length > 0 ? (
                    <>
                      <Select id="companyName" size="small" value={selectedCompanyId} onChange={handleChangeCompany}>
                        {companies.map((org) => (
                          <MenuItem key={org.id} value={org.id}>
                            {org.companyName}
                          </MenuItem>
                        ))}
                      </Select>
                      <Select margin="dense" id="companyRole" size="small" value={selectedCompanyRole} onChange={(e) => setSelectedCompanyRole(e.target.value)}>
                        <MenuItem key="ADMIN" value="ADMIN">
                          Admin
                        </MenuItem>
                        <MenuItem key="SHIP" value="SHIP">
                          Ship
                        </MenuItem>
                        <MenuItem key="BILL" value="BILL">
                          Bill
                        </MenuItem>
                      </Select>
                    </>
                  ) : (
                    <p> Companies Not found </p>
                  )}
                </Box>
              )}
            </Stack>
          ) : (
            <Skeleton variant="rectangular" height={50} width="100%" />
          )}
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: () => setShowManageCompanyDrawer(false),
              label: t("companyDrawer.actions.close"),
              variant: "outlined",
            },
            {
              action: handleCreateOrUpdateCompanyForUser,
              label: t("companyDrawer.actions.confirm"),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

ManageCompanyDrawer.propTypes = {
  showManageCompanyDrawer: PropTypes.bool.isRequired,
  setShowManageCompanyDrawer: PropTypes.func.isRequired,
  refetchClients: PropTypes.func.isRequired,
  client: PropTypes.shape({
    pk: PropTypes.string,
    userId: PropTypes.string,
    disabled: PropTypes.bool,
    createdDate: PropTypes.string,
    companyId: PropTypes.string,
    companyRole: PropTypes.string,
    clientAddress: PropTypes.shape({
      personName: PropTypes.string,
      companyName: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      provinceCode: PropTypes.string,
      countryCode: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    emailAddress: PropTypes.string,
    billing: PropTypes.shape({
      type: PropTypes.string,
      currency: PropTypes.string,
    }),
    pricingGroupName: PropTypes.string,
  }).isRequired,
}

export default ManageCompanyDrawer
