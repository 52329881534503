/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/dividers/
 * @link https://mui.com/api/divider/
 */

export default function Divider(theme) {
  return {
    MuiDivider: {
      defaultProps: { textAlign: "center" },
      styleOverrides: {
        root: {
          borderColor: theme.palette.strokeSeparator.main,
          color: theme.palette.contentNeutralSecondary.main,
          fontSize: theme.typography.bodyMedium400.fontSize,
          fontWeight: theme.typography.bodyMedium400.fontWeight,
          lineHeight: theme.typography.bodyMedium400.lineHeight,
          "&::before": {
            position: "inherit",
          },
          "&::after": {
            position: "inherit",
          },
        },
      },
    },
  }
}
