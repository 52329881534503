// REACT
import React from "react"
import PropTypes from "prop-types"

import MuiWrapper from "./style"

function SoeConfirmationDialogContent({ children, ...muiBoxProps }) {
  return (
    <MuiWrapper component="section" {...muiBoxProps}>
      {children}
    </MuiWrapper>
  )
}

SoeConfirmationDialogContent.propTypes = {
  children: PropTypes.node,
}

SoeConfirmationDialogContent.defaultProps = {
  children: undefined,
}

export default SoeConfirmationDialogContent
