// REACT
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import Currency from "../currency"
import DateTime from "../date-time"
import RateRequestSummaryCard from "../rate-request-summary-card"

import { ShippingOptionsProps } from "../../models/rate-request"

function ShippingOptionsCard({ shippingOptions, disabled }) {
  const { t } = useTranslation("shippingOptionsCard")
  const customTheme = useTheme()

  return (
    <RateRequestSummaryCard title={t("shippingOptionsTitle")} disabled={disabled}>
      {shippingOptions && (
        <Box mt={customTheme.spacing(1)} sx={{ display: "flex", flexDirection: "column" }}>
          {shippingOptions.shippingDate && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("shippingDate")} <DateTime date={shippingOptions.shippingDate} />
            </Typography>
          )}
          {shippingOptions.insurance && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("insurance")}
              <Currency amount={shippingOptions.insurance.amount} currency={shippingOptions.insurance.currency} currencyPosition="right" color={customTheme.palette.contentNeutralSecondary.main} showSymbol />
            </Typography>
          )}
          {shippingOptions.signature && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("signature.label")}
              {t(`signature.options.${shippingOptions.signature}`)}
            </Typography>
          )}
          {shippingOptions.nonConveyable && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("nonConveyable")}
            </Typography>
          )}
          {shippingOptions.createReturnLabel && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("createReturnLabel")}
            </Typography>
          )}
          {shippingOptions.deliveryInstructions && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("deliveryInstructions")}
            </Typography>
          )}
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

ShippingOptionsCard.propTypes = {
  shippingOptions: ShippingOptionsProps,
  disabled: PropTypes.bool,
}

ShippingOptionsCard.defaultProps = {
  shippingOptions: undefined,
  disabled: false,
}

export default ShippingOptionsCard
