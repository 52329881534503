import restApiSlice from "../rest-api/slice"

import { getAddressSearchText } from "../../utils/search"
import { totalPackages, totalWeight } from "../../utils/packages"

const ordersRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ from, to }) => ({ url: "/orders", params: { from, to }, method: "GET" }),
      providesTags: ["Orders"],
      transformResponse: (response) =>
        response.data.map((order) => {
          return {
            ...order,
            carrierSearchText: order.response?.carrier?.code || "",
            orderNumberSearchText: order.number?.concat(` ${order.tracking?.masterId}`),
            shipperSearchText: getAddressSearchText(order.quotation?.request?.shipper),
            recipientSearchText: getAddressSearchText(order.quotation?.request?.recipient),
            totalPackages: totalPackages(order.quotation?.request?.packages?.pieces),
            totalWeight: totalWeight(order.quotation?.request?.packages?.pieces),
          }
        }),
    }),
    getOrder: builder.query({
      query: (orderId) => ({ url: `/orders/${orderId}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    deleteOrder: builder.mutation({
      query: (orderId) => ({ url: `/v2/carriers/generic/void/${orderId}`, method: "DELETE" }),
      extraOptions: {
        target: "cancelOrder",
      },
      invalidatesTags: (_, error) => {
        if (error) return []
        return ["Orders"]
      },
      transformResponse: (response) => response.data,
    }),
    changeTransitStatus: builder.mutation({
      query: ({ orderId, payload }) => ({ url: `/orders/${orderId}`, method: "PATCH", body: payload }),
      invalidatesTags: ["Orders"],
    }),
    getTrackingInfo: builder.query({
      query: (orderId) => ({ url: `/orders/${orderId}/track`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    exportOrdersToCSV: builder.mutation({
      query: ({ fromDate, toDate, emailTo }) => ({ url: "/orders/export", params: { fromDate, toDate, emailTo }, method: "GET" }),
      transformResponse: (response) => response,
    }),
  }),
})

export const { useChangeTransitStatusMutation, useDeleteOrderMutation, useGetOrderQuery, useGetOrdersQuery, useGetTrackingInfoQuery, useExportOrdersToCSVMutation } = ordersRestApiSlice

export default ordersRestApiSlice.reducer
