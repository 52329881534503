// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import SummaryCard from "../../../../../summary-card"
import SummaryStatusVariantsEnum from "../../../../../summary-card/components/summary-status/contant"

function NextCustomerIdSettingsCard({ handleNextCustomerIdSettingsCardClick, nextCustomerIdSettings }) {
  const theme = useTheme()
  const { t } = useTranslation("settings")
  const getSummaryStatus = () => SummaryStatusVariantsEnum.success
  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <SummaryCard title={t("settings.nextCustomerIdSettingsCard.title.label")} handleClick={handleNextCustomerIdSettingsCardClick} summaryStatusProps={summaryStatusProps} isDisabled={false}>
      {nextCustomerIdSettings?.settings?.length > 0 && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {nextCustomerIdSettings?.settings?.map((setting, index) => (
            <Typography key={"".concat(index)} variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
              {t("settings.nextCustomerIdSettingsCard.fields.label")}
              {t("settings.keyValueSeparator")}
              {setting.value}
            </Typography>
          ))}
        </Box>
      )}
    </SummaryCard>
  )
}

NextCustomerIdSettingsCard.defaultProps = {
  nextCustomerIdSettings: undefined,
  handleNextCustomerIdSettingsCardClick: () => {},
}

NextCustomerIdSettingsCard.propTypes = {
  nextCustomerIdSettings: PropTypes.instanceOf(Object),
  handleNextCustomerIdSettingsCardClick: PropTypes.func,
}

export default NextCustomerIdSettingsCard
