/* eslint-disable no-console */
import React, { useState } from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Chip from "@mui/material/Chip"
import Collapse from "@mui/material/Collapse"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import SoeCancelIcon from "../../icons/SoeCancelIcon"
import SoeCropSquareIcon from "../../icons/SoeCropSquareIcon"
import SoeKeyboardArrowDownIcon from "../../icons/SoeKeyboardArrowDownIcon"
import SoeKeyboardArrowUpIcon from "../../icons/SoeKeyboardArrowUpIcon"

import "./styles.scss"

export default function DataDisplayExample() {
  // For the Table example
  const createData = (name, quantity, length, width, weight) => {
    return { name, quantity, length, width, weight }
  }
  const rows = [createData("FedEx", 159, 6.0, 24, 4.0), createData("Purolator", 237, 9.0, 37, 4.3), createData("Day&Ross", 262, 16.0, 24, 6.0), createData("UPS", 305, 3.7, 67, 4.3), createData("DHL", 356, 16.0, 49, 3.9)]

  // For the List example
  const [open, setOpen] = useState(true)
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Container maxWidth="fit-content">
      <List>
        <ListItem>
          <Link href="https://mui.com/components/chips/" target="_blank">
            {"MUI component <Chip />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/chip/" target="_blank">
            {"MUI API <Chip />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/components/tables/" target="_blank">
            {"MUI component <Table />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/table/" target="_blank">
            {"MUI API <Table />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/components/lists/" target="_blank">
            {"MUI component <List />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/list/" target="_blank">
            {"MUI API <List />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/components/dividers/" target="_blank">
            {"MUI component <Divider />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/divider/" target="_blank">
            {"MUI API <Divider />"}
          </Link>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Material UI Input components - CHIP" />
            <Box sx={{ width: "100%" }}>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - default" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip label="label" variant="outlined" size="medium" color="default" />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip label="label" variant="outlined" size="medium" color="default" disabled />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="outlined" size="medium" color="default" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="outlined" size="medium" color="default" disabled />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - medium - default" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip label="label" variant="fitted" size="medium" />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip label="label" variant="fitted" size="medium" color="default" disabled />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="fitted" size="medium" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="fitted" size="medium" color="default" disabled />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - default" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip label="label" variant="outlined" size="small" color="default" />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip label="label" variant="outlined" size="small" color="default" disabled />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="Chip outlined" variant="outlined" size="small" color="default" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="Chip outlined" variant="outlined" size="small" color="default" disabled />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - small - default" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip label="label" variant="fitted" size="small" color="default" />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip label="label" variant="fitted" size="small" color="default" disabled />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="Chip fitted" variant="fitted" size="small" color="default" />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="Chip fitted" variant="fitted" size="small" color="default" disabled />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - default - icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip label="label" variant="outlined" size="medium" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip label="label" variant="outlined" size="medium" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} disabled />} />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="outlined" size="medium" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="outlined" size="medium" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} disabled />} />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - medium - default - icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip label="label" variant="fitted" size="medium" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip label="label" variant="fitted" size="medium" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} disabled />} />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="fitted" size="medium" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="fitted" size="medium" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} disabled />} />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - default - icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip label="label" variant="outlined" size="small" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip label="label" variant="outlined" size="small" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} disabled />} />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="outlined" size="small" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="outlined" size="small" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} disabled />} />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - small - default - icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip label="label" variant="fitted" size="small" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip label="label" variant="fitted" size="small" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} disabled />} />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="fitted" size="small" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} />`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip label="label" variant="fitted" size="small" color="default" icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} disabled />} />`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - default - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="medium"
                          color="default"
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="medium"
                          color="default"
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="medium"
  color="default"
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="medium"
  color="default"
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - medium - default - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="medium"
                          color="default"
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="medium"
                          color="default"
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="medium"
  color="default"
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="medium"
  color="default"
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - default - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="small"
                          color="default"
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="small"
                          color="default"
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="small"
  color="default"
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="small"
  color="default"
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - small - default - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="small"
                          color="default"
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="small"
                          color="default"
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="small"
  color="default"
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="small"
  color="default"
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - default - icon - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="medium"
                          color="default"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="medium"
                          color="default"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="medium"
  color="default"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="medium"
  color="default"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - medium - default - icon - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="medium"
                          color="default"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="medium"
                          color="default"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="medium"
  color="default"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="medium"
  color="default"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - default - icon - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="small"
                          color="default"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="small"
                          color="default"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="small"
  color="default"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="small"
  color="default"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - small - default - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="small"
                          color="default"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="small"
                          color="default"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="small"
  color="default"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="small"
  color="default"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - primary - icon - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="medium"
                          color="primary"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="medium"
                          color="primary"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="medium"
  color="primary"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="medium"
  color="primary"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - medium - primary - icon - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="medium"
                          color="primary"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="medium"
                          color="primary"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="medium"
  color="primary"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="medium"
  color="primary"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - primary - icon - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="small"
                          color="primary"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="small"
                          color="primary"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="small"
  color="primary"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="small"
  color="primary"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - small - primary - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="small"
                          color="primary"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="small"
                          color="primary"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="small"
  color="primary"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="small"
  color="primary"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - info - icon - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="medium"
                          color="info"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="medium"
                          color="info"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="medium"
  color="info"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="medium"
  color="info"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - medium - info - icon - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="medium"
                          color="info"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="medium"
                          color="info"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="medium" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="medium"
  color="info"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="medium"
  color="info"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="medium" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - info - icon - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="small"
                          color="info"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="outlined"
                          size="small"
                          color="info"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="small"
  color="info"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="outlined"
  size="small"
  color="info"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="fitted - small - info - delete" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="small"
                          color="info"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Chip
                          label="label"
                          variant="fitted"
                          size="small"
                          color="info"
                          icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
                          deleteIcon={<SoeCancelIcon size="small" />}
                          onDelete={() => {
                            console.log("DELETE!")
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="small"
  color="info"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
/>`}</code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>{`<Chip
  label="label"
  variant="fitted"
  size="small"
  color="info"
  icon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}
  deleteIcon={<SoeCancelIcon size="small" />}
  onDelete={() => {
    console.log("DELETE!")
  }}
  disabled
/>`}</code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <CardContent />
      <CardContent />
      <CardContent />
      <Grid item xs={12}>
        <Card sx={{ marginTop: 2, marginBottom: 2 }}>
          <CardHeader title="Material UI Input components - TABLE" />
          <Box sx={{ width: "100%" }}>
            <CardContent>
              <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                <CardHeader title="Table basic" style={{ textAlign: "center" }} />
                <CardContent>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Dessert (100g serving)</TableCell>
                              <TableCell align="right">quantity</TableCell>
                              <TableCell align="right">length&nbsp;(g)</TableCell>
                              <TableCell align="right">width&nbsp;(g)</TableCell>
                              <TableCell align="right">weight&nbsp;(g)</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">{row.quantity}</TableCell>
                                <TableCell align="right">{row.length}</TableCell>
                                <TableCell align="right">{row.width}</TableCell>
                                <TableCell align="right">{row.weight}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <pre>
                        <code>{`/* For the Table example
const createData = (name, quantity, length, width, weight) => {
  return { name, quantity, length, width, weight }
}
const rows = [createData("FedEx", 159, 6.0, 24, 4.0), createData("Purolator", 237, 9.0, 37, 4.3), createData("Day&Ross", 262, 16.0, 24, 6.0), 
createData("UPS", 305, 3.7, 67, 4.3), createData("DHL", 356, 16.0, 49, 3.9)] 
*/
<TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell>Dessert (100g serving)</TableCell>
        <TableCell align="right">quantity</TableCell>
        <TableCell align="right">length&nbsp;(g)</TableCell>
        <TableCell align="right">width&nbsp;(g)</TableCell>
        <TableCell align="right">weight&nbsp;(g)</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.quantity}</TableCell>
          <TableCell align="right">{row.length}</TableCell>
          <TableCell align="right">{row.width}</TableCell>
          <TableCell align="right">{row.weight}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>`}</code>
                      </pre>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </CardContent>
          </Box>
        </Card>
      </Grid>
      <CardContent />
      <CardContent />
      <CardContent />
      <Grid item xs={12}>
        <Card sx={{ marginTop: 2, marginBottom: 2 }}>
          <CardHeader title="Material UI Input components - LIST" />
          <Box sx={{ width: "100%" }}>
            <CardContent>
              <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                <CardHeader title="List - nested" style={{ textAlign: "center" }} />
                <CardContent>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <List
                        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                          <ListSubheader component="div" id="nested-list-subheader">
                            Nested List Items
                          </ListSubheader>
                        }
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                          </ListItemIcon>
                          <ListItemText primary="Single-line item" />
                        </ListItemButton>
                        <ListItemButton onClick={handleClick}>
                          <ListItemIcon>
                            <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                          </ListItemIcon>
                          <ListItemText primary="Link List Item" />
                          {open ? <SoeKeyboardArrowUpIcon /> : <SoeKeyboardArrowDownIcon />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemIcon>
                                <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                              </ListItemIcon>
                              <ListItemText secondary="Secondary text" />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <pre>
                        <code>{`/* For the List example
const [open, setOpen] = useState(true)
const handleClick = () => {
  setOpen(!open)
} 
*/
<List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }} component="nav" aria-labelledby="nested-list-subheader"
  subheader={
    <ListSubheader component="div" id="nested-list-subheader">
      Nested List Items
    </ListSubheader>
  }
>
<ListItemButton>
  <ListItemIcon>
    <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
  </ListItemIcon>
  <ListItemText primary="Single-line item" />
</ListItemButton>
<ListItemButton onClick={handleClick}>
  <ListItemIcon>
    <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
  </ListItemIcon>
  <ListItemText primary="Link List Item" />
  {open ? <SoeKeyboardArrowUpIcon /> : <SoeKeyboardArrowDownIcon />}
</ListItemButton>
<Collapse in={open} timeout="auto" unmountOnExit>
  <List component="div" disablePadding>
    <ListItemButton sx={{ pl: 4 }}>
      <ListItemIcon>
        <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
      </ListItemIcon>
      <ListItemText secondary="Secondary text" />
    </ListItemButton>
  </List>
</Collapse>
</List>`}</code>
                      </pre>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </CardContent>
          </Box>
        </Card>
      </Grid>
      <CardContent />
      <CardContent />
      <CardContent />
      <Grid item xs={12}>
        <Card sx={{ marginTop: 2, marginBottom: 2 }}>
          <CardHeader title="Material UI Input components - DIVIDER" />
          <Box sx={{ width: "100%" }}>
            <CardContent>
              <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                <CardHeader title="Divider" style={{ textAlign: "center" }} />
                <CardContent>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <Divider fullWidth />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <pre>
                        <code>{`<Divider fullWidth={true} />`}</code>
                      </pre>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </CardContent>
          </Box>
        </Card>
      </Grid>
    </Container>
  )
}
