import restApiSlice from "../../services/rest-api/slice"

export const pricingRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarriers: builder.query({
      query: () => ({ url: "/carriers", method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getCarrierAccounts: builder.query({
      query: (code) => ({ url: `/carriers/details/${code}/accounts`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getPricingGroups: builder.query({
      query: () => ({ url: "/pricing/groups", method: "GET" }),
      providesTags: ["PricingGroups"],
      transformResponse: (response) => response.data,
    }),
    addPricingGroup: builder.mutation({
      query: (payload) => ({ url: `/pricing/groups`, method: "POST", body: payload }),
      invalidatesTags: ["PricingGroups"],
    }),
    editPricingGroup: builder.mutation({
      query: ({ id, payload }) => ({ url: `/pricing/groups/${id}`, method: "PATCH", body: payload }),
      invalidatesTags: ["PricingGroups"],
    }),
    deletePricingGroup: builder.mutation({
      query: (id) => ({ url: `/pricing/groups/${id}`, method: "DELETE" }),
      invalidatesTags: ["PricingGroups"],
    }),
    getPricingPlans: builder.query({
      query: (pricingGroupId) => ({ url: `/pricing/groups/${pricingGroupId}/plans`, method: "GET" }),
      providesTags: ["PricingPlans"],
      transformResponse: (response) => response.data,
    }),
    addPricingPlan: builder.mutation({
      query: ({ pricingGroupId, payload }) => ({ url: `/pricing/groups/${pricingGroupId}/plans`, method: "POST", body: payload }),
      invalidatesTags: ["PricingPlans"],
    }),
    editPricingPlan: builder.mutation({
      query: ({ pricingGroupId, pricingPlanId, payload }) => ({ url: `/pricing/groups/${pricingGroupId}/plans/${pricingPlanId}`, method: "PATCH", body: payload }),
      invalidatesTags: ["PricingPlans"],
    }),
    deletePricingPlan: builder.mutation({
      query: ({ pricingGroupId, pricingPlanId }) => ({ url: `/pricing/groups/${pricingGroupId}/plans/${pricingPlanId}`, method: "DELETE" }),
      invalidatesTags: ["PricingPlans"],
    }),
    getServices: builder.query({
      query: ({ pricingGroupId, pricingPlanId }) => ({ url: `/pricing/groups/${pricingGroupId}/plans/${pricingPlanId}/services`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getCharges: builder.query({
      query: () => ({ url: "/pricing/chargetypes", method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getMargins: builder.query({
      query: ({ pricingGroupId, pricingPlanId, serviceId, chargeId }) => ({ url: `/pricing/groups/${pricingGroupId}/plans/${pricingPlanId}/services/${serviceId}/charges/${chargeId}/margins`, method: "GET" }),
      providesTags: ["Margins"],
      transformResponse: (response) => response.data,
    }),
    editMargin: builder.mutation({
      query: ({ pricingGroupId, pricingPlanId, serviceId, chargeTypeId, payload }) => ({ url: `/pricing/groups/${pricingGroupId}/plans/${pricingPlanId}/services/${serviceId}/charges/${chargeTypeId}/margins`, method: "PUT", body: payload }),
      invalidatesTags: ["Margins"],
      transformResponse: (response) => response.data,
    }),
  }),
})

export const {
  useAddPricingGroupMutation,
  useAddPricingPlanMutation,
  useDeletePricingGroupMutation,
  useDeletePricingPlanMutation,
  useEditMarginMutation,
  useEditPricingGroupMutation,
  useEditPricingPlanMutation,
  useGetCarriersQuery,
  useGetCarrierAccountsQuery,
  useGetChargesQuery,
  useGetMarginsQuery,
  useGetPricingGroupsQuery,
  useGetPricingPlansQuery,
  useGetServicesQuery,
} = pricingRestApiSlice
