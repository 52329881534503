import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { Checkbox } from "@mui/material"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import AppConfig from "../../../../../../utils/app-config"
import Currency from "../../../../../currency"
import DateTimeService from "../../../../../../services/date-time"
import MoreMenu from "../../../../../more-menu"

import CustomChip from "../../../../../audits/components/audit/style"
import { useGetClientDetailsQuery } from "../../../../../clients/slice"

import BILLING_TYPE_ENUM from "../../../../../../utils/billing"
import { deselectInvoice, selectInvoice } from "../../../../../../reducers/invoicesReducer"
import InvoiceDrawers from "./components/invoice-drawers"

function InvoiceRow({ invoice }) {
  const isPaid = invoice.paymentStatus === "paid"
  const { t } = useTranslation("invoices")
  const customTheme = useTheme()

  const dispatch = useDispatch()
  const selectedInvoiceIds = useSelector((state) => state.selectedInvoices.selectedInvoiceIds)
  const handleSelect = (id) => {
    dispatch(selectInvoice(id))
  }

  const handleDeselect = (id) => {
    dispatch(deselectInvoice(id))
  }

  const userId = invoice?.user?.pk?.slice(2) || invoice?.company?.userId
  const { data: clientDetails } = useGetClientDetailsQuery(userId)
  const invoiceOwner = invoice.company || invoice.user

  const [onSubmitEvent, setSubmitEvent] = useState()
  const [showChargeCardDrawer, setShowChargeCardDrawer] = useState(false)
  const [showRecordPaymentDrawer, setShowRecordPaymentDrawer] = useState(false)
  const [showEmailInvoiceDrawer, setShowEmailInvoiceDrawer] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleAddRecordPaymentClick = () => {
    setSubmitEvent(undefined)
    setShowRecordPaymentDrawer(true)
  }

  const handlePrintClick = () => {
    window.open(`${AppConfig.aws.restApi.apiUrl}/invoices/print/${invoice.id}`, "_blank")
  }

  const handleEmailInvoiceClick = () => {
    setShowEmailInvoiceDrawer(true)
  }

  const handleChargeCardClick = () => {
    setShowChargeCardDrawer(true)
  }

  const userHasValidPaymentMethods = () => {
    const validPaymentMethods = clientDetails?.paymentMethods?.filter((paymentMethod) => paymentMethod.status !== "deleted")
    return validPaymentMethods?.length > 0
  }

  const isOverdue = () => !isPaid && new Date(invoice.dueDate) < new Date()

  const sideMenuOptionList = () => {
    let options = []
    if (isPaid) {
      options = [
        {
          label: t("invoicesTable.optionsList.print"),
          handleClick: handlePrintClick,
        },
        {
          label: t("invoicesTable.optionsList.emailInvoice"),
          handleClick: handleEmailInvoiceClick,
        },
      ]
    } else {
      options = [
        {
          label: t("invoicesTable.optionsList.recordPayment"),
          handleClick: handleAddRecordPaymentClick,
        },
        {
          label: t("invoicesTable.optionsList.print"),
          handleClick: handlePrintClick,
        },
        {
          label: t("invoicesTable.optionsList.emailInvoice"),
          handleClick: handleEmailInvoiceClick,
        },
      ]
      if (userHasValidPaymentMethods()) {
        options.unshift({
          label: t("invoicesTable.optionsList.chargePaymentMethod"),
          handleClick: handleChargeCardClick,
        })
      }
    }
    return options
  }

  const handleClose = () => {
    setShowRecordPaymentDrawer(false)
  }

  const handleCheckboxClick = () => {
    if (selectedInvoiceIds.includes(invoice.id)) {
      handleDeselect(invoice.id)
    } else if (selectedInvoiceIds.length < 10) {
      // to accommodate the 30-second timeout from our Lambdas for bulk emailing. 10 is a safe number to prevent timeout, actual max is around 17
      handleSelect(invoice.id)
    }
  }

  return (
    <>
      <TableRow hover component="tr" sx={{ backgroundColor: customTheme.palette.contentNeutralPrimaryReverse.main }} onClick={handleCheckboxClick}>
        <TableCell component="td" scope="row">
          <Checkbox name={t("invoicesStatus.selectedInvoices.label")} checked={selectedInvoiceIds.includes(invoice.id)} onChange={handleCheckboxClick} />
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography>{invoice.invoiceNumber}</Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography>{invoice.paymentStatus}</Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography>{DateTimeService.toLocaleDateString(invoice.createdDate)}</Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography color={isOverdue() ? "red" : ""}>{DateTimeService.toLocaleDateString(invoice.dueDate)}</Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography component="div">
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
              <Typography>
                <Typography noWrap sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                  {invoiceOwner?.clientAddress?.personName ?? "Unavailable"}
                </Typography>
                {` (${invoiceOwner?.companyName ?? "Unavailable"})`}
              </Typography>
            </Box>
            <Typography noWrap variant="bodySmall400">
              {invoiceOwner?.emailAddress}
            </Typography>
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography>
            <Currency amount={invoice.summary?.invoice?.total} currency={invoice.currency} currencyPosition="right" showSymbol={false} />
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography>{invoice.summary?.amountPaid !== 0 && <Currency amount={invoice.summary?.amountPaid} currency={invoice.currency} currencyPosition="right" showSymbol={false} />}</Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography>{invoice.summary?.amountOutstanding !== 0 && <Currency amount={invoice.summary?.amountOutstanding} currency={invoice.currency} currencyPosition="right" showSymbol={false} />}</Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          {invoiceOwner.billing?.type === BILLING_TYPE_ENUM.ACCOUNT && (
            <Chip label={t(`invoicesStatus.readyToInvoice.userGroup.${invoiceOwner?.billing?.type}`)} size="small" color="primary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />
          )}
          {invoiceOwner.billing?.type === BILLING_TYPE_ENUM.CREDIT_CARD && (
            <Chip label={t(`invoicesStatus.readyToInvoice.userGroup.${invoiceOwner?.billing?.type}`)} size="small" color="secondary" sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />
          )}
        </TableCell>
        <TableCell component="td" scope="row">
          {invoice?.sentByEmail && <CustomChip label={t("invoicesTable.emailSent")} color="success" size="small" sx={{ color: "white" }} />}
        </TableCell>
        <TableCell component="td" scope="row" align="right">
          <MoreMenu optionsList={sideMenuOptionList()} />
        </TableCell>
      </TableRow>
      <InvoiceDrawers
        showEmailInvoiceDrawer={showEmailInvoiceDrawer}
        handleClose={handleClose}
        showRecordPaymentDrawer={showRecordPaymentDrawer}
        setShowEmailInvoiceDrawer={setShowEmailInvoiceDrawer}
        isLoading={isLoading}
        showChargeCardDrawer={showChargeCardDrawer}
        isPaid={isPaid}
        onSubmitEvent={onSubmitEvent}
        setIsLoading={setIsLoading}
        setShowRecordPaymentDrawer={setShowRecordPaymentDrawer}
        setShowChargeCardDrawer={setShowChargeCardDrawer}
        invoice={invoice}
        setSubmitEvent={setSubmitEvent}
      />
    </>
  )
}

InvoiceRow.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
}

export default InvoiceRow
