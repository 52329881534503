import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Skeleton from "@mui/material/Skeleton"

import AppConfig from "../../../../../../utils/app-config"
import SummaryCard from "../../../../../summary-card"

import { useGetAttachmentsQuery } from "../../slice"

function Attachments({ selectedOrderId }) {
  const { data: attachments, isFetching } = useGetAttachmentsQuery({ orderId: selectedOrderId })

  const handleAttachmentClick = (filename) => {
    window.open(`${AppConfig.aws.restApi.apiUrl}/orders/${selectedOrderId}/attachments/${encodeURIComponent(filename)}`, "_blank")
  }

  return (
    <Box>
      {isFetching ? (
        <>
          {Array(3)
            .fill()
            .map((_, index) => {
              return (
                <Box key={"".concat(index)} sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
                  <Skeleton variant="rectangular" height={50} width="100%" />
                </Box>
              )
            })}
        </>
      ) : (
        attachments?.map((attachment, index) => {
          return (
            <SummaryCard
              sx={(theme) => ({
                marginBottom: theme.spacing(2),
                cursor: "pointer",
              })}
              key={`${index.toString()}`}
              summaryStatusProps={undefined}
              title={attachment}
              handleClick={() => handleAttachmentClick(attachment)}
            />
          )
        })
      )}
    </Box>
  )
}

Attachments.propTypes = {
  selectedOrderId: PropTypes.string.isRequired,
}

export default Attachments
