// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AutoCompletePaper from "../../../../../../soe-theme/src/components/auto-complete-paper"
import TranslationService from "../../../../../../services/translation"

import { useGetCountriesQuery } from "../../../../../../services/address-form/slice"

function Country({ formik, fieldFormat }) {
  const theme = useTheme()
  const { i18n, t } = useTranslation("country")
  const { data: countries } = useGetCountriesQuery()

  const SPACING = 10
  const size = fieldFormat?.grid || 12
  const label = fieldFormat?.label && TranslationService.getLabelFromLocale(i18n.language, fieldFormat.label)

  return (
    <Grid item xs={12} md={size} my={theme.utils.pxToThemeSpacing(SPACING)}>
      <Autocomplete
        id="countryCode"
        disablePortal
        value={countries?.find((country) => country?.code === formik?.values?.countryCode) || null}
        onChange={(_, value) => {
          formik.setFieldValue("provinceName", "")
          formik.setFieldValue("provinceCode", "")
          formik.setFieldValue("countryCode", value ? value.code : undefined)
        }}
        onBlur={formik.handleBlur}
        getOptionLabel={(option) => TranslationService.getLabelFromLocale(i18n.language, option.name)}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        options={countries || []}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.code}>
              {TranslationService.getLabelFromLocale(i18n.language, option.name)}
            </li>
          )
        }}
        PaperComponent={AutoCompletePaper}
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" error={formik.touched.countryCode && formik.errors.countryCode !== undefined} />}
        noOptionsText={t("fields.country.noOptionsText")}
        disableClearable
        fullWidth
        size="small"
      />
    </Grid>
  )
}

Country.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  fieldFormat: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string.isRequired,
      fr: PropTypes.string,
    }).isRequired,
    name: PropTypes.string.isRequired,
    grid: PropTypes.number.isRequired,
    form: PropTypes.shape({
      attributes: PropTypes.shape({
        inputType: PropTypes.string,
        maxlength: PropTypes.string,
      }),
    }),
    validations: PropTypes.shape({
      isRequired: PropTypes.bool,
      maxLength: PropTypes.number,
      minLength: PropTypes.number,
      regex: PropTypes.shape({
        error: PropTypes.shape({
          code: PropTypes.string,
          messages: PropTypes.shape({
            en: PropTypes.string.isRequired,
            fr: PropTypes.string,
          }),
        }),
        test: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default Country
