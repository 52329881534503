import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import LoadingButton from "@mui/lab/LoadingButton"
import PropTypes from "prop-types"
import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import DateTimeService from "../../../../../../../../services/date-time"
import RateRequestSummaryCard from "../../../../../../../rate-request-summary-card"

import { AuditItemProps, AuditItemSectionProps } from "../../../../../../../../models/audit"
import { pxToRem, pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"
import { AuditStatusEnum } from "../../../../../../constant"
import CustomChip from "./style"

function ClientDetailsHeader({ order, user, shippingOptions, auditStatus, handleUnmatchClick, handleValidateClick, handleUnvalidateClick, validateLoading, unvalidateLoading, unmatchLoading }) {
  const { t } = useTranslation("audits")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard sx={{ height: 1 }}>
      <Grid container>
        <Grid item xs={8}>
          {user.clientAddress?.personName && (
            <Box sx={{ height: theme.utils.pxToRem(36), display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
              <Typography noWrap sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22), fontSize: pxToRem(20) }} variant="bodySmall400">
                <b>{user.clientAddress.companyName ? user.clientAddress.companyName : user.clientAddress.personName}</b>
                {user.clientAddress.companyName ? ` (${user.clientAddress.personName})` : ""}
              </Typography>
            </Box>
          )}
          <Box mt={pxToThemeSpacing(10)}>
            <Stack direction="column">
              {order.number && (
                <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
                  {t("auditDetails.auditItemDetails.clientDetails.client.orderNumber")} <b>{order.number}</b>
                  {order.legacyOrderNumber ? ` (${order.legacyOrderNumber})` : ""}
                </Typography>
              )}
              {user.pricingGroup?.name && (
                <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
                  {t("auditDetails.auditItemDetails.clientDetails.client.pricingGroupName")} <b>{user.pricingGroup?.name}</b>
                </Typography>
              )}
              {shippingOptions?.shippingDate && (
                <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
                  {t("auditDetails.auditItemDetails.clientDetails.client.shippingDate")} <b>{DateTimeService.toLocaleDateString(shippingOptions.shippingDate)}</b>
                </Typography>
              )}{" "}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "right", alignItems: "start" }}>
          {auditStatus !== AuditStatusEnum.INVOICED && auditStatus !== AuditStatusEnum.VALIDATED ? (
            <>
              <LoadingButton variant="contained" onClick={handleValidateClick} loading={validateLoading}>
                {t("auditDetails.auditItemDetails.clientDetails.client.actions.validate")}
              </LoadingButton>
              <Box ml={pxToThemeSpacing(10)}>
                <LoadingButton variant="outlined" onClick={handleUnmatchClick} loading={unmatchLoading}>
                  {t("auditDetails.auditItemDetails.clientDetails.client.actions.unmatch")}
                </LoadingButton>
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" height={1}>
                <CustomChip color="success" label={auditStatus} sx={{ textTransform: "capitalize", height: pxToRem(40) }} />
              </Box>
              {auditStatus !== AuditStatusEnum.INVOICED && (
                <Box ml={pxToThemeSpacing(10)}>
                  <LoadingButton variant="outlined" color="error" onClick={handleUnvalidateClick} loading={unvalidateLoading}>
                    {t("auditDetails.auditItemDetails.clientDetails.client.actions.unvalidate")}
                  </LoadingButton>
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </RateRequestSummaryCard>
  )
}

ClientDetailsHeader.propTypes = {
  order: AuditItemProps.isRequired,
  user: AuditItemProps.isRequired,
  shippingOptions: AuditItemSectionProps.isRequired,
  auditStatus: PropTypes.string,
  handleValidateClick: PropTypes.func,
  handleUnvalidateClick: PropTypes.func,
  handleUnmatchClick: PropTypes.func,
  validateLoading: PropTypes.bool,
  unvalidateLoading: PropTypes.bool,
  unmatchLoading: PropTypes.bool,
}

ClientDetailsHeader.defaultProps = {
  auditStatus: undefined,
  handleValidateClick: () => {},
  handleUnvalidateClick: () => {},
  handleUnmatchClick: () => {},
  validateLoading: false,
  unvalidateLoading: false,
  unmatchLoading: false,
}

export default ClientDetailsHeader
