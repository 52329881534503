import TableCell from "@mui/material/TableCell"
import tableCellClasses from "@mui/material/TableCell/tableCellClasses"
import { styled } from "@mui/material/styles"

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.contentActionDefault.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.utils.pxToRem(14),
  },
}))

export default StyledTableCell
