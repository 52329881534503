import PropTypes from "prop-types";
import React, { useEffect } from "react";

// MUI
import { FormControl, InputLabel, MenuItem, Select, TextField, Box } from "@mui/material";

// Formik
import { useFormik } from "formik";

// Theme
import { useUpdateInexFeeMutation, useCreateInexFeeMutation } from "../../../../../../../../../services/inex-fees/slice";

function CompanyInexFeesDrawer({ inexFee, onSubmitEvent, setShowCompanyInexFeesDrawer, refetchInexFee, company }) {
  const [updateInexFee] = useUpdateInexFeeMutation();
  const [createInexFee] = useCreateInexFeeMutation();

  const feeTypes = [
    {
      id: 1,
      type: "third-party-billing",
    },
    {
      id: 2,
      type: "email-label",
    },
    {
      id: 3,
      type: "billing-sender",
    },
  ];

  const formik = useFormik({
    initialValues: {
      feeAmount: inexFee?.feeAmount ?? "",
      feeType: inexFee?.feeType ?? "",
    },
    validate: (formValues) => {
      const errors = {};

      if (formValues.feeAmount === "" || formValues.feeAmount === null) {
        errors.feeAmount = "Fee amount is required"; // t("fields.feeAmount.error.required")
      }

      if (!formValues.feeType) {
        errors.feeType = "Fee type is required"; // t("fields.feeType.error.required")
      }

      return errors;
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      const payload = {
        companyId: company.id,
        feeAmount: formValues.feeAmount,
        feeType: formValues.feeType,
      };
      if (inexFee) {
        await updateInexFee({ inexFeeId: inexFee.id, payload });
      } else {
        await createInexFee(payload);
      }
      formik.resetForm();
      setShowCompanyInexFeesDrawer(false);
      setTimeout(() => refetchInexFee(), 200);
    },
  });

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit();
    }
  }, [onSubmitEvent]);

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" sx={(theme) => ({ display: "flex", marginBottom: theme.spacing(1) })}>
          <TextField
            sx={(theme) => ({ marginRight: theme.spacing(1) })}
            id="feeAmount"
            variant="outlined"
            label="Fee Amount"
            size="small"
            value={formik.values.feeAmount !== "" ? formik.values.feeAmount : ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.feeAmount && Boolean(formik.errors.feeAmount)}
            helperText={formik.touched.feeAmount && formik.errors.feeAmount}
            inputMode="numeric"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
          />
          <FormControl fullWidth error={formik.touched.feeType && Boolean(formik.errors.feeType)}>
            <InputLabel />
            <Select
              name="feeType"
              onChange={formik.handleChange}
              value={formik.values.feeType}
              size="small"
              error={formik.touched.feeType && Boolean(formik.errors.feeType)}
            >
              {feeTypes.map((type) => (
                <MenuItem key={type.id} value={type.type}>
                  {type.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </form>
    </Box>
  );
}

CompanyInexFeesDrawer.defaultProps = {
  onSubmitEvent: undefined,
  inexFee: undefined,
  company: undefined,
};

CompanyInexFeesDrawer.propTypes = {
  inexFee: PropTypes.shape({
    id: PropTypes.string,
    pk: PropTypes.string,
    companyId: PropTypes.string,
    feeAmount: PropTypes.number,
    feeType: PropTypes.string,
  }),
  company: PropTypes.shape({
    id: PropTypes.string,
  }),
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowCompanyInexFeesDrawer: PropTypes.func.isRequired,
  refetchInexFee: PropTypes.func.isRequired,
};

export default CompanyInexFeesDrawer;
