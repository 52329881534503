import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import TextField from "@mui/material/TextField"

import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

import CustomDatePicker from "../../../date-picker"

import { useCreatePaymentRecordMutation } from "../../slice"

function RecordPaymentDrawer({ invoice, onSubmitEvent, setShowRecordPaymentDrawer, setIsLoading }) {
  const { t } = useTranslation("invoices")
  const [paymentRecord, { isLoading }] = useCreatePaymentRecordMutation()

  const formik = useFormik({
    initialValues: {
      paymentDate: useMemo(() => new Date(), []),
      amount: (invoice?.summary?.amountOutstanding !== undefined ? invoice.summary.amountOutstanding : invoice.summary.invoice.total).toFixed(2),
      currency: invoice.currency || "CAD",
      note: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.paymentDate) {
        errors.paymentDate = true
      }
      if (!formValues.amount || Number.isNaN(parseFloat(formValues.amount)) || parseFloat(formValues.amount) <= 0) {
        errors.amount = true
      }
      if (!formValues.currency) {
        errors.currency = true
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      formValues.paymentDate = new Date(formValues.paymentDate).toISOString()
      formValues.amount = parseFloat(formValues.amount)
      await paymentRecord({ id: invoice.id, payload: formValues })
      setShowRecordPaymentDrawer(false)
      formik.handleReset()
    },
  })

  useEffect(() => {
    formik.handleReset()
    formik.setErrors({})
    formik.setTouched({}, false)
  }, [])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <CustomDatePicker
            label={t("recordPaymentDrawer.form.fields.date.label")}
            name="paymentDate"
            value={formik.values.paymentDate}
            error={formik.touched.paymentDate && formik.errors.paymentDate}
            onChange={(value) => formik.setFieldValue("paymentDate", value)}
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            sx={(theme) => ({ marginRight: theme.spacing(1) })}
            id="amount"
            variant="outlined"
            label={t("recordPaymentDrawer.form.fields.amount.label")}
            size="small"
            value={formik.values.amount || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.amount && formik.errors.amount !== undefined}
            InputProps={{ endAdornment: <InputAdornment position="end">{formik.values.currency}</InputAdornment> }}
            fullWidth
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            sx={(theme) => ({ marginRight: theme.spacing(1) })}
            id="note"
            variant="outlined"
            label={t("recordPaymentDrawer.form.fields.note.label")}
            size="small"
            value={formik.values.note || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            fullWidth
          />
        </Box>
      </form>
    </Box>
  )
}

RecordPaymentDrawer.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowRecordPaymentDrawer: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
}

RecordPaymentDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

export default RecordPaymentDrawer
