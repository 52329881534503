import { Box } from "@mui/material"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useEditClientDetailsMutation, useGetClientsQuery } from "../../../clients/slice"
import ClientsAutocomplete from "../../../clients-autocomplete"

function SetAdminDrawer({ onSubmitEvent, setShowSetAdminDrawer }) {
  const { data: clients } = useGetClientsQuery()
  const [setClientDetails] = useEditClientDetailsMutation()

  const formik = useFormik({
    initialValues: {
      client: "",
    },
    validate: (formvalues) => {
      const errors = {}

      if (!formvalues.client) {
        errors.client = true
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async ({ client }) => {
      await setClientDetails({ clientId: client.pk.slice(2), payload: { isAdmin: true } })
      setShowSetAdminDrawer(false)
    },
  })

  useEffect(() => {
    formik.handleReset()
  }, [])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <form>
      <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
        <ClientsAutocomplete formik={formik} clients={clients?.filter((client) => !client.isAdmin)} />
      </Box>
    </form>
  )
}

SetAdminDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

SetAdminDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowSetAdminDrawer: PropTypes.func.isRequired,
}

export default SetAdminDrawer
