import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"

import RateRequestSummaryCard from "../../../../../rate-request-summary-card"
import { pxToRem } from "../../../../../../soe-theme/src/utils"

function StatisticsCard({ title, statistic }) {
  const theme = useTheme()

  const formatValue = (thisStatistic) => {
    const { type, value, units } = thisStatistic
    if (type === "currency") {
      return `${parseFloat(value)?.toFixed(2)} ${units}`
    }
    if (type === "percentage") {
      return `${(value * 100).toFixed(1)} %`
    }
    return value
  }

  const formatTotalValue = (thisStatistic) => {
    const { type, value, total } = thisStatistic
    if (type === "currency") {
      return `${parseFloat(total)?.toFixed(2)} (${(total !== 0 ? (value / total) * 100 : 0).toFixed(0)}%)`
    }
    if (type === "percentage") {
      return `${(total * 100).toFixed(1)}`
    }
    return `${total} (${(total !== 0 ? (value / total) * 100 : 0).toFixed(0)}%)`
  }

  return (
    <RateRequestSummaryCard title={title}>
      <Box
        mt={1}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22), fontSize: pxToRem(18) }} variant="bodySmall400" gutterBottom>
              <b>{formatValue(statistic)}</b>
            </Typography>
          </Grid>
          <Grid item sx={{ ml: theme.spacing(1) }}>
            <Typography sx={{ color: theme.palette.contentNeutralTertiaryForm.main, lineHeight: theme.utils.pxToLineHeight(16, 22), fontSize: pxToRem(18) }} variant="bodySmall400" gutterBottom>
              <i>of {formatTotalValue(statistic)}</i>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </RateRequestSummaryCard>
  )
}

StatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  statistic: PropTypes.instanceOf(Object).isRequired,
}

export default StatisticsCard
