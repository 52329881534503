// REACT
import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Divider from "@mui/material/Divider"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import LanguageSelector from "../../../../soe-theme/src/components/language-selector"
import { forgotPassword, showSignIn } from "../../../../reducers/authenticatorReducer"
import { LANGUAGES } from "../../../../constant"

function ForgotPassword() {
  const customTheme = useTheme()
  const { i18n, t } = useTranslation("authenticator")
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.email) {
        errors.email = t("forgotPassword.fields.email.error.required")
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = t("forgotPassword.fields.email.error.invalid")
      }
      return errors
    },
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(forgotPassword(formValues))
    },
  })

  return (
    <Box className="forgotPassword" component="div" my="auto" sx={{ display: "flex", justifyContent: "center" }}>
      <Card mt={2} mb={2}>
        <CardHeader title={t("forgotPassword.title")} />
        <Divider />
        <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(452) })}>
          <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                id="email"
                inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToLineHeight(16, 24) } }}
                label={t("forgotPassword.fields.email.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email !== undefined}
                helperText={(formik.touched.email && formik.errors.email) || " "}
                required
                autoFocus
              />
              <Box component="div" pb={1}>
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={(theme) => ({ fontSize: theme.typography.bodyMedium600.fontSize, textAlignLast: "center" })}>{t("forgotPassword.sendEmail")}</Typography>
                </CardContent>
              </Box>
              <Box component="div" pt={1}>
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Button type="submit" sx={{ display: "flex", textTransform: "none", borderRadius: "2px" }}>
                    {t("forgotPassword.fields.forgotPasswordButton.label")}
                  </Button>
                </CardContent>
              </Box>
            </form>
            <Box component="div" pt={2}>
              <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                <Link onClick={() => dispatch(showSignIn())} sx={(theme) => ({ textDecoration: "none", fontSize: theme.typography.bodyMedium600.fontSize })}>
                  {t("forgotPassword.backtoSignIn")}
                </Link>
              </CardContent>
            </Box>
            <Box component="div" pt={33}>
              <CardContentNoPaddingBottom sx={{ display: "flex", justifyContent: "center" }}>
                <LanguageSelector i18n={i18n} languages={LANGUAGES} />
              </CardContentNoPaddingBottom>
            </Box>
          </CardContentNoPaddingBottom>
        </Box>
      </Card>
    </Box>
  )
}

export default ForgotPassword
