// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Switch from "@mui/material/Switch"
import TextField from "@mui/material/TextField"

// Formik
import { useFormik } from "formik"

// Others
import AutoCompletePaper from "../../../../soe-theme/src/components/auto-complete-paper"

import { useAddPricingGroupMutation } from "../../slice"

function PricingGroupAdd({ selectedPricingGroup, pricingGroups, onSubmitEvent, setSubmitEvent, setShowPricingGroupAddDrawer }) {
  const { t } = useTranslation("pricing")
  const [duplicate, setDuplicate] = useState(false)

  const [addPricingGroup] = useAddPricingGroupMutation()

  const formik = useFormik({
    initialValues: {
      pricingGroupName: "",
      isDefault: false,
      copyFrom: selectedPricingGroup ? selectedPricingGroup.code : undefined,
    },
    validateOnChange: true,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}

      if (!values.pricingGroupName) {
        errors.pricingGroupName = true
      }
      return errors
    },
    onSubmit: async (formValues) => {
      addPricingGroup(formValues)
      setShowPricingGroupAddDrawer(false)
    },
  })

  useEffect(() => {
    if (formik.values.copyFrom) {
      setDuplicate(true)
    }
  }, [formik.values.copyFrom])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
      setSubmitEvent(undefined)
    }
  }, [onSubmitEvent])

  const handleIsDefaultChange = (event) => {
    formik.setFieldValue("isDefault", event.target.checked)
  }

  const handleDuplicateChange = (event) => {
    setDuplicate(event.target.checked)
    if (duplicate) {
      formik.setFieldValue("copyFrom", undefined)
    }
  }

  const handleCopyFromChange = (_, value) => {
    formik.setFieldValue("copyFrom", value.code)
  }

  return (
    <Box component="div">
      <CardContent variant="outlined" sx={(theme) => ({ width: "auto", borderRadius: 1, borderColor: theme.palette.contentActionDefault.main })}>
        <FormGroup>
          <TextField
            id="pricingGroupName"
            value={formik.values.pricingGroupName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.pricingGroupName && formik.errors.pricingGroupName !== undefined}
            variant="outlined"
            size="small"
            label={t("pricingGroupDrawer.pricingGroupName.fields.label")}
          />
          <FormControlLabel control={<Switch color="secondary" checked={formik.values.isDefault} onChange={handleIsDefaultChange} />} label={t("pricingGroupDrawer.setAsDefault.fields.label")} value={formik.values.pricingGroupName} />
          <FormControlLabel control={<Switch color="secondary" checked={duplicate} onChange={handleDuplicateChange} />} label={t("pricingGroupDrawer.duplicate.fields.label")} />
          {duplicate && (
            <CardContent variant="outlined" sx={(theme) => ({ paddingRight: 0, width: "auto", borderRadius: 1, borderColor: theme.palette.contentActionDefault.main })}>
              <Autocomplete
                id="copyFrom"
                disablePortal
                value={(pricingGroups && pricingGroups.find((pricingGroup) => pricingGroup.code === formik.values.copyFrom)) || null}
                onChange={handleCopyFromChange}
                onBlur={formik.handleBlur}
                getOptionLabel={(option) => (option && option.name) || ""}
                isOptionEqualToValue={(option, value) => {
                  return option.code === value.code
                }}
                options={pricingGroups || []}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.code}>
                      {option.name}
                    </li>
                  )
                }}
                PaperComponent={AutoCompletePaper}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("pricingGroupDrawer.duplicateFrom.fields.label")}
                    variant="outlined"
                    required
                    error={formik.touched.copyFrom && formik.errors.copyFrom !== undefined}
                    helperText={(formik.touched.copyFrom && formik.errors.copyFrom) || " "}
                  />
                )}
                noOptionsText={t("pricingGroupDrawer.duplicateFrom.noOptionText.label")}
                disableClearable
                size="small"
                fullWidth
              />
            </CardContent>
          )}
        </FormGroup>
      </CardContent>
    </Box>
  )
}

PricingGroupAdd.propTypes = {
  selectedPricingGroup: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    isDefault: PropTypes.bool,
    pricingPlans: PropTypes.arrayOf(Object),
  }),
  pricingGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  setSubmitEvent: PropTypes.func,
  setShowPricingGroupAddDrawer: PropTypes.func.isRequired,
}

PricingGroupAdd.defaultProps = {
  selectedPricingGroup: undefined,
  onSubmitEvent: undefined,
  setSubmitEvent: undefined,
}

export default PricingGroupAdd
