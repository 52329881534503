import React from "react"
import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"

function AddressSummary({ addressSummary }) {
  return (
    <>
      <Box component="div">
        <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {addressSummary?.companyName || addressSummary?.personName}
        </Typography>
      </Box>
      <Box component="div">
        <Typography variant="bodySmall400">{addressSummary?.personName || addressSummary?.companyName}</Typography>
      </Box>
      <Box component="div">
        <Typography variant="bodySmall400" gutterBottom>
          {addressSummary?.addressLine1 || ""} {addressSummary?.addressLine2 || ""} {addressSummary?.city || ""} {addressSummary?.provinceCode || ""} {addressSummary?.countryCode || ""} {addressSummary?.postalCode || ""}
        </Typography>
      </Box>
    </>
  )
}
AddressSummary.propTypes = {
  addressSummary: PropTypes.shape({
    companyName: PropTypes.string,
    personName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    provinceCode: PropTypes.string,
    countryCode: PropTypes.string,
  }).isRequired,
}

export default AddressSummary
