// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import SummaryCard from "../../../../../summary-card"

// Others
import SummaryStatusVariantsEnum from "../../../../../summary-card/components/summary-status/contant"

function GeneralInfoPasswordCard({ handleGeneralInfoPasswordCardClick }) {
  const { t } = useTranslation("userProfile")

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return <SummaryCard title={t("userProfile.generalInfoPasswordCard.changePassword.title")} handleClick={handleGeneralInfoPasswordCardClick} summaryStatusProps={summaryStatusProps} />
}

GeneralInfoPasswordCard.propTypes = {
  handleGeneralInfoPasswordCardClick: PropTypes.func,
}

GeneralInfoPasswordCard.defaultProps = {
  handleGeneralInfoPasswordCardClick: () => {},
}

export default GeneralInfoPasswordCard
