import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CarrierServiceLogo from "../../../../../carrier-service-logo"
import DateTimeService from "../../../../../../services/date-time"

import { AuditProps } from "../../../../../../models/audit"
import { pxToThemeSpacing } from "../../../../../../soe-theme/src/utils"

function AuditDetailsHeader({ audit }) {
  const { t } = useTranslation("audits")
  const theme = useTheme()

  return (
    <Card variant="outlined" square>
      <Box ml={pxToThemeSpacing(20)}>
        <Grid container>
          <Grid item xs={12} p={pxToThemeSpacing(10)}>
            {audit.carrier && <CarrierServiceLogo logo={audit.carrier.logo} align="left" />}
          </Grid>
        </Grid>
        <Grid container pb={pxToThemeSpacing(10)} pl={pxToThemeSpacing(10)}>
          <Grid item xs={3}>
            <Stack direction="column">
              {audit.filename && (
                <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {t("auditDetails.auditDetailsHeader.filename")} <b>{audit.filename}</b>
                </Typography>
              )}
              {audit.invoice?.accountNumber && (
                <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {t("auditDetails.auditDetailsHeader.accountNumber")} <b>{audit.invoice.accountNumber}</b>
                </Typography>
              )}
              {audit.invoice?.number && (
                <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {t("auditDetails.auditDetailsHeader.invoiceNumber")} <b>{audit.invoice.number}</b>
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column">
              {audit.createdDate && (
                <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {t("auditDetails.auditDetailsHeader.createdDate")}{" "}
                  <b>
                    {DateTimeService.toLocaleDateString(audit.createdDate)} {DateTimeService.toLocaleTimeString(audit.createdDate)}
                  </b>
                </Typography>
              )}
              {audit.invoice?.date && (
                <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {t("auditDetails.auditDetailsHeader.invoiceDate")} <b>{DateTimeService.toLocaleDateString(audit.invoice.date)}</b>
                </Typography>
              )}
              {audit.modifiedDate && (
                <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {t("auditDetails.auditDetailsHeader.modifiedDate")}{" "}
                  <b>
                    {DateTimeService.toLocaleDateString(audit.modifiedDate)} {DateTimeService.toLocaleTimeString(audit.modifiedDate)}
                  </b>
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <div />
          </Grid>
          <Grid item xs={3}>
            <div />
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

AuditDetailsHeader.propTypes = {
  audit: AuditProps.isRequired,
}

export default AuditDetailsHeader
