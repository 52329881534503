// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import isEmpty from "lodash/isEmpty"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AutoCompletePaper from "../../../../../../soe-theme/src/components/auto-complete-paper"
import TranslationService from "../../../../../../services/translation"

import { useEditCarrierChargeTypesMutation, useGetCarrierChargesQuery } from "../../../../../carriers/slice"

function ChargeMappingError({ error, carrierCode }) {
  const { i18n, t } = useTranslation("audits")
  const theme = useTheme()

  const { data: charges } = useGetCarrierChargesQuery(carrierCode, { skip: !carrierCode })
  const [editCarrierChargeTypes] = useEditCarrierChargeTypesMutation()

  const formik = useFormik({
    initialValues: {
      charge: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (formValues.charge === "") {
        errors.charge = t("auditError.chargeMappingError.fields.charge.error.required")
      }
      return errors
    },
    validateOnChange: false,
    onSubmit: async (formValues) => {
      const payload = { ...formValues.charge, billingCode: isEmpty(formValues.charge.billingCode) ? error.billingCode : formValues.charge.billingCode.concat(`,${error.billingCode}`) }
      editCarrierChargeTypes({ code: carrierCode, chargeTypeCode: payload.code, payload })
    },
  })

  return (
    <Box
      mt={theme.utils.pxToThemeSpacing(20)}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
      }}
    >
      {error.billingCode && (
        <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
          {t("auditError.chargeMappingError.codeLabel")} <b>{error.billingCode}</b>
        </Typography>
      )}
      {error.billingDescription && (
        <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
          {t("auditError.chargeMappingError.descriptionLabel")} <b>{error.billingDescription}</b>
        </Typography>
      )}
      <Box
        mt={theme.utils.pxToThemeSpacing(20)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
        }}
      >
        <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
          {t("auditError.chargeMappingError.mapTo")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: 1,
          }}
        >
          <Autocomplete
            name="chargeAutocomplete"
            disablePortal
            value={formik.values.charge || null}
            onChange={(event, value) => formik.setFieldValue("charge", value)}
            onBlur={formik.handleBlur}
            getOptionLabel={(option) => option.code}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.code}>
                  <div>
                    <div>
                      <b>{option.code}</b>
                    </div>
                    <div>{TranslationService.getLabelFromLocale(i18n.language, option.name)}</div>
                  </div>
                </li>
              )
            }}
            isOptionEqualToValue={(option, value) => {
              return option.code === value.code
            }}
            options={charges || []}
            PaperComponent={AutoCompletePaper}
            renderInput={(params) => <TextField {...params} name="charge" sx={{ backgroundColor: "white", color: "black" }} required error={formik.touched.charge && formik.errors.charge !== undefined} />}
            noOptionsText={t("auditError.chargeMappingError.fields.charge.noOptionsText")}
            disableClearable
            size="small"
            fullWidth
          />
          <Button size="small" onClick={formik.handleSubmit} sx={{ ml: theme.utils.pxToThemeSpacing(10) }}>
            {t("auditError.chargeMappingError.buttons.map.label")}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

ChargeMappingError.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string,
    billingCode: PropTypes.string,
    billingDescription: PropTypes.string,
    message: PropTypes.shape({}),
    severity: PropTypes.string,
  }).isRequired,
  carrierCode: PropTypes.string,
}

ChargeMappingError.defaultProps = {
  carrierCode: undefined,
}

export default ChargeMappingError
