// REACT
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Divider from "@mui/material/Divider"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import LanguageSelector from "../../../../soe-theme/src/components/language-selector"
import { currentAuthenticatedUser, showForgotPassword, signIn } from "../../../../reducers/authenticatorReducer"
import { LANGUAGES } from "../../../../constant"

function SignIn() {
  const customTheme = useTheme()
  const { i18n, t } = useTranslation("authenticator")
  const [checkUser, setCheckUser] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (checkUser) {
      dispatch(currentAuthenticatedUser())
      setCheckUser(false)
    }
  }, [checkUser, dispatch])

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.email) {
        errors.email = t("signIn.fields.email.error.required")
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = t("signIn.fields.email.error.invalid")
      }
      if (!formValues.password) {
        errors.password = t("signIn.fields.password.error.required")
      }
      return errors
    },
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(signIn(formValues))
    },
  })

  return (
    <Box className="signIn" component="div" my="auto" sx={{ display: "flex", justifyContent: "center" }}>
      <Card mt={2} mb={2}>
        <CardHeader title={t("signIn.title")} />
        <Divider />
        <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(452) })}>
          <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                id="email"
                inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToLineHeight(16, 24) } }}
                label={t("signIn.fields.email.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email !== undefined}
                helperText={(formik.touched.email && formik.errors.email) || " "}
                required
                autoFocus
              />
              <Box component="div" p={1} />
              <TextField
                id="password"
                inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToLineHeight(16, 24) } }}
                label={t("signIn.fields.password.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password !== undefined}
                helperText={(formik.touched.password && formik.errors.password) || " "}
                required
                type="password"
              />
              <Box component="div">
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Button type="submit" sx={{ display: "flex", textTransform: "none", borderRadius: "2px" }}>
                    {t("signIn.fields.signInButton.label")}
                  </Button>
                </CardContent>
              </Box>
              <Box component="div" pb={4}>
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Link onClick={() => dispatch(showForgotPassword())} sx={(theme) => ({ textDecoration: "none", fontSize: theme.typography.bodyMedium600.fontSize })}>
                    {t("signIn.forgotPassword")}
                  </Link>
                </CardContent>
              </Box>
              <Box component="div" pt={34}>
                <CardContentNoPaddingBottom sx={{ display: "flex", justifyContent: "center" }}>
                  <LanguageSelector i18n={i18n} languages={LANGUAGES} />
                </CardContentNoPaddingBottom>
              </Box>
            </form>
          </CardContentNoPaddingBottom>
        </Box>
      </Card>
    </Box>
  )
}

export default SignIn
