import { CognitoUserPool } from "amazon-cognito-identity-js"
import AppConfig from "../../utils/app-config"

const poolData = {
  UserPoolId: AppConfig.aws.cognito.userPoolId,
  ClientId: AppConfig.aws.cognito.clientId,
  Storage: window.sessionStorage,
}

export default new CognitoUserPool(poolData)
