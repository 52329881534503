/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/accordion/
 * @link https://mui.com/api/accordion/
 *
 * @link https://mui.com/api/accordion-actions/
 *
 * @link https://mui.com/api/accordion-details/
 *
 * @link https://mui.com/api/accordion-summary/
 */
import React from "react"

import SoeArrowRightIcon from "../icons/SoeArrowRightIcon"

export default function Accordion(theme) {
  return {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        defaultExpanded: false,
        elevation: 0,
        square: true,
      },
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.divider}`,
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.contentNeutralQuaternaire.main,
          },
        },
      },
    },
    MuiAccordionActions: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiAccordionDetails: {
      defaultProps: {},
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.backgroundNeutralTransparentLightGrey.main,
          padding: theme.spacing(2),
          borderTop: `1px solid ${theme.palette.backgroundNeutralTransparentDarkGrey.main}`,
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <SoeArrowRightIcon sx={{ color: theme.palette.contentNeutralPrimary.main }} />,
      },
      styleOverrides: {
        root: {
          color: theme.palette.contentNeutralPrimary,
          backgroundColor: theme.palette.backgroundNeutralGround.main,
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        },
        content: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        },
      },
    },
  }
}
