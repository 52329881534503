import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { useTranslation } from "react-i18next"

import Currency from "../../../../../../../currency"
import DateTimeService from "../../../../../../../../services/date-time"

import { AuditItemSectionProps } from "../../../../../../../../models/audit"
import { AuditItemTypeEnum } from "../../../../../../constant"
import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"

function AuditItemCarrierCard({ auditItemType, tracking, shippingOptions, rate }) {
  const { t } = useTranslation("audits")
  return (
    <Card variant="outlined" square sx={{ height: 1 }}>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Stack m={pxToThemeSpacing(20)}>
            {tracking.masterId && auditItemType === AuditItemTypeEnum.ORDER && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {tracking.masterId}
              </Typography>
            )}
            {auditItemType === AuditItemTypeEnum.ADDITIONAL_CHARGE && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22), color: "blue" })}>
                {t("auditDetails.auditItemCarrierCard.additionalCharge")}
              </Typography>
            )}
            {shippingOptions.shippingDate && (
              <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {DateTimeService.toLocaleDateString(shippingOptions.shippingDate)}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {rate.amount !== undefined && (
            <Typography variant="bodySmall400" mr={pxToThemeSpacing(20)} sx={(theme) => ({ fontSize: theme.utils.pxToRem(14), fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              <Currency amount={rate.amount} currency={rate.currency} currencyPosition="left" showSymbol />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

AuditItemCarrierCard.propTypes = {
  auditItemType: PropTypes.string.isRequired,
  tracking: AuditItemSectionProps.isRequired,
  shippingOptions: AuditItemSectionProps.isRequired,
  rate: AuditItemSectionProps.isRequired,
}

export default AuditItemCarrierCard
