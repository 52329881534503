// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import Button from "@mui/material/Button"
import InputAdornment from "@mui/material/InputAdornment"
import PercentIcon from "@mui/icons-material/Percent"
import Switch from "@mui/material/Switch"
import TextField from "@mui/material/TextField"

// Formik
import { useFormik } from "formik"

// Custom style
import { StyledTableCell, StyledTableRow } from "./style"

// Others
import TranslationService from "../../../../services/translation"
import { useEditMarginMutation } from "../../slice"

function PricingMargin({ pricingGroupId, pricingPlanId, serviceId, chargeTypeId, pricingMargin }) {
  const { t, i18n } = useTranslation("pricing")

  const [editMargin] = useEditMarginMutation()

  const formik = useFormik({
    initialValues: {
      chargeCode: pricingMargin.chargeCode,
      chargeType: pricingMargin.chargeType,
      serviceCode: pricingMargin.serviceCode,
      marginPercentage: pricingMargin.marginPercentage,
      marginAmount: pricingMargin.marginAmount,
      adminFeePercentage: pricingMargin.adminFeePercentage,
      adminFeeAmount: pricingMargin.adminFeeAmount,
      useDefault: pricingMargin.useDefault,
      required: pricingMargin.required,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: () => {},
    onSubmit: async (formValues) => {
      editMargin({ pricingGroupId, pricingPlanId, serviceId, chargeTypeId, payload: formValues })
    },
  })

  const handleUseDefaultChange = (event) => {
    formik.setFieldValue("useDefault", event.target.checked)
  }

  return (
    <StyledTableRow>
      <StyledTableCell align="center">{pricingMargin.chargeCode}</StyledTableCell>
      <StyledTableCell align="center">{TranslationService.getLabelFromLocale(i18n.language, pricingMargin.chargeName)}</StyledTableCell>
      <StyledTableCell align="center">
        <TextField
          id="marginPercentage"
          variant="outlined"
          size="small"
          value={formik.values.marginPercentage}
          onChange={formik.handleChange}
          fullWidth={false}
          disabled={formik.values.useDefault}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PercentIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <TextField
          id="marginAmount"
          variant="outlined"
          size="small"
          value={formik.values.marginAmount}
          onChange={formik.handleChange}
          fullWidth={false}
          disabled={formik.values.useDefault}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AttachMoneyIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <TextField
          id="adminFeePercentage"
          variant="outlined"
          size="small"
          value={formik.values.adminFeePercentage}
          onChange={formik.handleChange}
          fullWidth={false}
          disabled={formik.values.useDefault}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PercentIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <TextField
          id="adminFeeAmount"
          variant="outlined"
          size="small"
          value={formik.values.adminFeeAmount}
          onChange={formik.handleChange}
          fullWidth={false}
          disabled={formik.values.useDefault}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AttachMoneyIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <Switch size="small" color="secondary" checked={formik.values.useDefault} onChange={handleUseDefaultChange} disabled={formik.values.required} />
      </StyledTableCell>
      <StyledTableCell align="center">
        <Button variant="contained" className="button-primary" color="primary" size="small" onClick={formik.handleSubmit} disabled={!formik.dirty}>
          {t("pricingMargin.actions.save.label")}
        </Button>
      </StyledTableCell>
    </StyledTableRow>
  )
}

PricingMargin.propTypes = {
  pricingGroupId: PropTypes.string.isRequired,
  pricingPlanId: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
  chargeTypeId: PropTypes.string.isRequired,
  pricingMargin: PropTypes.shape({
    adminFeeAmount: PropTypes.number,
    adminFeePercentage: PropTypes.number,
    chargeCode: PropTypes.string,
    chargeName: PropTypes.shape({}),
    chargeType: PropTypes.string,
    marginAmount: PropTypes.number,
    marginPercentage: PropTypes.number,
    serviceCode: PropTypes.string,
    serviceFullName: PropTypes.shape({}),
    serviceShortName: PropTypes.shape({}),
    useDefault: PropTypes.bool,
    required: PropTypes.bool,
  }).isRequired,
}

export default PricingMargin
