// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AutoCompletePaper from "../../../../soe-theme/src/components/auto-complete-paper"
import CarrierNamesLanguagesForm from "../carrier-names-languages-form"
import CarrierChipsForm from "../carrier-chips-form"
import CarrierCodeForm from "../carrier-code-form"
import EmptyCarrierService from "../../../../services/empty-carrier"
import TranslationService from "../../../../services/translation"
import { useAddCarrierChargeTypesMutation, useEditCarrierChargeTypesMutation } from "../../slice"

function CarrierChargeDrawer({ carrierGeneralInfoCode, carrierCharges, carrierChargeIndex, chargeTypes, languages, onSubmitEvent, setShowCarrierChargeDrawer }) {
  const customTheme = useTheme()
  const { i18n, t } = useTranslation("carriers")
  const [addCarrierChargeTypes] = useAddCarrierChargeTypesMutation()
  const [editCarrierChargeTypes] = useEditCarrierChargeTypesMutation()
  const [editableChipsArray, setEditableChipsArray] = useState([])

  const emptyName = EmptyCarrierService.getFieldsWithLanguages("name", languages)

  const formik = useFormik({
    initialValues: {
      code: (carrierCharges && carrierCharges[carrierChargeIndex] && carrierCharges[carrierChargeIndex].code) || "",
      billingCode: (carrierCharges && carrierCharges[carrierChargeIndex] && carrierCharges[carrierChargeIndex].billingCode) || "",
      name: (carrierCharges && carrierCharges[carrierChargeIndex] && carrierCharges[carrierChargeIndex].name) || emptyName,
      type: (carrierCharges && carrierCharges[carrierChargeIndex] && carrierCharges[carrierChargeIndex].type) || "",
      chips: editableChipsArray || [],
      languages: languages || undefined,
    },
    validate: (formValues) => {
      const errors = {}
      if (carrierChargeIndex === undefined && carrierCharges && carrierCharges.some((chargeType) => chargeType.code.trim().toLowerCase() === formValues.code.trim().toLowerCase())) {
        errors.code = t("carrierDetails.carrierChargeTypesDrawer.code.errors.duplicate.label")
      }
      if (formValues.code.trim() === "") {
        errors.code = t("carrierDetails.carrierChargeTypesDrawer.code.errors.empty.label")
      }
      if (carrierChargeIndex === undefined && carrierCharges && carrierCharges.some((charge) => charge.billingCode && charge.billingCode.trim().toLowerCase() === formValues.billingCode.trim().toLowerCase())) {
        errors.billingCode = t("carrierDetails.carrierChargesDrawer.code.errors.duplicate.label")
      }
      if (formValues.type === "") {
        errors.type = t("carrierDetails.carrierChargeTypesDrawer.type.errors.empty.label")
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      const editableChipsArrayWithoutEmpty = []

      const removeEmptyChip = (element) => {
        if (
          !Object.values(element).every((item) => {
            if (item.trim() === "") return true
            return false
          })
        )
          editableChipsArrayWithoutEmpty.push(element)
      }

      const checkEmptyChip = () => {
        return (
          formValues.chips &&
          formValues.chips.filter((element) => {
            return removeEmptyChip(element)
          })
        )
      }

      checkEmptyChip()
      formValues.chips = editableChipsArrayWithoutEmpty

      if (carrierCharges) {
        const formValuesWithoutLanguages = Object.fromEntries(Object.entries(formValues).filter((element) => element[0] !== "languages"))
        const temporaryName = {}
        Object.keys(formValuesWithoutLanguages.name).forEach((key) => {
          if (formValuesWithoutLanguages.name[key]) temporaryName[key] = formValuesWithoutLanguages.name[key]
          else temporaryName[key] = undefined
        })
        formValuesWithoutLanguages.name = temporaryName
        if (carrierChargeIndex === undefined) {
          await addCarrierChargeTypes({ code: carrierGeneralInfoCode, payload: formValuesWithoutLanguages })
        } else {
          await editCarrierChargeTypes({ code: carrierGeneralInfoCode, chargeTypeCode: formValuesWithoutLanguages.code, payload: formValuesWithoutLanguages })
        }
      }
      formik.resetForm()
      setShowCarrierChargeDrawer(false)
    },
  })

  useEffect(() => {
    if (carrierCharges && carrierCharges[carrierChargeIndex] && carrierCharges[carrierChargeIndex].chips) setEditableChipsArray([...(carrierCharges && carrierCharges[carrierChargeIndex] && carrierCharges[carrierChargeIndex].chips)])
  }, [carrierCharges, carrierChargeIndex])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" py={1}>
          <CarrierCodeForm
            formik={formik}
            carrierCodeLabel={t("carrierDetails.carrierChargeTypesDrawer.information.label")}
            nameLabel={t("carrierDetails.carrierChargeTypesDrawer.code.codeCarrier.label")}
            carrierCode={carrierCharges}
            carrierIndex={carrierChargeIndex}
          />
          <Box component="div" p={1} />
          <TextField
            id="billingCode"
            name="billingCode"
            inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular } }}
            variant="outlined"
            size="small"
            label={t("carrierDetails.carrierServicesDrawer.code.codeBilling.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.billingCode || ""}
            error={formik.touched.billingCode && formik.errors.billingCode !== undefined}
            helperText={(formik.touched.billingCode && formik.errors.billingCode) || " "}
            fullWidth
          />
        </Box>
        <Box component="div" py={1}>
          <Autocomplete
            id="type"
            disablePortal
            value={(chargeTypes && chargeTypes.find((type) => type.chargeType === formik.values.type)) || null}
            onChange={(_, value) => {
              formik.setFieldValue("type", value && value.chargeType ? value.chargeType : undefined)
            }}
            onBlur={formik.handleBlur}
            getOptionLabel={(option) => option && TranslationService.getLabelFromLocale(i18n.language, option.fullName)}
            isOptionEqualToValue={(option, value) => {
              return option.chargeType === value.chargeType
            }}
            options={chargeTypes || []}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.chargeType}>
                  {TranslationService.getLabelFromLocale(i18n.language, option.fullName)}
                </li>
              )
            }}
            PaperComponent={AutoCompletePaper}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("carrierDetails.carrierChargeTypesDrawer.type.label")}
                variant="outlined"
                required
                error={formik.touched.type && formik.errors.type !== undefined}
                helperText={(formik.touched.type && formik.errors.type) || " "}
              />
            )}
            noOptionsText={t("carrierDetails.carrierChargeTypesDrawer.type.noOptionsText")}
            disableClearable
            size="small"
            fullWidth
          />
        </Box>
        <Divider orientation="horizontal" style={{ minHeight: 0 }} />
        <Box component="div" p={1} />
        <CarrierNamesLanguagesForm formik={formik} nameLabel={t("carrierDetails.carrierChargeTypesDrawer.name.label")} nameLabelPrefix="name" languages={languages} />
        <Box component="div" p={1} />
        <Divider orientation="horizontal" style={{ minHeight: 0 }} />
        <Box component="div" p={1} />
        <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {t("carrierDetails.carrierServicesDrawer.chips.label")}
        </Typography>
        <CarrierChipsForm formik={formik} languages={languages} />
      </form>
    </Box>
  )
}

CarrierChargeDrawer.defaultProps = {
  carrierGeneralInfoCode: "",
  carrierCharges: undefined,
  chargeTypes: undefined,
  carrierChargeIndex: undefined,
  languages: undefined,
  onSubmitEvent: undefined,
}

CarrierChargeDrawer.propTypes = {
  carrierGeneralInfoCode: PropTypes.string,
  carrierCharges: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.instanceOf(Object),
      chips: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
      code: PropTypes.string,
      billingCode: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  carrierChargeIndex: PropTypes.number,
  chargeTypes: PropTypes.instanceOf(Object),
  languages: PropTypes.instanceOf(Object),
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowCarrierChargeDrawer: PropTypes.func.isRequired,
}

export default CarrierChargeDrawer
