// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import SearchIcon from "@mui/icons-material/Search"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AddressForm from "./components/address-form"
import AddressAutocompleteOption from "./components/address-autocomplete-option"
import AutoCompletePaper from "../../soe-theme/src/components/auto-complete-paper"
import CardContentNoPaddingBottom from "../../soe-theme/src/components/cardContent-no-padding-bottom"

import { AddressSearchGroupsText, AddressTypeEnum } from "../../constant"
import { useGetAddressesQuery } from "./slice"

function FullAddressForm({
  formik,
  searchAddressBook,
  displaySearchAddressBar,
  displayResidentialToggle,
  displaySaveAddressCheckbox,
  saveAddressCheckboxLabel,
  saveAddressChecked,
  setSaveAddressChecked,
  displayEmailAddressField,
  displaySaveDefaultAddressCheckbox,
  saveDefaultAddressCheckboxLabel,
  saveDefaultAddressChecked,
  setSaveDefaultAddressChecked,
  saveDefaultAddressDisabled,
  setSelectedAddressFromAutoComplete,
}) {
  const { i18n, t } = useTranslation("fullAddressForm")
  const [addressAutoCompleteTimeoutID, setAddressAutoCompleteTimeoutID] = useState()
  const [skipAddressAutoComplete, setSkipAddressAutoComplete] = useState(true)
  const [skipCompleteAddress, setSkipCompleteAddress] = useState(true)
  const [addressAutoCompleteValue, setAddressAutoCompleteValue] = useState("")
  const [selectedAddressID, setSelectedAddressID] = useState()

  const { data: addresses } = useGetAddressesQuery({ action: "find", query: addressAutoCompleteValue, language: i18n.resolvedLanguage, country: formik.values.countryCode }, { skip: skipAddressAutoComplete })
  const { data: completeAddress } = useGetAddressesQuery({ action: "get", id: selectedAddressID, language: i18n.resolvedLanguage }, { skip: skipCompleteAddress })

  const customTheme = useTheme()

  const SPACING = 10

  const handleAddressAutoCompleteChange = (_, value) => {
    if (value) {
      if (value.origin === AddressSearchGroupsText.CanadaPost) {
        if (addressAutoCompleteTimeoutID) clearTimeout(addressAutoCompleteTimeoutID)
        setSkipAddressAutoComplete(true)
        setSkipCompleteAddress(false)
        setAddressAutoCompleteValue(undefined)
        setSelectedAddressFromAutoComplete(value)
      }
      setSelectedAddressID(value.id)
    }
  }

  const handleAddressAutoCompleteInputChange = (_, value) => {
    if (value && value.trim()) {
      if (addressAutoCompleteTimeoutID) clearTimeout(addressAutoCompleteTimeoutID)
      setSkipAddressAutoComplete(true)
      setSkipCompleteAddress(true)
      if (skipAddressAutoComplete) {
        setAddressAutoCompleteTimeoutID(
          setTimeout(() => {
            setSkipAddressAutoComplete(false)
            setAddressAutoCompleteTimeoutID(undefined)
          }, 1500)
        )
      }
      setAddressAutoCompleteValue(value)
    }
  }

  const handleOriginGroupsText = (value) => {
    if (!searchAddressBook) return ""
    if (value === AddressSearchGroupsText.CanadaPost) return t("fields.addressSearch.groupsText.canadaPost")
    return undefined
  }

  const handleFormReset = () => {
    formik.setFieldValue("id", undefined)
    formik.setFieldValue("companyName", "")
    formik.setFieldValue("personName", "")
    formik.setFieldValue("addressLine1", "")
    formik.setFieldValue("addressLine2", "")
    formik.setFieldValue("provinceName", "")
    formik.setFieldValue("provinceCode", "")
    formik.setFieldValue("city", "")
    formik.setFieldValue("postalCode", "")
    formik.setFieldValue("phoneNumber", "")
    formik.setFieldValue("emailAddress", "")
    formik.setFieldValue("residential", false)
  }

  useEffect(() => {
    if (completeAddress && completeAddress.data) {
      handleFormReset()
      if (completeAddress.data.companyName) formik.setFieldValue("companyName", completeAddress.data.companyName)
      if (completeAddress.data.personName) formik.setFieldValue("personName", completeAddress.data.personName)
      if (completeAddress.data.addressLine1) formik.setFieldValue("addressLine1", completeAddress.data.addressLine1)
      if (completeAddress.data.addressLine2) formik.setFieldValue("addressLine2", completeAddress.data.addressLine2)
      if (completeAddress.data.countryCode) formik.setFieldValue("countryCode", completeAddress.data.countryCode)
      if (completeAddress.data.provinceName) formik.setFieldValue("provinceName", completeAddress.data.provinceName)
      if (completeAddress.data.provinceCode) formik.setFieldValue("provinceCode", completeAddress.data.provinceCode)
      if (completeAddress.data.city) formik.setFieldValue("city", completeAddress.data.city)
      if (completeAddress.data.postalCode) formik.setFieldValue("postalCode", completeAddress.data.postalCode)
      if (completeAddress.data.phoneNumber) formik.setFieldValue("phoneNumber", completeAddress.data.phoneNumber)
      if (completeAddress.data.emailAddress) formik.setFieldValue("emailAddress", completeAddress.data.emailAddress)
      if (completeAddress.data.residential !== undefined) formik.setFieldValue("residential", completeAddress.data.residential)
    }
  }, [completeAddress])

  const handleSaveAddressCheck = (event) => {
    setSaveAddressChecked(event.target.checked)
  }

  const handleSaveDefaultAddressCheck = (event) => {
    setSaveDefaultAddressChecked(event.target.checked)
  }

  return (
    <form noValidate>
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {displaySearchAddressBar && (
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <Autocomplete
              id="addressAutoComplete"
              options={(addresses && addresses.data && addresses.data.slice(0, 20)) || []}
              getOptionLabel={(option) => option && option.address && option.address.join(" ")}
              groupBy={(option) => handleOriginGroupsText(option.origin)}
              PaperComponent={AutoCompletePaper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("fields.addressSearch.label")}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id
              }}
              renderOption={(props, option) => <AddressAutocompleteOption key={option.id} renderOptionProps={props} currentAddress={option} />}
              filterOptions={(options) => options}
              onChange={handleAddressAutoCompleteChange}
              onInputChange={handleAddressAutoCompleteInputChange}
              noOptionsText={addressAutoCompleteValue ? t("fields.addressSearch.noOptionsText") : t("fields.addressSearch.startTypingAddress")}
              autoFocus={displaySearchAddressBar}
              autoComplete
              size="small"
            />
          </Box>
        )}
        <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
          <TextField
            id="companyName"
            label={t("fields.companyName.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyName || ""}
            error={formik.touched.companyName && formik.errors.companyName !== undefined}
            autoFocus={!displaySearchAddressBar}
            type="text"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Box>
        <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
          <TextField
            id="personName"
            label={t("fields.personName.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.personName || ""}
            error={formik.touched.personName && formik.errors.personName !== undefined}
            type="text"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Box>
        {formik?.values?.countryCode && (
          <Grid container columnSpacing={2}>
            <AddressForm formik={formik} />
          </Grid>
        )}
        <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
          <TextField
            id="phoneNumber"
            label={t("fields.phoneNumber.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber || ""}
            error={formik.touched.phoneNumber && formik.errors.phoneNumber !== undefined}
            type="text"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Box>
        {displayEmailAddressField && (
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <TextField
              id="emailAddress"
              label={t("fields.emailAddress.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailAddress || ""}
              error={formik.touched.emailAddress && formik.errors.emailAddress !== undefined}
              type="text"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>
        )}
        {displayResidentialToggle && (
          <Card sx={(theme) => ({ mt: theme.utils.pxToThemeSpacing(SPACING), mb: theme.utils.pxToThemeSpacing(SPACING) })}>
            <CardContentNoPaddingBottom
              sx={{
                "&:last-child": {
                  pl: 1,
                  pt: 0,
                  pr: 0,
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Typography variant="bodyMedium400">{t("fields.addressType.label")}</Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="radio-address-type-group-label"
                    name="residential"
                    value={formik.values.residential}
                    onChange={(_, value) => {
                      if (value != null) formik.setFieldValue("residential", value === "true")
                    }}
                  >
                    <FormControlLabel
                      value
                      control={
                        <Radio
                          sx={(theme) => ({
                            color: theme.palette.contentActionDefault.main,
                            "&.Mui-checked": {
                              color: theme.palette.contentActionDefault.main,
                            },
                          })}
                        />
                      }
                      label={t("fields.addressType.residential", { ns: "fullAddressForm" })}
                    />
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          sx={(theme) => ({
                            color: theme.palette.contentActionDefault.main,
                            "&.Mui-checked": {
                              color: theme.palette.contentActionDefault.main,
                            },
                          })}
                        />
                      }
                      label={t("fields.addressType.commercial", { ns: "fullAddressForm" })}
                      sx={{ mr: 1 }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </CardContentNoPaddingBottom>
          </Card>
        )}
        {displaySaveAddressCheckbox && (
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveAddressChecked}
                  sx={(theme) => ({
                    "&.Mui-checked": {
                      color: theme.palette.contentActionDefault.main,
                    },
                  })}
                />
              }
              onChange={(event) => {
                handleSaveAddressCheck(event)
              }}
              label={t(saveAddressCheckboxLabel)}
            />
          </Box>
        )}
        {displaySaveDefaultAddressCheckbox && !saveDefaultAddressDisabled && (
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveDefaultAddressChecked}
                  sx={(theme) => ({
                    "&.Mui-checked": {
                      color: theme.palette.contentActionDefault.main,
                    },
                  })}
                />
              }
              onChange={(event) => {
                handleSaveDefaultAddressCheck(event)
              }}
              label={t(saveDefaultAddressCheckboxLabel)}
            />
          </Box>
        )}
      </Box>
    </form>
  )
}

FullAddressForm.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  searchAddressBook: PropTypes.bool,
  displaySearchAddressBar: PropTypes.bool,
  displayResidentialToggle: PropTypes.bool,
  displaySaveAddressCheckbox: PropTypes.bool,
  saveAddressCheckboxLabel: PropTypes.string,
  saveAddressChecked: PropTypes.bool,
  setSaveAddressChecked: PropTypes.func,
  displayEmailAddressField: PropTypes.bool,
  displaySaveDefaultAddressCheckbox: PropTypes.bool,
  saveDefaultAddressCheckboxLabel: PropTypes.string,
  saveDefaultAddressChecked: PropTypes.bool,
  setSaveDefaultAddressChecked: PropTypes.func,
  saveDefaultAddressDisabled: PropTypes.bool,
  addressType: PropTypes.string,
  setSelectedAddressFromAutoComplete: PropTypes.func,
}

FullAddressForm.defaultProps = {
  searchAddressBook: true,
  displaySearchAddressBar: true,
  displayResidentialToggle: true,
  displaySaveAddressCheckbox: true,
  saveAddressCheckboxLabel: undefined,
  saveAddressChecked: false,
  setSaveAddressChecked: () => {},
  displayEmailAddressField: true,
  displaySaveDefaultAddressCheckbox: false,
  saveDefaultAddressCheckboxLabel: undefined,
  saveDefaultAddressChecked: false,
  setSaveDefaultAddressChecked: () => {},
  setSelectedAddressFromAutoComplete: () => {},
  saveDefaultAddressDisabled: false,
  addressType: AddressTypeEnum.Shipping,
}

export default FullAddressForm
