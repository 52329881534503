import Box from "@mui/material/Box"
import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import BusinessLogo from "../../../../../../../../soe-theme/src/components/business-logo"
import DateTimeService from "../../../../../../../../services/date-time"

import { AuditItemProps } from "../../../../../../../../models/audit"
import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"
import RateRequestSummaryCard from "../../../../../../../rate-request-summary-card"

function AccountDetailsHeader({ auditItemDetails }) {
  const { t } = useTranslation("audits")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard sx={{ height: 1 }}>
      <Box sx={{ height: theme.utils.pxToRem(36), display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
        <BusinessLogo />
      </Box>
      <Box mt={pxToThemeSpacing(10)}>
        <Stack direction="column">
          {auditItemDetails.order?.tracking?.masterId && (
            <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
              {t("auditDetails.auditItemDetails.accountDetails.account.trackingNumber")} <b>{auditItemDetails.order?.tracking?.masterId}</b>
            </Typography>
          )}
          {auditItemDetails.order?.transitStatus && (
            <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
              {t("auditDetails.auditItemDetails.accountDetails.account.carrierStatus")} <b>{t(`transitStatus.${auditItemDetails.order?.transitStatus}`, { ns: "orders" })}</b>
            </Typography>
          )}
          {auditItemDetails.order?.quotation?.response?.carrierAccount?.name && (
            <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
              {t("auditDetails.auditItemDetails.accountDetails.account.accountName")} <b>{auditItemDetails.order?.quotation?.response?.carrierAccount?.name}</b>
            </Typography>
          )}
          {auditItemDetails.shippingOptions?.account?.declared?.shippingDate && (
            <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
              {t("auditDetails.auditItemDetails.accountDetails.account.shippingDate")} <b>{DateTimeService.toLocaleDateString(auditItemDetails.shippingOptions.account.declared.shippingDate)}</b>
            </Typography>
          )}
        </Stack>
      </Box>
    </RateRequestSummaryCard>
  )
}

AccountDetailsHeader.propTypes = {
  auditItemDetails: AuditItemProps.isRequired,
}

export default AccountDetailsHeader
