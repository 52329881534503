// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Masonry from "@mui/lab/Masonry"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom theme
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"

// Others
import CarrierAccountCard from "../carrier-account-card"
import CarrierAccountDrawer from "../carrier-account-drawer"
import { useGetCarrierAccountsQuery } from "../../slice"

function CarrierAccounts({ carrier }) {
  const customTheme = useTheme()
  const { t } = useTranslation("carriers")

  const masonryNumberColumns = 4

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  const [carrierAccountIndex, setCarrierAccountIndex] = useState()
  const [showCarrierAccountDrawer, setShowCarrierAcountDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()
  const [onDisableEvent, setDisableEvent] = useState()

  const { data: carrierAccounts, isFetching } = useGetCarrierAccountsQuery(carrier.code, { skip: !isAccordionExpanded })

  const carrierGeneralInfoSorted = { ...carrier }
  if (carrierGeneralInfoSorted && carrierGeneralInfoSorted.accountFields) carrierGeneralInfoSorted.accountFields = carrier.accountFields.slice().sort((a, b) => a.ordinal - b.ordinal)

  const handleAddCarrierAccountClick = (event) => {
    event.stopPropagation()
    setSubmitEvent()
    setCarrierAccountIndex()
    setShowCarrierAcountDrawer(true)
  }

  const handleAccordionChange = (_, expanded) => {
    setIsAccordionExpanded(expanded)
  }

  const handleCarrierAccountCardClick = () => {
    setSubmitEvent()
    setShowCarrierAcountDrawer(true)
  }

  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary>
        <Grid container>
          <Grid container item xs={11} alignItems="center">
            <Typography component="div" ml={customTheme.utils.pxToThemeSpacing(14)}>
              <Box fontWeight="fontWeightBold" display="inline">
                {t("carrierDetails.carrierAccounts.title")}
              </Box>
            </Typography>
          </Grid>
          <Grid container item justifyContent="flex-end" alignItems="center" xs={1}>
            <Button variant="outlined" size="medium" color="primary" onClick={handleAddCarrierAccountClick}>
              {t("carrierDetails.carrierAccounts.actions.add.label")}
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {isFetching ? (
          <Masonry columns={masonryNumberColumns} sx={{ alignContent: "flex-start" }}>
            {Array(masonryNumberColumns)
              .fill()
              .map((_, index) => {
                return <Skeleton key={"".concat(index)} variant="rectangular" height={75} />
              })}
          </Masonry>
        ) : (
          carrierAccounts &&
          carrierAccounts.length > 0 && (
            <Masonry columns={masonryNumberColumns} sx={{ margin: 0, alignContent: "flex-start" }}>
              {carrierAccounts.map((carrierAccount, index) => {
                return (
                  <CarrierAccountCard key={"".concat(index)} carrierAccount={carrierAccount} carrierGeneralInfoSorted={carrierGeneralInfoSorted} handleCarrierAccountCardClick={() => handleCarrierAccountCardClick(setCarrierAccountIndex(index))} />
                )
              })}
            </Masonry>
          )
        )}
      </AccordionDetails>
      <SoeDrawer showDrawer={showCarrierAccountDrawer} setShowDrawer={setShowCarrierAcountDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("carrierDetails.carrierAccountsDrawer.title")}
            setShowDrawer={() => {
              setShowCarrierAcountDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CarrierAccountDrawer
              carrierGeneralInfoCode={carrier.code}
              carrierAccounts={carrierAccounts}
              carrierAccountIndex={carrierAccountIndex}
              carrierGeneralInfoSorted={carrierGeneralInfoSorted}
              onSubmitEvent={onSubmitEvent}
              onDisableEvent={onDisableEvent}
              setShowCarrierAcountDrawer={setShowCarrierAcountDrawer}
              setDisableEvent={setDisableEvent}
            />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setDisableEvent,
                label:
                  carrierAccounts && carrierAccounts[carrierAccountIndex] && carrierAccounts[carrierAccountIndex].isDisabled && carrierAccounts[carrierAccountIndex].isDisabled === true
                    ? t("carrierDetails.carrierAccountsDrawer.actions.enable.label")
                    : t("carrierDetails.carrierAccountsDrawer.actions.disable.label"),
                variant: "outlined",
                color: "error",
              },
              {
                action: setSubmitEvent,
                label: t("carrierDetails.carrierAccountsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Accordion>
  )
}

CarrierAccounts.propTypes = {
  carrier: PropTypes.shape({
    accountFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    charges: PropTypes.shape({
      count: PropTypes.number,
      new: PropTypes.number,
    }),
    code: PropTypes.string,
    fullName: PropTypes.instanceOf(Object),
    logo: PropTypes.string,
    services: PropTypes.shape({
      count: PropTypes.number,
      new: PropTypes.number,
    }),
    shortName: PropTypes.instanceOf(Object),
    isDisabled: PropTypes.bool,
  }).isRequired,
}

export default CarrierAccounts
