// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import DateTimeService from "../../../../../../services/date-time"

function ClientDetailsInvoice({ invoice }) {
  const customTheme = useTheme()

  return (
    <TableRow hover component="tr" sx={{ backgroundColor: customTheme.palette.contentNeutralPrimaryReverse.main }}>
      <TableCell component="td" scope="row">
        {invoice.invoiceNumber}
      </TableCell>
      <TableCell component="td" scope="row">
        <Box component="div">{DateTimeService.toLocaleDateString(invoice?.createdDate)}</Box>
      </TableCell>
      <TableCell component="td" scope="row">
        {invoice?.user?.billing?.currency || "CAD"}
      </TableCell>
      <TableCell component="td" scope="row">
        <Box component="div">{DateTimeService.toLocaleDateString(invoice.dueDate)}</Box>
      </TableCell>
    </TableRow>
  )
}

ClientDetailsInvoice.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
}

export default ClientDetailsInvoice
