const chip = {}
const fullName = {}
const shortName = {}
const name = {}

const EmptyCarrierService = {
  getFieldsWithLanguages(key, languages) {
    if (key && languages) {
      if (key === "chip") {
        languages.map((k) => Object.assign(chip, { [k.code]: "" }))
        return chip
      }
      if (key === "fullName") {
        languages.map((k) => Object.assign(fullName, { [k.code]: "" }))
        return fullName
      }
      if (key === "shortName") {
        languages.map((k) => Object.assign(shortName, { [k.code]: "" }))
        return shortName
      }
      if (key === "name") {
        languages.map((k) => Object.assign(name, { [k.code]: "" }))
        return name
      }
    }
    return undefined
  },
}

export default EmptyCarrierService
