// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import Currency from "../currency"
import RateRequestSummaryCard from "../rate-request-summary-card"

import { CommercialInvoiceProps } from "../../models/rate-request"
import { UNIT_OF_WEIGHT } from "../../constant"

function CommercialInvoiceCard({ commercialInvoice, disabled }) {
  const { t } = useTranslation("commercialInvoiceCard")
  const customTheme = useTheme()

  const totalInvoiceItems = commercialInvoice?.invoiceItems?.length > 0 ? commercialInvoice.invoiceItems.reduce((accumulator, { quantity }) => accumulator + quantity, 0) : 0

  const totalAmount = commercialInvoice?.invoiceItems?.length > 0 ? commercialInvoice.invoiceItems.reduce((accumulator, { quantity, value }) => accumulator + quantity * value, 0) : 0

  const totalWeight =
    commercialInvoice?.invoiceItems?.length > 0
      ? commercialInvoice.invoiceItems.reduce((accumulator, invoice) => {
          if (invoice.weightUnits === "imperial") return accumulator + invoice.weight * invoice.quantity
          return accumulator + invoice.weight * invoice.quantity
        }, 0)
      : 0

  return (
    !disabled && (
      <RateRequestSummaryCard title={t("commercialInvoiceTitle")} disabled={disabled}>
        {commercialInvoice?.invoiceItems?.length > 0 && (
          <Box mt={customTheme.spacing(1)} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("totalItems")}
              {totalInvoiceItems}
            </Typography>
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("totalLines")}
              {commercialInvoice.invoiceItems.length}
            </Typography>
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("totalValue")}
              <Currency amount={totalAmount} currency={commercialInvoice.currency} currencyPosition="right" color={customTheme.palette.contentNeutralSecondary.main} showSymbol />
            </Typography>
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("totalWeight")} {totalWeight.toFixed(1)} {commercialInvoice.unitOfMeasure === "imperial" ? UNIT_OF_WEIGHT.POUND : UNIT_OF_WEIGHT.KILOGRAM}
            </Typography>
          </Box>
        )}
      </RateRequestSummaryCard>
    )
  )
}

CommercialInvoiceCard.propTypes = {
  commercialInvoice: CommercialInvoiceProps,
  disabled: PropTypes.bool,
}

CommercialInvoiceCard.defaultProps = {
  commercialInvoice: undefined,
  disabled: false,
}

export default CommercialInvoiceCard
