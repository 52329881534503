/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/tooltips/
 * @link https://mui.com/api/tooltip/
 *
 */
export default function Tooltip() {
  return {
    MuiTooltip: {
      defaultProps: {},
      styleOverrides: {},
    },
  }
}
