import "filepond/dist/filepond.min.css"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Paper from "@mui/material/Paper"
import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import { Skeleton, TableCell, TableRow, useTheme } from "@mui/material"
import getComparator from "../../utils/sorting"
import EnhancedTableHead from "../enhanced-table-head"
import FileUploader from "../file-uploader"
import { useAddNewBulkFileMutation, useGetBulkUploadsQuery } from "../../services/bulk/slice"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../soe-theme/src/components"
import CustomPagination from "../custom-pagination"
import BulkUpload from "./components/bulk-upload"
import CsvTemplate from "../../assets/csv/bulk-shipment-template.csv"

function Bulks({ nbRowsPerPage }) {
  const customTheme = useTheme()
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("createdDate")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(nbRowsPerPage)
  const [filteredBulkUploads, setFilteredBulkUploads] = useState()

  const { data: bulkUploads, isFetching, refetch: refetchBulkUploads } = useGetBulkUploadsQuery()

  useEffect(() => setFilteredBulkUploads(bulkUploads), [bulkUploads])

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const [showStartNewBulkUploadDrawer, setShowStartNewBulkDrawer] = useState(false)
  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [addBulkFile] = useAddNewBulkFileMutation()

  const handleRefreshClick = () => {
    refetchBulkUploads()
  }

  const downloadTemplate = () => {
    const link = document.createElement("a")
    link.href = CsvTemplate
    link.setAttribute("download", "bulk-shipment-template.csv")
    document.body.appendChild(link)
    link.click()
  }

  const headCells = [
    {
      id: "bulkUpload.filename",
      align: "center",
      disablePadding: false,
      label: "Filename",
    },
    {
      id: "bulkUpload.createdDate",
      disablePadding: false,
      label: "Created Date",
    },
    {
      id: "bulkUpload.status",
      disablePadding: false,
      label: "Status",
    },
    {
      id: "bulkUpload.processedItems",
      disablePadding: false,
      label: "Items Processed",
    },
    {
      id: "bulkUpload.failedItems",
      disablePadding: false,
      label: "Errors",
    },
    {
      id: "bulkUpload.url",
      disablePadding: false,
      label: "File",
    },
  ]

  useEffect(() => {
    if (!showStartNewBulkUploadDrawer && !isUploading) setFiles([])
  }, [showStartNewBulkUploadDrawer])

  return (
    <>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <CardHeader
          title="Upload bulk file"
          action={
            <>
              <Button variant="text" onClick={downloadTemplate} sx={(theme) => ({ mr: theme.spacing(1) })}>
                Template
              </Button>
              <Button variant="outlined" onClick={handleRefreshClick} sx={(theme) => ({ mr: theme.spacing(1) })}>
                Refresh
              </Button>
              <Button variant="contained" onClick={() => setShowStartNewBulkDrawer(true)} sx={(theme) => ({ mr: theme.spacing(1) })}>
                Upload File
              </Button>
            </>
          }
        />
      </Card>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <Box sx={{ width: "auto" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table size="small" sx={{ backgroundColor: customTheme.palette.backgroundNeutralUnderground1.main }}>
                <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {!filteredBulkUploads || isFetching ? (
                    <>
                      {Array(3)
                        .fill()
                        .map((_, index) => {
                          return (
                            <TableRow key={"".concat(index)}>
                              <TableCell colSpan={12}>
                                <Skeleton variant="rectangular" height={50} width="100%" />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </>
                  ) : (
                    filteredBulkUploads
                      .slice()
                      .sort(getComparator(order, orderBy))
                      .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                      .map((bulkUpload, index) => {
                        return <BulkUpload key={"".concat(index)} bulkUpload={bulkUpload} refetchBulkUploads={refetchBulkUploads} />
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination count={filteredBulkUploads ? Math.ceil(filteredBulkUploads.length / rowsPerPage) : 1} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />
          </Paper>
        </Box>
      </Card>
      <SoeDrawer showDrawer={showStartNewBulkUploadDrawer} setShowDrawer={setShowStartNewBulkDrawer} anchor="right" keepMounted={false} preventClosing={isUploading}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title="Upload file"
            setShowDrawer={() => {
              setShowStartNewBulkDrawer(false)
            }}
            preventClosing={isUploading}
          />
          <SoeDrawerContent>
            <FileUploader name="bulkUploadFile" files={files} setFiles={setFiles} signUrlMutation={addBulkFile} trigger={setIsUploading} invalidateTags={["Shipments"]} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: () => {
                  setShowStartNewBulkDrawer(false)
                  refetchBulkUploads()
                },
                label: "Upload",
                variant: "contained",
                loading: isUploading,
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </>
  )
}

Bulks.propTypes = {
  nbRowsPerPage: PropTypes.number,
}

Bulks.defaultProps = {
  nbRowsPerPage: 15,
}

export default Bulks
