// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import groupBy from "lodash/groupBy"
import Currency from "../currency"
import LogoText from "../../assets/images/business-logo-text.svg"
import TranslationService from "../../services/translation"
import RateRequestSummaryCard from "../rate-request-summary-card"

function CostSummaryCard({ rate, disabled }) {
  const { i18n, t } = useTranslation("costSummaryCard")
  const customTheme = useTheme()

  const chargesGrouped = groupBy(rate.charges, (charge) => charge.type)

  return (
    <RateRequestSummaryCard title={t("title")} disabled={disabled}>
      {rate ? (
        <Box component="div" className="cardInformationText" mt={customTheme.spacing(1)}>
          <Typography variant="h5" component="span" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
            <Currency amount={rate.totals.account.total.amount} currency={rate.totals.account.total.currency} currencyPosition="right" showSymbol />
          </Typography>
          {false && (
            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
              <Box component="div">
                <img width={136} src={LogoText} alt="Business logo" />
              </Box>
              <Typography variant="bodyMedium400" component="span" mx={customTheme.spacing(1)}>
                {t("discountTitle.label", { ns: "priceSummaryCard" })}
              </Typography>
              <Typography variant="inherit" component="span">
                <Currency amount={rate.totals.retail.total.amount - rate.totals.account.total.amount} currency={rate.totals.account.total.currency} showSymbol={false} />
              </Typography>
            </Box>
          )}
          <Box component="div" mt={customTheme.spacing(1)}>
            {rate.charges && rate.charges.length > 0 && (
              <Box>
                {Object.keys(chargesGrouped).map((chargeGroup, indexTitle) => (
                  <Box component="div" key={chargeGroup.concat(indexTitle)}>
                    <Box component="div">
                      <Typography variant="bodyMedium600" component="span" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                        {t(`chargeTypes.${chargeGroup}.label`)}
                      </Typography>
                    </Box>
                    {chargesGrouped[chargeGroup].map((charge, index) => (
                      <Box component="div" key={charge.code.concat(index)} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="inherit" component="span" sx={(theme) => ({ lineHeight: theme.utils.pxToRem(24) })}>
                          {TranslationService.getLabelFromLocale(i18n.language, charge.name)}
                        </Typography>
                        <Typography variant="inherit" component="span" sx={(theme) => ({ lineHeight: theme.utils.pxToRem(24) })}>
                          <Currency amount={charge.account.amount} currency={charge.account.currency} showSymbol />
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box component="div" mt={customTheme.spacing(1)} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="bodyMedium600" component="span" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
              {`${t("charges.totalTitle.label")} (${rate.totals.account.total.currency})`}
            </Typography>
            <Typography variant="inherit" component="span" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToRem(24) })}>
              <Currency amount={rate.totals.account.total.amount} currency={rate.totals.account.total.currency} showSymbol />
            </Typography>
          </Box>
        </Box>
      ) : (
        <Typography variant="inherit" component="span" sx={(theme) => ({ lineHeight: theme.utils.pxToRem(24) })}>
          {t("noQuote.label")}
        </Typography>
      )}
    </RateRequestSummaryCard>
  )
}

CostSummaryCard.propTypes = {
  rate: PropTypes.shape({
    charges: PropTypes.arrayOf(PropTypes.shape({})),
    totals: PropTypes.shape({
      account: PropTypes.shape({
        total: PropTypes.shape({
          amount: PropTypes.number,
          currency: PropTypes.string,
        }),
      }),
      retail: PropTypes.shape({
        total: PropTypes.shape({
          amount: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
  disabled: PropTypes.bool,
}

CostSummaryCard.defaultProps = {
  disabled: false,
}

export default CostSummaryCard
