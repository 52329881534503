// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import MoreMenu from "../../../../../more-menu"
import SoeConfirmationDialog from "../../../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogActions from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import SoeConfirmationDialogContainer from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogContent from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogHeader from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"

import AddressSummary from "../../../../../address-summary"
import CarrierServiceLogo from "../../../../../carrier-service-logo"
import Currency from "../../../../../currency"
import DateTimeService from "../../../../../../services/date-time"
import TranslationService from "../../../../../../services/translation"
import AddCreditDrawer from "../../../add-credit-drawer"
import { useDeleteCreditMutation } from "../../../../slice"

function AuditedOrder({ auditedOrder, refetchCandidates, refetchCredits }) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showCreditDrawer, setShowCreditDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()
  const [isCreditUpdating, setIsCreditUpdating] = useState(false)
  const [deleteCredit] = useDeleteCreditMutation()

  const { i18n, t } = useTranslation("invoices")
  const customTheme = useTheme()

  const handleAddEditClick = () => {
    setSubmitEvent(undefined)
    setShowCreditDrawer(true)
  }

  const sideMenuOptionList = [
    {
      label: t("invoicesTable.creditsOptionsList.edit"),
      handleClick: handleAddEditClick,
    },
    {
      label: t("invoicesTable.creditsOptionsList.delete"),
      handleClick: () => setShowConfirmationDialog(true),
    },
  ]

  const handleDeleteCredit = () => {
    if (auditedOrder.id && auditedOrder.credit?.companyId) {
      deleteCredit({ creditId: auditedOrder.id, companyId: auditedOrder.credit.companyId })
      setShowConfirmationDialog(false)
      setTimeout(() => {
        refetchCandidates()
        refetchCredits()
      }, 1200)
    }
  }

  return (
    <>
      {/* DELETE CREDIT MODAL */}
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title="Delete Credit"
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              Are you sure you want to delete credit?
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: () => setShowConfirmationDialog(false),
                label: "Cancel",
                variant: "outlined",
              },
              {
                action: () => handleDeleteCredit(),
                label: "Ok",
                variant: "contained",
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>

      {/* EDIT CREDIT DRAWER */}
      <SoeDrawer showDrawer={showCreditDrawer} setShowDrawer={setShowCreditDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title="Edit Credit"
            setShowDrawer={() => {
              setShowCreditDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <AddCreditDrawer onSubmitEvent={onSubmitEvent} setShowCreditDrawer={setShowCreditDrawer} setIsCreditCreating={setIsCreditUpdating} credit={auditedOrder.credit} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: "Save",
                variant: "contained",
                disabled: isCreditUpdating,
                loading: isCreditUpdating,
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <TableRow hover component="tr" sx={{ backgroundColor: customTheme.palette.contentNeutralPrimaryReverse.main }}>
        <TableCell component="td" scope="row" align="center">
          <CarrierServiceLogo logo={auditedOrder.order?.quotation?.response?.carrier?.logo} align="center" />
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
            {TranslationService.getLabelFromLocale(i18n.language, auditedOrder.service?.client?.actual?.fullName)}
            {auditedOrder.credit?.isAppliedFromBalance && (
              <small>
                <br />
                From Balance
              </small>
            )}
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">
            <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
              {auditedOrder.order?.number}
            </Typography>
          </Box>
          <Box component="div">
            <Typography variant="bodySmall400" gutterBottom>
              {auditedOrder.tracking?.carrierInvoice?.actual?.masterId}
            </Typography>
          </Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" gutterBottom>
            {DateTimeService.toLocaleDateString(auditedOrder.order?.createdDate)}
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">
            {(auditedOrder.shipper?.client?.actual || auditedOrder.order?.quotation?.request.shipper) && <AddressSummary addressSummary={auditedOrder.shipper?.client?.actual || auditedOrder.order?.quotation?.request.shipper} />}
          </Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">
            {(auditedOrder.recipient?.client?.actual || auditedOrder.order?.quotation?.request.recipient) && <AddressSummary addressSummary={auditedOrder.recipient?.client?.actual || auditedOrder.order?.quotation?.request.recipient} />}
          </Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
            <Currency amount={auditedOrder.rate?.totals?.total?.carrierInvoice?.actual?.amount} currency={auditedOrder.rate?.totals?.total?.carrierInvoice?.actual?.currency} currencyPosition="right" showSymbol={false} />
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
            <Currency amount={auditedOrder.rate?.totals?.total?.client?.actual?.amount} currency={auditedOrder.rate?.totals?.total?.client?.actual?.currency} currencyPosition="right" showSymbol={false} />
          </Typography>
        </TableCell>
        {auditedOrder.service?.client?.actual?.code === "CREDIT" ? (
          <TableCell component="td" scope="row" align="right">
            <MoreMenu optionsList={sideMenuOptionList} />
          </TableCell>
        ) : (
          <TableCell component="td" scope="row" align="right" />
        )}
      </TableRow>
    </>
  )
}

AuditedOrder.propTypes = {
  auditedOrder: PropTypes.instanceOf(Object).isRequired,
  refetchCandidates: PropTypes.func,
  refetchCredits: PropTypes.func,
}

AuditedOrder.defaultProps = {
  refetchCredits: () => {},
  refetchCandidates: () => {},
}

export default AuditedOrder
