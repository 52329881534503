import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import EmailInvoiceDrawer from "../../../../../email-invoice-drawer"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../../../soe-theme/src/components"
import ChargePaymentMethodDrawer from "../../../../../charge-payment-method-drawer"
import RecordPaymentDrawer from "../../../../../record-payment-drawer"

function InvoiceDrawers({
  invoice,
  isPaid,
  isLoading,
  setIsLoading,
  showEmailInvoiceDrawer,
  setShowEmailInvoiceDrawer,
  showChargeCardDrawer,
  setShowChargeCardDrawer,
  showRecordPaymentDrawer,
  setShowRecordPaymentDrawer,
  setSubmitEvent,
  onSubmitEvent,
  handleClose,
}) {
  const { t } = useTranslation("invoices")

  return isPaid ? (
    showEmailInvoiceDrawer && <EmailInvoiceDrawer invoice={invoice} showEmailInvoiceDrawer={showEmailInvoiceDrawer} setShowEmailInvoiceDrawer={setShowEmailInvoiceDrawer} />
  ) : (
    <>
      {showChargeCardDrawer && (
        <SoeDrawer showDrawer={showChargeCardDrawer} setShowDrawer={setShowChargeCardDrawer} anchor="right" keepMounted={false}>
          <SoeDrawerContainer>
            <SoeDrawerHeader
              title={t("chargePaymentMethod.title")}
              setShowDrawer={() => {
                setShowChargeCardDrawer(false)
              }}
            />
            <SoeDrawerContent>
              <ChargePaymentMethodDrawer invoice={invoice} onSubmitEvent={onSubmitEvent} setShowDrawer={setShowChargeCardDrawer} setIsLoading={setIsLoading} />
            </SoeDrawerContent>
            <SoeDrawerActions
              buttons={[
                {
                  action: handleClose,
                  label: t("chargePaymentMethod.actions.cancel.label"),
                  variant: "outlined",
                  color: "error",
                },
                {
                  action: setSubmitEvent,
                  label: t("chargePaymentMethod.actions.save.label"),
                  variant: "contained",
                  loading: isLoading,
                },
              ]}
            />
          </SoeDrawerContainer>
        </SoeDrawer>
      )}
      {showRecordPaymentDrawer && (
        <SoeDrawer showDrawer={showRecordPaymentDrawer} setShowDrawer={setShowRecordPaymentDrawer} anchor="right" keepMounted={false}>
          <SoeDrawerContainer>
            <SoeDrawerHeader
              title={t("recordPaymentDrawer.title")}
              setShowDrawer={() => {
                setShowRecordPaymentDrawer(false)
              }}
            />
            <SoeDrawerContent>
              <RecordPaymentDrawer invoice={invoice} onSubmitEvent={onSubmitEvent} setShowRecordPaymentDrawer={setShowRecordPaymentDrawer} setIsLoading={setIsLoading} />
            </SoeDrawerContent>
            <SoeDrawerActions
              buttons={[
                {
                  action: handleClose,
                  label: t("recordPaymentDrawer.actions.cancel.label"),
                  variant: "outlined",
                  color: "error",
                },
                {
                  action: setSubmitEvent,
                  label: t("recordPaymentDrawer.actions.save.label"),
                  variant: "contained",
                  loading: isLoading,
                },
              ]}
            />
          </SoeDrawerContainer>
        </SoeDrawer>
      )}
      {showEmailInvoiceDrawer && <EmailInvoiceDrawer invoice={invoice} showEmailInvoiceDrawer={showEmailInvoiceDrawer} setShowEmailInvoiceDrawer={setShowEmailInvoiceDrawer} />}
    </>
  )
}

InvoiceDrawers.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
  isPaid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  showEmailInvoiceDrawer: PropTypes.bool.isRequired,
  setShowEmailInvoiceDrawer: PropTypes.func.isRequired,
  showChargeCardDrawer: PropTypes.bool.isRequired,
  setShowChargeCardDrawer: PropTypes.func.isRequired,
  showRecordPaymentDrawer: PropTypes.bool.isRequired,
  setShowRecordPaymentDrawer: PropTypes.func.isRequired,
  setSubmitEvent: PropTypes.func.isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  handleClose: PropTypes.func.isRequired,
}

InvoiceDrawers.defaultProps = {
  onSubmitEvent: undefined,
}

export default InvoiceDrawers
