import PropTypes from "prop-types"

const AddressProps = PropTypes.shape({
  id: PropTypes.string,
  companyName: PropTypes.string,
  personName: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  provinceCode: PropTypes.string,
  country: PropTypes.string,
  countryCode: PropTypes.string,
  postalCode: PropTypes.string,
  residential: PropTypes.bool,
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,
  timezone: PropTypes.string,
  validationProviderId: PropTypes.string,
})

export default AddressProps
