import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import IsEmpty from "lodash/isEmpty"
import MenuItem from "@mui/material/MenuItem"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

import { useFormik } from "formik"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import BILLING_TYPE_ENUM from "../../../../utils/billing"
import TransactionStatus from "../../../transaction-status"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useAddNewClientMutation, useGetCurrenciesQuery } from "../../slice"

function NewClientDrawer({ showNewClientDrawer, setShowNewClientDrawer, clients }) {
  const { t } = useTranslation("clients")
  const customTheme = useTheme()

  const [showTransactionStatus, setShowTransactionStatus] = useState(false)

  const { data: currencies } = useGetCurrenciesQuery()

  const [addNewClient, { data: addNewClientResponse, isLoading, isError, isSuccess }] = useAddNewClientMutation()

  const formik = useFormik({
    initialValues: {
      emailAddress: "",
      companyName: "",
      personName: "",
      customerId: "",
      billingType: "account",
      currency: "CAD",
    },
    validate: (formValues) => {
      const errors = {}

      if (!formValues.emailAddress) {
        errors.emailAddress = t("newClient.drawer.fields.emailAddress.error.required")
      }
      if (clients?.some((client) => client.emailAddress.toLowerCase() === formValues.emailAddress.toLowerCase())) {
        errors.emailAddress = t("newClient.drawer.fields.emailAddress.error.alreadyExists")
      }
      if (!formValues.personName) {
        errors.personName = t("newClient.drawer.fields.personName.error.required")
      }
      if (!formValues.billingType) {
        errors.billingType = t("newClient.drawer.fields.billingType.error.required")
      }
      if (!formValues.currency) {
        errors.currency = t("newClient.drawer.fields.currency.error.required")
      }

      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      setShowTransactionStatus(true)
      Object.keys(formValues).forEach((key) => {
        if (IsEmpty(formValues[key])) formValues[key] = undefined
      })
      addNewClient(formValues)
    },
  })

  const handleCancelClick = () => {
    formik.resetForm()
    setShowNewClientDrawer(false)
    setShowTransactionStatus(false)
  }

  const handleConfirmClick = () => {
    formik.handleSubmit()
  }

  const handleCloseClick = () => {
    formik.resetForm()
    setShowNewClientDrawer(false)
    setShowTransactionStatus(false)
  }

  return (
    <SoeDrawer showDrawer={showNewClientDrawer} setShowDrawer={setShowNewClientDrawer} anchor="right" keepMounted={false} preventClosing={isLoading}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("newClient.drawer.title")}
          setShowDrawer={() => {
            setShowNewClientDrawer(false)
          }}
          preventClosing={isLoading}
        />
        <SoeDrawerContent>
          {showTransactionStatus && <TransactionStatus transaction={addNewClientResponse} title={t("newClient.drawer.transaction.label")} isError={isError} isLoading={isLoading} />}
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(10)}>
            <TextField
              id="emailAddress"
              label={t("newClient.drawer.fields.emailAddress.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailAddress || ""}
              error={formik.touched.emailAddress && formik.errors.emailAddress !== undefined}
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
            />
          </Box>
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(10)}>
            <TextField
              id="companyName"
              label={t("newClient.drawer.fields.companyName.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyName || ""}
              error={formik.touched.companyName && formik.errors.companyName !== undefined}
              type="text"
              variant="outlined"
              size="small"
              fullWidth
            />
          </Box>
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(10)}>
            <TextField
              id="personName"
              label={t("newClient.drawer.fields.personName.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.personName || ""}
              error={formik.touched.personName && formik.errors.personName !== undefined}
              type="text"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(10)}>
            <TextField
              id="customerId"
              label={t("newClient.drawer.fields.customerId.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.customerId || ""}
              error={formik.touched.customerId && formik.errors.customerId !== undefined}
              type="text"
              variant="outlined"
              size="small"
            />
          </Box>
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(10)}>
            <FormControl error={formik.touched.billingType && formik.errors.billingType !== undefined} fullWidth>
              <InputLabel id="billingType-label">{t("newClient.drawer.fields.billingType.label")}</InputLabel>
              <Select
                name="billingType"
                labelId="billingType-label"
                size="small"
                label={t("newClient.drawer.fields.billingType.label")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.billingType || undefined}
                defaultValue={BILLING_TYPE_ENUM.ACCOUNT}
              >
                <MenuItem value={BILLING_TYPE_ENUM.ACCOUNT}>{t("newClient.drawer.billingType.account")}</MenuItem>
                <MenuItem value={BILLING_TYPE_ENUM.CREDIT_CARD}>{t("newClient.drawer.billingType.creditcard")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(10)}>
            {currencies && (
              <FormControl error={formik.touched.currency && formik.errors.currency !== undefined} fullWidth>
                <InputLabel id="currency-label">{t("newClient.drawer.fields.currency.label")}</InputLabel>
                <Select
                  name="currency"
                  labelId="currency-label"
                  size="small"
                  label={t("newClient.drawer.fields.currency.label")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currency || undefined}
                  defaultValue="CAD"
                >
                  {currencies?.map(({ code }, currencyIndex) => (
                    <MenuItem key={code.concat(currencyIndex)} value={code}>
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={
            !isSuccess
              ? [
                  {
                    action: handleCancelClick,
                    label: t("newClient.drawer.actions.cancel"),
                    variant: "outlined",
                  },
                  {
                    action: handleConfirmClick,
                    label: t("newClient.drawer.actions.confirm"),
                    variant: "contained",
                    loading: isLoading,
                  },
                ]
              : [
                  {
                    action: handleCloseClick,
                    label: t("newClient.drawer.actions.close"),
                    variant: "contained",
                  },
                ]
          }
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

NewClientDrawer.propTypes = {
  showNewClientDrawer: PropTypes.bool.isRequired,
  setShowNewClientDrawer: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
}

NewClientDrawer.defaultProps = {
  clients: undefined,
}

export default NewClientDrawer
