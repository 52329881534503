// REACT
import React from "react"

// MUI
import { Paper } from "@mui/material"

function AutoCompletePaper(props) {
  return <Paper elevation={4} sx={(theme) => ({ mx: theme.spacing(1) })} square {...props} />
}

export default AutoCompletePaper
