import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import React from "react"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CarrierServiceLogo from "../carrier-service-logo"
import TranslationService from "../../services/translation"

import { CustomChip } from "./style"
import RateRequestSummaryCard from "../rate-request-summary-card"

function CarrierDetailsCard({ service, carrier, charges, disabled, shippingDate, createdDate }) {
  const { i18n } = useTranslation(["carrierDetails", "quotations"])
  const theme = useTheme()

  return (
    <RateRequestSummaryCard disabled={disabled}>
      <CarrierServiceLogo serviceName={TranslationService.getLabelFromLocale(i18n.language, service.fullName)} logo={carrier.logo} align="left" />
      <Box component="div">{service.fullName && TranslationService.getLabelFromLocale(i18n.language, service.fullName)}</Box>
      <Box component="div">
        {service?.chips?.map((chip, index) => (
          <CustomChip
            key={"serviceChip".concat(index)}
            sx={{
              backgroundColor: theme.palette.contentAlertInformativeDarker.main,
            }}
            label={TranslationService.getLabelFromLocale(i18n.language, chip)}
          />
        ))}
        {charges?.map((charge) => {
          return charge?.chips?.map((chip, index) => (
            <CustomChip
              key={"chargeChip".concat(index)}
              sx={{
                backgroundColor: "orange",
              }}
              label={TranslationService.getLabelFromLocale(i18n.language, chip)}
            />
          ))
        })}
      </Box>
      {(shippingDate || createdDate) && (
        <Box component="div">
          {shippingDate && <br />}
          {shippingDate && <Typography>Shipping Date: {shippingDate}</Typography>}
          {shippingDate && <br />}
          {createdDate && <Typography>Created Date: {createdDate.substring(0, 10)}</Typography>}
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

CarrierDetailsCard.propTypes = {
  service: PropTypes.shape({
    fullName: PropTypes.shape({}),
    chips: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  carrier: PropTypes.shape({
    logo: PropTypes.string,
  }).isRequired,
  charges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  shippingDate: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
}

CarrierDetailsCard.defaultProps = {
  disabled: false,
}

export default CarrierDetailsCard
