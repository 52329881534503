// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CompanyDetailsSummaryCard from "../../../company-details-summary-card"
import { SummaryStatusVariantsEnum } from "../../../company-details-summary-card/components/summary-status"

function CompanyAddCreditCard({ company, title, defaultToIconSuccessStatus, handleCompanyAddCreditCardClick }) {
  const { t } = useTranslation("companies")
  const customTheme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToIconSuccessStatus) return SummaryStatusVariantsEnum.icon
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <CompanyDetailsSummaryCard title={title} handleClick={handleCompanyAddCreditCardClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={customTheme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
          {t("totalAvailableCredits")}: {company.credit?.total?.amount || 0} {company.credit?.total?.currency || "CAD"} <br />
          {company.credit?.privateNote && `Note: ${company.credit?.privateNote}`}
        </Typography>
      </Box>
    </CompanyDetailsSummaryCard>
  )
}

CompanyAddCreditCard.propTypes = {
  title: PropTypes.string,
  defaultToIconSuccessStatus: PropTypes.bool,
  handleCompanyAddCreditCardClick: PropTypes.func,
  company: PropTypes.shape({
    credit: PropTypes.shape({
      updatedDate: PropTypes.string,
      total: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      privateNote: PropTypes.string,
    }),
  }).isRequired,
}

CompanyAddCreditCard.defaultProps = {
  handleCompanyAddCreditCardClick: () => {},
  defaultToIconSuccessStatus: false,
  title: "",
}

export default CompanyAddCreditCard
