import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"

import { PackagesProps } from "../../models/rate-request"
import { UNIT_OF_WEIGHT } from "../../constant"

function PackagesCard({ packages, disabled }) {
  const theme = useTheme()
  const { t } = useTranslation("packagesCard")

  const totalPackages = packages?.pieces?.length > 0 ? packages.pieces.reduce((accumulator, { quantity }) => accumulator + quantity, 0) : 0

  const totalWeight =
    packages?.pieces?.length > 0
      ? packages.pieces.reduce((accumulator, piece) => {
          return accumulator + piece.weight * piece.quantity
        }, 0)
      : 0

  return (
    <RateRequestSummaryCard title={t("packagesTitle")} disabled={disabled} sx={{ height: 1 }}>
      {packages?.pieces?.length > 0 && (
        <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {t("totalPackages")}: {totalPackages}
          </Typography>
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {t("totalWeight")}: {(totalWeight || 0).toFixed(1)} {packages.unitOfMeasure === "imperial" ? UNIT_OF_WEIGHT.POUND : UNIT_OF_WEIGHT.KILOGRAM} (
            {packages.unitOfMeasure === "imperial" ? `${((totalWeight || 0) * 0.453592).toFixed(1)} ${UNIT_OF_WEIGHT.KILOGRAM}` : `${((totalWeight || 0) * 2.20462).toFixed(1)} ${UNIT_OF_WEIGHT.POUND}`})
          </Typography>
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

PackagesCard.propTypes = {
  packages: PackagesProps,
  disabled: PropTypes.bool,
}

PackagesCard.defaultProps = {
  packages: undefined,
  disabled: false,
}

export default PackagesCard
