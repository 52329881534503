// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

// Custom theme
import { useTheme } from "@mui/material/styles"

// Others
import { ORDER_STATUS } from "../../constants"
import { TRANSACTION_STATUS_ENUM } from "../../../transaction-status"
import { useDeleteOrderMutation } from "../../../../services/order/slice"

import AppConfig from "../../../../utils/app-config"
import AttachmentsDrawer from "../attachments-drawer"
import CarrierServiceLogo from "../../../carrier-service-logo"
import ChangeTransitStatusDrawer from "../change-transit-status-drawer"
import Currency from "../../../currency"
import DateTimeService from "../../../../services/date-time"
import MoreMenu from "../../../more-menu"
import OrderDetailsDrawer from "../order-details-drawer"
import OrderProps from "../../../../services/order/model"
import SoeConfirmationDialog from "../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogContainer from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogHeader from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"
import SoeConfirmationDialogContent from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogActions from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import TrackingDrawer from "../tracking-drawer"
import TranslationService from "../../../../services/translation"
import UnitsOfMeasureEnum from "../../../../services/units-conversion"

function Order({ order, showServiceName, companyView }) {
  const { i18n, t } = useTranslation("orders")
  const customTheme = useTheme()

  const [showOrderDetailsDrawer, setShowOrderDetailsDrawer] = useState(false)
  const [showAttachmentsDrawer, setShowAttachmentsDrawer] = useState(false)
  const [showTrackingDrawer, setShowTrackingDrawer] = useState(false)
  const [showChangeTransitStatusDrawer, setShowChangeTransitStatusDrawer] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showTransactionStatus, setShowTransactionStatus] = useState(false)

  const [deleteOrder, { data: deleteOrderResponse, isLoading, isError }] = useDeleteOrderMutation()

  const handleOrderClick = () => {
    setShowTransactionStatus(false)
    setShowOrderDetailsDrawer(true)
  }

  const handleAttachmentsClick = () => {
    setShowAttachmentsDrawer(true)
  }

  const handleTrackingClick = () => {
    setShowTrackingDrawer(true)
  }

  const handleCancelShipmentClick = () => {
    setShowTransactionStatus(false)
    setShowOrderDetailsDrawer(true)
  }

  const handleChangeTransitStatusClick = () => {
    setShowChangeTransitStatusDrawer(true)
  }

  const sideMenuOptionList = [
    {
      label: t("orderDetails.optionsList.details.label"),
      handleClick: handleOrderClick,
    },
    {
      label: t("orderDetails.optionsList.attachments.label"),
      handleClick: handleAttachmentsClick,
    },
  ]

  if (order.entityType !== "additionalCharge") {
    sideMenuOptionList.push({
      label: t("orderDetails.optionsList.track.label"),
      handleClick: handleTrackingClick,
    })
    sideMenuOptionList.push({
      label: t("orderDetails.optionsList.cancelOrder.label"),
      handleClick: handleCancelShipmentClick,
    })
    sideMenuOptionList.push({
      label: t("orderDetails.optionsList.changeTransitStatus.label"),
      handleClick: handleChangeTransitStatusClick,
    })
  }

  const handleClose = () => {
    setShowOrderDetailsDrawer(false)
  }

  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false)
    setShowOrderDetailsDrawer(false)
  }

  const handleConfirmationDialogConfirm = () => {
    deleteOrder(order.id)
    setShowTransactionStatus(true)
    setShowConfirmationDialog(false)
  }

  return (
    <>
      <TableRow hover onClick={handleOrderClick} component="tr" sx={{ backgroundColor: customTheme.palette.contentNeutralPrimaryReverse.main }}>
        <TableCell component="td" scope="row" align="center">
          {showServiceName ? (
            <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
              {TranslationService.getLabelFromLocale(i18n.language, order.carrierService.shortName)}
            </Typography>
          ) : (
            <CarrierServiceLogo logo={order.quotation?.response?.carrier?.logo} code={order.quotation?.response?.carrier?.code} align="center" />
          )}
        </TableCell>
        {AppConfig.features.showOrderTypeColumn && (
          <TableCell component="td" scope="row">
            <Chip label={t("orderParts.parcel.label")} color="secondary" size="small" />
          </TableCell>
        )}
        <TableCell component="td" scope="row">
          <div>{order.number}</div>
          {order.tracking?.masterId && <div>{order.tracking.masterId}</div>}
        </TableCell>
        {!companyView && (
          <TableCell component="td" scope="row">
            <Box>
              <Typography variant="bodyMedium400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                {order.userInfo?.companyName ? `${order.userInfo.companyName}` : order.userInfo?.personName}
              </Typography>
            </Box>
            <Box>
              <Typography variant="bodySmall400">{order.userInfo?.email ? `${order.userInfo.email}` : ""}</Typography>
            </Box>
          </TableCell>
        )}
        <TableCell component="td" scope="row">
          {order?.totalPackages}
        </TableCell>
        <TableCell component="td" scope="row">
          {order.totalWeight.toFixed(1)} {order?.quotation?.request?.packages?.unitOfMeasure === UnitsOfMeasureEnum.IMPERIAL && "lb"}
          {order?.quotation?.request?.packages?.unitOfMeasure === UnitsOfMeasureEnum.METRIC && "kg"}
        </TableCell>
        <TableCell component="td" scope="row">
          <Currency amount={order.quotation?.response?.rate?.totals?.discounted?.total?.amount} currency={order.quotation?.response?.rate?.totals?.discounted?.total?.currency} currencyPosition="right" showSymbol />
        </TableCell>
        <TableCell component="td" scope="row">
          <Currency amount={order.quotation?.response?.rate?.totals?.account?.total?.amount} currency={order.quotation?.response?.rate?.totals?.account?.total?.currency} currencyPosition="right" showSymbol />
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">
            <Typography variant="bodySmall400">{DateTimeService.toLocaleDateString(order.createdDate)}</Typography>
          </Box>
        </TableCell>
        <TableCell component="td" scope="row">
          {order?.orderStatus === ORDER_STATUS.MATCHED && t("orderStatus.matched.label")}
          {order?.orderStatus === ORDER_STATUS.VALIDATED && t("orderStatus.validated.label")}
          {order?.orderStatus === ORDER_STATUS.INVOICED && t("orderStatus.invoiced.label")}
          {order?.orderStatus === ORDER_STATUS.DISPUTED && t("orderStatus.disputed.label")}
          {order?.orderStatus === ORDER_STATUS.CANCELED && t("orderStatus.canceled.label")}
          {order?.orderStatus === ORDER_STATUS.PREPAID && t("orderStatus.prepaid.label")}
          {order?.orderStatus === undefined && t("orderStatus.open.label")}
        </TableCell>
        <TableCell component="td" scope="row">
          {t(`transitStatus.${order?.transitStatus}`)}
        </TableCell>
        <TableCell component="td" scope="row" align="right">
          <MoreMenu optionsList={sideMenuOptionList} />
        </TableCell>
      </TableRow>
      {showOrderDetailsDrawer && (
        <OrderDetailsDrawer
          selectedOrderId={order.id}
          showOrderDetailsDrawer={showOrderDetailsDrawer}
          setShowOrderDetailsDrawer={setShowOrderDetailsDrawer}
          showTransactionStatus={showTransactionStatus}
          deleteOrderResponse={deleteOrderResponse}
          isError={isError}
          isLoading={isLoading}
          actions={
            !(deleteOrderResponse?.status === TRANSACTION_STATUS_ENUM.SUCCESS)
              ? [
                  {
                    action: () => setShowConfirmationDialog(true),
                    label: t("orderDetailsDrawer.actions.cancel"),
                    variant: "outlined",
                    color: "error",
                    loading: isLoading,
                  },
                  {
                    action: handleClose,
                    label: t("orderDetailsDrawer.actions.close"),
                    variant: "contained",
                    disabled: isLoading,
                  },
                ]
              : [
                  {
                    action: handleClose,
                    label: t("orderDetailsDrawer.actions.close"),
                    variant: "contained",
                    disabled: isLoading,
                  },
                ]
          }
        />
      )}
      {showAttachmentsDrawer && <AttachmentsDrawer selectedOrderId={order.id} showAttachmentsDrawer={showAttachmentsDrawer} setShowAttachmentsDrawer={setShowAttachmentsDrawer} />}
      {showTrackingDrawer && <TrackingDrawer selectedOrderId={order.id} showTrackingDrawer={showTrackingDrawer} setShowTrackingDrawer={setShowTrackingDrawer} />}
      {showChangeTransitStatusDrawer && (
        <ChangeTransitStatusDrawer orderId={order.id} transitStatus={order.transitStatus} showChangeTransitStatusDrawer={showChangeTransitStatusDrawer} setShowChangeTransitStatusDrawer={setShowChangeTransitStatusDrawer} />
      )}
      {showConfirmationDialog && (
        <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
          <SoeConfirmationDialogContainer>
            <SoeConfirmationDialogHeader
              title={t("orderDetailsDrawer.confirmationDialog.title")}
              setShowConfirmationDialog={() => {
                setShowConfirmationDialog(false)
              }}
            />
            <SoeConfirmationDialogContent>
              <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
                {t("orderDetailsDrawer.confirmationDialog.content.label")}
              </Typography>
            </SoeConfirmationDialogContent>
            <SoeConfirmationDialogActions
              buttons={[
                {
                  action: handleConfirmationDialogClose,
                  label: t("orderDetailsDrawer.confirmationDialog.actions.cancel.label"),
                  variant: "outlined",
                },
                {
                  action: handleConfirmationDialogConfirm,
                  label: t("orderDetailsDrawer.confirmationDialog.actions.confirm.label"),
                  variant: "contained",
                },
              ]}
            />
          </SoeConfirmationDialogContainer>
        </SoeConfirmationDialog>
      )}
    </>
  )
}

Order.propTypes = {
  order: OrderProps.isRequired,
  showServiceName: PropTypes.bool,
  companyView: PropTypes.bool,
}

Order.defaultProps = {
  showServiceName: false,
  companyView: false,
}

export default Order
