/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/api/input/
 *
 */

export default function Input(theme) {
  return {
    MuiAutocomplete: {
      defaultProps: { color: "contentActionDefault" },
      styleOverrides: {
        root: {
          color: "inherit",
          borderColor: "inherit",
          backgroundColor: "inherit",
          "&.Mui-disabled": {
            color: theme.palette.contentNeutralTertiary.main,
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiTextField: {
      defaultProps: { fullWidth: true },
      styleOverrides: {
        root: {
          color: "inherit",
          borderColor: "inherit",
          backgroundColor: "inherit",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.palette.contentNeutralTertiaryForm.main,
          "&.Mui-disabled": {
            color: theme.palette.contentNeutralTertiary.main,
          },
          "&.Mui-error": {
            "&.Mui-focused": { color: theme.palette.contentAlertError.main },
          },
          "&.Mui-focused": { color: theme.palette.contentActionDefault.main },
          "&:hover": {},
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {},
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            "&.Mui-disabled": {
              color: theme.palette.contentNeutralTertiary.main,
            },
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: { label: "outlined", variant: "outlined", color: "contentActionDefault", fullWidth: true },
      styleOverrides: {
        root: {
          color: theme.palette.contentNeutralPrimary.main,
          borderColor: "inherit",
          backgroundColor: "inherit",
          fontWeight: theme.typography.bodyMedium600.fontWeight,
          fontSize: theme.typography.bodyMedium600.fontSize,
          lineHeight: theme.typography.bodyMedium600.lineHeight,
          "& svg": {
            color: theme.palette.contentNeutralPrimary.main, // @todo: Color in Figma is "#555555", possibly an error in Figma so to validate.
          },
          "&.Mui-disabled": {
            color: theme.palette.contentNeutralTertiary.main,
            "& svg": {
              color: theme.palette.contentNeutralTertiary.main,
            },
          },
          "&.Mui-disabled input": {
            TextFillColor: theme.palette.contentNeutralTertiary.main,
            WebkitTextFillColor: theme.palette.contentNeutralTertiary.main,
          },
          "&.Mui-error": {
            color: theme.palette.contentAlertError.main,
            "& svg": {
              color: theme.palette.contentAlertError.main,
            },
          },
          "&:hover": {},
          "&.Mui-focused": {},
          "& fieldset": {
            borderColor: theme.palette.contentActionDefault.main,
          },
        },
        inputSizeMedium: {
          fontSize: theme.typography.bodyMedium400.fontSize,
          fontWeight: theme.typography.bodyMedium400.fontWeight,
          lineHeight: theme.typography.bodyMedium400.lineHeight,
        },
        inputSizeSmall: {
          fontWeight: theme.typography.bodyMedium400.fontWeight,
          fontSize: theme.typography.bodyMedium400.fontSize,
          lineHeight: theme.typography.bodyMedium400.lineHeight,
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: theme.palette.contentNeutralTertiary.main,
          },
        },
      },
    },
    MuiFormGroup: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiFormHelperText: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: "inherit",
          "&.Mui-disabled": {
            color: theme.palette.contentNeutralTertiary.main,
          },
          "&.Mui-error": {
            color: theme.palette.contentAlertError.main,
          },
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.palette.contentNeutralPrimary.main,
        },
      },
    },
    MuiInputAdornment: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiOutlinedInput: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: "inherit",
          borderColor: "inherit",
          backgroundColor: "inherit",
          borderRadius: "8px",
          "& .MuiSelect-select ": {
            "&.Mui-disabled": {
              TextFillColor: theme.palette.contentNeutralTertiary.main,
              WebkitTextFillColor: theme.palette.contentNeutralTertiary.main,
            },
          },
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: "inherit",
          borderColor: "inherit",
          backgroundColor: "inherit",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        },
      },
    },
    MuiInput: {
      defaultProps: {},
      styleOverrides: {
        // @todo: To validate, do we want icons to be that low?
        root: {
          color: "inherit",
          borderColor: "inherit",
          backgroundColor: "inherit",
        },
      },
    },
  }
}
