// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

// MUI
import Box from "@mui/material/Box"

// Formik
import { FieldArray, FormikProvider, useFormik } from "formik"

import { TextField, useTheme } from "@mui/material"
import { useEditSettingsMutation } from "../../../../slice"

function StripeSettingsDrawer({ onSubmitEvent, setShowStripeSettingsDrawer, stripeSettings }) {
  const theme = useTheme()

  const [editSettings] = useEditSettingsMutation()

  const formik = useFormik({
    initialValues: {
      settings: stripeSettings.settings,
    },
    validate: ({ settings }) => {
      const errors = {
        settings: [],
      }
      settings.forEach((setting) => {
        let error
        if (!setting.value) {
          error = true
        }
        errors.settings.push(error)
      })
      let isValid = true

      errors.settings.forEach((err) => {
        if (err) {
          isValid = false
        }
      })

      return isValid ? undefined : errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async ({ settings }) => {
      await editSettings({ id: stripeSettings.id, payload: settings })
      formik.resetForm()
      setShowStripeSettingsDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  const { values } = formik

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="settings"
        render={() => (
          <Box component="div">
            {values.settings.map((setting, index) => (
              <TextField
                key={"".concat(index)}
                label={setting.name}
                name={`settings[${index}].value`}
                value={formik.values.settings[index].value}
                onChange={formik.handleChange}
                variant="outlined"
                size="small"
                error={formik.touched?.settings && formik.touched?.settings[index] && formik.errors?.settings && formik.errors?.settings[index]}
                sx={{ marginTop: theme.spacing(1) }}
              />
            ))}
          </Box>
        )}
      />
    </FormikProvider>
  )
}

StripeSettingsDrawer.defaultProps = {
  onSubmitEvent: undefined,
  stripeSettings: undefined,
  setShowStripeSettingsDrawer: () => {},
}

StripeSettingsDrawer.propTypes = {
  stripeSettings: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowStripeSettingsDrawer: PropTypes.func,
}

export default StripeSettingsDrawer
