// React
import React from "react"

import { Link as RouterLink } from "react-router-dom"

// Material
import { Box, Button, Typography, Container } from "@mui/material"

/**
 * @todo : add translation and theme
 */
export default function NotFound() {
  return (
    <Container>
      <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
        <Typography variant="headingH1">Sorry, page not found!</Typography>
        <Typography sx={{ mb: 2 }}>Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.</Typography>
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
      </Box>
    </Container>
  )
}
