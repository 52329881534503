import React from "react"
import PropTypes from "prop-types"

function AddressAutocompleteOption({ renderOptionProps, currentAddress }) {
  return (
    <li {...renderOptionProps}>
      <div>
        <span>{currentAddress && currentAddress.address && currentAddress.address.join(" ")}</span>
      </div>
    </li>
  )
}

AddressAutocompleteOption.propTypes = {
  renderOptionProps: PropTypes.shape({
    "aria-disabled": PropTypes.bool,
    "aria-selected": PropTypes.bool,
    className: PropTypes.string,
    "data-option-index": PropTypes.number,
    id: PropTypes.string,
    key: PropTypes.string,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onTouchStart: PropTypes.func,
    role: PropTypes.string,
    tabIndex: PropTypes.number,
  }).isRequired,
  currentAddress: PropTypes.shape({
    address: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)), PropTypes.arrayOf(PropTypes.string)]),
    companyName: PropTypes.string,
    id: PropTypes.string,
    origin: PropTypes.string,
    personName: PropTypes.string,
  }).isRequired,
}

export default AddressAutocompleteOption
