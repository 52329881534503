// REACT
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Masonry from "@mui/lab/Masonry"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material"

// Others
import CanadaPostSettingsCard from "./components/canada-post-settings-card"
import CanadaPostSettingsDrawer from "./components/canada-post-settings-drawer"
import GooglePlacesSettingsCard from "./components/google-places-settings-card"
import GooglePlacesSettingsDrawer from "./components/google-places-settings-drawer"
import StripeSettingsCard from "./components/stripe-settings-card"
import StripeSettingsDrawer from "./components/stripe-settings-drawer"
import FxRateSpreadSettingsCard from "./components/fx-rate-spread-settings-card"
import FxRateSpreadSettingsDrawer from "./components/fx-rate-spread-settings-drawer"
import NextCustomerIdSettingsCard from "./components/next-customer-id-settings-card"
import NextCustomerIdSettingsDrawer from "./components/next-customer-id-settings-drawer"
import NextInvoiceIdSettingsCard from "./components/next-invoice-id-settings-card"
import NextInvoiceIdSettingsDrawer from "./components/next-invoice-id-settings-drawer"
import MinimumVersionsCard from "./components/minimum-versions-card"
import MinimumVersionsDrawer from "./components/minimum-versions-drawer"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useGetSettingsQuery } from "../../slice"

function GeneralInformation() {
  const { t } = useTranslation("settings")
  const { data: settings } = useGetSettingsQuery()

  const customTheme = useTheme()
  const [showStripeSettingsDrawer, setShowStripeSettingsDrawer] = useState(false)
  const [showCanadaPostSettingsDrawer, setShowCanadaPostSettingsDrawer] = useState(false)
  const [showGooglePlacesSettingsDrawer, setShowGooglePlacesSettingsDrawer] = useState(false)
  const [showFxRateSpreadSettingsDrawer, setShowFxRateSpreadSettingsDrawer] = useState(false)
  const [showNextCustomerIdSettingsDrawer, setShowNextCustomerIdSettingsDrawer] = useState(false)
  const [showNextInvoiceIdSettingsDrawer, setShowNextInvoiceIdSettingsDrawer] = useState(false)
  const [showMinimumVersionsDrawer, setShowMinimumVersionsDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState(undefined)

  const handleStripeSettingsCardClick = () => {
    setSubmitEvent(undefined)
    setShowStripeSettingsDrawer(true)
  }
  const handleCanadaPostSettingsCardClick = () => {
    setSubmitEvent(undefined)
    setShowCanadaPostSettingsDrawer(true)
  }
  const handleGooglePlacesSettingsCardClick = () => {
    setSubmitEvent(undefined)
    setShowGooglePlacesSettingsDrawer(true)
  }
  const handleFxRateSpreadSettingsCardClick = () => {
    setSubmitEvent(undefined)
    setShowFxRateSpreadSettingsDrawer(true)
  }
  const handleNextCustomerIdSettingsCardClick = () => {
    setSubmitEvent(undefined)
    setShowNextCustomerIdSettingsDrawer(true)
  }
  const handleNextInvoiceIdSettingsCardClick = () => {
    setSubmitEvent(undefined)
    setShowNextInvoiceIdSettingsDrawer(true)
  }
  const handleMinimumVersionsCardClick = () => {
    setSubmitEvent(undefined)
    setShowMinimumVersionsDrawer(true)
  }

  return (
    <Box component="div">
      <Box display="flex" m={customTheme.utils.pxToThemeSpacing(16)}>
        <Box component="h5">
          <Typography sx={(theme) => ({ fontSize: customTheme.utils.pxToRem(18), fontWeight: theme.typography.fontWeightBold })}>{t("settings.generalInformation.title.label")}</Typography>
        </Box>
      </Box>
      <Box display="flex" pl={customTheme.utils.pxToThemeSpacing(6)}>
        <Masonry columns={4} sx={{ alignContent: "flex-start" }}>
          <CanadaPostSettingsCard handleCanadaPostSettingsCardClick={() => handleCanadaPostSettingsCardClick()} canadaPostSettings={settings?.find((setting) => setting.id === "canadapost-address-api")} />
          <GooglePlacesSettingsCard handleGooglePlacesSettingsCardClick={() => handleGooglePlacesSettingsCardClick()} googlePlacesSettings={settings?.find((setting) => setting.id === "google-places-api")} />
          <StripeSettingsCard handleStripeSettingsCardClick={() => handleStripeSettingsCardClick()} stripeSettings={settings?.find((setting) => setting.id === "stripe-api")} />
          <FxRateSpreadSettingsCard handleFxRateSpreadSettingsCardClick={() => handleFxRateSpreadSettingsCardClick()} fxRateSpreadSettings={settings?.find((setting) => setting.id === "fxrate-spread")} />
          <NextCustomerIdSettingsCard handleNextCustomerIdSettingsCardClick={() => handleNextCustomerIdSettingsCardClick()} nextCustomerIdSettings={settings?.find((setting) => setting.id === "next-customer-id")} />
          <NextInvoiceIdSettingsCard handleNextInvoiceIdSettingsCardClick={() => handleNextInvoiceIdSettingsCardClick()} nextInvoiceIdSettings={settings?.find((setting) => setting.id === "next-invoice-id")} />
          <MinimumVersionsCard handleMinimumVersionsCardClick={() => handleMinimumVersionsCardClick()} minimumVersions={settings?.find((setting) => setting.id === "versions")} />
        </Masonry>
      </Box>

      <SoeDrawer showDrawer={showCanadaPostSettingsDrawer} setShowDrawer={setShowCanadaPostSettingsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("settings.canadaPostSettingsDrawer.title")}
            setShowDrawer={() => {
              setShowCanadaPostSettingsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CanadaPostSettingsDrawer onSubmitEvent={onSubmitEvent} setShowCanadaPostSettingsDrawer={setShowCanadaPostSettingsDrawer} canadaPostSettings={settings?.find((setting) => setting.id === "canadapost-address-api")} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("settings.canadaPostSettingsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showStripeSettingsDrawer} setShowDrawer={setShowStripeSettingsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("settings.stripeSettingsDrawer.title")}
            setShowDrawer={() => {
              setShowStripeSettingsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <StripeSettingsDrawer onSubmitEvent={onSubmitEvent} setShowStripeSettingsDrawer={setShowStripeSettingsDrawer} stripeSettings={settings?.find((setting) => setting.id === "stripe-api")} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("settings.stripeSettingsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showGooglePlacesSettingsDrawer} setShowDrawer={setShowGooglePlacesSettingsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("settings.googlePlacesSettingsDrawer.title")}
            setShowDrawer={() => {
              setShowGooglePlacesSettingsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <GooglePlacesSettingsDrawer onSubmitEvent={onSubmitEvent} setShowGooglePlacesSettingsDrawer={setShowGooglePlacesSettingsDrawer} googlePlacesSettings={settings?.find((setting) => setting.id === "google-places-api")} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("settings.googlePlacesSettingsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showFxRateSpreadSettingsDrawer} setShowDrawer={setShowFxRateSpreadSettingsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("settings.fxRateSpreadSettingsDrawer.title")}
            setShowDrawer={() => {
              setShowFxRateSpreadSettingsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <FxRateSpreadSettingsDrawer onSubmitEvent={onSubmitEvent} setShowFxRateSpreadSettingsDrawer={setShowFxRateSpreadSettingsDrawer} fxRateSpreadSettings={settings?.find((setting) => setting.id === "fxrate-spread")} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("settings.fxRateSpreadSettingsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showNextCustomerIdSettingsDrawer} setShowDrawer={setShowNextCustomerIdSettingsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("settings.nextCustomerIdSettingsDrawer.title")}
            setShowDrawer={() => {
              setShowNextCustomerIdSettingsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <NextCustomerIdSettingsDrawer onSubmitEvent={onSubmitEvent} setShowNextCustomerIdSettingsDrawer={setShowNextCustomerIdSettingsDrawer} nextCustomerIdSettings={settings?.find((setting) => setting.id === "next-customer-id")} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("settings.nextCustomerIdSettingsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showNextInvoiceIdSettingsDrawer} setShowDrawer={setShowNextInvoiceIdSettingsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("settings.nextInvoiceIdSettingsDrawer.title")}
            setShowDrawer={() => {
              setShowNextInvoiceIdSettingsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <NextInvoiceIdSettingsDrawer onSubmitEvent={onSubmitEvent} ShowNextInvoiceIdSettingsDrawer={setShowNextInvoiceIdSettingsDrawer} nextInvoiceIdSettings={settings?.find((setting) => setting.id === "next-invoice-id")} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("settings.nextInvoiceIdSettingsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showMinimumVersionsDrawer} setShowDrawer={setShowMinimumVersionsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("settings.minimumVersionsDrawer.title")}
            setShowDrawer={() => {
              setShowMinimumVersionsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <MinimumVersionsDrawer onSubmitEvent={onSubmitEvent} setShowMinimumVersionsDrawer={setShowMinimumVersionsDrawer} minimumVersions={settings?.find((setting) => setting.id === "versions")} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("settings.minimumVersionsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Box>
  )
}

export default GeneralInformation
