// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"

// Formik
import { FieldArray, FormikProvider, useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import { useEditSettingsMutation } from "../../../../slice"

function NextInvoiceIdSettingsDrawer({ onSubmitEvent, ShowNextInvoiceIdSettingsDrawer, nextInvoiceIdSettings }) {
  const { t } = useTranslation("settings")
  const theme = useTheme()

  const [editSettings] = useEditSettingsMutation()

  const formik = useFormik({
    initialValues: {
      settings: nextInvoiceIdSettings.settings,
    },
    validate: ({ settings }) => {
      const errors = {
        settings: [],
      }
      settings.forEach((setting) => {
        let error
        if (!setting.value) {
          error = true
        }
        errors.settings.push(error)
      })
      let isValid = true
      errors.settings.forEach((err) => {
        if (err) {
          isValid = false
        }
      })
      return isValid ? undefined : errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async ({ settings }) => {
      await editSettings({ id: nextInvoiceIdSettings.id, payload: settings })
      formik.resetForm()
      ShowNextInvoiceIdSettingsDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  const { values } = formik

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="settings"
        render={() => (
          <Box component="div">
            {values.settings.map((setting, index) => (
              <TextField
                key={"".concat(index)}
                label={t("settings.nextInvoiceIdSettingsDrawer.fields.label")}
                name={`settings[${index}].value`}
                value={formik.values.settings[index].value}
                onChange={formik.handleChange}
                variant="outlined"
                size="small"
                error={formik.touched?.settings && formik.touched?.settings[index] && formik.errors?.settings && formik.errors?.settings[index]}
                type="Number"
                sx={{ marginTop: theme.spacing(1) }}
              />
            ))}
          </Box>
        )}
      />
    </FormikProvider>
  )
}

NextInvoiceIdSettingsDrawer.defaultProps = {
  onSubmitEvent: undefined,
  nextInvoiceIdSettings: undefined,
}

NextInvoiceIdSettingsDrawer.propTypes = {
  nextInvoiceIdSettings: PropTypes.instanceOf(Object),
  onSubmitEvent: PropTypes.instanceOf(Object),
  ShowNextInvoiceIdSettingsDrawer: PropTypes.func.isRequired,
}

export default NextInvoiceIdSettingsDrawer
