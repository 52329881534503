import { createAction, isRejectedWithValue } from "@reduxjs/toolkit"

const rtkQueryActionHandler = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload) {
      const sendRestApiErrorAction = createAction(`restApi/error/${action.payload.target}`)
      api.dispatch(sendRestApiErrorAction(action.payload))
    }
  }

  return next(action)
}

export default rtkQueryActionHandler
