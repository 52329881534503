import PropTypes from "prop-types"
import React from "react"

import TransactionStatusMessage from "../transaction-status-message"

function TransactionStatusMessageList({ messages }) {
  return (
    <>
      {messages.map((message, index) => {
        return <TransactionStatusMessage key={"".concat(index)} message={message} />
      })}
    </>
  )
}

TransactionStatusMessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
}

export default TransactionStatusMessageList
