/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/icons/
 * @link https://mui.com/api/icon/
 *
 * @link https://mui.com/api/icon-button/
 *
 * @link https://mui.com/components/material-icons/
 * @link https://mui.com/api/svg-icon/
 */

export default function Icon(theme) {
  return {
    MuiIcon: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiSvgIcon: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiIconButton: {
      defaultProps: { color: "contentActionDefault", size: "medium" },
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
          "&:disabled": {
            background: theme.palette.backgroundNeutralTransparent.main,
            backgroundColor: theme.palette.backgroundNeutralTransparent.main,
          },
        },
      },
    },
  }
}
