import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import StepWizard from "react-step-wizard"

export const MuiWrapper = styled(Box, { name: "SoeDrawerWizard", slot: "Root" })(() => ({ width: "100%", height: "100%" }))
export const SoeStepWizard = styled(StepWizard, { name: "SoeStepWizard", slot: "Root" })(() => ({
  width: "100%",
  height: "100%",
  "& .rsw_2Y": {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    "& .rsw_2f": {
      width: "100%",
      height: "100%",
    },
  },
}))
