// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Masonry from "@mui/lab/Masonry"

import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom theme
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"

// Others
import CarrierChargeCard from "../carrier-charge-card"
import CarrierChargeDrawer from "../carrier-charge-drawer"
import { useGetCarrierChargesQuery, useGetCarrierChargeTypesQuery } from "../../slice"
import { useGetLanguagesQuery } from "../../../../services/rest-api/slice"

function CarrierCharges({ carrier }) {
  const customTheme = useTheme()
  const { t } = useTranslation("carriers")

  const masonryNumberColumns = 4

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  const [showCarrierChargeDrawer, setShowCarrierChargeDrawer] = useState(false)
  const [carrierChargeIndex, setCarrierChargeIndex] = useState()
  const [onSubmitEvent, setSubmitEvent] = useState()

  const { data: languages } = useGetLanguagesQuery()
  const { data: carrierCharges, isFetching } = useGetCarrierChargesQuery(carrier.code, { skip: !isAccordionExpanded })
  const { data: chargeTypes } = useGetCarrierChargeTypesQuery()

  const handleAccordionChange = (_, expanded) => {
    setIsAccordionExpanded(expanded)
  }

  const handleAddCarrierChargeClick = (event) => {
    event.stopPropagation()
    setSubmitEvent()
    setCarrierChargeIndex()
    setShowCarrierChargeDrawer(true)
  }

  const handleCarrierChargeCardClick = () => {
    setSubmitEvent()
    setShowCarrierChargeDrawer(true)
  }

  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary>
        <Grid container>
          <Grid container item xs={11} alignItems="center">
            <Typography component="div" ml={customTheme.utils.pxToThemeSpacing(14)}>
              <Box fontWeight="fontWeightBold" display="inline">
                {t("carrierDetails.carrierCharges.title")}
              </Box>
            </Typography>
            {carrier.charges.new > 0 && <Chip label={`${carrier.charges.new} ${t("carriersManagement.chipStatus.newItems.label")}`} size="small" color="error" sx={(theme) => ({ ml: theme.utils.pxToThemeSpacing(14), textTransform: "lowercase" })} />}
          </Grid>
          <Grid container item justifyContent="flex-end" alignItems="center" xs={1}>
            <Button variant="outlined" size="medium" color="primary" onClick={handleAddCarrierChargeClick}>
              {t("carrierDetails.carrierCharges.actions.add.label")}
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {isFetching ? (
          <Masonry columns={masonryNumberColumns} sx={{ alignContent: "flex-start" }}>
            {Array(masonryNumberColumns)
              .fill()
              .map((_, index) => {
                return <Skeleton key={"".concat(index)} variant="rectangular" height={75} />
              })}
          </Masonry>
        ) : (
          carrierCharges && (
            <Masonry columns={masonryNumberColumns} sx={{ alignContent: "flex-start" }}>
              {carrierCharges.map((carrierCharge, index) => {
                return <CarrierChargeCard key={"".concat(index)} carrierCharge={carrierCharge} chargeTypes={chargeTypes} handleCarrierChargeCardClick={() => handleCarrierChargeCardClick(setCarrierChargeIndex(index))} />
              })}
            </Masonry>
          )
        )}
      </AccordionDetails>
      <SoeDrawer showDrawer={showCarrierChargeDrawer} setShowDrawer={setShowCarrierChargeDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("carrierDetails.carrierChargeTypesDrawer.title")}
            setShowDrawer={() => {
              setShowCarrierChargeDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CarrierChargeDrawer
              carrierGeneralInfoCode={carrier.code}
              carrierCharges={carrierCharges}
              carrierChargeIndex={carrierChargeIndex}
              chargeTypes={chargeTypes}
              languages={languages}
              onSubmitEvent={onSubmitEvent}
              setShowCarrierChargeDrawer={setShowCarrierChargeDrawer}
            />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("carrierDetails.carrierChargeTypesDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Accordion>
  )
}

CarrierCharges.propTypes = {
  carrier: PropTypes.shape({
    accountFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    charges: PropTypes.shape({
      count: PropTypes.number,
      new: PropTypes.number,
    }),
    code: PropTypes.string,
    fullName: PropTypes.instanceOf(Object),

    logo: PropTypes.string,
    services: PropTypes.shape({
      count: PropTypes.number,
      new: PropTypes.number,
    }),
    shortName: PropTypes.instanceOf(Object),
    isDisabled: PropTypes.bool,
  }).isRequired,
}

export default CarrierCharges
