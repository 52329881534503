import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"

function InvoiceNumberCard({ invoiceNumber, disabled }) {
  const { t } = useTranslation("invoiceNumberCard")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard disabled={disabled}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={2} textAlign="left">
          <Typography variant="bodySmall200" component="div" sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 16) }}>
            {t("invoiceNumber")}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" ml={theme.spacing(1)} component="div" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {invoiceNumber}
          </Typography>
        </Grid>
      </Grid>
    </RateRequestSummaryCard>
  )
}

InvoiceNumberCard.propTypes = {
  invoiceNumber: PropTypes.string,
  disabled: PropTypes.bool,
}

InvoiceNumberCard.defaultProps = {
  invoiceNumber: "",
  disabled: false,
}

export default InvoiceNumberCard
