// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CompanyDetailsSummaryCard from "../../../company-details-summary-card"
import { SummaryStatusVariantsEnum } from "../../../company-details-summary-card/components/summary-status"

function CompanyBillingCard({ billing, defaultToIconSuccessStatus, handleCompanyBillingCardClick }) {
  const { t } = useTranslation("companies")
  const customTheme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToIconSuccessStatus) return SummaryStatusVariantsEnum.icon
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <CompanyDetailsSummaryCard title={t("companyDetails.companyDetailsInformations.billingCardTitle")} handleClick={handleCompanyBillingCardClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={customTheme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {billing?.type && (
          <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
            {t(`companyDetails.companyDetailsInformations.billing.type`)} {t(`companyDetails.companyDetailsInformations.billing.${billing?.type}`)}
          </Typography>
        )}
        {billing?.account?.number && (
          <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
            {t(`companyDetails.companyDetailsInformations.billing.number`)} {billing?.account?.number}
          </Typography>
        )}
        {billing?.account?.paymentTermsDays !== undefined && (
          <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
            {t(`companyDetails.companyDetailsInformations.billing.paymentTermsDays`)} {billing?.account?.paymentTermsDays}
          </Typography>
        )}
        {billing?.currency && (
          <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
            {t(`companyDetails.companyDetailsInformations.billing.currency`)} {billing?.currency}
          </Typography>
        )}
      </Box>
    </CompanyDetailsSummaryCard>
  )
}

CompanyBillingCard.propTypes = {
  billing: PropTypes.shape({
    type: PropTypes.string,
    currency: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    account: PropTypes.object,
  }),
  defaultToIconSuccessStatus: PropTypes.bool,
  handleCompanyBillingCardClick: PropTypes.func,
}

CompanyBillingCard.defaultProps = {
  handleCompanyBillingCardClick: () => {},
  defaultToIconSuccessStatus: false,
  billing: PropTypes.shape({
    type: "",
    currency: "",
    account: {},
  }),
}

export default CompanyBillingCard
