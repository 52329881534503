// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import Currency from "../currency"
import RateRequestSummaryCard from "../rate-request-summary-card"

import { InternationalOptionsProps } from "../../models/rate-request"

function InternationalOptionsCard({ internationalOptions, disabled }) {
  const { t } = useTranslation("internationalOptionsCard")
  const customTheme = useTheme()

  return (
    !disabled && (
      <RateRequestSummaryCard title={t("internationalOptionsTitle")} disabled={disabled}>
        {internationalOptions && (
          <Box mt={customTheme.spacing(1)} sx={{ display: "flex", flexDirection: "column" }}>
            {internationalOptions.documentsOnly && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {t("documentsOnly")}
              </Typography>
            )}
            {internationalOptions.declaredValue && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {t("declaredValue")}
                <Currency amount={internationalOptions.declaredValue.amount} currency={internationalOptions.declaredValue.currency} currencyPosition="right" color={customTheme.palette.contentNeutralSecondary.main} showSymbol />
              </Typography>
            )}
            {internationalOptions.billDutyTo && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {t("billDutyTo.label")}
                {t(`billDutyTo.options.${internationalOptions.billDutyTo}`)}
              </Typography>
            )}
            {internationalOptions.dutyAccountNumber && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {t("dutyAccountNumber.label")}
                {internationalOptions.dutyAccountNumber}
              </Typography>
            )}
            {internationalOptions.recipientTaxId && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {t("recipientTaxId")}
                {internationalOptions.recipientTaxId}
              </Typography>
            )}
            {internationalOptions.senderIsProducer && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {t("senderIsProducer")}
              </Typography>
            )}
            {internationalOptions.businessRelationship && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {t("businessRelationship.label")}
                {t(`businessRelationship.options.${internationalOptions.businessRelationship}`)}
              </Typography>
            )}
            {internationalOptions.exportType && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {t("exportType.label")}
                {t(`exportType.options.${internationalOptions.exportType}`)}
              </Typography>
            )}
          </Box>
        )}
      </RateRequestSummaryCard>
    )
  )
}

InternationalOptionsCard.propTypes = {
  internationalOptions: InternationalOptionsProps,
  disabled: PropTypes.bool,
}

InternationalOptionsCard.defaultProps = {
  internationalOptions: undefined,
  disabled: false,
}

export default InternationalOptionsCard
