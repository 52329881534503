// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

// Others
import ChargeType from "../charge-type"
import TranslationService from "../../../../services/translation"
import { useGetChargesQuery } from "../../slice"

function Services({ pricingGroupId, pricingPlanId, service }) {
  const { t, i18n } = useTranslation("pricing")

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  const { data: charges, isFetching } = useGetChargesQuery({ skip: !isAccordionExpanded })

  const handleAccordionChange = (_, expanded) => {
    setIsAccordionExpanded(expanded)
  }

  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary>
        <Grid container alignItems="center">
          <Grid container item xs={12} alignItems="center">
            <Typography component="div">
              {`${t("service.fields.label")}: `}
              <Box fontWeight="fontWeightBold" display="inline">
                {TranslationService.getLabelFromLocale(i18n.language, service.fullName)}
              </Box>{" "}
            </Typography>
            {service.isDisabled && <Chip label={t("service.chips.disabled.label")} size="small" color="error" sx={(theme) => ({ ml: theme.utils.pxToThemeSpacing(16) })} />}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {!charges || isFetching ? (
          <Skeleton variant="rectangular" width="100%" height={100} />
        ) : (
          <>
            {charges.map((chargeType, index) => {
              return <ChargeType key={"".concat(index)} pricingGroupId={pricingGroupId} pricingPlanId={pricingPlanId} serviceId={service.serviceCode} chargeType={chargeType} />
            })}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

Services.propTypes = {
  pricingGroupId: PropTypes.string.isRequired,
  pricingPlanId: PropTypes.string.isRequired,
  service: PropTypes.shape({
    serviceCode: PropTypes.string,
    isDisabled: PropTypes.bool,
    fullName: PropTypes.shape({}),
    shortName: PropTypes.shape({}),
    chargeTypes: PropTypes.arrayOf(Object),
  }).isRequired,
}

export default Services
