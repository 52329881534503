// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

import { CustomBox, CustomBoxContent, MuiWrapper } from "./style"

function SoeConfirmationDialogHeader({ title, setShowConfirmationDialog, preventClosing, ...muiBoxProps }) {
  return (
    <MuiWrapper component="header" {...muiBoxProps}>
      <CustomBox>
        <CustomBoxContent>
          <Typography variant="headingH6" sx={(theme) => ({ color: theme.palette.contentNeutralPrimary.main, fontWeight: theme.typography.fontWeightBold })}>
            {title}
          </Typography>
          {!preventClosing && (
            <IconButton onClick={() => setShowConfirmationDialog(false)}>
              <CloseIcon />
            </IconButton>
          )}
        </CustomBoxContent>
      </CustomBox>
    </MuiWrapper>
  )
}

SoeConfirmationDialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  setShowConfirmationDialog: PropTypes.func,
  preventClosing: PropTypes.bool,
}

SoeConfirmationDialogHeader.defaultProps = {
  setShowConfirmationDialog: () => {},
  preventClosing: false,
}

export default SoeConfirmationDialogHeader
