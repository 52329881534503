import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

const MuiWrapper = styled(Box, { name: "SoeConfirmationDialogContent", slot: "Root" })(({ theme }) => ({
  padding: theme.spacing(2),
  flexGrow: 1,
  overflow: "auto",
}))

export default MuiWrapper
