import PropTypes from "prop-types"

import AddressProps from "../address"

import { PieceProps } from "../piece"

export const ShipperProps = AddressProps

export const RecipientProps = AddressProps

export const PackagesProps = PropTypes.shape({
  unitOfMeasure: PropTypes.string,
  pieces: PropTypes.arrayOf(PieceProps),
  totals: PropTypes.shape({}),
})

export const ShippingOptionsProps = PropTypes.shape({
  insurance: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  nonConveyable: PropTypes.bool,
  shippingDate: PropTypes.string,
  shippingDateTimezone: PropTypes.string,
  references: PropTypes.shape({
    customer: PropTypes.string,
    order: PropTypes.string,
    invoice: PropTypes.string,
  }),
  signature: PropTypes.string,
  createReturnLabel: PropTypes.bool,
  deliveryInstructions: PropTypes.string,
})

export const FreightOptionsProps = PropTypes.shape({
  tailgate: PropTypes.string,
  appointment: PropTypes.string,
  twoPersons: PropTypes.string,
  prebookedAppointment: PropTypes.string,
  afterHours: PropTypes.string,
})

export const InternationalOptionsProps = PropTypes.shape({
  billDutyToAccount: PropTypes.string,
  recipientTaxId: PropTypes.string,
  declaredValue: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  senderIsProducer: PropTypes.bool,
  businessRelationship: PropTypes.string,
  documentsOnly: PropTypes.bool,
  exportType: PropTypes.string,
})

export const BrokerProps = AddressProps

export const CommercialInvoiceProps = PropTypes.shape({
  currency: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  invoiceItems: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      quantity: PropTypes.number,
      hsCode: PropTypes.string,
      description: PropTypes.string,
      weight: PropTypes.number,
      value: PropTypes.number,
    })
  ),
})

export const BuyerProps = AddressProps

const RateRequestProps = PropTypes.shape({
  createdDate: PropTypes.string,
  rateRequestId: PropTypes.string,
  shipper: ShipperProps,
  recipient: RecipientProps,
  packages: PackagesProps,
  shippingOptions: ShippingOptionsProps,
  freightOptions: FreightOptionsProps,
  internationalOptions: InternationalOptionsProps,
  broker: BrokerProps,
  commercialInvoice: CommercialInvoiceProps,
  buyer: BuyerProps,
})

export default RateRequestProps
