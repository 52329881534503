import { useTheme } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import PropTypes from "prop-types"
import React from "react"

function SoeDrawer({ children, showDrawer, setShowDrawer, anchor, keepMounted, preventClosing }) {
  const theme = useTheme()

  return (
    <Drawer
      anchor={anchor}
      open={showDrawer}
      onClose={() => {
        if (!preventClosing) {
          setShowDrawer(false)
        }
      }}
      PaperProps={{ className: "soeDrawer" }}
      variant="temporary"
      ModalProps={{
        keepMounted,
      }}
      sx={{ zIndex: theme.zIndex.drawer + 2 }}
    >
      {children}
    </Drawer>
  )
}

SoeDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  showDrawer: PropTypes.bool.isRequired,
  setShowDrawer: PropTypes.func.isRequired,
  anchor: PropTypes.string,
  keepMounted: PropTypes.bool,
  preventClosing: PropTypes.bool,
}

SoeDrawer.defaultProps = {
  anchor: "left",
  keepMounted: true,
  preventClosing: false,
}

export default SoeDrawer
