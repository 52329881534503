import React from "react"
import PropTypes from "prop-types"

import Box from "@mui/material/Box"
import DateTimeService from "../../services/date-time"

function DateTime(props) {
  const { date, time, timezoneCode } = props

  return (
    <Box component="span">
      {DateTimeService.toLocaleDateString(date)} {time} {timezoneCode}
    </Box>
  )
}

DateTime.defaultProps = {
  time: undefined,
  timezoneCode: undefined,
}

DateTime.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string,
  timezoneCode: PropTypes.string,
}

export default DateTime
