// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
// eslint-disable-next-line import/no-extraneous-dependencies
import LoadingButton from "@mui/lab/LoadingButton"

// Others
import { CustomBox, MuiWrapper } from "./style"

function SoeConfirmationDialogActions({ buttons, ...muiBoxProps }) {
  return (
    <MuiWrapper component="footer" {...muiBoxProps}>
      <CustomBox>
        {buttons.map((button, index) => {
          return (
            <LoadingButton key={`${index.toString()}`} variant={button.variant} color={button?.color} disabled={buttons.disabled || false} size="medium" onClick={button.action} loading={button.loading || false}>
              {button.label}
            </LoadingButton>
          )
        })}
      </CustomBox>
    </MuiWrapper>
  )
}

SoeConfirmationDialogActions.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      label: PropTypes.string,
      variant: PropTypes.string,
      disabled: PropTypes.bool,
      loading: PropTypes.bool,
    })
  ).isRequired,
  setDecisionConfirmationDialog: PropTypes.func,
}

SoeConfirmationDialogActions.defaultProps = {
  setDecisionConfirmationDialog: () => {},
}

export default SoeConfirmationDialogActions
