/* eslint-disable no-console */
import React from "react"
import { Alert, AlertTitle, Button, Card, CardHeader, CardContent, Container, Grid, IconButton, List, ListItem, Link, Typography } from "@mui/material"

import SoeCancelIcon from "../../icons/SoeCancelIcon"

import "./styles.scss"

export default function AlertsExample() {
  return (
    <Container maxWidth="lg">
      <List>
        <ListItem>
          <Link href="https://mui.com/components/Alert/" target="_blank">
            {"MUI component <Alert />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/Alert/" target="_blank">
            {"MUI API <Alert />"}
          </Link>
        </ListItem>
      </List>
      <Card sx={{ minWidth: 275, marginBottom: 2, marginTop: 2 }}>
        <CardHeader title="Material UI Alert component - ERROR" />
        <CardContent>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title only" />
            <CardContent>
              <Alert severity="error">
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    error title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="error">
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">error title</Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text only" />
            <CardContent>
              <Alert severity="error">
                <Typography component="p" variant="bodyMedium400">
                  error body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi
                  quidem quibusdam.
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="error">
  <Typography component="p" variant="bodyMedium400">
    error body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum 
    inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and text" />
            <CardContent>
              <Alert severity="error">
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    error title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  error body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi
                  quidem quibusdam.
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="error">
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      error title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    error body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum 
    inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and button action" />
            <CardContent>
              <Alert
                severity="error"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    error title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="error"
  onClick={() => {
    // console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      error title
    </Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text and button action" />
            <CardContent>
              <Alert
                severity="error"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <Typography component="p" variant="bodyMedium400">
                  this is a error alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="error"
  onClick={() => {
    console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
    }
  >
    <Typography component="p" variant="bodyMedium400">
      this is a error alert with a button action — check it out!
    </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title, text and button action" />
            <CardContent>
              <Alert
                severity="error"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    error title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  this is a error alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="error"
  onClick={() => {
    console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      error title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    this is a error alert with a button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and icon close button action" />
            <CardContent>
              <Alert
                severity="error"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    error title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="error"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <AlertTitle>
  <Typography component="span" variant="bodyMedium400">
    error title
  </Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text and icon close button action" />
            <CardContent>
              <Alert
                severity="error"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <Typography component="p" variant="bodyMedium400">
                  this is a error alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="error"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <Typography component="p" variant="bodyMedium400">
    this is a error alert with a button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title, text and icon close button action" />
            <CardContent>
              <Alert
                severity="error"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    error title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  this is a error alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="error"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      error title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    this is a error alert with a button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: 2, marginTop: 2 }}>
        <CardHeader title="Material UI Alert component - INFO" />
        <CardContent>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title only" />
            <CardContent>
              <Alert severity="info">
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    info title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="info">
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">info title</Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text only" />
            <CardContent>
              <Alert severity="info">
                <Typography component="p" variant="bodyMedium400">
                  info body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi
                  quidem quibusdam.
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="info">
  <Typography component="p" variant="bodyMedium400">
    error body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum 
    inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and text" />
            <CardContent>
              <Alert severity="info">
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    info title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  info body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi
                  quidem quibusdam.
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="info">
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      info title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    info body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum 
    inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and button action" />
            <CardContent>
              <Alert
                severity="info"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    info title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="info"
  onClick={() => {
    // console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      info title
    </Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text and button action" />
            <CardContent>
              <Alert
                severity="info"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <Typography component="p" variant="bodyMedium400">
                  This is a info alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="info"
  onClick={() => {
    console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
    }
  >
    <Typography component="p" variant="bodyMedium400">
      This is a info alert with a button action — check it out!
    </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title, text and button action" />
            <CardContent>
              <Alert
                severity="info"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    info title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  This is a info alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="info"
  onClick={() => {
    console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      info title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    This is a info alert with a button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and icon close button action" />
            <CardContent>
              <Alert
                severity="info"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    info title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="info"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <AlertTitle>
  <Typography component="span" variant="bodyMedium400">
    info title
  </Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text and icon close button action" />
            <CardContent>
              <Alert
                severity="info"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <Typography component="p" variant="bodyMedium400">
                  This is a info alert with an icon close button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="info"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <Typography component="p" variant="bodyMedium400">
    This is a info alert with an icon close button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title, text and icon close button action" />
            <CardContent>
              <Alert
                severity="info"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    info title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  This is a info alert with an icon close button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="info"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      info title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    This is a info alert with an icon close button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: 2, marginTop: 2 }}>
        <CardHeader title="Material UI Alert component - SUCCESS" />
        <CardContent>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title only" />
            <CardContent>
              <Alert severity="success">
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    success title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="success">
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">success title</Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text only" />
            <CardContent>
              <Alert severity="success">
                <Typography component="p" variant="bodyMedium400">
                  success body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                  quasi quidem quibusdam.
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="success">
<Typography component="p" variant="bodyMedium400">
  error body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum 
  inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
</Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and text" />
            <CardContent>
              <Alert severity="success">
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    success title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  success body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                  quasi quidem quibusdam.
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="success">
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      success title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    success body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum 
    inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and button action" />
            <CardContent>
              <Alert
                severity="success"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    success title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="success"
  onClick={() => {
    // console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      success title
    </Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text and button action" />
            <CardContent>
              <Alert
                severity="success"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <Typography component="p" variant="bodyMedium400">
                  This is a success alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="success"
  onClick={() => {
    console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
    }
  >
    <Typography component="p" variant="bodyMedium400">
      This is a success alert with a button action — check it out!
    </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title, text and button action" />
            <CardContent>
              <Alert
                severity="success"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    success title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  This is a success alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="success"
  onClick={() => {
    console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      success title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    This is a success alert with a button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and icon close button action" />
            <CardContent>
              <Alert
                severity="success"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    success title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="success"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <AlertTitle>
  <Typography component="span" variant="bodyMedium400">
    success title
  </Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text and icon close button action" />
            <CardContent>
              <Alert
                severity="success"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <Typography component="p" variant="bodyMedium400">
                  This is a success alert with an icon close button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="success"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <Typography component="p" variant="bodyMedium400">
    This is a success alert with an icon close button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title, text and icon close button action" />
            <CardContent>
              <Alert
                severity="success"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    success title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  This is a success alert with an icon close button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="success"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      success title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    This is a success alert with an icon close button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: 2, marginTop: 2 }}>
        <CardHeader title="Material UI Alert component - WARNING" />
        <CardContent>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title only" />
            <CardContent>
              <Alert severity="warning">
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    warning title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="warning">
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">warning title</Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text only" />
            <CardContent>
              <Alert severity="warning">
                <Typography component="p" variant="bodyMedium400">
                  warning body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                  quasi quidem quibusdam.
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="warning">
  <Typography component="p" variant="bodyMedium400">
    error body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum 
    inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and text" />
            <CardContent>
              <Alert severity="warning">
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    warning title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  warning body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                  quasi quidem quibusdam.
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert severity="warning">
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      warning title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    warning body. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum 
    inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and button action" />
            <CardContent>
              <Alert
                severity="warning"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    warning title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="warning"
  onClick={() => {
    // console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      warning title
    </Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text and button action" />
            <CardContent>
              <Alert
                severity="warning"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <Typography component="p" variant="bodyMedium400">
                  This is a warning alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="warning"
  onClick={() => {
    console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
    }
  >
    <Typography component="p" variant="bodyMedium400">
      This is a warning alert with a button action — check it out!
    </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title, text and button action" />
            <CardContent>
              <Alert
                severity="warning"
                onClick={() => {
                  console.log("CLICK!")
                }}
                action={
                  <Button color="inherit" variant="outlined" size="medium">
                    Action
                  </Button>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    warning title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  This is a warning alert with a button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="warning"
  onClick={() => {
    console.log("CLICK!")
  }}
  action={
    <Button color="inherit" variant="outlined" size="medium">
      Action
    </Button>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      warning title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    This is a warning alert with a button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title and icon close button action" />
            <CardContent>
              <Alert
                severity="warning"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    warning title
                  </Typography>
                </AlertTitle>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="warning"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <AlertTitle>
  <Typography component="span" variant="bodyMedium400">
    warning title
  </Typography>
  </AlertTitle>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with text and icon close button action" />
            <CardContent>
              <Alert
                severity="warning"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <Typography component="p" variant="bodyMedium400">
                  This is a warning alert with an icon close button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="warning"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <Typography component="p" variant="bodyMedium400">
    This is a warning alert with an icon close button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title="Alert with title, text and icon close button action" />
            <CardContent>
              <Alert
                severity="warning"
                action={
                  <IconButton
                    onClose={() => {
                      console.log("CLOSE!")
                    }}
                  >
                    <SoeCancelIcon size="medium" />
                  </IconButton>
                }
              >
                <AlertTitle>
                  <Typography component="span" variant="bodyMedium400">
                    warning title
                  </Typography>
                </AlertTitle>
                <Typography component="p" variant="bodyMedium400">
                  This is a warning alert with an icon close button action — check it out!
                </Typography>
              </Alert>
            </CardContent>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <pre>
                    <code>
                      {`<Alert
  severity="warning"
  action={
    <IconButton
      onClose={() => {
        console.log("CLOSE!")
      }}
    >
      <SoeCancelIcon size="medium"></SoeCancelIcon>
    </IconButton>
  }
>
  <AlertTitle>
    <Typography component="span" variant="bodyMedium400">
      warning title
    </Typography>
  </AlertTitle>
  <Typography component="p" variant="bodyMedium400">
    This is a warning alert with an icon close button action — check it out!
  </Typography>
</Alert>`}
                    </code>
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </Container>
  )
}
