import restApiSlice from "../../services/rest-api/slice"
import { getAddressSearchText } from "../../utils/search"

export const clientListRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => ({ url: "/clients", method: "GET" }),
      providesTags: ["Clients"],
      transformResponse: (response) =>
        response.data.map((client) => {
          return {
            ...client,
            addressSearchText: getAddressSearchText(client.clientAddress),
            billingSearchText: client?.billing?.type || "",
          }
        }),
    }),
  }),
})

export const { useGetClientsQuery } = clientListRestApiSlice
