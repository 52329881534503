import deburr from "lodash/deburr"
import get from "lodash/get"

export const searchInvoices = (entry, searchValue, searchRegex) => {
  const searchKeys = ["invoiceNumber", "company.companyName", "company.clientAddress.personName", "user.clientAddress.personName"]

  return searchKeys.some((key) => {
    const value = get(entry, key)
    if (typeof value === "string") {
      return searchRegex.test(deburr(value.toLowerCase()))
    }
    return false
  })
}

export const getFailedEmailInvoiceIds = (response) => {
  const failedIds = []
  response?.data?.details?.results.forEach((result) => {
    if (result.status === "failed") failedIds.push(result.invoiceNumber)
  })
  return failedIds.join(", ")
}
