import React from "react"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import Currency from "../../../../../currency"

//
function GroupedInvoicesSummary({ groupedInvoices }) {
  const { t } = useTranslation("invoices")

  return Object.keys(groupedInvoices).map((emailAddress) => {
    return (
      <Box
        key={emailAddress}
        component="div"
        sx={(theme) => ({
          pb: theme.spacing(3),
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        })}
      >
        <Typography sx={() => ({ fontWeight: "bold" })}>
          {t("bulkEmailDrawer.content.sendTo")}
          {emailAddress}
        </Typography>
        <Box component="div" sx={(theme) => ({ gap: theme.spacing(0.5), display: "flex", flexDirection: "column" })}>
          {groupedInvoices[emailAddress].invoices
            .sort((a, b) => a.invoiceNumber - b.invoiceNumber)
            .map((invoice) => {
              const invoiceOwner = invoice.company || invoice.user
              return (
                <Box
                  key={invoice.id}
                  component="div"
                  sx={() => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  })}
                >
                  <Box
                    component="div"
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      gap: theme.spacing(2),
                    })}
                  >
                    <Typography
                      sx={() => ({
                        fontStyle: "italic",
                        color: invoice.paymentStatus === "paid" ? "green" : "red",
                      })}
                    >{`#${invoice.invoiceNumber}: `}</Typography>
                    <Typography sx={() => ({})}>
                      <Currency amount={invoice.summary?.invoice?.total} currency={invoice.currency} currencyPosition="right" showSymbol={false} />
                    </Typography>
                  </Box>
                  <Typography>{`(${invoiceOwner?.companyName || "Unavailable"})`}</Typography>
                </Box>
              )
            })}
        </Box>
      </Box>
    )
  })
}

GroupedInvoicesSummary.propTypes = {
  groupedInvoices: PropTypes.instanceOf(Object).isRequired,
}

export default GroupedInvoicesSummary
