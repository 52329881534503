/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/api/select/
 * @link https://mui.com/api/native-select/
 */

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        color: "contentActionDefault",
        fullWidth: true,
        MenuProps: {
          PaperProps: {
            sx: {
              "&.MuiPaper-root": {
                ml: theme.spacing(1),
              },
            },
          },
        },
      },
      styleOverrides: {
        root: {
          fontSize: theme.typography.bodyMedium600.fontSize,
          fontWeight: theme.typography.bodyMedium600.fontWeight,
          lineHeight: theme.typography.bodyMedium600.lineHeight,
          paddingRight: theme.spacing(2),
          "& .MuiSelect-outlined": {
            "&.Mui-disabled": {
              TextFillColor: theme.palette.contentNeutralTertiary.main,
              WebkitTextFillColor: theme.palette.contentNeutralTertiary.main,
            },
          },
          "& .MuiSelect-standard": {
            "&:focus": {
              backgroundColor: theme.palette.backgroundNeutralTransparent.main,
            },
            "&.Mui-disabled": {
              TextFillColor: theme.palette.contentNeutralTertiary.main,
              WebkitTextFillColor: theme.palette.contentNeutralTertiary.main,
            },
          },
        },
      },
    },
    MuiNativeSelect: {
      defaultProps: {},
      styleOverrides: {},
    },
  }
}
