import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

import OrderList from "../../../../../order-list"
import RateRequestSummaryCard from "../../../../../rate-request-summary-card"

import { pxToThemeSpacing } from "../../../../../../soe-theme/src/utils"
import { useGetClientDetailsOrdersQuery } from "../../../../slice"
import ClientDetailsInvoiceList from "../client-details-invoice-list"

function ClientDetailsActivities({ client, dataRangeFilterValues }) {
  const { t } = useTranslation("clients")

  const { data: orders, isFetching } = useGetClientDetailsOrdersQuery({ companyId: client.companyId, from: dataRangeFilterValues?.from, to: dataRangeFilterValues?.to }, { skip: !client || !dataRangeFilterValues })

  return (
    <Box>
      <Box pt={pxToThemeSpacing(20)} pr={pxToThemeSpacing(10)} pb={pxToThemeSpacing(20)} pl={pxToThemeSpacing(10)} display="flex">
        <Box component="h5">
          <b>{t("clientDetails.clientDetailsActivities.title")}</b>
        </Box>
      </Box>
      <Box pl={pxToThemeSpacing(6)}>
        <RateRequestSummaryCard title={t("clientDetails.clientDetailsActivities.orderListTitle")}>
          <Box mt={pxToThemeSpacing(10)}>
            <OrderList isFetching={isFetching} orders={orders} maxRows={5} companyView />
          </Box>
        </RateRequestSummaryCard>
      </Box>
      <Box pl={pxToThemeSpacing(6)} mt={pxToThemeSpacing(20)}>
        <RateRequestSummaryCard title={t("clientDetails.clientDetailsActivities.invoiceListTitle")}>
          <Box mt={pxToThemeSpacing(10)}>
            <ClientDetailsInvoiceList companyId={client.companyId} maxRows={5} dataRangeFilterValues={dataRangeFilterValues} />
          </Box>
        </RateRequestSummaryCard>
      </Box>
    </Box>
  )
}

ClientDetailsActivities.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  dataRangeFilterValues: PropTypes.instanceOf(Object),
}

ClientDetailsActivities.defaultProps = {
  dataRangeFilterValues: undefined,
}

export default ClientDetailsActivities
