import { useTranslation } from "react-i18next"

const DateTimeService = {
  newDateToDateString() {
    return new Date().toDateString()
  },
  toLocaleDateString(date) {
    const { i18n } = useTranslation()
    if (!date) return ""
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    }

    return new Date(date).toLocaleDateString(i18n.language, options)
  },
  toLocaleTimeString(date) {
    const { i18n } = useTranslation()
    return new Date(date).toLocaleTimeString(i18n.language)
  },
}

export default DateTimeService
