import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

import OrderList from "../../../../../../order-list"
import RateRequestSummaryCard from "../../../../../../rate-request-summary-card"

import { pxToThemeSpacing } from "../../../../../../../soe-theme/src/utils"
import CompanyDetailsInvoiceList from "../company-details-invoice-list"
import { useGetClientDetailsOrdersQuery } from "../../../../../../clients/slice"
import CompanyUsersList from "../company-users-list"

function CompanyDetailsActivities({ company, dataRangeFilterValues }) {
  const { t } = useTranslation("companies")
  const { data: orders, isFetching } = useGetClientDetailsOrdersQuery({ companyId: company.id, from: dataRangeFilterValues?.from, to: dataRangeFilterValues?.to }, { skip: !company || !dataRangeFilterValues })

  return (
    <Box>
      <Box pt={pxToThemeSpacing(20)} pr={pxToThemeSpacing(10)} pb={pxToThemeSpacing(20)} pl={pxToThemeSpacing(10)} display="flex">
        <Box component="h5">
          <b>{t("companyDetails.companyDetailsActivities.title")}</b>
        </Box>
      </Box>
      <Box pl={pxToThemeSpacing(6)}>
        <RateRequestSummaryCard title={t("companyDetails.companyDetailsActivities.usersListTitle")}>
          <Box mt={pxToThemeSpacing(10)}>
            <CompanyUsersList company={company} companyView />
          </Box>
        </RateRequestSummaryCard>
      </Box>
      <Box pl={pxToThemeSpacing(6)} mt={pxToThemeSpacing(20)}>
        <RateRequestSummaryCard title={t("companyDetails.companyDetailsActivities.orderListTitle")}>
          <Box mt={pxToThemeSpacing(10)}>
            <OrderList isFetching={isFetching} orders={orders} maxRows={5} companyView />
          </Box>
        </RateRequestSummaryCard>
      </Box>
      <Box pl={pxToThemeSpacing(6)} mt={pxToThemeSpacing(20)}>
        <RateRequestSummaryCard title={t("companyDetails.companyDetailsActivities.invoiceListTitle")}>
          <Box mt={pxToThemeSpacing(10)}>
            <CompanyDetailsInvoiceList companyId={company.id} maxRows={5} dataRangeFilterValues={dataRangeFilterValues} />
          </Box>
        </RateRequestSummaryCard>
      </Box>
    </Box>
  )
}

CompanyDetailsActivities.propTypes = {
  company: PropTypes.instanceOf(Object).isRequired,
  dataRangeFilterValues: PropTypes.instanceOf(Object),
}

CompanyDetailsActivities.defaultProps = {
  dataRangeFilterValues: undefined,
}

export default CompanyDetailsActivities
