// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import FormGroup from "@mui/material/FormGroup"
import TextField from "@mui/material/TextField"

// Formik
import { useFormik } from "formik"

// Others
import AutoCompletePaper from "../../../../soe-theme/src/components/auto-complete-paper"
import TranslationService from "../../../../services/translation"

import { useAddPricingPlanMutation, useGetCarrierAccountsQuery, useGetCarriersQuery } from "../../slice"

function PricingPlanAdd({ selectedPricingGroupId, onSubmitEvent, setSubmitEvent, setShowPricingPlanAddDrawer }) {
  const { i18n, t } = useTranslation("pricing")

  const { data: carriers } = useGetCarriersQuery()

  const [addPricingPlan] = useAddPricingPlanMutation()

  const formik = useFormik({
    initialValues: {
      pricingPlanName: "",
      carrierCode: "",
      carrierAccountCode: "",
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: async (formValues) => {
      const errors = {}
      if (!formValues.pricingPlanName) errors.pricingPlanName = true
      if (!formValues.carrierCode) errors.carrierCode = true
      if (!formValues.carrierAccountCode) errors.carrierAccountCode = true
      return errors
    },
    onSubmit: async (formValues) => {
      addPricingPlan({ pricingGroupId: selectedPricingGroupId, payload: formValues })
      setShowPricingPlanAddDrawer(false)
    },
  })

  const { data: accounts } = useGetCarrierAccountsQuery(formik.values.carrierCode, { skip: !formik.values.carrierCode })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
      setSubmitEvent(undefined)
    }
  }, [onSubmitEvent])

  const handleCarrierCodeFromChange = (_, value) => {
    formik.setFieldValue("carrierCode", value.code)
  }

  const handleCarrierAccountCodeFromChange = (_, value) => {
    formik.setFieldValue("carrierAccountCode", value.code)
  }

  return (
    <Box component="div">
      <CardContent variant="outlined" sx={(theme) => ({ width: "auto", borderRadius: 1, borderColor: theme.palette.contentActionDefault.main })}>
        <FormGroup>
          <TextField
            id="pricingPlanName"
            label={t("pricingPlanDrawer.pricingPlanName.fields.label")}
            value={formik.values.pricingPlanName}
            onChange={formik.handleChange}
            variant="outlined"
            size="small"
            sx={(theme) => ({ marginBottom: theme.spacing(3) })}
            onBlur={formik.handleBlur}
            error={formik.touched.pricingPlanName && formik.errors.pricingPlanName !== undefined}
          />
          <Autocomplete
            id="carrierCode"
            disablePortal
            value={(carriers && carriers.find((carrier) => carrier.code === formik.values.carrierCode)) || null}
            onChange={handleCarrierCodeFromChange}
            onBlur={formik.handleBlur}
            getOptionLabel={(option) => option && TranslationService.getLabelFromLocale(i18n.language, option.fullName)}
            isOptionEqualToValue={(option, value) => {
              return option.code === value.code
            }}
            options={carriers || []}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.code}>
                  {TranslationService.getLabelFromLocale(i18n.language, option.fullName)}
                </li>
              )
            }}
            PaperComponent={AutoCompletePaper}
            renderInput={(params) => <TextField {...params} label={t("pricingPlanDrawer.carrierCode.fields.label")} variant="outlined" required error={formik.touched.carrierCode && formik.errors.carrierCode !== undefined} />}
            noOptionsText={t("pricingPlanDrawer.carrierCode.fields.noOptionText.label")}
            disableClearable
            size="small"
            fullWidth
            sx={(theme) => ({ marginBottom: theme.spacing(3) })}
          />
          {formik.values.carrierCode && (
            <Autocomplete
              id="carrierAccountCode"
              disablePortal
              value={(accounts && accounts.find((account) => account.code === formik.values.carrierAccountCode)) || null}
              onChange={handleCarrierAccountCodeFromChange}
              onBlur={formik.handleBlur}
              getOptionLabel={(option) => option && option.name}
              isOptionEqualToValue={(option, value) => {
                return option.code === value.code
              }}
              options={accounts || []}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.code}>
                    {option.name}
                  </li>
                )
              }}
              PaperComponent={AutoCompletePaper}
              renderInput={(params) => <TextField {...params} label={t("pricingPlanDrawer.carrierAccountCode.fields.label")} variant="outlined" required error={formik.errors.carrierAccountCode !== undefined} />}
              noOptionsText={t("pricingPlanDrawer.carrierAccountCode.fields.noOptionText.label")}
              disableClearable
              disabled={!formik.values.carrierCode}
              size="small"
              fullWidth
            />
          )}
        </FormGroup>
      </CardContent>
    </Box>
  )
}

PricingPlanAdd.propTypes = {
  selectedPricingGroupId: PropTypes.string.isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  setSubmitEvent: PropTypes.func,
  setShowPricingPlanAddDrawer: PropTypes.func.isRequired,
}

PricingPlanAdd.defaultProps = {
  onSubmitEvent: undefined,
  setSubmitEvent: undefined,
}

export default PricingPlanAdd
