import { createSlice } from "@reduxjs/toolkit"

import restApiSlice from "../../services/rest-api/slice"

export const carriersSlice = createSlice({
  name: "carriers",
  initialState: {},
  reducers: {},
})

export const carriersRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarriers: builder.query({
      query: () => ({ url: "/carriers", method: "GET" }),
      providesTags: ["Carriers"],
      transformResponse: (response) => response.data,
    }),
    getCarrierDetails: builder.query({
      query: (code) => ({ url: `/carriers/details/${code}`, method: "GET" }),
      providesTags: ["CarrierDetails"],
      transformResponse: (response) => response.data,
    }),
    getCarrierAccounts: builder.query({
      query: (code) => ({ url: `/carriers/details/${code}/accounts`, method: "GET" }),
      providesTags: ["CarrierAccounts"],
      transformResponse: (response) => response.data,
    }),
    getCarrierServices: builder.query({
      query: ({ code }) => ({ url: `/carriers/details/${code}/services`, method: "GET" }),
      providesTags: ["CarrierServices"],
      transformResponse: (response, _, arg) => {
        return [
          ...response.data,
          ...(arg.addAdditionalCharge && !response.data.some((service) => service.code === "ADDITIONAL_CHARGE")
            ? [
                {
                  code: "ADDITIONAL_CHARGE",
                  billingCode: "",
                  fullName: {
                    fr: "Charge additionnelle",
                    en: "Additional Charge",
                  },
                  shortName: {
                    fr: "Charge addl",
                    en: "Addl Charge",
                  },
                  chips: [],
                  isDisabled: false,
                },
              ]
            : []),
        ]
      },
    }),
    getCarrierCharges: builder.query({
      query: (code) => ({ url: `/carriers/details/${code}/charges`, method: "GET" }),
      providesTags: ["CarrierCharges"],
      transformResponse: (response) => response.data,
    }),
    getCarrierChargeTypes: builder.query({
      query: () => ({ url: `/carriers/details/chargetypes`, method: "GET" }),
      providesTags: ["CarrierChargeTypes"],
      transformResponse: (response) => response.data,
    }),
    addCarrierAccounts: builder.mutation({
      query: ({ code, payload }) => ({ url: `/carriers/details/${code}/accounts`, method: "POST", body: payload }),
      invalidatesTags: ["CarrierAccounts"],
    }),
    addCarrierServices: builder.mutation({
      query: ({ code, payload }) => ({ url: `/carriers/details/${code}/services`, method: "POST", body: payload }),
      invalidatesTags: ["CarrierServices"],
    }),
    addCarrierChargeTypes: builder.mutation({
      query: ({ code, payload }) => ({ url: `/carriers/details/${code}/charges`, method: "POST", body: payload }),
      invalidatesTags: ["CarrierCharges"],
    }),
    editCarrierDetails: builder.mutation({
      query: ({ code, payload }) => ({ url: `/carriers/details/${code}`, method: "PUT", body: payload }),
      invalidatesTags: ["Carriers", "CarrierDetails"],
    }),
    editCarrierAccounts: builder.mutation({
      query: ({ code, accountCode, payload }) => ({ url: `/carriers/details/${code}/accounts/${accountCode}`, method: "PUT", body: payload }),
      invalidatesTags: ["CarrierAccounts"],
    }),
    editCarrierServices: builder.mutation({
      query: ({ code, serviceCode, payload }) => ({ url: `/carriers/details/${code}/services/${serviceCode}`, method: "PUT", body: payload }),
      invalidatesTags: ["CarrierServices"],
    }),
    editCarrierChargeTypes: builder.mutation({
      query: ({ code, chargeTypeCode, payload }) => ({ url: `/carriers/details/${code}/charges/${chargeTypeCode}`, method: "PUT", body: payload }),
      invalidatesTags: ["CarrierCharges"],
    }),
  }),
})

export const {
  useGetCarriersQuery,
  useGetCarrierDetailsQuery,
  useGetCarrierAccountsQuery,
  useGetCarrierServicesQuery,
  useGetCarrierChargesQuery,
  useGetCarrierChargeTypesQuery,
  useAddCarrierAccountsMutation,
  useAddCarrierServicesMutation,
  useAddCarrierChargeTypesMutation,
  useEditCarrierDetailsMutation,
  useEditCarrierAccountsMutation,
  useEditCarrierServicesMutation,
  useEditCarrierChargeTypesMutation,
} = carriersRestApiSlice

export default carriersSlice.reducer
