import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import TextField from "@mui/material/TextField"

import { useDispatch } from "react-redux"

import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

import AppConfig from "../../../../utils/app-config"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useSendEmailInvoiceMutation } from "../../slice"
import restApiSlice from "../../../../services/rest-api/slice"

function EmailInvoiceDrawer({ invoice, showEmailInvoiceDrawer, setShowEmailInvoiceDrawer }) {
  const { t } = useTranslation("invoices")
  const dispatch = useDispatch()

  const [sendEmailInvoice, { isLoading }] = useSendEmailInvoiceMutation()

  const invoiceOwner = invoice.company || invoice.user
  const formik = useFormik({
    initialValues: {
      to: invoiceOwner.billing?.address?.emailAddress || invoiceOwner.emailAddress,
      cc: AppConfig.features.emailInvoiceCCList,
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.to) {
        errors.to = true
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      sendEmailInvoice({ payload: { ...formValues, invoiceId: invoice.id } }).then(() => {
        dispatch(restApiSlice.util.invalidateTags(["UnpaidInvoices", "PaidInvoices"]))
        setShowEmailInvoiceDrawer(false)
      })
    },
  })

  const handleCloseClick = () => {
    setShowEmailInvoiceDrawer(false)
  }

  const handleSendClick = () => {
    formik.handleSubmit()
  }

  return (
    <SoeDrawer showDrawer={showEmailInvoiceDrawer} setShowDrawer={setShowEmailInvoiceDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("emailInvoiceDrawer.title")}
          setShowDrawer={() => {
            setShowEmailInvoiceDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
            <TextField
              sx={(theme) => ({ marginRight: theme.spacing(1) })}
              id="to"
              variant="outlined"
              label={t("emailInvoiceDrawer.form.fields.to.label")}
              size="small"
              value={formik.values.to || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.to && formik.errors.to !== undefined}
              fullWidth
            />
          </Box>
          <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
            <TextField
              sx={(theme) => ({ marginRight: theme.spacing(1) })}
              id="cc"
              variant="outlined"
              label={t("emailInvoiceDrawer.form.fields.cc.label")}
              size="small"
              value={formik.values.cc || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
          </Box>
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleCloseClick,
              label: t("emailInvoiceDrawer.actions.cancel.label"),
              variant: "outlined",
              disabled: isLoading,
            },
            {
              action: handleSendClick,
              label: t("emailInvoiceDrawer.actions.send.label"),
              variant: "contained",
              loading: isLoading,
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

EmailInvoiceDrawer.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
  showEmailInvoiceDrawer: PropTypes.bool.isRequired,
  setShowEmailInvoiceDrawer: PropTypes.func.isRequired,
}

export default EmailInvoiceDrawer
