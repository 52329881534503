// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Card from "@mui/material/Card"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import Skeleton from "@mui/material/Skeleton"

// Theme
import { useTheme } from "@mui/material/styles"
// Others
import { useGetCompanyUsersQuery } from "../../../../../../../services/company/slice"
import CustomPagination from "../../../../../../custom-pagination"
import Client from "../../../../../../client-list/components/client"
import EnhancedTableHead from "../../../../../../enhanced-table-head"
import getComparator from "../../../../../../../utils/sorting"

function CompanyUsersList({ company, companyView }) {
  const { t } = useTranslation("clients")
  const customTheme = useTheme()
  const showMoreMenu = true

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("personName")
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [page, setPage] = useState(1)
  const { data: users, isFetching, refetch: refetchClients } = useGetCompanyUsersQuery(company.id, { skip: !company })

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const headCells = [
    ...(!companyView
      ? [
          {
            id: "clientAddress.personName",
            disablePadding: false,
            label: t("headers.name"),
          },
        ]
      : []),
    {
      id: "emailAddress",
      disablePadding: false,
      label: t("headers.emailAddress"),
    },
    ...(!companyView
      ? [
          {
            id: "clientAddress.companyName",
            disablePadding: false,
            label: t("headers.company"),
          },
          {
            id: "companyId",
            disablePadding: false,
            label: t("headers.hasCompany"),
          },
          {
            id: "clientAddress.addressLine1",
            disablePadding: false,
            label: t("headers.address"),
          },
        ]
      : []),
    {
      id: "pricingGroupName",
      disablePadding: false,
      label: t("headers.pricingGroup"),
    },
    ...(!companyView
      ? [
          {
            id: "billingCurrency",
            disablePadding: false,
            label: t("headers.billingCurrency"),
          },
        ]
      : []),

    {
      id: "createdDate",
      disablePadding: false,
      label: t("headers.createdDate"),
    },
    {
      id: "billing.type",
      disablePadding: false,
      label: t("headers.billingType"),
    },
    ...(!companyView
      ? [
          {
            id: "company.paymentMethods",
            disablePadding: false,
            label: t("headers.ccAdded"),
          },
        ]
      : []),
    {
      id: "disabled",
      disablePadding: false,
      label: t("headers.status"),
    },
    ...(showMoreMenu ? [{ id: "menu" }] : []),
  ]

  return (
    <Card variant="elevation" square>
      <Box sx={{ width: "auto" }} className="manage-companies-table">
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table size="small" sx={{ backgroundColor: customTheme.palette.backgroundNeutralUnderground1.main }}>
              <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {!users?.length && isFetching ? (
                  <>
                    {Array(3)
                      .fill()
                      .map((_, index) => {
                        return (
                          <TableRow key={"".concat(index)}>
                            <TableCell colSpan={12}>
                              <Skeleton variant="rectangular" height={50} width="100%" />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </>
                ) : (
                  users
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                    .map((client, index) => {
                      return <Client key={"".concat(index)} client={client} refetchClients={refetchClients} companyView={companyView} showMoreMenu />
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination count={users ? Math.ceil(users.length / rowsPerPage) : 1} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />
        </Paper>
      </Box>
    </Card>
  )
}

CompanyUsersList.defaultProps = {
  companyView: false,
}

CompanyUsersList.propTypes = {
  company: PropTypes.instanceOf(Object).isRequired,
  companyView: PropTypes.bool,
}

export default CompanyUsersList
