// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import Dialog from "@mui/material/Dialog"

function SoeConfirmationDialog({ children, showConfirmationDialog, preventClosing, setShowConfirmationDialog }) {
  return (
    <Dialog
      open={showConfirmationDialog}
      onClose={() => {
        if (!preventClosing) setShowConfirmationDialog(false)
      }}
      PaperProps={{ className: "soeConfirmationDialog" }}
    >
      {children}
    </Dialog>
  )
}

SoeConfirmationDialog.propTypes = {
  children: PropTypes.node.isRequired,
  showConfirmationDialog: PropTypes.bool.isRequired,
  preventClosing: PropTypes.bool,
  setShowConfirmationDialog: PropTypes.func.isRequired,
}

SoeConfirmationDialog.defaultProps = {
  preventClosing: false,
}

export default SoeConfirmationDialog
