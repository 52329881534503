/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/chips/
 * @link https://mui.com/api/chip/
 */

export default function Chip(theme) {
  return {
    MuiChip: {
      defaultProps: { variant: "filled", size: "medium", color: "default" },
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
        outlined: {
          borderColor: theme.palette.contentActionDefault.main,
          color: theme.palette.contentActionDefault.main,
        },
        outlinedPrimary: {
          color: theme.palette.contentNeutralPrimary.main,
        },
        outlinedSecondary: {},
        fitted: {
          backgroundColor: theme.palette.contentActionDefault.main,
          color: theme.palette.contentNeutralPrimaryReverse.main,
        },
        fittedPrimary: {
          color: theme.palette.contentNeutralPrimary.main,
        },
        fittedSecondary: {},
        colorPrimary: {},
        colorSecondary: {},
        disabled: {},
        focusVisible: {},
        clickable: {},
        clickableColorPrimary: {},
        clickableColorSecondary: {},
        label: {},
        labelSmall: {
          fontSize: theme.typography.bodySmall600.fontSize,
          fontWeight: theme.typography.bodySmall600.fontWeight,
          lineHeight: theme.typography.bodySmall600.lineHeight,
        },
        labelMedium: {
          fontSize: theme.typography.bodyMedium400.fontSize,
          fontWeight: theme.typography.bodyMedium400.fontWeight,
          lineHeight: theme.typography.bodyMedium400.lineHeight,
        },
        sizeSmall: {
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
        sizeMedium: {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        icon: {
          color: "inherit",
        },
        iconSmall: {
          fontSize: "1rem", // @todo: To validate. bodySmall600?
        },
        iconMedium: {
          fontSize: "1.275rem", // @todo: To validate. bodyMedium400?
        },
        iconColorPrimary: {
          color: theme.palette.contentNeutralPrimary.main,
        },
        iconColorSecondary: {},
        deletable: {},
        deletableColorPrimary: {},
        deletableColorSecondary: {},
        deleteIcon: {
          color: "inherit",
        },
        deleteIconSmall: {},
        deleteIconMedium: {},
        deleteIconColorPrimary: {
          color: theme.palette.contentNeutralPrimary.main,
        },
        deleteIconColorSecondary: {
          color: "red",
        },
        deleteIconOutlinedColorPrimary: {},
        deleteIconOutlinedColorSecondary: {},
        avatar: {},
        avatarSmall: {},
        avatarMedium: {},
        avatarColorPrimary: {},
        avatarColorSecondary: {},
      },
    },
  }
}
