import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import AuditedOrder from "../audited-order"
import CustomPagination from "../../../../../custom-pagination"
import EnhancedTableHead from "../../../../../enhanced-table-head"
import getComparator from "../../../../../../utils/sorting"

function AuditedOrderList({ auditedOrders, maxRows, refetchCandidates, refetchCredits }) {
  const { t } = useTranslation("invoices")
  const theme = useTheme()

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("personName")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(maxRows)

  const headCells = [
    {
      id: "order.quotation.response.carrier.code",
      align: "center",
      disablePadding: false,
      label: t("invoicesStatus.readyToInvoice.auditedOrderList.headers.carrier"),
    },
    {
      id: "service.client.actual.code",
      disablePadding: false,
      label: t("invoicesStatus.readyToInvoice.auditedOrderList.headers.service"),
    },
    {
      id: "order.number",
      disablePadding: false,
      label: t("invoicesStatus.readyToInvoice.auditedOrderList.headers.orderInfo"),
    },
    {
      id: "order.createdDate",
      disablePadding: false,
      label: t("invoicesStatus.readyToInvoice.auditedOrderList.headers.orderDate"),
    },
    {
      id: "shipper.client.actual.personName",
      disablePadding: false,
      label: t("invoicesStatus.readyToInvoice.auditedOrderList.headers.shipper"),
    },
    {
      id: "recipient.client.actual.personName",
      disablePadding: false,
      label: t("invoicesStatus.readyToInvoice.auditedOrderList.headers.recipient"),
    },
    {
      id: "rate.totals.total.account.declared.amount",
      disablePadding: false,
      label: t("invoicesStatus.readyToInvoice.auditedOrderList.headers.totalCost"),
    },
    {
      id: "rate.totals.total.client.actual.amount",
      disablePadding: false,
      label: t("invoicesStatus.readyToInvoice.auditedOrderList.headers.totalPrice"),
    },
    {
      id: "nolabel",
      disablePadding: false,
      label: "",
    },
  ]

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Box sx={{ width: "auto" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table size="small" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1.main }}>
            <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {auditedOrders &&
                auditedOrders
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((auditedOrder, index) => {
                    return <AuditedOrder key={"".concat(index)} auditedOrder={auditedOrder} refetchCandidates={refetchCandidates} refetchCredits={refetchCredits} />
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        {auditedOrders && <CustomPagination count={Math.ceil(auditedOrders.length / rowsPerPage)} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />}
      </Paper>
    </Box>
  )
}

AuditedOrderList.propTypes = {
  auditedOrders: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  maxRows: PropTypes.number,
  refetchCandidates: PropTypes.func,
  refetchCredits: PropTypes.func,
}

AuditedOrderList.defaultProps = {
  maxRows: Infinity,
  refetchCandidates: () => {},
  refetchCredits: () => {},
}

export default AuditedOrderList
