// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CustomDatePicker from "../date-picker"
import DateRangeEnum from "./constants"

function DateRangeFilter({ setDataRangeFilterValues }) {
  const { t } = useTranslation("dateRangeFilter")
  const customTheme = useTheme()

  const formik = useFormik({
    initialValues: {
      dateRange: DateRangeEnum.LAST_MONTH,
      from: DateRangeEnum.LAST_MONTH.from,
      to: DateRangeEnum.LAST_MONTH.to,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate: () => {},
    onSubmit: (formValues) => {
      setDataRangeFilterValues({
        from: new Date(formValues.from).toISOString(),
        to: new Date(formValues.to).toISOString(),
      })
    },
  })

  useEffect(() => formik.handleSubmit(), [])

  const handleDateRangeChange = (event) => {
    const { from, to } = event.target.value
    formik.setFieldValue("dateRange", event.target.value)
    formik.setFieldValue("from", from)
    formik.setFieldValue("to", to)
  }

  const handleFromChange = (value) => {
    formik.setFieldValue("dateRange", DateRangeEnum.CUSTOM)
    formik.setFieldValue("from", value)
    formik.setFieldValue("to", formik.values.to)
  }

  const handleToChange = (value) => {
    formik.setFieldValue("dateRange", DateRangeEnum.CUSTOM)
    formik.setFieldValue("from", formik.values.from)
    formik.setFieldValue("to", value)
  }

  return (
    <Box component="div" sx={{ display: "flex" }}>
      <Box component="div" sx={(theme) => ({ pr: theme.utils.pxToThemeSpacing(10) })}>
        <FormControl variant="outlined" size="small" style={{ width: customTheme.utils.pxToRem(200) }}>
          <InputLabel id="dateRangeInputLabel">{t("fields.dateRange.label")}</InputLabel>
          <Select labelId="dateRangeLabel" id="dateRange" value={formik.values.dateRange} label={t("fields.dateRange.label")} onChange={handleDateRangeChange} SelectDisplayProps={{ style: { height: 0 } }}>
            <MenuItem value={DateRangeEnum.TODAY}>{t("fields.dateRange.options.today")}</MenuItem>
            <MenuItem value={DateRangeEnum.YESTERDAY}>{t("fields.dateRange.options.yesterday")}</MenuItem>
            <MenuItem value={DateRangeEnum.THIS_WEEK}>{t("fields.dateRange.options.thisWeek")}</MenuItem>
            <MenuItem value={DateRangeEnum.LAST_WEEK}>{t("fields.dateRange.options.lastWeek")}</MenuItem>
            <MenuItem value={DateRangeEnum.LAST_TWO_WEEKS}>{t("fields.dateRange.options.lastTwoWeeks")}</MenuItem>
            <MenuItem value={DateRangeEnum.LAST_MONTH}>{t("fields.dateRange.options.lastMonth")}</MenuItem>
            <MenuItem value={DateRangeEnum.CUSTOM}>{t("fields.dateRange.options.custom")}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box component="div" sx={(theme) => ({ width: theme.utils.pxToRem(160), pr: theme.utils.pxToThemeSpacing(10) })}>
        <CustomDatePicker id="from" onChange={handleFromChange} label={t("fields.from.label")} value={formik.values.from} />
      </Box>
      <Box component="div" sx={(theme) => ({ width: theme.utils.pxToRem(160), pr: theme.utils.pxToThemeSpacing(10) })}>
        <CustomDatePicker id="to" onChange={handleToChange} label={t("fields.to.label")} value={formik.values.to} />
      </Box>
      <Box component="div">
        <Button onClick={formik.handleSubmit}>{t("actions.apply.label")}</Button>
      </Box>
    </Box>
  )
}

DateRangeFilter.propTypes = {
  setDataRangeFilterValues: PropTypes.func,
}

DateRangeFilter.defaultProps = {
  setDataRangeFilterValues: () => {},
}

export default DateRangeFilter
