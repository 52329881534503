import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import PropTypes from "prop-types"
import React from "react"
import Select from "@mui/material/Select"

import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

import { CARRIERS_STATUS } from "../../constants"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useChangeTransitStatusMutation } from "../../../../services/order/slice"

function ChangeTransitStatusDrawer({ orderId, transitStatus, showChangeTransitStatusDrawer, setShowChangeTransitStatusDrawer }) {
  const { t } = useTranslation("orders")

  const [changeTransitStatus, { isLoading }] = useChangeTransitStatusMutation()

  const formik = useFormik({
    initialValues: {
      transitStatus: transitStatus || CARRIERS_STATUS[0],
    },
    validateOnChange: true,
    enableReinitialize: true,
    validate: () => {},
    onSubmit: (formValues) => {
      changeTransitStatus({ orderId, payload: { transitStatus: formValues.transitStatus } }).then(() => {
        setShowChangeTransitStatusDrawer(false)
      })
    },
  })

  return (
    <SoeDrawer showDrawer={showChangeTransitStatusDrawer} setShowDrawer={setShowChangeTransitStatusDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("changeTransitStatusDrawer.title")}
          setShowDrawer={() => {
            setShowChangeTransitStatusDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <FormControl error={formik.touched.type && formik.errors.type !== undefined} fullWidth>
            <InputLabel id="transitStatus-label">{t("changeTransitStatusDrawer.fields.transitStatus.label")}</InputLabel>
            <Select name="transitStatus" labelId="transitStatus-label" size="small" label={t("changeTransitStatusDrawer.fields.transitStatus.label")} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.transitStatus}>
              {Object.keys(CARRIERS_STATUS).map((key) => {
                return (
                  <MenuItem key={key} value={CARRIERS_STATUS[key]}>
                    {t(`transitStatus.${CARRIERS_STATUS[key]}`)}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: () => setShowChangeTransitStatusDrawer(false),
              label: t("changeTransitStatusDrawer.actions.cancel"),
              variant: "outlined",
              disabled: isLoading,
            },
            {
              action: () => formik.handleSubmit(),
              label: t("changeTransitStatusDrawer.actions.confirm"),
              variant: "contained",
              loading: isLoading,
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

ChangeTransitStatusDrawer.propTypes = {
  orderId: PropTypes.string.isRequired,
  transitStatus: PropTypes.string.isRequired,
  showChangeTransitStatusDrawer: PropTypes.bool.isRequired,
  setShowChangeTransitStatusDrawer: PropTypes.func.isRequired,
}

export default ChangeTransitStatusDrawer
