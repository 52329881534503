// PROP-TYPES
import PropTypes from "prop-types"

// REACT
import React, { useEffect } from "react"

// MUI
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { FormikProvider } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

function CarrierAccountFieldsForm({ formik, nameLabel, formikCarrierAccountFields, carrierGeneralInfoSorted }) {
  const customTheme = useTheme()

  const getAccountFieldDescription = (account, carrier) => {
    let result = null
    carrier.accountFields.forEach((element) => {
      if (element.name === account.name) {
        result = element.description
      }
    })
    return result
  }

  const { values } = formik

  useEffect(() => {
    if (!values.accountDetails.length) formik.setFieldValue(formikCarrierAccountFields, [...carrierGeneralInfoSorted.accountFields])
  }, [values, carrierGeneralInfoSorted])

  return (
    <Box component="div">
      <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
        {nameLabel}
      </Typography>
      <Box component="div" p={1} />
      <FormikProvider value={formik} enableReinitialize>
        {values.accountDetails.map(
          (accDetails, accDetailsIndex) =>
            getAccountFieldDescription(accDetails, carrierGeneralInfoSorted) && (
              <Box component="div" key={`${accDetailsIndex.toString()}`} mb={customTheme.utils.pxToThemeSpacing(16)}>
                <TextField
                  key={`${accDetailsIndex.toString()}`}
                  name={`${formikCarrierAccountFields}[${accDetailsIndex}].value`}
                  id={`${formikCarrierAccountFields}[${accDetailsIndex}].value`}
                  inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular } }}
                  variant="outlined"
                  size="small"
                  onChange={(event) => {
                    formik.setFieldValue(`${formikCarrierAccountFields}[${accDetailsIndex}].value`, event.target.value)
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.accountDetails[accDetailsIndex].value || ""}
                  label={getAccountFieldDescription(accDetails, carrierGeneralInfoSorted)}
                  fullWidth
                />
              </Box>
            )
        )}
      </FormikProvider>
    </Box>
  )
}

CarrierAccountFieldsForm.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  nameLabel: PropTypes.string.isRequired,
  formikCarrierAccountFields: PropTypes.string.isRequired,
  carrierGeneralInfoSorted: PropTypes.instanceOf(Object).isRequired,
}

export default CarrierAccountFieldsForm
