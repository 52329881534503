// REACT
import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import LoadingButton from "@mui/lab/LoadingButton"
import Typography from "@mui/material/Typography"
import { Checkbox, FormControl } from "@mui/material"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"

// Others
import AddCreditDrawer from "./components/add-credit-drawer"
import ExportInvoicesDrawer from "./components/export-invoices-drawer"
import INVOICES_STATUS from "./constants"

import SoeConfirmationDialog from "../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogActions from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import SoeConfirmationDialogContainer from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogContent from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogHeader from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../soe-theme/src/components"
import { useCreateCompaniesInvoiceMutation, useGetAllInvoicesQuery } from "./slice"
import AllInvoices from "./components/all-invoices"
import SearchInput from "../search-input"
import { searchInvoices } from "./utils"
import { clearSelection, selectAll, setInvoices } from "../../reducers/invoicesReducer"
import BulkEmailDrawer from "./components/bulk-email-drawer"
import DrawerAlert from "../drawer-alert"

function Invoices() {
  const { t } = useTranslation("invoices")

  const dispatch = useDispatch()
  const selectedInvoiceIds = useSelector((state) => state.selectedInvoices.selectedInvoiceIds)
  const invoices = useSelector((state) => state.selectedInvoices.invoices)

  const [invoicesStatus, setInvoicesStatus] = useState(INVOICES_STATUS.ALL_INVOICES)
  const [showCreditDrawer, setShowCreditDrawer] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showExportInvoicesDrawer, setShowExportInvoicesDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()
  const [isSleeping, setIsSleeping] = useState(false)
  const [isCreditCreating, setIsCreditCreating] = useState(false)
  const [filteredInvoices, setFilteredInvoices] = useState(invoices)
  const [showBulkEmailDrawer, setShowBulkEmailDrawer] = useState(false)

  const [createCompaniesInvoice, { isLoading }] = useCreateCompaniesInvoiceMutation()

  const { data: queriedInvoices, isFetching, isSuccess } = useGetAllInvoicesQuery()

  const sleep = (ms) => {
    return new Promise((resolve) => {
      setIsSleeping(true)
      setTimeout(() => {
        setIsSleeping(false)
        resolve()
      }, ms)
    })
  }

  const handleClose = () => {
    setShowConfirmationDialog(false)
  }

  const handleConfirm = () => {
    createCompaniesInvoice().then(() => {
      sleep(7000).then(() => {
        setShowConfirmationDialog(false)
      })
    })
  }

  const handleClearSelectedInvoices = () => {
    return dispatch(clearSelection())
  }

  const handleSelectAllInvoices = () => {
    return dispatch(selectAll(filteredInvoices))
  }

  const filterInvoicesByPaid = () => {
    switch (invoicesStatus) {
      case INVOICES_STATUS.UNPAID_INVOICES:
        return invoices.filter((invoice) => invoice.paymentStatus === "pending")
      case INVOICES_STATUS.PAID_INVOICES:
        return invoices.filter((invoice) => invoice.paymentStatus === "paid")
      default:
        return invoices
    }
  }

  useEffect(() => {
    if (!isSuccess) return
    dispatch(setInvoices(queriedInvoices))
    setFilteredInvoices(queriedInvoices)
  }, [queriedInvoices, isSuccess])

  useEffect(() => {
    if (!invoices) return

    setFilteredInvoices(filterInvoicesByPaid())
  }, [invoicesStatus])

  return (
    <>
      <Box component="div">
        <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
          <CardHeader title={t("title")} />
        </Card>
        <DrawerAlert />
        <Card variant="elevation" square>
          <Box component="div" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={(theme) => ({ p: theme.spacing(1.5) })}>
            <Box component="div" display="flex" flexDirection="row" marginTop="auto">
              <Button
                variant="text"
                onClick={handleSelectAllInvoices}
                sx={(theme) => ({
                  marginRight: "auto",
                  padding: `0 ${theme.spacing(1)}`,
                })}
              >
                {t("invoicesStatus.selectedInvoices.actions.selectAll")}
              </Button>
              <Button
                variant="text"
                onClick={handleClearSelectedInvoices}
                sx={(theme) => ({
                  marginRight: "auto",
                  padding: `0 ${theme.spacing(1)}`,
                })}
              >
                {t("invoicesStatus.selectedInvoices.actions.unselectAll")}
              </Button>
            </Box>
            <Box component="div" display="flex" flexDirection="column" flexGrow={1} alignItems="center" sx={(theme) => ({ gap: theme.spacing(2) })}>
              <Box component="div" width="100%" justifyContent="center">
                <FormControl
                  sx={() => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  })}
                >
                  <FormControlLabel
                    label={t("invoicesStatus.unpaidInvoices.label")}
                    control={
                      <Checkbox
                        size="small"
                        name={t("invoicesStatus.unpaidInvoices.label")}
                        checked={invoicesStatus === INVOICES_STATUS.UNPAID_INVOICES || invoicesStatus === INVOICES_STATUS.ALL_INVOICES}
                        onChange={() => {
                          if (invoicesStatus === INVOICES_STATUS.ALL_INVOICES) {
                            setInvoicesStatus(INVOICES_STATUS.PAID_INVOICES)
                          } else if (invoicesStatus === INVOICES_STATUS.PAID_INVOICES) {
                            setInvoicesStatus(INVOICES_STATUS.ALL_INVOICES)
                          }
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t("invoicesStatus.paidInvoices.label")}
                    control={
                      <Checkbox
                        size="small"
                        name={t("invoicesStatus.paidInvoices.label")}
                        checked={invoicesStatus === INVOICES_STATUS.PAID_INVOICES || invoicesStatus === INVOICES_STATUS.ALL_INVOICES}
                        onChange={() => {
                          if (invoicesStatus === INVOICES_STATUS.ALL_INVOICES) {
                            setInvoicesStatus(INVOICES_STATUS.UNPAID_INVOICES)
                          } else if (invoicesStatus === INVOICES_STATUS.UNPAID_INVOICES) {
                            setInvoicesStatus(INVOICES_STATUS.ALL_INVOICES)
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              </Box>
              <SearchInput width={0.5} setFilteredData={setFilteredInvoices} data={filterInvoicesByPaid()} searchFunction={searchInvoices} searchDependencies={[invoicesStatus]} />
            </Box>
            <Box component="div" display="flex" flexDirection="column" alignItems="right" sx={(theme) => ({ gap: theme.spacing(2) })}>
              <LoadingButton variant="contained" onClick={() => setShowExportInvoicesDrawer(true)} loading={isLoading} sx={(theme) => ({ mr: theme.spacing(2) })}>
                {t("invoicesStatus.actions.exportInvoices")}
              </LoadingButton>
              <LoadingButton variant="contained" disabled={selectedInvoiceIds.length < 2} onClick={() => setShowBulkEmailDrawer(true)} loading={isLoading} sx={(theme) => ({ mr: theme.spacing(2) })}>
                {t("invoicesStatus.selectedInvoices.actions.bulkEmail")}
              </LoadingButton>
            </Box>
          </Box>
        </Card>
        <AllInvoices invoices={filteredInvoices} isFetching={isFetching} />
      </Box>
      {showBulkEmailDrawer && <BulkEmailDrawer showBulkActionsDrawer={showBulkEmailDrawer} setShowBulkActionsDrawer={setShowBulkEmailDrawer} />}
      <SoeDrawer showDrawer={showCreditDrawer} setShowDrawer={setShowCreditDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("addCreditDrawer.title")}
            setShowDrawer={() => {
              setShowCreditDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <AddCreditDrawer onSubmitEvent={onSubmitEvent} setShowCreditDrawer={setShowCreditDrawer} setIsCreditCreating={setIsCreditCreating} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("addCreditDrawer.actions.save.label"),
                variant: "contained",
                disabled: isCreditCreating,
                loading: isCreditCreating,
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <ExportInvoicesDrawer showExportInvoicesDrawer={showExportInvoicesDrawer} setShowExportInvoicesDrawer={setShowExportInvoicesDrawer} />
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={t("invoiceAllConfirmationDialog.title")}
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("invoiceAllConfirmationDialog.content")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleClose,
                label: t("invoiceAllConfirmationDialog.actions.cancel"),
                variant: "outlined",
                disabled: isLoading,
              },
              {
                action: handleConfirm,
                label: t("invoiceAllConfirmationDialog.actions.confirm"),
                variant: "contained",
                loading: isLoading || isSleeping,
                disabled: isLoading || isSleeping,
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
    </>
  )
}

export default Invoices
