/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/links/
 * @link https://mui.com/api/link/
 *
 */

export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: { color: theme.palette.contentActionDefault.main }, // @todo: To validate if we want SOE blue as link font color
      styleOverrides: {
        root: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
  }
}
