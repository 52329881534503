import restApiSlice from "../../services/rest-api/slice"

const settingsRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: () => ({ url: "/settings", method: "GET" }),
      providesTags: ["Settings"],
      transformResponse: (response) => response.data,
    }),
    editSettings: builder.mutation({
      query: ({ id, payload }) => ({ url: `/settings/${id}`, method: "PATCH", body: payload }),
      invalidatesTags: ["Settings"],
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetSettingsQuery, useEditSettingsMutation } = settingsRestApiSlice

export default settingsRestApiSlice.reducer
