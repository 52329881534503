import { alpha } from "@mui/material/styles"
import palette from "./palette"

const createCustomShadows = () => {
  const transparent = 0.24
  return {
    contentActionFocusShadow: `0 0 8px 3px ${alpha(palette.contentActionFocus.main, transparent)}`,
  }
}

export default createCustomShadows
