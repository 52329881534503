// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Custom style
import Skeleton from "@mui/material/Skeleton"
import { CustomCard, CustomCardContent, CustomLogoBox, MuiWrapper } from "./style"

// Others
import TranslationService from "../../services/translation"
import { useGetCarriersQuery } from "../../services/carriers/slice"

const getLogoPosition = (position) => {
  switch (position) {
    case "center":
      return {
        textAlign: "center",
        margin: "0 auto",
      }
    case "left":
      return {
        textAlign: "left",
        marginLeft: "0",
      }
    case "right":
      return {
        textAlign: "right",
        marginRight: "0",
      }
    default:
      return {
        textAlign: "center",
        margin: "0 auto",
      }
  }
}

function CarrierServiceLogo({ serviceName, logo, align, code, ...muiBoxProps }) {
  const { i18n } = useTranslation()
  const [carrierLogo, setCarrierLogo] = useState(logo)

  const { data: carriers, isFetching } = useGetCarriersQuery(undefined, { skip: !code || logo })
  const [temporaryCarriers, setTemporaryCarriers] = useState([])

  useEffect(() => {
    if (temporaryCarriers.length > 0) {
      const carrierInfo = temporaryCarriers?.find((carrier) => carrier.code === code)
      if (carrierInfo) setCarrierLogo(carrierInfo.logo)
    } else {
      setCarrierLogo(logo)
    }
  }, [code, logo])

  useEffect(() => {
    if (carriers) {
      setTemporaryCarriers(carriers)
      const carrierInfo = carriers?.find((carrier) => carrier.code === code)
      if (carrierInfo) setCarrierLogo(carrierInfo.logo)
    }
  }, [carriers])

  return (
    <MuiWrapper {...muiBoxProps}>
      <CustomCard variant="outlined">
        <CustomCardContent>
          {isFetching ? (
            <Skeleton variant="rectangular" height={40} sx={(theme) => ({ minWidth: theme.spacing(25) })} />
          ) : (
            <>
              <CustomLogoBox component="div" sx={{ ...getLogoPosition(align) }} dangerouslySetInnerHTML={{ __html: carrierLogo }} />
              {serviceName && (
                <Box component="div" sx={{ ...getLogoPosition(align) }}>
                  <Typography component="span" sx={(theme) => ({ color: theme.palette.contentNeutralPrimary.main })} variant="bodySmall600">
                    {TranslationService.getLabelFromLocale(i18n.language, serviceName)}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </CustomCardContent>
      </CustomCard>
    </MuiWrapper>
  )
}

CarrierServiceLogo.defaultProps = {
  serviceName: undefined,
  logo: undefined,
  align: "center",
  code: undefined,
}

CarrierServiceLogo.propTypes = {
  serviceName: PropTypes.string,
  logo: PropTypes.string,
  align: PropTypes.string,
  code: PropTypes.string,
}

export default CarrierServiceLogo
