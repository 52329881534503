/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/grid/
 * @link https://mui.com/api/grid/
 */

export default function Grid() {
  return {
    // @todo: Still need to be completed, Grid theming is huge so we might want to open a ticket just for this one.
    MuiGrid: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiDataGrid: {
      defaultProps: {},
      styleOverrides: {
        root: {},
      },
    },
  }
}
