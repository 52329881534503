/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/typography/
 * @link https://mui.com/api/typography/
 *
 */
export default function Typography(theme) {
  return {
    MuiTypography: {
      defaultProps: {
        component: "span",
        variant: "bodyMedium400",
        variantMapping: {
          h1: "h1", // Use as fail safe, use custom variant instead
          h2: "h2", // Use as fail safe, use custom variant instead
          h3: "h3", // Use as fail safe, use custom variant instead
          h4: "h4", // Use as fail safe, use custom variant instead
          h5: "h5", // Use as fail safe, use custom variant instead
          h6: "h6", // Use as fail safe, use custom variant instead
          subtitle1: "h2", // Use as fail safe, use custom variant instead
          subtitle2: "h2", // Use as fail safe, use custom variant instead
          body1: "span", // Use as fail safe, use custom variant instead
          body2: "span", // Use as fail safe, use custom variant instead
          button: "h2", // Use as fail safe, use custom variant instead
          caption: "span", // Use as fail safe, use custom variant instead
          overline: "span", // Use as fail safe, use custom variant instead
          headingH1: "h1",
          headingH2: "h2",
          headingH3: "h3",
          headingH4: "h4",
          headingH5: "h5",
          headingH6: "h6",
          bodyMedium600: "span",
          bodyMedium400: "span",
          bodySmall600: "span",
          bodySmall400: "span",
          bodySmall200: "span",
        },
      },
      styleOverrides: {
        root: {
          // color: theme.palette.contentNeutralSecondary.main, @todo check with christian to apply this color at good place
          "&.MuiTypography-h5": {
            "&.MuiCardHeader-title": {
              color: theme.palette.contentNeutralPrimary.main,
              fontSize: theme.typography.headingH6.fontSize,
              fontWeight: theme.typography.fontWeightBold,
              lineHeight: "2rem", // @todo: Use theme (ajust in typography)
            },
          },
        },
      },
    },
  }
}
