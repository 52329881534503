// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Pagination from "@mui/material/Pagination"
import Select from "@mui/material/Select"

// Theme
import { useTheme } from "@mui/material/styles"

function CustomPagination({ count, page, setPage, rowsPerPage, setRowsPerPage, showFirstButton, showLastButton }) {
  const customTheme = useTheme()
  const { t } = useTranslation("customPagination")

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleRowPerPageChange = (event) => {
    setPage(1)
    setRowsPerPage(event.target.value)
  }

  return (
    <Box component="div">
      <Box component="span" mt={customTheme.spacing(2)} pb={customTheme.spacing(2)} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box component="span" pr={customTheme.utils.pxToRem(128)} sx={{ flexGrow: 1 }} />
        <Pagination count={count} color="secondary" page={page} onChange={handleChangePage} showFirstButton={showFirstButton} showLastButton={showLastButton} />
        <Box component="span" sx={{ flexGrow: 1 }} />
        <FormControl sx={(theme) => ({ minWidth: theme.utils.pxToRem(120), pr: theme.spacing(2) })}>
          <InputLabel id="rows-per-page-label">{t("rowsPerPage")}</InputLabel>
          <Select labelId="rows-per-page-label" value={rowsPerPage} size="small" label="Rows per page" onChange={handleRowPerPageChange}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

CustomPagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  showFirstButton: PropTypes.bool.isRequired,
  showLastButton: PropTypes.bool.isRequired,
}

export default CustomPagination
