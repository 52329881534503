import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"

import { useDispatch } from "react-redux"

import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import RadioGroup from "@mui/material/RadioGroup"

import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

import CreditCardList from "./components/credit-card-list"

import { useChargePaymentMethodMutation } from "../../slice"
import { useGetClientDetailsQuery } from "../../../clients/slice"
import restApiSlice from "../../../../services/rest-api/slice"

function ChargePaymentMethodDrawer({ invoice, onSubmitEvent, setShowDrawer, setIsLoading }) {
  const { t } = useTranslation("invoices")
  const dispatch = useDispatch()
  const [chargePayment] = useChargePaymentMethodMutation()

  const userId = invoice?.user?.pk.slice(2) || invoice?.company?.userId
  const { data: clientDetails } = useGetClientDetailsQuery(userId)
  const activePaymentMethods = clientDetails?.paymentMethods?.filter((paymentMethod) => paymentMethod.status !== "deleted")

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState()

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value)
  }

  const formik = useFormik({
    initialValues: {
      paymentDate: useMemo(() => new Date(), []),
      amount: (invoice?.summary?.amountOutstanding !== undefined ? invoice.summary.amountOutstanding : invoice.summary.invoice.total).toFixed(2),
      currency: invoice.currency || "CAD",
      note: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.amount || Number.isNaN(parseFloat(formValues.amount)) || parseFloat(formValues.amount) <= 0) {
        errors.amount = true
      }
      if (!formValues.currency) {
        errors.currency = true
      }
      if (!selectedPaymentMethod) {
        errors.paymentMethod = true
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      setIsLoading(true)
      const payload = {
        amount: parseFloat(formValues.amount),
        currency: formValues.currency,
        paymentMethod: selectedPaymentMethod,
        note: formValues.note?.trim() || undefined,
      }
      try {
        await chargePayment({ id: invoice.id, payload })
        dispatch(restApiSlice.util.invalidateTags(["UnpaidInvoices", "PaidInvoices"]))
        setShowDrawer(false)
      } catch (error) {
        console.error('Error charging payment:', error)
      } finally {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    formik.handleReset()
    formik.setErrors({})
    formik.setTouched({}, false)
  }, [])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  useEffect(() => {
    if (activePaymentMethods) {
      const defaultMethod = activePaymentMethods.find((paymentMethod) => paymentMethod.status === "default") || ""
      setDefaultPaymentMethod(defaultMethod)
      setSelectedPaymentMethod(defaultMethod?.id)
    }
  }, [activePaymentMethods])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            sx={(theme) => ({ marginRight: theme.spacing(1) })}
            id="amount"
            variant="outlined"
            label={t("chargePaymentMethodDrawer.form.fields.amount.label")}
            size="small"
            value={formik.values.amount || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.amount && formik.errors.amount !== undefined}
            InputProps={{ endAdornment: <InputAdornment position="end">{formik.values.currency}</InputAdornment> }}
            fullWidth
          />
        </Box>
        <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
          <TextField
            sx={(theme) => ({ marginRight: theme.spacing(1) })}
            id="note"
            variant="outlined"
            label={t("chargePaymentMethodDrawer.form.fields.note.label")}
            size="small"
            value={formik.values.note || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            fullWidth
          />
        </Box>
        {activePaymentMethods && defaultPaymentMethod !== undefined && (
          <FormControl>
            <RadioGroup aria-label="paymentMethod" name="paymentMethod" onChange={handlePaymentMethodChange} defaultValue={defaultPaymentMethod?.id} value={selectedPaymentMethod?.id}>
              <CreditCardList activePaymentMethods={activePaymentMethods} />
            </RadioGroup>
          </FormControl>
        )}
      </form>
    </Box>
  )
}

ChargePaymentMethodDrawer.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowDrawer: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
}

ChargePaymentMethodDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

export default ChargePaymentMethodDrawer
