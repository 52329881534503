import "filepond/dist/filepond.min.css"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import React, { useEffect, useState } from "react"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"

import { useDispatch, useSelector } from "react-redux"
import { useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

import Audit from "./components/audit"
import CustomPagination from "../custom-pagination"
import EnhancedTableHead from "../enhanced-table-head"
import FileUploader from "../file-uploader"
import getComparator from "../../utils/sorting"
import restApiSlice from "../../services/rest-api/slice"
import SearchInput from "../search-input"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../soe-theme/src/components"
import { useGetAuditsMutation, useAddAuditFileMutation, setOrder, setOrderBy } from "../../reducers/auditsReducer"

function Audits() {
  const { t } = useTranslation("audits")
  const customTheme = useTheme()
  const dispatch = useDispatch()

  const { order, orderBy } = useSelector((state) => state.audits)

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [filteredAudits, setFilteredAudits] = useState()
  const [showStartNewAuditDrawer, setShowStartNewAuditDrawer] = useState(false)
  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [refreshAudits, setRefreshAudits] = useState(false)

  const [getAudits, { data: audits, isFetching }] = useGetAuditsMutation()

  const [addAuditFile] = useAddAuditFileMutation()

  useEffect(() => {
    getAudits()
  }, [])

  useEffect(() => {
    if (refreshAudits) {
      getAudits()
      setRefreshAudits(false)
    }
  }, [refreshAudits])

  useEffect(() => {
    setFilteredAudits(audits)
  }, [audits])

  const headCells = [
    {
      id: "carrier.code",
      align: "center",
      disablePadding: false,
      label: t("headers.carrier"),
    },
    {
      id: "filename",
      disablePadding: false,
      label: t("headers.filename"),
    },
    {
      id: "createdDate",
      disablePadding: false,
      label: t("headers.createdDate"),
    },
    {
      id: "invoice.date",
      disablePadding: false,
      label: t("headers.billingDate"),
    },
    {
      id: "invoice.accountNumber",
      disablePadding: false,
      label: t("headers.accountNumber"),
    },
    {
      id: "invoice.number",
      disablePadding: false,
      label: t("headers.invoiceNumber"),
    },
    {
      id: "status",
      disablePadding: false,
      label: t("headers.status"),
    },
    {
      id: "menu",
    },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    dispatch(setOrder(isAsc ? "desc" : "asc"))
    dispatch(setOrderBy(property))
  }

  const handleRefreshClick = () => {
    dispatch(restApiSlice.util.invalidateTags("Audits"))
    getAudits()
  }

  useEffect(() => {
    if (!showStartNewAuditDrawer && !isUploading) setFiles([])
  }, [showStartNewAuditDrawer])

  return (
    <>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <CardHeader
          title={t("title")}
          action={
            <>
              <Button variant="outlined" onClick={handleRefreshClick} sx={(theme) => ({ mr: theme.spacing(1) })}>
                {t("startNewAudit.actions.refresh.label")}
              </Button>
              <Button variant="contained" onClick={() => setShowStartNewAuditDrawer(true)} sx={(theme) => ({ mr: theme.spacing(1) })}>
                {t("startNewAudit.actions.add.label")}
              </Button>
            </>
          }
        />
      </Card>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <Box my={customTheme.utils.pxToThemeSpacing(20)} mx={customTheme.spacing(2)}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <SearchInput data={audits} setFilteredData={setFilteredAudits} excludeFields={["pk", "id", "sk", "entityType", "rawFileHash"]} width={0.5} />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <Box sx={{ width: "auto" }}>
          <Paper sx={{ width: "100%" }}>
            {!filteredAudits || isFetching ? (
              <Skeleton variant="rectangular" width="100%" height={100} />
            ) : (
              <>
                <TableContainer>
                  <Table size="small" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1.main })}>
                    <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    <TableBody>
                      {filteredAudits
                        .slice()
                        .sort(getComparator(order, orderBy))
                        .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                        .map((audit) => {
                          return <Audit key={audit.id} audit={audit} setRefreshAudits={setRefreshAudits} />
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {filteredAudits && <CustomPagination count={Math.ceil(filteredAudits.length / rowsPerPage)} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />}
              </>
            )}
          </Paper>
        </Box>
      </Card>
      <SoeDrawer showDrawer={showStartNewAuditDrawer} setShowDrawer={setShowStartNewAuditDrawer} anchor="right" keepMounted={false} preventClosing={isUploading}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("startNewAuditDrawer.title")}
            setShowDrawer={() => {
              setShowStartNewAuditDrawer(false)
            }}
            preventClosing={isUploading}
          />
          <SoeDrawerContent>
            <FileUploader name="auditFile" files={files} setFiles={setFiles} signUrlMutation={addAuditFile} trigger={setIsUploading} invalidateTags={["Audits"]} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: () => {
                  setShowStartNewAuditDrawer(false)
                  setRefreshAudits(true)
                },
                label: t("startNewAuditDrawer.actions.close"),
                variant: "contained",
                loading: isUploading,
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </>
  )
}

export default Audits
