import AppBar from "@mui/material/AppBar"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"

const miniDrawerWidth = 260

export const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  borderBottom: `1px solid ${theme.palette.strokeDefault.main}`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: miniDrawerWidth,
    width: `calc(100% - ${miniDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export const CustomButton = styled(Button, { label: "CustomButton", slot: "Root" })(({ theme }) => ({
  "& .MuiButton-startIcon": {
    "& .MuiSvgIcon-root": {
      fontSize: theme.utils.pxToRem(24),
    },
  },
}))
