// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import TranslationService from "../../../../services/translation"

function TransactionStatusMessage({ message }) {
  const { i18n } = useTranslation()
  return (
    <Alert
      severity={message.severity || "error"}
      sx={(theme) => ({
        border: 1,
        borderStyle: "solid",
        "& .MuiAlert-message": {
          width: 1,
        },
        mt: theme.utils.pxToThemeSpacing(16),
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          width: 1,
          mt: theme.utils.pxToThemeSpacing(6),
          mb: theme.utils.pxToThemeSpacing(0),
        })}
      >
        <Typography variant="bodySmall400" sx={(theme) => ({ mb: theme.utils.pxToThemeSpacing(6) })}>
          {TranslationService.getLabelFromLocale(i18n.language, message.message)}
        </Typography>
      </Box>
    </Alert>
  )
}

TransactionStatusMessage.propTypes = {
  message: PropTypes.instanceOf(Object).isRequired,
}

export default TransactionStatusMessage
