/* eslint-disable react/jsx-filename-extension */

import merge from "lodash/merge"

import Accordion from "./Accordion"
import Alert from "./Alert"
import AppBar from "./AppBar"
import Button from "./Button"
import Card from "./Card"
import Checkbox from "./Checkbox"
import Chip from "./Chip"
import Container from "./Container"
import Divider from "./Divider"
import Drawer from "./Drawer"
import Grid from "./Grid"
import Icon from "./Icon"
import ImageList from "./ImageList"
import Inputs from "./Inputs"
import Link from "./Link"
import List from "./List"
import Paper from "./Paper"
import Radio from "./Radio"
import Rating from "./Rating"
import Select from "./Select"
import Slider from "./Slider"
import Skeleton from "./Skeleton"
import Switch from "./Switch"
import Table from "./Table"
import Tooltip from "./Tooltip"
import Typography from "./Typography"

export default function ComponentsOverrides(theme) {
  return merge(
    Accordion(theme),
    Alert(theme),
    AppBar(theme),
    Button(theme),
    Card(theme),
    Checkbox(theme),
    Chip(theme),
    Container(theme),
    Divider(theme),
    Drawer(theme),
    Grid(theme),
    Icon(theme),
    ImageList(theme),
    Inputs(theme),
    Link(theme),
    List(theme),
    Paper(theme),
    Radio(theme),
    Rating(theme),
    Select(theme),
    Slider(theme),
    Skeleton(theme),
    Switch(theme),
    Table(theme),
    Tooltip(theme),
    Typography(theme)
  )
}
