// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
// MUI
import Box from "@mui/material/Box"
import Select from "@mui/material/Select"

// Formik
import { useFormik } from "formik"

// Others
import { FormControl, InputLabel, MenuItem, TextField } from "@mui/material"
import { useEditClientDetailsMutation, useGetCurrenciesQuery } from "../../../../../../slice"

import BILLING_TYPE_ENUM from "../../../../../../../../utils/billing"

function ClientBillingDrawer({ client, onSubmitEvent, setSubmitEvent, setShowClientBillingDrawer }) {
  const { t } = useTranslation("clients")

  const [setClientDetails] = useEditClientDetailsMutation()
  const { data: currencies } = useGetCurrenciesQuery()

  const formik = useFormik({
    initialValues: {
      type: client?.billing?.type || "",
      number: client?.billing?.account?.number || "",
      paymentTermsDays: client?.billing?.account?.paymentTermsDays || "",
      currency: client?.billing.currency || "CAD",
    },
    validateOnChange: true,
    enableReinitialize: true,
    validate: (formValues) => {
      const errors = {}
      if (!formValues.type) {
        errors.type = true
      }
      if (!formValues.number) {
        errors.number = true
      }
      if (!formValues.paymentTermsDays) {
        errors.paymentTermsDays = true
      }
      if (!formValues.currency) {
        errors.currency = true
      }
      return errors
    },
    onSubmit: async (formValues) => {
      const temporaryBilling = {
        account: {
          number: formValues.number,
          paymentTermsDays: Number(formValues.paymentTermsDays),
        },
        currency: formValues.currency,
        type: formValues.type,
      }
      await setClientDetails({ clientId: client.pk.slice(2), payload: { billing: temporaryBilling } })
      setShowClientBillingDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
      setSubmitEvent(undefined)
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <FormControl error={formik.touched.type && formik.errors.type !== undefined} fullWidth sx={(theme) => ({ marginBottom: theme.spacing(3) })}>
        <InputLabel id="type-label">{t("clientDetails.clientDetailsInformations.clientBillingDrawer.fields.type.label")}</InputLabel>
        <Select name="type" labelId="type-label" size="small" label={t("clientDetails.clientDetailsInformations.clientBillingDrawer.fields.type.label")} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.type}>
          <MenuItem value={BILLING_TYPE_ENUM.ACCOUNT}>{t("clientDetails.clientDetailsInformations.clientBillingDrawer.type.account")}</MenuItem>
          <MenuItem value={BILLING_TYPE_ENUM.CREDIT_CARD}>{t("clientDetails.clientDetailsInformations.clientBillingDrawer.type.creditcard")}</MenuItem>
        </Select>
      </FormControl>

      <TextField
        id="number"
        label={t("clientDetails.clientDetailsInformations.clientBillingDrawer.fields.number.label")}
        variant="outlined"
        size="small"
        sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.number}
        error={formik.touched.number && formik.errors.number !== undefined}
        required
      />
      <TextField
        id="paymentTermsDays"
        label={t("clientDetails.clientDetailsInformations.clientBillingDrawer.fields.paymentTermsDays.label")}
        variant="outlined"
        size="small"
        sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.paymentTermsDays}
        error={formik.touched.paymentTermsDays && formik.errors.paymentTermsDays !== undefined}
        required
      />
      {currencies && (
        <FormControl error={formik.touched.currency && formik.errors.currency !== undefined} fullWidth sx={(theme) => ({ marginBottom: theme.spacing(3) })}>
          <InputLabel id="currency-label">{t("clientDetails.clientDetailsInformations.clientBillingDrawer.fields.currency.label")}</InputLabel>
          <Select
            name="currency"
            labelId="currency-label"
            size="small"
            label={t("clientDetails.clientDetailsInformations.clientBillingDrawer.fields.currency.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currency || undefined}
            defaultValue="CAD"
          >
            {currencies
              ?.filter((currency) => currency.code === "CAD" || currency.code === "USD")
              .map(({ code }, currencyIndex) => (
                <MenuItem key={code.concat(currencyIndex)} value={code}>
                  {code}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </Box>
  )
}

ClientBillingDrawer.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string,
    pk: PropTypes.string,
    emailAddress: PropTypes.string,
    billing: PropTypes.shape({
      type: PropTypes.string,
      currency: PropTypes.string,
      account: PropTypes.shape({
        number: PropTypes.string,
        paymentTermsDays: PropTypes.number,
      }),
    }),
    pricingGroupCode: PropTypes.string,
  }).isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  setSubmitEvent: PropTypes.func,
  setShowClientBillingDrawer: PropTypes.func.isRequired,
}

ClientBillingDrawer.defaultProps = {
  onSubmitEvent: undefined,
  setSubmitEvent: undefined,
}

export default ClientBillingDrawer
