// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CompanyDetailsSummaryCard from "../../../company-details-summary-card"
import { SummaryStatusVariantsEnum } from "../../../company-details-summary-card/components/summary-status"

function CompanyInexFeesCard({ inexFee, defaultToIconSuccessStatus, handleCompanyInexFeeCardClick }) {
  const theme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToIconSuccessStatus) return SummaryStatusVariantsEnum.icon
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <CompanyDetailsSummaryCard title="Inex Fees" handleClick={handleCompanyInexFeeCardClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={theme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
          {inexFee?.feeAmount}$ - {inexFee?.feeType}
        </Typography>
        <Box
          mt={theme.spacing(1)}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        />
      </Box>
    </CompanyDetailsSummaryCard>
  )
}

CompanyInexFeesCard.propTypes = {
  inexFee: PropTypes.shape({
    id: PropTypes.string,
    pk: PropTypes.string,
    companyId: PropTypes.string,
    feeAmount: PropTypes.number,
    feeType: PropTypes.string,
  }),
  defaultToIconSuccessStatus: PropTypes.bool,
  handleCompanyInexFeeCardClick: PropTypes.func,
}

CompanyInexFeesCard.defaultProps = {
  handleCompanyInexFeeCardClick: () => {},
  defaultToIconSuccessStatus: false,
  inexFee: undefined,
}

export default CompanyInexFeesCard
