import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"

import { ShipperProps } from "../../models/rate-request"

function ShipperCard({ shipper, disabled, showPhoneNumber, showEmailAddress }) {
  const { t } = useTranslation("shipperCard")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard title={t("shipperTitle")} disabled={disabled} sx={{ height: 1 }}>
      <Box
        mt={1}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {shipper?.companyName && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {shipper.companyName}
          </Typography>
        )}
        {shipper?.personName && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {shipper.personName}
          </Typography>
        )}
        {shipper?.addressLine1 && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {shipper.addressLine1}
          </Typography>
        )}
        {shipper?.addressLine2 && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {shipper.addressLine2}
          </Typography>
        )}
        {(shipper?.city || shipper?.provinceCode || shipper?.countryCode) && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {`${shipper?.city || ""}${shipper?.city && shipper?.provinceCode ? ", " : ""}${shipper?.provinceCode || ""}${(shipper?.city || shipper?.provinceCode) && shipper?.countryCode ? ", " : ""}${shipper?.countryCode || ""}`}
          </Typography>
        )}
        {shipper?.postalCode && (
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {shipper.postalCode}
          </Typography>
        )}
        {(showPhoneNumber || showEmailAddress) && (
          <Box
            mt={1}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {showPhoneNumber && shipper?.phoneNumber && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {shipper.phoneNumber}
              </Typography>
            )}
            {showEmailAddress && shipper?.emailAddress && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {shipper.emailAddress}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </RateRequestSummaryCard>
  )
}

ShipperCard.propTypes = {
  shipper: ShipperProps,
  disabled: PropTypes.bool,
  showPhoneNumber: PropTypes.bool,
  showEmailAddress: PropTypes.bool,
}

ShipperCard.defaultProps = {
  shipper: undefined,
  disabled: false,
  showPhoneNumber: false,
  showEmailAddress: false,
}

export default ShipperCard
