import { createSlice } from "@reduxjs/toolkit"
import { changePassword } from "./authenticatorReducer"

export const drawerAlertSlice = createSlice({
  name: "drawer",
  initialState: {
    alert: {
      showAlert: false,
      severity: undefined,
      message: undefined,
    },
  },
  reducers: {
    createDrawerAlert: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "warning"
      state.alert.message = action.payload
    },
    clearDrawerAlert: (state) => {
      state.alert = {
        showAlert: false,
        severity: undefined,
        message: undefined,
      }
    },
  },
  extraReducers: {
    [changePassword.fulfilled]: (state) => {
      state.alert.showAlert = false
      state.alert.severity = undefined
      state.alert.message = undefined
    },
    [changePassword.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
    "restApi/error/cancelOrder": (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.payload?.data?.message || action?.payload?.data?.error?.message
    },
  },
})

export const { clearDrawerAlert, createDrawerAlert } = drawerAlertSlice.actions

export default drawerAlertSlice.reducer
