import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import Switch from "@mui/material/Switch"
import TextField from "@mui/material/TextField"
import { useTranslation } from "react-i18next"

import { useFormik } from "formik"

import { useEditPricingGroupMutation } from "../../slice"

function PricingGroupEdit({ selectedPricingGroup, onSubmitEvent, setSubmitEvent, setShowPricingGroupEditDrawer }) {
  const [editPricingGroup] = useEditPricingGroupMutation()
  const { t } = useTranslation("pricing")

  const formik = useFormik({
    initialValues: {
      pricingGroupName: selectedPricingGroup.name,
      isDefault: selectedPricingGroup.isDefault,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}
      if (!values.pricingGroupName) {
        errors.pricingGroupName = true
      }
      return errors
    },
    onSubmit: async (formValues) => {
      editPricingGroup({ id: selectedPricingGroup.code, payload: formValues })
      setShowPricingGroupEditDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
      setSubmitEvent(undefined)
    }
  }, [onSubmitEvent])

  const handleIsDefaultChange = (event) => {
    formik.setFieldValue("isDefault", event.target.checked)
  }

  return (
    <Box component="div">
      <CardContent variant="outlined" sx={(theme) => ({ width: "auto", borderRadius: 1, borderColor: theme.palette.contentActionDefault.main })}>
        <FormGroup>
          <TextField
            id="pricingGroupName"
            value={formik.values.pricingGroupName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.pricingGroupName && formik.errors.pricingGroupName !== undefined}
            variant="outlined"
            size="small"
            label={t("pricingGroupDrawer.pricingGroupName.fields.label")}
          />
          <FormControlLabel control={<Switch color="secondary" checked={formik.values.isDefault} onChange={handleIsDefaultChange} disabled={selectedPricingGroup.isDefault} />} label={t("pricingGroupDrawer.setAsDefault.fields.label")} />
        </FormGroup>
      </CardContent>
    </Box>
  )
}

PricingGroupEdit.propTypes = {
  selectedPricingGroup: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    isDefault: PropTypes.bool,
    pricingPlans: PropTypes.arrayOf(Object),
  }).isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  setSubmitEvent: PropTypes.func,
  setShowPricingGroupEditDrawer: PropTypes.func.isRequired,
}

PricingGroupEdit.defaultProps = {
  onSubmitEvent: undefined,
  setSubmitEvent: undefined,
}

export default PricingGroupEdit
