import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import React, { useState } from "react"
import Stack from "@mui/material/Stack"

import CarrierDetailsHeader from "../carrier-details-header"
import CarrierDetailsInvoiceItem from "../carrier-details-invoice-item"
import ClientSelection from "../../../../../../../client-selection"
import OrderSearch from "../order-search"
import PackagesCard from "../../../../../../../packages-card"
import RecipientCard from "../../../../../../../recipient-card"
import ServiceCard from "../service-card"
import ShipperCard from "../../../../../../../shipper-card"

import { AuditItemProps, AuditProps } from "../../../../../../../../models/audit"
import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"

function NoMatchDisplay({ audit, auditItemDetails }) {
  const [showAssignClient, setShowAssignClient] = useState(false)

  return (
    <Grid container spacing={pxToThemeSpacing(6)} direction="row" alignItems="stretch">
      <Grid item xs={3}>
        <Stack direction="column" spacing={pxToThemeSpacing(4)}>
          <CarrierDetailsHeader carrier={audit.carrier} tracking={auditItemDetails.tracking.carrierInvoice.actual} service={auditItemDetails.service.carrierInvoice.actual} shippingOptions={auditItemDetails.shippingOptions.carrierInvoice.actual} />
          <ServiceCard service={auditItemDetails.service.carrierInvoice.actual} />
          <CarrierDetailsInvoiceItem rate={auditItemDetails.rate} />
          <ShipperCard shipper={auditItemDetails.shipper.carrierInvoice.actual} />
          <RecipientCard recipient={auditItemDetails.recipient.carrierInvoice.actual} />
          <PackagesCard packages={auditItemDetails.packages.carrierInvoice.actual} />
        </Stack>
      </Grid>
      <Grid item xs={9}>
        <Card variant="outlined" sx={{ height: 1 }}>
          {!showAssignClient ? <OrderSearch setShowAssignClient={setShowAssignClient} /> : <ClientSelection auditId={audit.id} auditItemId={auditItemDetails.id} auditItemType={auditItemDetails.auditItemType} />}
        </Card>
      </Grid>
    </Grid>
  )
}

NoMatchDisplay.propTypes = {
  audit: AuditProps.isRequired,
  auditItemDetails: AuditItemProps.isRequired,
}

export default NoMatchDisplay
