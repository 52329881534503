/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/api/alert-title/
 *
 * @link https://mui.com/components/alert/
 * @link https://mui.com/api/alert/
 */
import React from "react"

import SoeErrorOutlineIcon from "../icons/SoeErrorOutlineIcon"
import SoeWarningAmberIcon from "../icons/SoeWarningAmberIcon"
import SoeInfoOutlinedIcon from "../icons/SoeInfoOutlinedIcon"
import SoeTaskAltIcon from "../icons/SoeTaskAltIcon"

export default function Alert(theme) {
  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <SoeErrorOutlineIcon fontSize="inherit" />,
          info: <SoeInfoOutlinedIcon fontSize="inherit" />,
          success: <SoeTaskAltIcon fontSize="inherit" />,
          warning: <SoeWarningAmberIcon fontSize="inherit" />,
        },
      },
      styleOverrides: {
        root: {
          fontSize: theme.typography.bodySmall400.fontSize,
          fontWeight: theme.typography.bodySmall400.fontWeight,
          lineHeight: theme.typography.bodySmall400.lineHeight,
          "& .MuiAlert-action": {
            padding: "0", // To override wrong padding force in MUI
            alignItems: "center",
            "& .MuiButton-root": {
              margin: "auto 0",
            },
          },
          "& .MuiAlert-icon": {
            "& .MuiSvgIcon-root": { margin: "auto 0" },
          },
          "&.MuiAlert-standardError": {
            backgroundColor: theme.palette.backgroundAlertError,
            color: theme.palette.contentAlertErrorDarker.main,
            "& .MuiAlert-action": {
              "& .MuiButton-outlined": {
                borderColor: theme.palette.contentAlertError.main,
                color: theme.palette.contentAlertErrorDarker.main,
                "&:hover": {
                  backgroundColor: theme.palette.backgroundAlertError.main, // @todo: Event color on button, to be verified with Figma
                  borderColor: theme.palette.backgroundNeutralTransparent, // @todo: Event color on button, to be verified with Figma
                  color: theme.palette.contentAlertErrorDarker.main, // @todo: Event color on button, to be verified with Figma
                },
              },
              "& .MuiButtonBase-root": {
                color: theme.palette.contentAlertErrorDarker.main,
              },
            },
          },
          "&.MuiAlert-standardInfo": {
            backgroundColor: theme.palette.backgroundAlertInformative.main,
            color: theme.palette.contentAlertInformative.main,
            "& .MuiAlert-action": {
              "& .MuiButton-outlined": {
                borderColor: theme.palette.contentAlertInformative.main,
                color: theme.palette.contentAlertInformativeDarker.main,
                "&:hover": {
                  backgroundColor: theme.palette.backgroundAlertInformative.main, // @todo: Event color on button, to be verified with Figma
                  borderColor: theme.palette.backgroundNeutralTransparent, // @todo: Event color on button, to be verified with Figma
                  color: theme.palette.contentAlertInformativeDarker.main, // @todo: Event color on button, to be verified with Figma
                },
              },
              "& .MuiButtonBase-root": {
                color: theme.palette.contentAlertInformativeDarker.main,
              },
            },
          },
          "&.MuiAlert-standardSuccess": {
            backgroundColor: theme.palette.backgroundAlertPositive.main,
            color: theme.palette.contentAlertPositive.main,
            "& .MuiAlert-action": {
              "& .MuiButton-outlined": {
                borderColor: theme.palette.contentAlertPositive.main,
                color: theme.palette.contentAlertPositiveDarker.main,
                "&:hover": {
                  backgroundColor: theme.palette.backgroundAlertPositive.main, // @todo: Event color on button, to be verified with Figma
                  borderColor: theme.palette.backgroundNeutralTransparent, // @todo: Event color on button, to be verified with Figma
                  color: theme.palette.contentAlertPositiveDarker.main, // @todo: Event color on button, to be verified with Figma
                },
              },
              "& .MuiButtonBase-root": {
                color: theme.palette.contentAlertPositiveDarker.main,
              },
            },
          },
          "&.MuiAlert-standardWarning": {
            backgroundColor: theme.palette.backgroundAlertWarning.main,
            color: theme.palette.contentAlertWarning.main,
            "& .MuiAlert-action": {
              "& .MuiButton-outlined": {
                borderColor: theme.palette.contentAlertWarning.main,
                color: theme.palette.contentAlertWarningDarker.main,
                "&:hover": {
                  backgroundColor: theme.palette.backgroundAlertWarning.main, // @todo: Event color on button, to be verified with Figma
                  borderColor: theme.palette.backgroundNeutralTransparent, // @todo: Event color on button, to be verified with Figma
                  color: theme.palette.contentAlertWarningDarker.main, // @todo: Event color on button, to be verified with Figma
                },
              },
              "& .MuiButtonBase-root": {
                color: theme.palette.contentAlertWarningDarker.main,
              },
            },
          },
        },
      },
    },
    MuiAlertTitle: {
      defaultProps: {},
      styleOverrides: {
        root: {
          "&.MuiAlertTitle-root": {
            fontSize: theme.typography.bodyMedium400.fontSize,
            fontWeight: theme.typography.bodyMedium400.fontWeight,
            lineHeight: theme.typography.bodyMedium400.lineHeight,
          },
        },
      },
    },
  }
}
