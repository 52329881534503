import restApiSlice from "../rest-api/slice"

export const companyRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => ({ url: "/organizations", method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    createCompany: builder.mutation({
      query: (payload) => ({ url: "/organization", method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
    getCompany: builder.query({
      query: (companyId) => ({ url: `/organization/${companyId}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    updateCompany: builder.mutation({
      query: ({ companyId, ...payload }) => ({ url: `/organization/${companyId}`, method: "PATCH", body: payload }),
      transformResponse: (response) => response.data,
    }),
    getCompanyUsers: builder.query({
      query: (companyId) => ({ url: `/organization/${companyId}/users`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetCompaniesQuery, useCreateCompanyMutation, useGetCompanyQuery, useUpdateCompanyMutation, useGetCompanyUsersQuery } = companyRestApiSlice
