// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import ClientDetailsSummaryCard from "../../../client-details-summary-card"
import { SummaryStatusVariantsEnum } from "../../../client-details-summary-card/components/summary-status"

function ClientBillingContactCard({ address, defaultToSuccessStatus, handleClientBillingContactCardClick }) {
  const { t } = useTranslation("clients")
  const theme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToSuccessStatus) return SummaryStatusVariantsEnum.success
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <ClientDetailsSummaryCard title={t("clientDetails.clientDetailsInformations.clientBillingContactCardTitle")} handleClick={handleClientBillingContactCardClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={theme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {address?.companyName && (
          <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
            {address.companyName}
          </Typography>
        )}
        {address?.personName && (
          <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
            {address.personName}
          </Typography>
        )}
        {address?.addressLine1 && (
          <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
            {address.addressLine1}
          </Typography>
        )}
        {address?.addressLine2 && (
          <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
            {address.addressLine2}
          </Typography>
        )}
        {(address?.city || address?.provinceCode || address?.countryCode) && (
          <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
            {`${address?.city || ""}${address?.city && address?.provinceCode ? ", " : ""}${address?.provinceCode || ""}${(address?.city || address?.provinceCode) && address?.countryCode ? ", " : ""}${address?.countryCode || ""}`}
          </Typography>
        )}
        {address?.postalCode && (
          <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
            {address.postalCode}
          </Typography>
        )}
        <Box
          mt={theme.spacing(1)}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {address?.phoneNumber && (
            <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
              {address.phoneNumber}
            </Typography>
          )}
          {address?.emailAddress && (
            <Typography variant="bodySmall400" gutterBottom sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }}>
              {address.emailAddress}
            </Typography>
          )}
        </Box>
      </Box>
    </ClientDetailsSummaryCard>
  )
}

ClientBillingContactCard.propTypes = {
  address: PropTypes.shape({
    personName: PropTypes.string,
    companyName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    provinceCode: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    emailAddress: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  defaultToSuccessStatus: PropTypes.bool,
  handleClientBillingContactCardClick: PropTypes.func,
}

ClientBillingContactCard.defaultProps = {
  handleClientBillingContactCardClick: () => {},
  defaultToSuccessStatus: false,
  address: undefined,
}

export default ClientBillingContactCard
