// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CarrierChipsForm from "../carrier-chips-form"
import CarrierCodeForm from "../carrier-code-form"
import CarrierNamesLanguagesForm from "../carrier-names-languages-form"
import EmptyCarrierService from "../../../../services/empty-carrier"
import { useAddCarrierServicesMutation, useEditCarrierServicesMutation } from "../../slice"

function CarrierServiceDrawer({ carrierGeneralInfoCode, carrierServices, carrierServiceIndex, languages, onSubmitEvent, onDisableEvent, setShowCarrierServicesDrawer, setDisableEvent }) {
  const customTheme = useTheme()
  const { t } = useTranslation("carriers")
  const [addCarrierServices] = useAddCarrierServicesMutation()
  const [editCarrierServices] = useEditCarrierServicesMutation()
  const [editableChipsArray, setEditableChipsArray] = useState([])

  const emptyFullName = EmptyCarrierService.getFieldsWithLanguages("fullName", languages)
  const emptyShortName = EmptyCarrierService.getFieldsWithLanguages("shortName", languages)

  const formik = useFormik({
    initialValues: {
      code: (carrierServices && carrierServices[carrierServiceIndex] && carrierServices[carrierServiceIndex].code) || "",
      billingCode: (carrierServices && carrierServices[carrierServiceIndex] && carrierServices[carrierServiceIndex].billingCode) || "",
      fullName: (carrierServices && carrierServices[carrierServiceIndex] && carrierServices[carrierServiceIndex].fullName) || emptyFullName,
      shortName: (carrierServices && carrierServices[carrierServiceIndex] && carrierServices[carrierServiceIndex].shortName) || emptyShortName,
      chips: editableChipsArray || [],
      isDisabled: (carrierServices && carrierServices[carrierServiceIndex] && carrierServices[carrierServiceIndex].isDisabled) || false,
      languages: languages || undefined,
    },
    validate: (formValues) => {
      const errors = {}
      if (carrierServiceIndex === undefined && carrierServices && carrierServices.some((service) => service.code.trim().toLowerCase() === formValues.code.trim().toLowerCase())) {
        errors.code = t("carrierDetails.carrierServicesDrawer.code.errors.duplicate.label")
      }
      if (formValues.code.trim() === "") {
        errors.code = t("carrierDetails.carrierServicesDrawer.code.errors.empty.label")
      }
      if (carrierServiceIndex === undefined && carrierServices && carrierServices.some((service) => service.billingCode && service.billingCode.trim().toLowerCase() === formValues.billingCode.trim().toLowerCase())) {
        errors.billingCode = t("carrierDetails.carrierServicesDrawer.code.errors.duplicate.label")
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      const editableChipsArrayWithoutEmpty = []

      const removeEmptyChip = (element) => {
        if (
          !Object.values(element).every((item) => {
            if (item.trim() === "") return true
            return false
          })
        )
          editableChipsArrayWithoutEmpty.push(element)
      }

      const checkEmptyChip = () => {
        return (
          formValues.chips &&
          formValues.chips.filter((element) => {
            return removeEmptyChip(element)
          })
        )
      }

      checkEmptyChip()
      formValues.chips = editableChipsArrayWithoutEmpty

      if (carrierServices) {
        const formValuesWithoutLanguages = Object.fromEntries(Object.entries(formValues).filter((element) => element[0] !== "languages"))
        if (carrierServiceIndex === undefined) {
          await addCarrierServices({ code: carrierGeneralInfoCode, payload: formValuesWithoutLanguages })
        } else {
          await editCarrierServices({ code: carrierGeneralInfoCode, serviceCode: formValuesWithoutLanguages.code, payload: formValuesWithoutLanguages })
        }
      }
      formik.resetForm()
      setShowCarrierServicesDrawer(false)
      setDisableEvent()
    },
  })

  useEffect(() => {
    if (carrierServices && carrierServices[carrierServiceIndex] && carrierServices[carrierServiceIndex].chips) setEditableChipsArray([...(carrierServices && carrierServices[carrierServiceIndex] && carrierServices[carrierServiceIndex].chips)])
  }, [carrierServices, carrierServiceIndex])

  useEffect(() => {
    if (onDisableEvent) {
      if (formik.values && formik.values.isDisabled && formik.values.isDisabled === true) formik.setFieldValue("isDisabled", false)
      else formik.setFieldValue("isDisabled", true)
      formik.handleSubmit()
    }
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent, onDisableEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" py={1}>
          <CarrierCodeForm
            formik={formik}
            carrierCodeLabel={t("carrierDetails.carrierServicesDrawer.information.label")}
            nameLabel={t("carrierDetails.carrierServicesDrawer.code.codeCarrier.label")}
            carrierCode={carrierServices}
            carrierIndex={carrierServiceIndex}
          />
          <Box component="div" p={1} />
          <TextField
            id="billingCode"
            name="billingCode"
            inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular } }}
            variant="outlined"
            size="small"
            label={t("carrierDetails.carrierServicesDrawer.code.codeBilling.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.billingCode || ""}
            error={formik.touched.billingCode && formik.errors.billingCode !== undefined}
            helperText={(formik.touched.billingCode && formik.errors.billingCode) || " "}
            fullWidth
          />
        </Box>
        <Divider orientation="horizontal" style={{ minHeight: 0 }} />
        <Box component="div" p={1} />
        <CarrierNamesLanguagesForm formik={formik} nameLabel={t("carrierDetails.carrierServicesDrawer.fullName.label")} nameLabelPrefix="fullName" languages={languages} />
        <Divider orientation="horizontal" style={{ minHeight: customTheme.utils.pxToRem(24) }} />
        <Box component="div" p={1} />
        <CarrierNamesLanguagesForm formik={formik} nameLabel={t("carrierDetails.carrierServicesDrawer.shortName.label")} nameLabelPrefix="shortName" languages={languages} />
        <Divider orientation="horizontal" style={{ minHeight: customTheme.utils.pxToRem(24) }} />
        <Box component="div" p={1} />
        <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {t("carrierDetails.carrierServicesDrawer.chips.label")}
        </Typography>
        <CarrierChipsForm formik={formik} languages={languages} />
        <Box component="div" p={1} />
      </form>
    </Box>
  )
}

CarrierServiceDrawer.defaultProps = {
  carrierGeneralInfoCode: "",
  carrierServices: undefined,
  carrierServiceIndex: undefined,
  languages: undefined,
  onSubmitEvent: undefined,
  onDisableEvent: undefined,
}

CarrierServiceDrawer.propTypes = {
  carrierGeneralInfoCode: PropTypes.string,
  carrierServices: PropTypes.arrayOf(
    PropTypes.shape({
      chips: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
      code: PropTypes.string,
      billingCode: PropTypes.string,
      fullName: PropTypes.instanceOf(Object),
      shortName: PropTypes.instanceOf(Object),
      isDisabled: PropTypes.bool,
    })
  ),
  carrierServiceIndex: PropTypes.number,
  languages: PropTypes.instanceOf(Object),
  onSubmitEvent: PropTypes.instanceOf(Object),
  onDisableEvent: PropTypes.instanceOf(Object),
  setShowCarrierServicesDrawer: PropTypes.func.isRequired,
  setDisableEvent: PropTypes.func.isRequired,
}

export default CarrierServiceDrawer
