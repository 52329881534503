// REACT
import React, { useContext, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import { Button } from "@mui/material"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import DateRangeFilter from "../date-range-filter"
import OrderList from "../order-list"
import SearchInput from "../search-input"

import { useExportOrdersToCSVMutation, useGetOrdersQuery } from "../../services/order/slice"
import UserContext from "../../services/user/context"

function Orders() {
  const { t } = useTranslation("orders")
  const customTheme = useTheme()

  const [filteredOrders, setFilteredOrders] = useState()
  const [dataRangeFilterValues, setDataRangeFilterValues] = useState()
  const { currentUser } = useContext(UserContext)

  const { data: orders, isFetching } = useGetOrdersQuery({ from: dataRangeFilterValues?.from, to: dataRangeFilterValues?.to }, { skip: !dataRangeFilterValues })
  const [exportOrdersToCSV, { isLoading }] = useExportOrdersToCSVMutation()

  useEffect(() => {
    setFilteredOrders(orders)
  }, [orders])

  const handleOrdersExport = () => {
    if (dataRangeFilterValues?.from && dataRangeFilterValues?.to && currentUser?.emailAddress) {
      const fromDate = dataRangeFilterValues.from.split("T")[0]
      const toDate = dataRangeFilterValues.to.split("T")[0]
      const emailTo = currentUser.emailAddress
      exportOrdersToCSV({ fromDate, toDate, emailTo })
    }
  }

  return (
    <>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <CardHeader title={t("title")} />
      </Card>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <Box my={customTheme.utils.pxToThemeSpacing(20)} mx={customTheme.spacing(2)} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box component="div" width={1}>
            <SearchInput data={orders || []} setFilteredData={setFilteredOrders} width={0.75} excludeFields={["pk", "sk", "id", "entityType", "createdDate", "transitStatus", "user"]} />
          </Box>
          <Box component="div">
            <DateRangeFilter setDataRangeFilterValues={setDataRangeFilterValues} />
          </Box>
        </Box>
        {orders?.length > 0 && (
          <Box component="div" style={{ margin: 15 }}>
            <Button onClick={handleOrdersExport}>{t("orderHeaders.export.label")}</Button>
            {isLoading && <span style={{ marginLeft: 15 }}>{t("orderHeaders.export.message")}</span>}
          </Box>
        )}
      </Card>
      <Card variant="elevation" square>
        <OrderList orders={filteredOrders || []} maxRows={15} isFetching={isFetching} />
      </Card>
    </>
  )
}

export default Orders
