import { createSlice } from "@reduxjs/toolkit"

import { forgotPassword, forgotPasswordSubmit, signIn } from "./authenticatorReducer"

const initialState = {
  showAlert: false,
  severity: undefined,
  message: undefined,
}

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    createAlert: (state, action) => {
      state.showAlert = true
      state.severity = "warning"
      state.message = action.payload
    },
    clearAlert: () => initialState,
  },
  extraReducers: {
    [signIn.rejected]: (state, action) => {
      state.showAlert = true
      state.severity = "error"
      state.message = action.error.message
    },
    [forgotPassword.rejected]: (state, action) => {
      state.showAlert = true
      state.severity = "error"
      state.message = action.error.message
    },
    [forgotPasswordSubmit.rejected]: (state, action) => {
      state.showAlert = true
      state.severity = "error"
      state.message = action.error.message
    },
    "restApi/executeQuery/rejected": (state, action) => {
      if (action.payload) {
        state.showAlert = true
        state.severity = "error"
        state.message = action.payload.data ? action.payload.data.message : action.payload.error
      }
    },
    "graphqlApi/executeQuery/rejected": (state, action) => {
      if (action.payload) {
        state.showAlert = true
        state.severity = "error"
        state.message = action.payload.message
      }
    },
    "restApi/error/global": (state, action) => {
      if (action.payload) {
        state.showAlert = true
        state.severity = "error"
        state.message = action.payload.data ? action.payload.data.message : action.payload.error
      }
    },
  },
})

export const { clearAlert, createAlert } = alertSlice.actions

export default alertSlice.reducer
