import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"

import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

import ChargeMappingError from "./components/charge-mapping-error"
import ServiceMappingError from "./components/service-mapping-error"
import TranslationService from "../../../../services/translation"

import { AuditErrorEnum } from "../../constant"
import { pxToRem } from "../../../../soe-theme/src/typography"

function AuditError({ error, carrierCode }) {
  const { i18n, t } = useTranslation("audits")

  return (
    <Alert
      severity={error.severity || "error"}
      sx={{
        border: pxToRem(1),
        borderStyle: "solid",
        "& .MuiAlert-message": {
          width: 1,
        },
        "& .MuiAutocomplete-listbox": {
          fontSize: pxToRem(12),
        },
      }}
    >
      <AlertTitle>
        <b>{t(`auditErrorsEnum.${error.type}`)}</b>
      </AlertTitle>
      {TranslationService.getLabelFromLocale(i18n.language, error.message)}
      {error.type === AuditErrorEnum.SERVICE_MAPPING_ERROR && <ServiceMappingError error={error} carrierCode={carrierCode} />}
      {error.type === AuditErrorEnum.CHARGE_MAPPING_ERROR && <ChargeMappingError error={error} carrierCode={carrierCode} />}
    </Alert>
  )
}

AuditError.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string,
    billingCode: PropTypes.string,
    billingDescription: PropTypes.string,
    message: PropTypes.shape({}),
    severity: PropTypes.string,
  }).isRequired,
  carrierCode: PropTypes.string,
}

AuditError.defaultProps = {
  carrierCode: undefined,
}

export default AuditError
