// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

// Custom style
import { StyledTableCell } from "./style"

// Others
import PricingMargin from "../pricing-margin"

function PricingMargins({ pricingGroupId, pricingPlanId, serviceId, chargeTypeId, pricingMargins }) {
  const { t } = useTranslation("pricing")
  const chargeTypeColumnNames = [
    t("pricingMargins.fields.chargeCode.label"),
    t("pricingMargins.fields.chargeName.label"),
    t("pricingMargins.fields.marginPercentage.label"),
    t("pricingMargins.fields.marginAmount.label"),
    t("pricingMargins.fields.adminFeePercentage.label"),
    t("pricingMargins.fields.adminFeeAmount.label"),
    t("pricingMargins.fields.useDefault.label"),
  ]

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            {chargeTypeColumnNames.map((columnName, index) => (
              <StyledTableCell key={`${index.toString()}`} align="center">
                {columnName}
              </StyledTableCell>
            ))}
            <StyledTableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {pricingMargins &&
            pricingMargins.map((pricingMargin, index) => {
              return <PricingMargin key={"".concat(index)} pricingGroupId={pricingGroupId} pricingPlanId={pricingPlanId} serviceId={serviceId} chargeTypeId={chargeTypeId} pricingMargin={pricingMargin} />
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

PricingMargins.propTypes = {
  pricingGroupId: PropTypes.string.isRequired,
  pricingPlanId: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
  chargeTypeId: PropTypes.string.isRequired,
  pricingMargins: PropTypes.arrayOf(
    PropTypes.shape({
      adminFeeAmount: PropTypes.number,
      adminFeePercentage: PropTypes.number,
      chargeCode: PropTypes.string,
      chargeType: PropTypes.string,
      marginAmount: PropTypes.number,
      marginPercentage: PropTypes.number,
      serviceCode: PropTypes.string,
      useDefault: PropTypes.bool,
    })
  ).isRequired,
}

export default PricingMargins
