import AppConfig from "./app-config"

/**
 * @link https://create-react-app.dev/docs/adding-custom-environment-variables/
 */
export const isProd = () => {
  return AppConfig.app.nodeEnv === "production"
}

export const isDev = () => {
  return AppConfig.app.nodeEnv === "development"
}

export const isTest = () => {
  return AppConfig.app.nodeEnv === "test"
}
