// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { FieldArray, FormikProvider } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import Currency from "../../../../../../../currency"
import CurrencyService from "../../../../../../../../services/currency"
import RateRequestSummaryCard from "../../../../../../../rate-request-summary-card"

import { AuditItemSectionProps } from "../../../../../../../../models/audit"
import { AuditStatusEnum } from "../../../../../../constant"

function ClientDetailsAdjustedPrice({ formik, rate, auditStatus }) {
  const { i18n, t } = useTranslation("audits")
  const customTheme = useTheme()
  const [sumClientActualAmount, setSumClientActualAmount] = useState(rate.totals.total.client.actual.amount)

  const calculateAmount = (amount) => {
    const amountString = amount.toString().replace(/\s/g, "")
    return parseFloat(i18n.language.toLowerCase().startsWith("fr") ? amountString.replace(/,/g, ".") : amountString.replace(/,/g, ""))
  }

  const roundAmount = (value) => {
    const amount = calculateAmount(value)
    if (Number.isNaN(amount)) return 0.0
    return Math.round((amount + Number.EPSILON) * 100) / 100
  }

  const handleBlur = (index, event) => {
    const thisValue = calculateAmount(event.target.value)

    formik.setFieldValue(`rate.charges[${index}].client.actual.amount`, thisValue)
    const chargesWithoutTax = formik.values.rate?.charges?.filter((charge) => charge.type !== "tax")
    const chargesWithoutTaxToFloat = chargesWithoutTax.map((charge) => {
      return calculateAmount(charge?.client?.actual?.amount) || 0.0
    })

    const calculatedSubTotal = chargesWithoutTaxToFloat?.reduce((acc, chargeWithoutTax) => acc + (chargeWithoutTax ? calculateAmount(chargeWithoutTax) : 0.0), 0.0)
    const taxTotal = formik.values.rate?.charges?.filter((charge) => charge.type === "tax")?.reduce((acc, charge) => acc + (charge?.client?.actual?.amount ? calculateAmount(charge?.client?.actual?.amount) : 0.0), 0.0)

    formik.values.rate?.charges?.forEach((chargeTax, indexTax) => {
      if (chargeTax.type === "tax") {
        if (indexTax !== index && rate.charges[index].type !== "tax" && rate.charges[indexTax]?.client?.actual?.amount !== undefined) {
          const taxAmount = roundAmount(chargeTax.percentage * calculatedSubTotal)
          // taxField = true
          formik.setFieldValue(`rate.charges[${indexTax}].client.actual.amount`, taxAmount || 0.0)
        }
      }
    })

    const calculatedTotal = calculatedSubTotal + taxTotal
    setSumClientActualAmount(calculatedTotal)
    formik.handleBlur(event)
  }

  useEffect(() => {
    setSumClientActualAmount(rate.totals.total.client.actual.amount)
  }, [i18n.language])

  return (
    <RateRequestSummaryCard
      title={t("auditDetails.auditItemDetails.clientDetails.adjustedPrice.title")}
      backgroundColor={auditStatus !== AuditStatusEnum.INVOICED && auditStatus !== AuditStatusEnum.VALIDATED ? customTheme.palette.backgroundAlertPositive.main : customTheme.palette.contentNeutralPrimaryReverse.main}
      sx={{ height: 1 }}
    >
      <Box>
        <Stack direction="column">
          <Box>
            {rate && (
              <Box>
                <Grid container>
                  <Grid item xs={3} />
                  <Grid item xs={2} pr={customTheme.utils.pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right" }}>
                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.clientDetails.adjustedPrice.headers.actualPrice")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.clientDetails.adjustedPrice.headers.margins")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} pr={customTheme.utils.pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right" }}>
                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.clientDetails.adjustedPrice.headers.newPrice")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3} />
                  <Grid item xs={2} pb={customTheme.utils.pxToThemeSpacing(6)} pr={customTheme.utils.pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", borderBottom: 1 }}>
                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {`(${rate.totals.total.client.actual.currency})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", borderBottom: 1 }}>
                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", borderBottom: 1 }}>
                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      $
                    </Typography>
                  </Grid>
                  <Grid item xs={3} pr={customTheme.utils.pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", borderBottom: 1 }}>
                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {`(${rate.totals.total.client.actual.currency})`}
                    </Typography>
                  </Grid>
                </Grid>
                <FormikProvider value={formik}>
                  <FieldArray
                    name="rate.charges"
                    render={() => (
                      <Box component="div">
                        {formik.values?.rate?.charges?.map((charge, index) => {
                          return (
                            <Box key={"".concat(index)} my={customTheme.utils.pxToThemeSpacing(6)} sx={{ height: customTheme.utils.pxToRem(22) }}>
                              <Grid container>
                                <Grid item xs={3} />
                                <Grid item xs={2} pr={customTheme.utils.pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                                  {charge.carrierInvoice?.actual?.amount !== undefined && (
                                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                                      <Currency amount={charge.carrierInvoice.actual.amount * rate.fxRate} currency={charge.carrierInvoice.actual.currency} currencyPosition="none" showSymbol={false} />
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  {charge.client?.actualMargin?.marginPercentage !== undefined && (
                                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                                      {charge.client.actualMargin.marginPercentage} %
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  {charge.client?.actualMargin?.marginAmount !== undefined && (
                                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                                      <Currency amount={charge.client.actualMargin.marginAmount} currency={charge.client.actual.currency} currencyPosition="none" showSymbol={false} />
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item xs={3} pl={customTheme.utils.pxToThemeSpacing(2)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                                  {charge.client?.actual?.amount !== undefined &&
                                    (auditStatus !== AuditStatusEnum.INVOICED && auditStatus !== AuditStatusEnum.VALIDATED ? (
                                      <Typography noWrap variant="bodySmall400" sx={{ lineHeight: customTheme.utils.pxToThemeSpacing(4) }}>
                                        <TextField
                                          id={`rate.charges[${index}].client.actual.amount`}
                                          variant="outlined"
                                          size="small"
                                          value={CurrencyService.toLocaleString(i18n.language, formik.values.rate.charges[index].client.actual.amount, formik.values.rate.charges[index].client.actual.currency, false)}
                                          onChange={formik.handleChange}
                                          onBlur={(event) => {
                                            handleBlur(index, event)
                                          }}
                                          inputProps={{ style: { fontSize: customTheme.utils.pxToRem(12), lineHeight: customTheme.utils.pxToLineHeight(16, 22), textAlign: "right" } }}
                                          sx={(theme) => ({ backgroundColor: theme.palette.contentNeutralPrimaryReverse.main, "& .MuiOutlinedInput-input": { height: theme.utils.pxToRem(4) } })}
                                        />
                                      </Typography>
                                    ) : (
                                      <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22), marginRight: theme.utils.pxToThemeSpacing(10) })}>
                                        <Currency amount={calculateAmount(formik.values.rate.charges[index].client?.actual.amount)} currency={charge.carrierInvoice?.actual.currency} currencyPosition="none" showSymbol={false} />
                                      </Typography>
                                    ))}
                                </Grid>
                              </Grid>
                            </Box>
                          )
                        })}
                      </Box>
                    )}
                  />
                </FormikProvider>
                <Grid container mt={customTheme.utils.pxToThemeSpacing(6)}>
                  <Grid item xs={3} />
                  <Grid item xs={2}>
                    <Box sx={{ borderTop: 1 }} />
                  </Grid>
                  <Grid item xs={4}>
                    <Box pt={customTheme.utils.pxToThemeSpacing(2)} sx={{ display: "flex", justifyContent: "center", borderTop: 1 }}>
                      <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                        {`${t("auditDetails.auditItemDetails.clientDetails.adjustedPrice.fxRate")} `}
                        {rate.fxRate.toLocaleString(i18n.language, { minimumFractionDigits: 4 })}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box sx={{ borderTop: 1 }} />
                  </Grid>
                </Grid>
                <Grid container mt={customTheme.utils.pxToThemeSpacing(6)}>
                  <Grid item xs={3} />
                  <Grid item xs={2} pr={customTheme.utils.pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right" }}>
                    {rate.totals?.total?.carrierInvoice?.actual?.amount !== undefined && (
                      <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                        <Currency amount={rate.totals.total.carrierInvoice.actual.amount * rate.fxRate} currency={rate.totals.total.carrierInvoice.actual.currency} currencyPosition="none" showSymbol={false} />
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} sx={{ borderTop: 1, display: "flex", justifyContent: "right" }}>
                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })} />
                  </Grid>
                  <Grid item xs={3} pr={customTheme.utils.pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right" }}>
                    {rate.totals?.total?.client?.actual?.amount !== undefined && (
                      <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                        <Currency amount={sumClientActualAmount} currency={rate.totals.total.client.actual.currency} currencyPosition="none" showSymbol={false} />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </RateRequestSummaryCard>
  )
}

ClientDetailsAdjustedPrice.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  rate: AuditItemSectionProps.isRequired,
  auditStatus: PropTypes.string,
}

ClientDetailsAdjustedPrice.defaultProps = {
  auditStatus: undefined,
}

export default ClientDetailsAdjustedPrice
