import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "CarrierDetailsCard", slot: "Root" })(() => ({}))

export const CustomChip = styled(Chip, { label: "CustomChip" })(({ theme }) => ({
  backgroundColor: theme.palette.contentAlertPositive.main,
  color: theme.palette.backgroundNeutralGround.main,
  textTransform: "capitalize",
  borderRadius: "8px",
  marginRight: theme.spacing(theme.utils.pxToThemeSpacing(8)),
  marginTop: theme.spacing(theme.utils.pxToThemeSpacing(6)),
}))
