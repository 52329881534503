import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import React from "react"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import AccountDetailsExpectedPrice from "../account-details-expected-price"
import AccountDetailsHeader from "../account-details-header"
import CarrierDetailsHeader from "../carrier-details-header"
import CarrierDetailsInvoiceItem from "../carrier-details-invoice-item"
import ClientDetailsAdjustedPrice from "../client-details-adjusted-price"
import ClientDetailsHeader from "../client-details-header"
import ClientDetailsOrderPrice from "../client-details-order-price"
import CurrencyService from "../../../../../../../../services/currency"
import PackagesCard from "../../../../../../../packages-card"
import RecipientCard from "../../../../../../../recipient-card"
import ServiceCard from "../service-card"
import ShipperCard from "../../../../../../../shipper-card"

import { AuditItemProps, AuditProps } from "../../../../../../../../models/audit"
import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"
import { useDeleteAuditItemsManualUnmatchOrderMutation, useUnvalidateAuditItemDetailsMutation, useValidateAuditItemDetailsMutation } from "../../../../../../../../reducers/auditsReducer"
import { AuditStatusEnum } from "../../../../../../constant"

function AuditItemDetailsDiplay({ audit, auditItemDetails }) {
  const { i18n, t } = useTranslation("audits")
  const navigate = useNavigate()

  const [validateAuditItemDetails, { isLoading: validateLoading }] = useValidateAuditItemDetailsMutation()
  const [unvalidateAuditItemDetails, { isLoading: unvalidateLoading }] = useUnvalidateAuditItemDetailsMutation()
  const [deleteAuditItemsManualUnmatchOrder, { isLoading: unmatchLoading }] = useDeleteAuditItemsManualUnmatchOrderMutation()

  const calculateAmount = (amount) => {
    const amountString = amount.toString().replace(/\s/g, "")
    return parseFloat(i18n.language.toLowerCase().startsWith("fr") ? amountString.replace(/,/g, ".") : amountString.replace(/,/g, ""))
  }

  const formik = useFormik({
    initialValues: {
      rate: {
        ...auditItemDetails.rate,
        charges: auditItemDetails.rate.charges.map((charge) => {
          return {
            ...charge,
            client: {
              ...charge.client,
              ...(charge.client.actual && { actual: { ...charge.client.actual, amount: CurrencyService.toLocaleString(i18n.language, charge.client.actual.amount, charge.client.actual.currency, false) } }),
            },
          }
        }),
      },
      note: auditItemDetails.note,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate: () => {},
    onSubmit: async (formValues) => {
      const charges = formValues.rate.charges.map((charge) => {
        return {
          ...charge,
          client: {
            ...charge.client,
            ...(charge.client.actual && { actual: { ...charge.client.actual, amount: calculateAmount(charge.client.actual.amount).toFixed(2) } }),
          },
        }
      })
      await validateAuditItemDetails({ auditId: audit.id, auditItemId: auditItemDetails.id, orderId: auditItemDetails.order.id, payload: { ...formValues, rate: { ...formValues.rate, charges } } })
      navigate(`/audits/${audit.id}`, { replace: true })
    },
  })

  const handleValidateClick = () => {
    formik.handleSubmit()
  }

  const handleUnvalidateClick = () => {
    unvalidateAuditItemDetails({ auditId: audit.id, auditItemId: auditItemDetails.id, orderId: auditItemDetails.order.id })
  }

  const handleUnmatchClick = () => {
    deleteAuditItemsManualUnmatchOrder({ auditId: audit.id, auditItemId: auditItemDetails.id, orderId: auditItemDetails.order.id })
  }

  return (
    <Grid container spacing={pxToThemeSpacing(4)} alignItems="stretch">
      <Grid item xs={12}>
        <Grid container spacing={pxToThemeSpacing(10)} alignItems="stretch">
          <Grid item xs={3}>
            <CarrierDetailsHeader carrier={audit.carrier} tracking={auditItemDetails.tracking.carrierInvoice.actual} service={auditItemDetails.service.carrierInvoice.actual} shippingOptions={auditItemDetails.shippingOptions.carrierInvoice.actual} />
          </Grid>
          <Grid item xs={3}>
            <AccountDetailsHeader auditItemDetails={auditItemDetails} />
          </Grid>
          <Grid item xs={6}>
            <ClientDetailsHeader
              order={auditItemDetails.order}
              user={auditItemDetails.user || auditItemDetails.company}
              shippingOptions={auditItemDetails.shippingOptions.client.declared}
              auditStatus={auditItemDetails.auditStatus}
              handleValidateClick={handleValidateClick}
              handleUnvalidateClick={handleUnvalidateClick}
              handleUnmatchClick={handleUnmatchClick}
              validateLoading={validateLoading}
              unvalidateLoading={unvalidateLoading}
              unmatchLoading={unmatchLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={pxToThemeSpacing(10)} alignItems="stretch">
          <Grid item xs={3}>
            <ServiceCard service={auditItemDetails.service.carrierInvoice.actual} />
          </Grid>
          <Grid item xs={3}>
            <ServiceCard service={auditItemDetails.service.account.declared} />
          </Grid>
          <Grid item xs={3}>
            <ServiceCard service={auditItemDetails.service.client.declared} />
          </Grid>
          <Grid item xs={3}>
            <ServiceCard service={auditItemDetails.service.client.actual} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={pxToThemeSpacing(10)} alignItems="stretch">
          <Grid item xs={3}>
            <CarrierDetailsInvoiceItem rate={auditItemDetails.rate} />
          </Grid>
          <Grid item xs={3}>
            <AccountDetailsExpectedPrice rate={auditItemDetails.rate} />
          </Grid>
          <Grid item xs={3}>
            <ClientDetailsOrderPrice rate={auditItemDetails.rate} />
          </Grid>
          <Grid item xs={3}>
            <ClientDetailsAdjustedPrice formik={formik} rate={auditItemDetails.rate} auditStatus={auditItemDetails.auditStatus} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={pxToThemeSpacing(10)} alignItems="stretch">
          <Grid item xs={3}>
            <ShipperCard shipper={auditItemDetails.shipper.carrierInvoice.actual} />
          </Grid>
          <Grid item xs={3}>
            <ShipperCard shipper={auditItemDetails.shipper.account.declared} />
          </Grid>
          <Grid item xs={3}>
            <ShipperCard shipper={auditItemDetails.shipper.client.declared} />
          </Grid>
          <Grid item xs={3}>
            <ShipperCard shipper={auditItemDetails.shipper.client.actual} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={pxToThemeSpacing(10)} alignItems="stretch">
          <Grid item xs={3}>
            <RecipientCard recipient={auditItemDetails.recipient.carrierInvoice.actual} />
          </Grid>
          <Grid item xs={3}>
            <RecipientCard recipient={auditItemDetails.recipient.account.declared} />
          </Grid>
          <Grid item xs={3}>
            <RecipientCard recipient={auditItemDetails.recipient.client.declared} />
          </Grid>
          <Grid item xs={3}>
            <RecipientCard recipient={auditItemDetails.recipient.client.actual} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={pxToThemeSpacing(10)} alignItems="stretch">
          <Grid item xs={3}>
            <PackagesCard packages={auditItemDetails.packages.carrierInvoice.actual} />
          </Grid>
          <Grid item xs={3}>
            <PackagesCard packages={auditItemDetails.packages.account.declared} />
          </Grid>
          <Grid item xs={3}>
            <PackagesCard packages={auditItemDetails.packages.client.declared} />
          </Grid>
          <Grid item xs={3}>
            <PackagesCard packages={auditItemDetails.packages.client.actual} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={pxToThemeSpacing(10)} alignItems="stretch">
          <Grid item xs={3}>
            <Card sx={{ height: 1 }} />
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ height: 1 }} />
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ height: 1, p: pxToThemeSpacing(10) }}>
              {auditItemDetails.auditStatus !== AuditStatusEnum.INVOICED && auditItemDetails.auditStatus !== AuditStatusEnum.VALIDATED ? (
                <TextField
                  id="note"
                  variant="outlined"
                  label={t("auditDetails.auditItemDetails.fields.note.label")}
                  size="small"
                  placeholder={t("auditDetails.auditItemDetails.fields.note.placeHolder")}
                  value={formik.values.note || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <Box>
                  {auditItemDetails.note && (
                    <Typography noWrap variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {auditItemDetails.note}
                    </Typography>
                  )}
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

AuditItemDetailsDiplay.propTypes = {
  audit: AuditProps.isRequired,
  auditItemDetails: AuditItemProps.isRequired,
}

export default AuditItemDetailsDiplay
