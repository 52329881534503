// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Others
import { useEditClientDetailsMutation, useChangeUserEmailMutation } from "../../../../../../slice"

function ClientEmailDrawer({ email, onSubmitEvent, setShowClientEmailDrawer }) {
  const { t } = useTranslation("clients")

  const [handleChangeUserEmail] = useChangeUserEmailMutation()
  const [editUserProfile] = useEditClientDetailsMutation()

  const formik = useFormik({
    initialValues: {
      oldEmail: email,
      newEmail: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.oldEmail) {
        errors.oldEmail = t("clientDetails.clientDetailsInformations.clientEmailDrawer.changeEmail.fields.oldEmail.error.required")
      }
      if (!formValues.newEmail) {
        errors.newEmail = t("clientDetails.clientDetailsInformations.clientEmailDrawer.changeEmail.fields.newEmail.error.required")
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      handleChangeUserEmail(formValues).then((response) => {
        if (!response.error) setShowClientEmailDrawer(false)

        editUserProfile({ clientId: formValues.oldEmail, payload: { emailAddress: formValues.newEmail } })
      })
    },
  })


  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Typography variant="bodyMedium600" pb={1} gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {t("clientDetails.clientDetailsInformations.clientEmailDrawer.changeEmail.fields.accountEmail.label")}
        </Typography>
        <Box component="div" py={2}>
          <TextField
            id="oldEmail"
            label={t("clientDetails.clientDetailsInformations.clientEmailDrawer.changeEmail.fields.oldEmail.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldEmail}
            error={formik.touched.oldEmail && formik.errors.oldEmail !== undefined}
            helperText={(formik.touched.oldEmail && formik.errors.oldEmail) || " "}
            variant="outlined"
            size="small"
            type="text"
            required
            disabled
          />
        </Box>
        <Box component="div">
          <TextField
            id="newEmail"
            label={t("clientDetails.clientDetailsInformations.clientEmailDrawer.changeEmail.fields.newEmail.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newEmail}
            error={formik.touched.newEmail && formik.errors.newEmail !== undefined}
            helperText={(formik.touched.newEmail && formik.errors.newEmail) || " "}
            variant="outlined"
            size="small"
            type="text"
            required
          />
        </Box>
      </form>
    </Box>
  )
}

ClientEmailDrawer.defaultProps = {
  onSubmitEvent: undefined,
  email: "",
}

ClientEmailDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowClientEmailDrawer: PropTypes.func.isRequired,
  email: PropTypes.string
}

export default ClientEmailDrawer
