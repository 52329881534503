import restApiSlice from "../../services/rest-api/slice"
import { AddressSearchGroupsText } from "../../constant"

export const fullAddressFormRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    validateAddress: builder.mutation({
      query: (payload) => ({ url: `/addresses/validate`, method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
    getAddresses: builder.query({
      query: (params) => ({ url: "/addresses/autocomplete", method: "GET", params: { ...params } }),
      transformResponse: (response) => {
        if (Array.isArray(response.data)) {
          response.data.forEach((entry) => {
            entry.origin = AddressSearchGroupsText.CanadaPost
          })
          return response
        }
        return response
      },
    }),
  }),
})

export const { useValidateAddressMutation, useGetAddressesQuery } = fullAddressFormRestApiSlice
