// REACT
import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

// MUI
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import SignOut from "../../../authenticator/components/sign-out"

function AppBarMenu({ open, onClose, anchorEl }) {
  const { t } = useTranslation("appBar")
  const navigate = useNavigate()

  const handleUserMenuClose = () => {
    onClose(true)
  }

  const handleProfileClick = () => {
    handleUserMenuClose()
    navigate("/user-profile", { replace: true })
  }

  return (
    <Box component="div">
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleUserMenuClose}
      >
        <MenuItem onClick={() => handleProfileClick()}>{t("userMenu.profile")}</MenuItem>
        <SignOut
          setIsMenuOpen={(isMenuOpen) => {
            if (!isMenuOpen) {
              handleUserMenuClose()
            }
          }}
        />
      </Menu>
    </Box>
  )
}

AppBarMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Object),
}

AppBarMenu.defaultProps = {
  anchorEl: undefined,
}

export default AppBarMenu
