/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/container/
 * @link https://mui.com/api/container/
 */

export default function Container() {
  return {
    MuiContainer: {
      defaultProps: {},
      styleOverrides: {},
    },
  }
}
