// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Custom theme
import CustomChip from "./style"

// Others
import CarrierDetailsSummaryCard from "../carrier-details-summary-card"
import TranslationService from "../../../../services/translation"
import { SummaryStatusVariantsEnum } from "../carrier-details-summary-card/components/summary-status"

function CarrierServiceCard({ carrierService, handleCarrierServiceCardClick }) {
  const { i18n, t } = useTranslation("carriers")

  const getEmptyValue = (element) => {
    return Object.keys(element).filter((key) => element[key].trim() === "")
  }

  const getSummaryStatus = () => {
    if (carrierService && carrierService.isDisabled && carrierService.isDisabled === true) return SummaryStatusVariantsEnum.disabled
    if (
      !carrierService === undefined ||
      carrierService.isNew === true ||
      (carrierService.fullName && getEmptyValue(carrierService.fullName).length > 0) ||
      (carrierService.shortName && getEmptyValue(carrierService.shortName).length > 0) ||
      (carrierService.chips && carrierService.chips.some((chip) => getEmptyValue(chip).length > 0))
    )
      return SummaryStatusVariantsEnum.warning
    return SummaryStatusVariantsEnum.success
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <CarrierDetailsSummaryCard
      title={TranslationService.getLabelFromLocale(i18n.language, carrierService.fullName)}
      handleClick={handleCarrierServiceCardClick}
      summaryStatusProps={summaryStatusProps}
      isDisabled={carrierService && carrierService.isDisabled === true}
    >
      {carrierService && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {carrierService.code && (
            <Typography noWrap sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("carrierDetails.carrierServicesCard.carrierCode.label")}: {carrierService.code}
            </Typography>
          )}
          {carrierService.billingCode && (
            <Typography noWrap sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("carrierDetails.carrierServicesCard.billingCode.label")}: {carrierService.billingCode}
            </Typography>
          )}
          {carrierService.shortName && (
            <Typography noWrap sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("carrierDetails.carrierServicesCard.shortName.label")}: {TranslationService.getLabelFromLocale(i18n.language, carrierService.shortName)}
            </Typography>
          )}
          <Box sx={{ display: "flex-inline", flexDirection: "column" }}>
            {carrierService.isNew && <CustomChip label={t("carriersManagement.chipStatus.new.label")} size="small" sx={(theme) => ({ backgroundColor: theme.palette.contentAlertError.main })} />}
            {carrierService.chips && carrierService.chips.map((chip, index) => <CustomChip key={"chip".concat(index.toString())} label={TranslationService.getLabelFromLocale(i18n.language, chip)} size="small" />)}
          </Box>
        </Box>
      )}
    </CarrierDetailsSummaryCard>
  )
}

CarrierServiceCard.propTypes = {
  carrierService: PropTypes.shape({
    code: PropTypes.string,
    billingCode: PropTypes.string,
    chips: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    fullName: PropTypes.shape({}),
    shortName: PropTypes.shape({}),
    isNew: PropTypes.bool,
    isDisabled: PropTypes.bool,
  }).isRequired,
  handleCarrierServiceCardClick: PropTypes.func,
}

CarrierServiceCard.defaultProps = {
  handleCarrierServiceCardClick: () => {},
}

export default CarrierServiceCard
