import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import { styled } from "@mui/material/styles"

export const CustomContainer = styled(Container, { label: "CustomContainer", slot: "Root" })(() => ({
  height: "100%",
}))

export const CustomPageBox = styled(Box, { name: "CustomPageBox", slot: "Root" })(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}))

export const CustomOutletBox = styled(Box, { name: "OutletBox", slot: "Root" })(() => ({
  flewGrow: 1,
  height: "100%",
  width: "100%",
}))

export const CustomAuthContainerBox = styled(Box, { name: "AuthContainerBox", slot: "Root" })(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}))

export const CustomMiniDrawerContainer = styled(Box, { name: "CustomMiniDrawerContainer", slot: "Root" })(() => ({
  display: "flex",
  height: "100%",
  flewGrow: 1,
}))
