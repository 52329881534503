import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import React from "react"
import Stack from "@mui/material/Stack"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import BusinessLogo from "../../../../../../soe-theme/src/components/business-logo"
import CarrierServiceLogo from "../../../../../carrier-service-logo"

import { pxToRem, pxToThemeSpacing } from "../../../../../../soe-theme/src/utils"

function AuditItemsHeader({ carrierLogo }) {
  const { t } = useTranslation("audits")
  const theme = useTheme()

  return (
    <TableRow component="tr">
      <TableCell sx={{ border: "none" }}>
        <Grid container spacing={pxToThemeSpacing(10)}>
          <Grid item xs={3}>
            <Card variant="outlined" square sx={{ height: 1 }}>
              <Box p={pxToThemeSpacing(20)}>
                <Grid container>
                  <Grid item xs={12}>
                    {carrierLogo && <CarrierServiceLogo logo={carrierLogo} align="left" />}
                  </Grid>
                </Grid>
                <Stack mt={pxToThemeSpacing(10)}>
                  <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                    <b>{t("auditDetails.auditItemsHeader.trackingNumber")}</b>
                  </Typography>
                  <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
                    <b>{t("auditDetails.auditItemsHeader.shippingDate")}</b>
                  </Typography>
                </Stack>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={1}>
            <Card variant="outlined" square sx={{ height: 1 }} />
          </Grid>
          <Grid item xs={3}>
            <Card variant="outlined" square sx={{ height: 1 }}>
              <Box p={pxToThemeSpacing(20)}>
                <Grid container>
                  <Grid item xs={12}>
                    <BusinessLogo />
                  </Grid>
                </Grid>
                <Stack mt={pxToThemeSpacing(20)}>
                  <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                    <b>{t("auditDetails.auditItemsHeader.orderNumber")}</b>
                  </Typography>
                  <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
                    <b>{t("auditDetails.auditItemsHeader.shippingDate")}</b>
                  </Typography>
                </Stack>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card variant="outlined" square sx={{ height: 1 }}>
              <Box p={pxToThemeSpacing(20)}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22), fontSize: pxToRem(20) }} variant="bodySmall400" gutterBottom>
                      <b>{t("auditDetails.auditItemsHeader.clients")}</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Stack mt={pxToThemeSpacing(20)}>
                  <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                    <b>{t("auditDetails.auditItemsHeader.companyName")}</b>
                  </Typography>
                  <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
                    <b>{t("auditDetails.auditItemsHeader.personName")}</b>
                  </Typography>
                </Stack>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card variant="outlined" square sx={{ height: 1 }} />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

AuditItemsHeader.propTypes = {
  carrierLogo: PropTypes.string,
}

AuditItemsHeader.defaultProps = {
  carrierLogo: undefined,
}

export default AuditItemsHeader
