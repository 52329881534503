import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "SoeDrawerActions", slot: "Root" })(({ theme }) => ({
  width: "100%",
  minHeight: theme.utils.pxToRem(66),
  borderTop: `1px solid ${theme.palette.strokeDefault.main}`,
  display: "inline-flex",
  justifyContent: "center",
  overflow: "hidden",
}))

export const CustomBox = styled(Box, { name: "CustomBox", slot: "Root" })(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.utils.pxToRem(12),
}))
