import React, { useRef } from "react"
import PropTypes from "prop-types"

import MuiWrapper from "./style"

function SoeDrawerContent({ children, drawerScrollToTop, ...muiBoxProps }) {
  const ref = useRef()

  const scrollToTop = (reference) => {
    const elem = reference.current.scrollTop
    if (elem > 0) {
      reference.current.scrollTop = 0
    }
  }

  return (
    <MuiWrapper component="section" {...muiBoxProps} ref={ref}>
      {children}
      {drawerScrollToTop && scrollToTop(ref)}
    </MuiWrapper>
  )
}

SoeDrawerContent.propTypes = {
  children: PropTypes.node,
  drawerScrollToTop: PropTypes.bool,
}

SoeDrawerContent.defaultProps = {
  children: undefined,
  drawerScrollToTop: false,
}

export default SoeDrawerContent
