import PropTypes from "prop-types"
import { PackagesProps } from "../rate-request"

export const AuditProps = PropTypes.shape({
  id: PropTypes.string,
  createdDate: PropTypes.string,
  filename: PropTypes.string,
  carrier: PropTypes.shape({
    code: PropTypes.string,
    logo: PropTypes.string,
  }),
  invoice: PropTypes.shape({
    date: PropTypes.string,
    number: PropTypes.string,
    accountNumber: PropTypes.string,
  }),
  isRetryable: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  status: PropTypes.string,
})

export const AuditItemSectionProps = PropTypes.shape({
  service: PropTypes.shape({
    actual: PropTypes.shape({
      fullName: PropTypes.shape({}),
    }),
  }),
  shippingOptions: PropTypes.shape({
    actual: PropTypes.shape({
      shippingDate: PropTypes.string,
    }),
  }),
  entityType: PropTypes.string,
  shipper: PropTypes.shape({
    actual: PropTypes.shape({
      companyName: PropTypes.string,
      personName: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      provinceCode: PropTypes.string,
      countryCode: PropTypes.string,
      postalCode: PropTypes.string,
    }),
  }),
  rate: PropTypes.shape({
    actual: PropTypes.shape({
      charges: PropTypes.arrayOf(
        PropTypes.shape({
          invoiced: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
          name: PropTypes.shape({}),
        })
      ),
      totals: PropTypes.shape({
        invoice: PropTypes.shape({
          total: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
  recipient: PropTypes.shape({
    actual: PropTypes.shape({
      companyName: PropTypes.string,
      personName: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      provinceCode: PropTypes.string,
      countryCode: PropTypes.string,
      postalCode: PropTypes.string,
    }),
  }),
  tracking: PropTypes.shape({
    actual: PropTypes.shape({
      masterId: PropTypes.string,
    }),
  }),
  packages: PropTypes.shape({
    account: {
      declared: PackagesProps,
    },
    carrier: {
      actual: PackagesProps,
    },
    client: {
      actual: PackagesProps,
      declared: PackagesProps,
    },
  }),
})

export const AuditItemProps = PropTypes.shape({
  id: PropTypes.string,
  carrier: AuditItemSectionProps,
  account: AuditItemSectionProps,
  client: AuditItemSectionProps,
})
