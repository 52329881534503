// REACT
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Skeleton from "@mui/material/Skeleton"

// Others
import CarrierManagement from "./components/carrier-management"

import { useGetCarriersQuery } from "./slice"

function Carriers() {
  const { t } = useTranslation("carriers")

  const { data: carriers, isFetching } = useGetCarriersQuery()

  return (
    <Box component="div">
      <Card variant="elevation" square>
        <CardHeader title={t("carriersManagement.title")} />
      </Card>
      <Card variant="elevation" square>
        {isFetching ? <Skeleton variant="rectangular" width="100%" height={100} /> : carriers && carriers.map((carrier, index) => <CarrierManagement key={`${index.toString()}`} carrier={carrier} />)}
      </Card>
    </Box>
  )
}

export default Carriers
