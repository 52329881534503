// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import { Grid } from "@mui/material"
import Box from "@mui/material/Box"
import Masonry from "@mui/lab/Masonry"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom theme
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../../soe-theme/src/components"

// Others
import CompanyBillingCard from "./components/company-billing-card"
import CompanyBillingDrawer from "./components/company-billing-drawer"
import CompanyBillingContactCard from "./components/company-billing-contact-card"
import CompanyBillingContactDrawer from "./components/company-billing-contact-drawer"
import CompanyContactInformationCard from "./components/company-contact-information-card"
import CompanyContactInformationDrawer from "./components/company-contact-information-drawer"
import CompanyPricingGroupCard from "./components/company-pricing-group-card"
import CompanyPricingGroupDrawer from "./components/company-pricing-group-drawer"
import CompanyInexFeesCard from "./components/company-inex-fees-card"
import CompanyInexFeesDrawer from "./components/company-inex-fees-drawer"
import CompanyFeatureFlagsCard from "./components/company-feature-flags-card"
import CompanyFeatureFlagsDrawer from "./components/company-feature-flags-drawer"

import { useGetPricingGroupsQuery } from "../../../../../../pricing/slice"
import { useGetInexFeesByCompanyIdQuery } from "../../../../../../../services/inex-fees/slice"
import AddCreditToCompanyDrawer from "./components/add-credit-to-company-drawer"
import CompanyAddCreditCard from "./components/company-add-credit-card"

function CompanyDetailsInformations({ company, refetchCompany }) {
  const { t } = useTranslation("companies")
  const theme = useTheme()

  const [showCompanyContactInformationDrawer, setShowCompanyContactInformationDrawer] = useState(false)
  const [showCompanyPricingGroupDrawer, setShowCompanyPricingGroupDrawer] = useState(false)
  const [showCompanyBillingContactDrawer, setShowCompanyBillingContactDrawer] = useState(false)
  const [showCompanyBillingDrawer, setShowCompanyBillingDrawer] = useState(false)
  const [showCreditDrawer, setShowCreditDrawer] = useState(false)
  const [showCompanyInexFeesDrawer, setShowCompanyInexFeesDrawer] = useState(false)
  const [showCompanyFeatureFlagsDrawer, setShowCompanyFeatureFlagsDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()

  const { data: pricingGroups } = useGetPricingGroupsQuery()
  const { data: inexFee, refetch: refetchInexFee } = useGetInexFeesByCompanyIdQuery(company.id)

  const handleCompanyContactInformationCardClick = () => {
    setSubmitEvent()
    setShowCompanyContactInformationDrawer(true)
  }

  const handleCompanyPricingGroupCardClick = () => {
    setSubmitEvent()
    setShowCompanyPricingGroupDrawer(true)
  }

  const handleCompanyAddCreditCardClick = () => {
    setSubmitEvent()
    setShowCreditDrawer(true)
  }

  const handleCompanyBillingContactCardClick = () => {
    setSubmitEvent()
    setShowCompanyBillingContactDrawer(true)
  }

  const handleCompanyInexFeeCardClick = () => {
    setSubmitEvent()
    setShowCompanyInexFeesDrawer(true)
  }

  const handleCompanyBillingCardClick = () => {
    setSubmitEvent()
    setShowCompanyBillingDrawer(true)
  }

  const handleCompanyFeatureFlagsClick = () => {
    setSubmitEvent()
    setShowCompanyFeatureFlagsDrawer(true)
  }

  const handleSetDefaultTag = () => {
    let companyPricingGroup
    let companyPricingGroupDefault
    if (pricingGroups) {
      const customerPricingGroup = company.pricingGroupCode
      if (customerPricingGroup) companyPricingGroup = pricingGroups?.find((pricingGroup) => pricingGroup?.code === customerPricingGroup)
      else companyPricingGroupDefault = `${t("companyDetails.companyDetailsInformations.companyPricingGroup.default.label")}${" ("}${pricingGroups?.find((pricingGroup) => pricingGroup?.isDefault === true)?.name}${")"}`
      if (companyPricingGroupDefault) return <Typography>{companyPricingGroupDefault}</Typography>
      return <Typography>{companyPricingGroup?.name}</Typography>
    }
    return undefined
  }

  return (
    <Box component="div">
      <Box display="flex" pt={theme.utils.pxToThemeSpacing(20)} pr={theme.utils.pxToThemeSpacing(10)} pb={theme.utils.pxToThemeSpacing(20)} pl={theme.utils.pxToThemeSpacing(10)}>
        <Box component="div">
          <Typography variant="headingH5" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {t("companyDetails.companyDetailsInformations.title")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" pl={theme.utils.pxToThemeSpacing(6)}>
        <Masonry columns={4} spacing={theme.utils.pxToThemeSpacing(6)}>
          <CompanyContactInformationCard clientAddress={company.clientAddress || {}} companyCreatedDate={company.createdDate} defaultToIconSuccessStatus handleCompanyContactInformationCardClick={() => handleCompanyContactInformationCardClick()} />
          <Grid>
            <CompanyPricingGroupCard
              title={t("companyDetails.companyDetailsInformations.pricingGroupCardTitle")}
              pricingGroupCode={company.pricingGroupCode}
              pricingGroups={pricingGroups}
              pricingGroupName={handleSetDefaultTag()}
              defaultToIconSuccessStatus
              handleCompanyPricingGroupCardClick={() => handleCompanyPricingGroupCardClick()}
            />
            <div style={{ height: "10px" }}> </div>
            <CompanyAddCreditCard title={t("companyDetails.companyDetailsInformations.accountCredits")} company={company} defaultToIconSuccessStatus handleCompanyAddCreditCardClick={() => handleCompanyAddCreditCardClick()} />
          </Grid>
          <Grid>
            <CompanyBillingContactCard address={company?.billing?.address} defaultToIconSuccessStatus handleCompanyBillingContactCardClick={handleCompanyBillingContactCardClick} />
            <div style={{ height: "10px" }}> </div>
            <CompanyInexFeesCard inexFee={inexFee} defaultToIconSuccessStatus handleCompanyInexFeeCardClick={handleCompanyInexFeeCardClick} />
          </Grid>
          <Grid>
            <CompanyBillingCard billing={company.billing || {}} defaultToIconSuccessStatus handleCompanyBillingCardClick={() => handleCompanyBillingCardClick()} />
            <CompanyFeatureFlagsCard company={company} defaultToIconSuccessStatus handleCompanyFeatureFlagsClick={handleCompanyFeatureFlagsClick} />
          </Grid>
        </Masonry>
      </Box>

      {/* Contact form drawer */}
      <SoeDrawer showDrawer={showCompanyContactInformationDrawer} setShowDrawer={setShowCompanyContactInformationDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("companyDetails.companyDetailsInformations.companyContactInformationDrawer.title")}
            setShowDrawer={() => {
              setShowCompanyContactInformationDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CompanyContactInformationDrawer refetchCompany={refetchCompany} company={company} onSubmitEvent={onSubmitEvent} setSubmitEvent={setSubmitEvent} setShowCompanyContactInformationDrawer={setShowCompanyContactInformationDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("companyDetails.companyDetailsInformations.companyContactInformationDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      {/* Pricing group form drawer */}
      <SoeDrawer showDrawer={showCompanyPricingGroupDrawer} setShowDrawer={setShowCompanyPricingGroupDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("companyDetails.companyDetailsInformations.companyPricingGroupDrawer.title")}
            setShowDrawer={() => {
              setShowCompanyPricingGroupDrawer(false)
            }}
          />
          <SoeDrawerContent>
            {pricingGroups && (
              <CompanyPricingGroupDrawer
                company={company}
                pricingGroups={pricingGroups}
                refetchCompany={refetchCompany}
                selectedPricingGroup={pricingGroups?.find((pricingGroup) => pricingGroup.code === company.pricingGroupCode)}
                onSubmitEvent={onSubmitEvent}
                setSubmitEvent={setSubmitEvent}
                setShowCompanyPricingGroupDrawer={setShowCompanyPricingGroupDrawer}
              />
            )}
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("companyDetails.companyDetailsInformations.companyPricingGroupDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      {/* Billing contact form drawer */}
      <SoeDrawer showDrawer={showCompanyBillingContactDrawer} setShowDrawer={setShowCompanyBillingContactDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("companyDetails.companyDetailsInformations.companyBillingContactDrawer.title")}
            setShowDrawer={() => {
              setShowCompanyBillingContactDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CompanyBillingContactDrawer refetchCompany={refetchCompany} company={company} onSubmitEvent={onSubmitEvent} setShowCompanyBillingContactDrawer={setShowCompanyBillingContactDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("companyDetails.companyDetailsInformations.companyBillingContactDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      {/* Inex Fees form drawer */}
      <SoeDrawer showDrawer={showCompanyInexFeesDrawer} setShowDrawer={setShowCompanyInexFeesDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title="Add an Inex Fee"
            setShowDrawer={() => {
              setShowCompanyInexFeesDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CompanyInexFeesDrawer refetchInexFee={refetchInexFee} company={company} inexFee={inexFee} onSubmitEvent={onSubmitEvent} setShowCompanyInexFeesDrawer={setShowCompanyInexFeesDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("companyDetails.companyDetailsInformations.companyBillingContactDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      {/* Feature Flags form drawer */}
      <SoeDrawer showDrawer={showCompanyFeatureFlagsDrawer} setShowDrawer={setShowCompanyFeatureFlagsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title="Enable a feature"
            setShowDrawer={() => {
              setShowCompanyFeatureFlagsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CompanyFeatureFlagsDrawer company={company} onSubmitEvent={onSubmitEvent} setShowCompanyFeatureFlagsDrawer={setShowCompanyFeatureFlagsDrawer} refetchCompany={refetchCompany} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("companyDetails.companyDetailsInformations.companyBillingContactDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      {/* Company billing terms drawer */}
      <SoeDrawer showDrawer={showCompanyBillingDrawer} setShowDrawer={setShowCompanyBillingDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("companyDetails.companyDetailsInformations.companyBillingDrawer.title")}
            setShowDrawer={() => {
              setShowCompanyBillingDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CompanyBillingDrawer company={company} onSubmitEvent={onSubmitEvent} refetchCompany={refetchCompany} setSubmitEvent={setSubmitEvent} setShowCompanyBillingDrawer={setShowCompanyBillingDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("companyDetails.companyDetailsInformations.companyBillingDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      {/* Add credits to company drawer */}
      <SoeDrawer showDrawer={showCreditDrawer} setShowDrawer={setShowCreditDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("companyDetails.companyDetailsInformations.companyCreditDrawer.title")}
            setShowDrawer={() => {
              setShowCreditDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <AddCreditToCompanyDrawer onSubmitEvent={onSubmitEvent} company={company} setShowCreditDrawer={setShowCreditDrawer} refetchCompany={refetchCompany} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("companyDetails.companyDetailsInformations.companyCreditDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Box>
  )
}

CompanyDetailsInformations.propTypes = {
  company: PropTypes.instanceOf(Object).isRequired,
  refetchCompany: PropTypes.func.isRequired,
}

export default CompanyDetailsInformations
