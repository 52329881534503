// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Custom theme
import CustomChip from "./style"

// Others
import CarrierDetailsSummaryCard from "../carrier-details-summary-card"
import TranslationService from "../../../../services/translation"
import { SummaryStatusVariantsEnum } from "../carrier-details-summary-card/components/summary-status"

function CarrierChargeCard({ carrierCharge, chargeTypes, handleCarrierChargeCardClick }) {
  const { i18n, t } = useTranslation("carriers")

  const getEmptyValue = (element) => {
    return Object.keys(element).filter((key) => element[key].trim() === "")
  }

  const getSummaryStatus = () => {
    if (
      !carrierCharge === undefined ||
      carrierCharge.isNew === true ||
      (carrierCharge.name && Object.keys(carrierCharge.name).length === 0) ||
      (carrierCharge.name && getEmptyValue(carrierCharge.name).length > 0) ||
      (carrierCharge.chips && carrierCharge.chips.some((chip) => getEmptyValue(chip).length > 0))
    )
      return SummaryStatusVariantsEnum.warning
    return SummaryStatusVariantsEnum.success
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <CarrierDetailsSummaryCard title={TranslationService.getLabelFromLocale(i18n.language, carrierCharge.name)} handleClick={handleCarrierChargeCardClick} summaryStatusProps={summaryStatusProps}>
      {carrierCharge && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {carrierCharge.code && (
            <Typography noWrap sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("carrierDetails.carrierChargesCard.carrierCode.label")}: {carrierCharge.code}
            </Typography>
          )}
          {carrierCharge.billingCode && (
            <Typography noWrap sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("carrierDetails.carrierChargesCard.billingCode.label")}: {carrierCharge.billingCode}
            </Typography>
          )}
          {carrierCharge.type && (
            <Typography noWrap sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("carrierDetails.carrierChargesCard.type.label")}: {TranslationService.getLabelFromLocale(i18n.language, chargeTypes && chargeTypes.find((type) => type.chargeType === carrierCharge.type).shortName)}
            </Typography>
          )}
          <Box sx={{ display: "flex-inline", flexDirection: "column" }}>
            {carrierCharge.isNew && <CustomChip label={t("carriersManagement.chipStatus.new.label")} size="small" sx={(theme) => ({ backgroundColor: theme.palette.contentAlertError.main })} />}
            {carrierCharge.chips && carrierCharge.chips.map((chip, index) => <CustomChip key={"chip".concat(index.toString())} label={TranslationService.getLabelFromLocale(i18n.language, chip)} size="small" />)}
          </Box>
        </Box>
      )}
    </CarrierDetailsSummaryCard>
  )
}

CarrierChargeCard.propTypes = {
  carrierCharge: PropTypes.shape({
    code: PropTypes.string,
    billingCode: PropTypes.string,
    chips: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    type: PropTypes.string,
    name: PropTypes.shape({}),
    isNew: PropTypes.bool,
  }).isRequired,
  chargeTypes: PropTypes.instanceOf(Object).isRequired,
  handleCarrierChargeCardClick: PropTypes.func,
}

CarrierChargeCard.defaultProps = {
  handleCarrierChargeCardClick: () => {},
}

export default CarrierChargeCard
