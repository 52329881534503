import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Card from "@mui/material/Card"

import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "ClientDetailsInfoCard", slot: "Root" })(() => ({}))

export const CustomCard = styled(Card, { name: "CustomCard", slot: "Root" })(({ disabled }) => ({
  backgroundColor: disabled && "rgba(0, 0, 0, .003)",
  boxShadow: "0px 1px 1px 1px rgba(204,204,204,1)",
  cursor: "pointer",
}))

export const CustomCardContent = styled(CardContent, { name: "CustomCardContent", slot: "Root" })(({ theme }) => ({
  "&:last-child": {
    padding: theme.spacing(2),
  },
}))
