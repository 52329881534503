// REACT
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"

// Formik
import { useFormik } from "formik"

// Theme
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { LANGUAGES } from "../../../../../../constant"

import UserContext from "../../../../../../services/user/context"
import { useEditUserProfileMutation } from "../../../../../../services/user/slice"

function GeneralInfoLanguageDrawer({ onSubmitEvent, setShowGeneralInfoLanguageDrawer }) {
  const { i18n } = useTranslation("userProfile")
  const { currentUser } = useContext(UserContext)
  const [editUserProfile] = useEditUserProfileMutation()

  const formik = useFormik({
    initialValues: {
      defaultLanguage: currentUser.defaultLanguage || i18n.resolvedLanguage,
    },
    validate: () => {},
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      editUserProfile({
        defaultLanguage: formValues.defaultLanguage,
      })
      i18n.changeLanguage(formValues.defaultLanguage)
      setShowGeneralInfoLanguageDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <FormControl size="small" fullWidth>
          <InputLabel />
          <Select variant="outlined" name="defaultLanguage" value={formik.values.defaultLanguage} onChange={formik.handleChange} onBlur={formik.handleBlur}>
            {LANGUAGES.map((language, index) => {
              return (
                <MenuItem key={`${index.toString()}`} value={language.code}>
                  {language.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </form>
    </Box>
  )
}

GeneralInfoLanguageDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

GeneralInfoLanguageDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowGeneralInfoLanguageDrawer: PropTypes.func.isRequired,
}

export default GeneralInfoLanguageDrawer
