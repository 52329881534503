import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "SoeDrawerHeader", slot: "Root" })(({ theme }) => ({
  width: "100%",
  minHeight: theme.utils.pxToRem(66),
  overflow: "hidden",
}))

export const CustomBox = styled(Box, { name: "CustomBox", slot: "Root" })(({ theme }) => ({
  width: "100%",
  height: "auto",
  borderBottom: `1px solid ${theme.palette.strokeDefault.main}`,
  paddingTop: theme.utils.pxToRem(12),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.utils.pxToRem(12),
}))

export const CustomBoxContent = styled(Box, { name: "CustomBoxContent", slot: "Root" })(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))
