// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import CardContent from "@mui/material/CardContent"

// Theme
import { styled } from "@mui/material/styles"

const StyledCardContentNoPaddingTop = styled(CardContent, { label: "StyledCardContentNoPaddingTop", slot: "Root" })(() => ({
  paddingTop: 0,
}))

function CardContentNoPaddingTop(props) {
  const { sx, ...others } = props

  return <StyledCardContentNoPaddingTop sx={sx} {...others} />
}

CardContentNoPaddingTop.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]),
}

CardContentNoPaddingTop.defaultProps = {
  sx: {},
}
export default CardContentNoPaddingTop
