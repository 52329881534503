// REACT
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

// Theme
import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"

// Others
import Currency from "../../../../../../../currency"
import RateRequestSummaryCard from "../../../../../../../rate-request-summary-card"
import TranslationService from "../../../../../../../../services/translation"

import { AuditItemSectionProps } from "../../../../../../../../models/audit"

function AccountDetailsExpectedPrice({ rate }) {
  const { i18n, t } = useTranslation("audits")

  return (
    <RateRequestSummaryCard title={t("auditDetails.auditItemDetails.accountDetails.expectedPrice.title")} sx={{ height: 1 }}>
      <Box mb={pxToThemeSpacing(20)}>
        <Stack direction="column">
          <Box>
            {rate && (
              <Box>
                <Grid container>
                  <Grid item xs={4}>
                    <Box />
                  </Grid>
                  <Grid item xs={2} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.accountDetails.expectedPrice.headers.retailPrice")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.accountDetails.expectedPrice.headers.cost")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.accountDetails.expectedPrice.headers.cost")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Box />
                  </Grid>
                  <Grid item xs={2} pb={pxToThemeSpacing(6)} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", borderBottom: 1 }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {`(${rate.totals.total.retail.declared.currency})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", borderBottom: 1 }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {`(${rate.totals.total.account.declared.currency})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", borderBottom: 1 }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {`(${rate.totals.total.client.declared.currency})`}
                    </Typography>
                  </Grid>
                </Grid>
                {rate.charges?.map((charge, index) => {
                  return (
                    <Box key={"".concat(index)} mt={pxToThemeSpacing(6)} mb={pxToThemeSpacing(6)} sx={(theme) => ({ height: theme.utils.pxToRem(22) })}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography noWrap variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                            {TranslationService.getLabelFromLocale(i18n.language, charge.name)} {charge.type === "tax" && `(${(charge.percentage * 100).toFixed(3)}%)`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                          {charge.retail?.declared?.amount !== undefined && (
                            <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                              <Currency amount={charge.retail.declared.amount} currency={charge.retail.declared.currency} currencyPosition="none" showSymbol={false} />
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                          {charge.account?.declared?.amount !== undefined && (
                            <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                              <Currency amount={charge.account.declared.amount} currency={charge.account.declared.currency} currencyPosition="none" showSymbol={false} />
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                          {charge.account?.declared?.amount !== undefined && (
                            <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                              <Currency
                                amount={charge.account.declared.amount * (rate.totals.total.account.declared.currency !== rate.totals.total.client.declared.currency ? rate.fxRate : 1.0)}
                                currency={charge.account.declared.currency}
                                currencyPosition="none"
                                showSymbol={false}
                              />
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  )
                })}
                <Grid container mt={pxToThemeSpacing(6)}>
                  <Grid item xs={4}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.accountDetails.expectedPrice.carrierFx", { currency: rate.totals.total.account.declared.currency })}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} pr={pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                    {rate.totals?.total?.retail?.declared?.amount !== undefined && (
                      <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                        <Currency amount={rate.totals.total.retail.declared.amount} currency={rate.totals.total.retail.declared.currency} currencyPosition="none" showSymbol={false} />
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                    {rate.totals?.total?.account?.declared?.amount !== undefined && (
                      <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                        <Currency amount={rate.totals.total.account.declared.amount} currency={rate.totals.total.account.declared.currency} currencyPosition="none" showSymbol={false} />
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })} />
                  </Grid>
                </Grid>
                <Grid container mt={pxToThemeSpacing(6)}>
                  <Grid item xs={4}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {t("auditDetails.auditItemDetails.accountDetails.expectedPrice.cutomerFx", { currency: rate.totals.total.client.declared.currency })}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} pr={pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                    {rate.totals?.total?.retail?.declared?.amount !== undefined && (
                      <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                        <Currency
                          amount={rate.totals.total.retail.declared.amount * (rate.totals.total.retail.declared.currency !== rate.totals.total.client.declared.currency ? rate.fxRate : 1.0)}
                          currency={rate.totals.total.retail.declared.currency}
                          currencyPosition="none"
                          showSymbol={false}
                        />
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })} />
                  </Grid>
                  <Grid item xs={3} pr={pxToThemeSpacing(10)} sx={{ borderTop: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                    <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                      {rate.totals?.total?.account?.declared?.amount !== undefined && (
                        <Typography variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                          <Currency
                            amount={rate.totals.total.account.declared.amount * (rate.totals.total.account.declared.currency !== rate.totals.total.client.declared.currency ? rate.fxRate : 1.0)}
                            currency={rate.totals.total.account.declared.currency}
                            currencyPosition="none"
                            showSymbol={false}
                          />
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </RateRequestSummaryCard>
  )
}

AccountDetailsExpectedPrice.propTypes = {
  rate: AuditItemSectionProps.isRequired,
}

export default AccountDetailsExpectedPrice
