// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

// Others
import Services from "../services"
import TranslationService from "../../../../services/translation"
import { useDeletePricingPlanMutation, useGetServicesQuery } from "../../slice"
import MoreMenu from "../../../more-menu"

import SoeConfirmationDialog from "../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogActions from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import SoeConfirmationDialogContainer from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogContent from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogHeader from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"

function PricePlan({ pricingGroupId, pricingPlan, setSelectedPricingPlan, setShowPricingPlanEditDrawer }) {
  const { t, i18n } = useTranslation("pricing")
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  const [deletePricingPlan] = useDeletePricingPlanMutation()

  const { data: services, isFetching } = useGetServicesQuery({ pricingGroupId, pricingPlanId: pricingPlan.code }, { skip: !isAccordionExpanded })

  const handleAccordionChange = (_, expanded) => {
    setIsAccordionExpanded(expanded)
  }

  const handleEditClick = (event) => {
    event.stopPropagation()
    setSelectedPricingPlan(pricingPlan)
    setShowPricingPlanEditDrawer(true)
  }

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    setShowConfirmationDialog(true)
  }

  const sideMenuOptionList = [
    {
      label: t("sideMenu.edit"),
      handleClick: handleEditClick,
    },
    {
      label: t("sideMenu.delete"),
      handleClick: handleDeleteClick,
    },
  ]

  const handleClose = () => {
    setShowConfirmationDialog(false)
  }

  const handleConfirm = () => {
    setShowConfirmationDialog(false)
    deletePricingPlan({ pricingGroupId, pricingPlanId: pricingPlan.code })
  }

  return (
    <>
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={t("pricePlan.confirmationDialog.title")}
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("pricePlan.confirmationDialog.content.label")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleClose,
                label: t("pricePlan.confirmationDialog.action.cancel.label"),
                variant: "outlined",
              },
              {
                action: handleConfirm,
                label: t("pricePlan.confirmationDialog.action.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
      <Accordion onChange={handleAccordionChange}>
        <AccordionSummary>
          <Grid container alignItems="center">
            <Grid container item xs={8} alignItems="center">
              <Typography component="div">
                {`${t("pricePlan.fields.label")}: `}
                <Box fontWeight="fontWeightBold" display="inline">
                  {`${pricingPlan.name} `}
                </Box>
                ({`${t("pricePlan.carrier.label")}: `}
                {`${TranslationService.getLabelFromLocale(i18n.language, pricingPlan.carrier.fullName)}, `}
                {`${t("account.fields.label")}: `}
                {`${pricingPlan.carrierAccount.name}`})
              </Typography>
              {pricingPlan.isCarrierAccountDisabled && <Chip label="Account Disabled" size="small" color="error" sx={(theme) => ({ ml: theme.utils.pxToThemeSpacing(16) })} />}
            </Grid>
            <Grid container item justifyContent="flex-end" alignItems="center" xs={4}>
              <MoreMenu optionsList={sideMenuOptionList} />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {!services || isFetching ? (
            <Skeleton variant="rectangular" width="100%" height={100} />
          ) : (
            <>
              {services.map((service, index) => {
                return <Services key={"".concat(index)} pricingGroupId={pricingGroupId} pricingPlanId={pricingPlan.code} service={service} />
              })}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

PricePlan.propTypes = {
  pricingGroupId: PropTypes.string.isRequired,
  pricingPlan: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    carrier: PropTypes.shape({
      code: PropTypes.string,
      fullName: PropTypes.shape({
        en: PropTypes.string,
        fr: PropTypes.string,
      }),
      shortName: PropTypes.shape({
        en: PropTypes.string,
        fr: PropTypes.string,
      }),
    }),
    carrierAccount: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
    isCarrierAccountDisabled: PropTypes.bool,
    services: PropTypes.arrayOf(Object),
  }).isRequired,
  setSelectedPricingPlan: PropTypes.func,
  setShowPricingPlanEditDrawer: PropTypes.func,
}

PricePlan.defaultProps = {
  setSelectedPricingPlan: undefined,
  setShowPricingPlanEditDrawer: undefined,
}

export default PricePlan
