import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import SummaryCard from "../../../../../summary-card"
import SummaryStatusVariantsEnum from "../../../../../summary-card/components/summary-status/contant"

function GooglePlacesSettingsCard({ handleGooglePlacesSettingsCardClick, googlePlacesSettings }) {
  const theme = useTheme()
  const { t } = useTranslation("settings")

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <SummaryCard title={t("settings.googlePlacesSettingsCard.title.label")} handleClick={handleGooglePlacesSettingsCardClick} summaryStatusProps={summaryStatusProps} isDisabled={false}>
      {googlePlacesSettings?.settings?.length > 0 && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {googlePlacesSettings?.settings?.map((setting, index) => (
            <Typography key={"".concat(index)} sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {setting.name}
              {t("settings.keyValueSeparator")}
              {setting.value}
            </Typography>
          ))}
        </Box>
      )}
    </SummaryCard>
  )
}

GooglePlacesSettingsCard.defaultProps = {
  googlePlacesSettings: undefined,
  handleGooglePlacesSettingsCardClick: () => {},
}

GooglePlacesSettingsCard.propTypes = {
  googlePlacesSettings: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
  handleGooglePlacesSettingsCardClick: PropTypes.func,
}

export default GooglePlacesSettingsCard
