// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import Currency from "../../../../../../../../../currency"
import DateTimeService from "../../../../../../../../../../services/date-time"
import OrderDetailsDrawer from "./components/order-details-drawer"
import OrderProps from "../../../../../../../../../../services/order/model"
import TranslationService from "../../../../../../../../../../services/translation"
import AddressSummary from "../../../../../../../../../address-summary"

import { UNIT_OF_WEIGHT } from "../../../../../../../../../../constant"

function Order({ order, auditId, auditItemId }) {
  const { i18n, t } = useTranslation("audits")
  const customTheme = useTheme()

  const [showOrderDetailsDrawer, setShowOrderDetailsDrawer] = useState(false)

  const handleOrderClick = () => {
    setShowOrderDetailsDrawer(true)
  }

  const totalPackages = order.quotation?.request?.packages?.pieces?.length > 0 ? order.quotation?.request?.packages?.pieces.reduce((accumulator, { quantity }) => accumulator + quantity, 0) : 0

  const totalWeight =
    order.quotation?.request?.packages?.pieces?.length > 0
      ? order.quotation?.request?.packages?.pieces.reduce((accumulator, piece) => {
          return accumulator + piece.weight * piece.quantity
        }, 0)
      : 0

  return (
    <>
      <TableRow hover component="tr" onClick={handleOrderClick} sx={{ backgroundColor: customTheme.palette.contentNeutralPrimaryReverse.main }}>
        <TableCell component="td" scope="row">
          <div>
            <Typography variant="bodySmall400">{TranslationService.getLabelFromLocale(i18n.language, order.quotation?.response?.service?.fullName)}</Typography>
          </div>
          <div>{order.tracking?.masterId && <Typography variant="bodySmall400">{order.tracking.masterId}</Typography>}</div>
        </TableCell>
        <TableCell component="td" scope="row">
          {order.quotation?.request?.shipper && <AddressSummary addressSummary={order.quotation.request.shipper} />}
        </TableCell>
        <TableCell component="td" scope="row">
          {order.quotation?.request?.recipient && <AddressSummary addressSummary={order.quotation.request.recipient} />}
        </TableCell>
        <TableCell component="td" scope="row">
          {order.quotation?.request?.packages && (
            <>
              <Box component="div">
                <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                  {t("auditDetails.auditItemDetails.orderSearch.packages.totalPackages")} {totalPackages}
                </Typography>
              </Box>
              <Box component="div">
                <Typography variant="bodySmall400" gutterBottom>
                  {t("auditDetails.auditItemDetails.orderSearch.packages.totalWeight")} {totalWeight.toFixed(1)} {order.quotation?.request?.packages?.unitOfMeasure === "imperial" ? UNIT_OF_WEIGHT.POUND : UNIT_OF_WEIGHT.KILOGRAM}
                </Typography>
              </Box>
            </>
          )}
        </TableCell>
        <TableCell component="td" scope="row">
          {order.quotation?.response?.rate?.totals?.account?.total?.amount !== undefined && (
            <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
              <Currency amount={order.quotation?.response?.rate?.totals?.account?.total?.amount} currency={order.quotation?.response?.rate?.totals?.account?.total?.currency} showSymbol={false} currencyPosition="right" />
            </Typography>
          )}
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">
            <Typography variant="bodySmall400">{DateTimeService.toLocaleDateString(order.quotation?.request?.shippingOptions?.shippingDate)}</Typography>
          </Box>
        </TableCell>
      </TableRow>
      {showOrderDetailsDrawer && <OrderDetailsDrawer orderId={order.id} auditId={auditId} auditItemId={auditItemId} showOrderDetailsDrawer={showOrderDetailsDrawer} setShowOrderDetailsDrawer={setShowOrderDetailsDrawer} />}
    </>
  )
}

Order.propTypes = {
  order: OrderProps.isRequired,
  auditId: PropTypes.string.isRequired,
  auditItemId: PropTypes.string.isRequired,
}

export default Order
