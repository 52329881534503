// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

import SummaryStatusVariantsEnum from "./contant"

function SummaryStatus({ variant, text, sx }) {
  const theme = useTheme()
  const { t } = useTranslation("summaryCard")

  if (!Object.values(SummaryStatusVariantsEnum).includes(variant)) throw new Error("Invalid variant")

  let color
  let tempText

  if (variant === SummaryStatusVariantsEnum.success) {
    color = theme.palette.contentAlertPositive.main
    tempText = t("summaryCard.summaryStatus.toEdit.label") || text
  }
  if (variant === SummaryStatusVariantsEnum.warning) {
    color = theme.palette.contentAlertWarning.main
    tempText = t("summaryCard.summaryStatus.toComplete.label") || text
  }
  if (variant === SummaryStatusVariantsEnum.disabled) {
    color = theme.palette.contentNeutralSecondary.main
    tempText = t("summaryCard.summaryStatus.disabled.label") || text
  }
  return (
    <Typography noWrap variant="bodySmall600" sx={{ color, ...sx }}>
      {tempText}
    </Typography>
  )
}

SummaryStatus.propTypes = {
  variant: PropTypes.string.isRequired,
  text: PropTypes.string,
  sx: PropTypes.instanceOf(Object),
}

SummaryStatus.defaultProps = {
  text: undefined,
  sx: undefined,
}

export default SummaryStatus
