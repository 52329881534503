/**
 * Colors from Figma https://www.figma.com/file/NEkxr0jcaiX1LKu4if6FOE/SaveONexpress-(DS)
 */
const figmaColors = {
  content: {
    neutral: {
      primary: "#383838",
      secondary: "#67737F",
      tertiary: "#999999",
      tertiaryForm: "#BCBCBC",
      quaternaire: "#F4F4F4",
      primaryReverse: "#FFFFFF",
      inexPrimary: "#00A2B2",
      inexSecondary: "#40C9A9",
      inexTertiary: "#001F22",
      inexTertiaryLighter: "#292929",
    },
    action: {
      default: "#095797",
      lighter: "#157CD0",
      darker: "#1D4264",
      focus: "#6AA9DE",
    },
    alert: {
      informative: "#2196F3",
      informativeDarker: "#157CD0",
      positive: "#4CAF50",
      positiveDarker: "#1D8221",
      warning: "#FB8C00",
      warningDarker: "#FB8C00",
      error: "#FF5252",
      errorDarker: "#E12727",
    },
  },
  stroke: {
    default: "#E0E0E0",
    light: "#EAEAEA",
    separator: "#DADDE1",
  },
  background: {
    neutral: {
      ground: "#FFFFFF",
      underground1: "#F8F8F8",
      underground2: "#D9D9D9",
      bluecorporate: "#ECF6FF",
      transparent: "rgba(255, 255, 255, 0.005)",
      transparentDark: "rgba(255, 255, 255, .05)",
      transparentLightGrey: "rgba(0, 0, 0, .03)",
      transparentDarkGrey: "rgba(0, 0, 0, .125)",
    },
    highlight: {
      light: "#EAF5FF",
      strong: "#D7EDFF",
    },
    alert: {
      informative: "#E9F5FE",
      positive: "#EDF7EE",
      warning: "#FFF4E6",
      error: "#FFEEEE",
    },
  },
}

export const themeCustomPalette = {
  // Inex new colors
  inexPrimary: {
    main: figmaColors.content.neutral.inexPrimary
  },
  inexSecondary: {
    main: figmaColors.content.neutral.inexSecondary
  },
  inexTertiary: {
    main: figmaColors.content.neutral.inexTertiary,
    lighter: figmaColors.content.neutral.inexTertiaryLighter
  },

  // Content neutral
  contentNeutralPrimary: {
    main: figmaColors.content.neutral.primary,
  },
  contentNeutralSecondary: {
    main: figmaColors.content.neutral.secondary,
  },
  contentNeutralTertiary: {
    main: figmaColors.content.neutral.tertiary,
  },
  contentNeutralTertiaryForm: {
    main: figmaColors.content.neutral.tertiaryForm,
  },
  contentNeutralQuaternaire: {
    main: figmaColors.content.neutral.quaternaire,
  },
  contentNeutralPrimaryReverse: {
    main: figmaColors.content.neutral.primaryReverse,
  },

  // Content action
  contentActionDefault: {
    main: figmaColors.content.action.default,
  },
  contentActionLighter: {
    main: figmaColors.content.action.lighter,
  },
  contentActionDarker: {
    main: figmaColors.content.action.darker,
  },
  contentActionFocus: {
    main: figmaColors.content.action.focus,
  },

  // Content alert
  contentAlertInformative: {
    main: figmaColors.content.alert.informative,
  },
  contentAlertInformativeDarker: {
    main: figmaColors.content.alert.informativeDarker,
  },
  contentAlertPositive: {
    main: figmaColors.content.alert.positive,
  },
  contentAlertPositiveDarker: {
    main: figmaColors.content.alert.positiveDarker,
  },
  contentAlertWarning: {
    main: figmaColors.content.alert.warning,
  },
  contentAlertWarningDarker: {
    main: figmaColors.content.alert.warningDarker,
  },
  contentAlertError: {
    main: figmaColors.content.alert.error,
  },
  contentAlertErrorDarker: {
    main: figmaColors.content.alert.errorDarker,
  },

  // Stroke
  strokeDefault: {
    main: figmaColors.stroke.default,
  },
  strokeLight: {
    main: figmaColors.stroke.light,
  },
  strokeSeparator: {
    main: figmaColors.stroke.separator,
  },

  // Background neutral
  backgroundNeutralGround: {
    main: figmaColors.background.neutral.ground,
  },
  backgroundNeutralUnderground1: {
    main: figmaColors.background.neutral.underground1,
  },
  backgroundNeutralUnderground2: {
    main: figmaColors.background.neutral.underground2,
  },
  backgroundNeutralBluecorporate: {
    main: figmaColors.background.neutral.bluecorporate,
  },
  backgroundNeutralTransparent: {
    main: figmaColors.background.neutral.transparent,
  },
  backgroundNeutralTransparentDark: {
    main: figmaColors.background.neutral.transparentDark,
  },
  backgroundNeutralTransparentLightGrey: {
    main: figmaColors.background.neutral.transparentLightGrey,
  },
  backgroundNeutralTransparentDarkGrey: {
    main: figmaColors.background.neutral.transparentDarkGrey,
  },

  // Background highlight
  backgroundNeutralHightlightLight: {
    main: figmaColors.background.highlight.light,
  },
  backgroundNeutralHightlightStrong: {
    main: figmaColors.background.highlight.strong,
  },

  // Background alert
  backgroundAlertInformative: {
    main: figmaColors.background.alert.informative,
  },
  backgroundAlertPositive: {
    main: figmaColors.background.alert.positive,
  },
  backgroundAlertWarning: {
    main: figmaColors.background.alert.warning,
  },
  backgroundAlertError: {
    main: figmaColors.background.alert.error,
  },
}

/**
 * Use as fail safe and to set MUI default. Use themeCustomPalette instead
 */
export const muiPalette = {
  tonalOffset: 0.2,
  contrastThreshold: 3,
  primary: {
    main: figmaColors.content.neutral.secondary,
    dark: figmaColors.content.neutral.primary,
    light: figmaColors.content.neutral.tertiary,
  },
  secondary: {
    main: figmaColors.content.action.default,
    light: figmaColors.content.action.lighter,
    dark: figmaColors.content.action.darker,
  },
  background: {
    default: figmaColors.background.neutral.underground1,
    paper: figmaColors.background.neutral.ground,
  },
  text: {
    primary: figmaColors.content.neutral.primary,
    secondary: figmaColors.content.neutral.secondary,
    disabled: figmaColors.content.neutral.quaternaire,
    hint: figmaColors.content.action.default,
  },
  error: {
    main: figmaColors.content.alert.error,
    dark: figmaColors.content.alert.errorDarker,
  },
  warning: {
    main: figmaColors.content.alert.warning,
    dark: figmaColors.content.alert.warningDarker,
  },
  info: {
    main: figmaColors.content.alert.informative,
    dark: figmaColors.content.alert.informativeDarker,
  },
  success: {
    main: figmaColors.content.alert.positive,
    dark: figmaColors.content.alert.positiveDarker,
  },
  divider: figmaColors.stroke.separator,
}

const palette = { ...muiPalette, ...themeCustomPalette }

export default palette
