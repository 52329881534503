// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Masonry from "@mui/lab/Masonry"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import DateRangeFilter from "../../../../../../date-range-filter"
import StatisticsCard from "../statistics-card"
import TranslationService from "../../../../../../../services/translation"
import { useGetClientDetailsStatisticsQuery } from "../../../../../../clients/slice"

function CompanyDetailsStatistics({ company, dataRangeFilterValues, setDataRangeFilterValues }) {
  const { i18n, t } = useTranslation("clients")
  const theme = useTheme()

  const { data: statistics } = useGetClientDetailsStatisticsQuery({ companyId: company.id, from: dataRangeFilterValues?.from, to: dataRangeFilterValues?.to }, { skip: !company || !dataRangeFilterValues })

  return (
    <Box component="div">
      <Box pt={theme.utils.pxToThemeSpacing(20)} pr={theme.utils.pxToThemeSpacing(6)} pb={theme.utils.pxToThemeSpacing(20)} pl={theme.utils.pxToThemeSpacing(10)} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box component="div">
          <Typography variant="headingH5" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {t("clientDetails.clientDetailsStatistics.title")}
          </Typography>
        </Box>
        <Box component="div">
          <DateRangeFilter setDataRangeFilterValues={setDataRangeFilterValues} />
        </Box>
      </Box>
      <Box pl={theme.utils.pxToThemeSpacing(6)} sx={{ display: "flex" }}>
        <Masonry columns={4} spacing={theme.utils.pxToThemeSpacing(6)}>
          {statistics ? (
            statistics.map((statistic, index) => {
              return <StatisticsCard key={statistic.id.concat(index)} title={TranslationService.getLabelFromLocale(i18n.language, statistic.name)} statistic={statistic} />
            })
          ) : (
            <Box component="div" />
          )}
        </Masonry>
      </Box>
    </Box>
  )
}

CompanyDetailsStatistics.propTypes = {
  company: PropTypes.instanceOf(Object).isRequired,
  dataRangeFilterValues: PropTypes.instanceOf(Object),
  setDataRangeFilterValues: PropTypes.func,
}

CompanyDetailsStatistics.defaultProps = {
  dataRangeFilterValues: undefined,
  setDataRangeFilterValues: () => {},
}

export default CompanyDetailsStatistics
