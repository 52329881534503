import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React, { useMemo } from "react"

import { useFormik } from "formik"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import AppConfig from "../../../../utils/app-config"
import CustomDatePicker from "../../../date-picker"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useGetCarriersQuery } from "../../../../services/carriers/slice"

function ExportInvoicesDrawer({ showExportInvoicesDrawer, setShowExportInvoicesDrawer }) {
  const { t } = useTranslation("invoices")
  const customTheme = useTheme()

  const { data: carriers } = useGetCarriersQuery()

  const handleClose = () => {
    setShowExportInvoicesDrawer(false)
  }

  const formik = useFormik({
    initialValues: {
      toDate: useMemo(() => new Date(), []),
      fromDate: useMemo(() => new Date(), []),
    },
    validate: () => {},
    validateOnChange: true,
    enableReinitialize: true,
  })

  const handleSubmit = () => {
    window.open(`${AppConfig.aws.restApi.apiUrl}/invoices/export?fromDate=${formik.values.fromDate.toISOString().split("T")[0]}&toDate=${formik.values.toDate.toISOString().split("T")[0]}`, "_blank")
    setShowExportInvoicesDrawer(false)
  }

  return (
    <SoeDrawer showDrawer={showExportInvoicesDrawer} setShowDrawer={setShowExportInvoicesDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("exportInvoicesDrawer.title")}
          setShowDrawer={() => {
            setShowExportInvoicesDrawer(false)
          }}
        />
        <SoeDrawerContent>
          {carriers && (
            <form noValidate>
              <Box component="div" my={customTheme.utils.pxToRem(10)}>
                <CustomDatePicker
                  name="fromDate"
                  label={t("exportInvoicesDrawer.fields.fromDate")}
                  value={formik.values.fromDate}
                  onChange={(value) => {
                    formik.setFieldValue("fromDate", value)
                  }}
                  error={formik.touched.fromDate && formik.errors.fromDate !== undefined}
                />
              </Box>
              <Box component="div" my={customTheme.utils.pxToRem(10)}>
                <CustomDatePicker
                  name="toDate"
                  label={t("exportInvoicesDrawer.fields.toDate")}
                  value={formik.values.toDate}
                  onChange={(value) => {
                    formik.setFieldValue("toDate", value)
                  }}
                  error={formik.touched.toDate && formik.errors.toDate !== undefined}
                />
              </Box>
            </form>
          )}
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleClose,
              label: t("exportInvoicesDrawer.actions.cancel"),
              variant: "outlined",
            },
            {
              action: handleSubmit,
              label: t("exportInvoicesDrawer.actions.export"),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

ExportInvoicesDrawer.propTypes = {
  showExportInvoicesDrawer: PropTypes.bool.isRequired,
  setShowExportInvoicesDrawer: PropTypes.func.isRequired,
}

export default ExportInvoicesDrawer
