// React
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Others
import SummaryStatus from "./components/summary-status"

// Custom style
import { CustomCard, CustomCardContent, MuiWrapper } from "./style"

function SummaryCard({ title, children, summaryStatusProps, handleClick, isDisabled, isTest, ...muiBoxProps }) {
  const customCardOnClick = (event) => {
    if (isDisabled) event.preventDefault()
    if (handleClick) handleClick(event)
  }

  return (
    <MuiWrapper {...muiBoxProps}>
      <CustomCard onClick={customCardOnClick} disabled={isDisabled}>
        <CustomCardContent>
          <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Typography noWrap variant="bodyMedium600">
              {title}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={(theme) => ({ display: "flex", justifyContent: "flex-end", alignItems: "center", marginLeft: theme.spacing(2) })}>{summaryStatusProps && <SummaryStatus {...summaryStatusProps} />}</Box>
          </Box>
          {children}
        </CustomCardContent>
      </CustomCard>
    </MuiWrapper>
  )
}

SummaryCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  summaryStatusProps: PropTypes.shape({
    variant: PropTypes.string,
  }),
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isTest: PropTypes.bool,
}

SummaryCard.defaultProps = {
  title: "",
  summaryStatusProps: undefined,
  children: undefined,
  isDisabled: false,
  isTest: false,
}

export default SummaryCard
