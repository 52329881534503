/* eslint-disable react/jsx-filename-extension */

import React from "react"

// material
import { useTheme } from "@mui/material/styles"
import { GlobalStyles as GlobalThemeStyles } from "@mui/material"

/**
 *  Use as fail safe, set MUI Default on HTML, set for compatibility purpose
 *
 * Base MUI theme :
 * - on HTML tags
 * - set globalDefault
 * IMPORTANT: Always use MUI component instead of pure HTML when it's possible, like <Typography>.
 *
 * <p>, <span>, <b> and <strong> : use MUI <Typography />
 * <CssBaseline /> :
 * - Set the theme.typography.body1 style on the <body> element.
 * - Set the font-weight to theme.typography.fontWeightBold for the <b> and <strong> elements.
 * @link https://mui.com/api/typography/
 * @link https://mui.com/components/css-baseline/
 *
 * <h1> - <h6>, <button>, <caption> : use MUI <Typography />
 * @link https://mui.com/api/typography/
 *
 * <hr> : use MUI <Divider />
 * @link https://mui.com/api/divider/
 *
 * <li> : use MUI <List />
 * @link https://mui.com/components/lists/
 *
 * @link https://mui.com/customization/how-to-customize/#4-global-theme-variation
 * @link https://mui.com/api/global-styles/
 *
 * The "lineHeight" and "letterSpacing" function value can be found here:
 * @link https://github.com/mui-org/material-ui/blob/master/packages/mui-joy/src/styles/defaultTheme.ts
 * file: packages/mui-joy/src/styles/defaultTheme.ts
 */
export default function GlobalStyles() {
  const theme = useTheme()

  return (
    <GlobalThemeStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        html: {
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          width: "100%",
          height: "100%",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        h1: theme.typography.headingH1,
        h2: theme.typography.headingH2,
        h3: theme.typography.headingH3,
        h4: theme.typography.headingH4,
        h5: theme.typography.headingH5,
        h6: theme.typography.headingH6,
        p: theme.typography.bodyMedium400,
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },
        textarea: {
          "&::-webkit-input-placeholder": {
            color: theme.palette.text.disabled,
          },
          "&::-moz-placeholder": {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          "&:-ms-input-placeholder": {
            color: theme.palette.text.disabled,
          },
          "&::placeholder": {
            color: theme.palette.text.disabled,
          },
        },

        img: { display: "block", maxWidth: "100%" },

        // Lazy Load Img
        ".blur-up": {
          WebkitFilter: "blur(5px)",
          filter: "blur(5px)",
          transition: "filter 400ms, -webkit-filter 400ms",
        },
        ".blur-up.lazyloaded ": {
          WebkitFilter: "blur(0)",
          filter: "blur(0)",
        },
      }}
    />
  )
}
