/**
 * @link https://www.figma.com/file/NEkxr0jcaiX1LKu4if6FOE/SaveONexpress-(DS)?node-id=178%3A37
 * @link https://mui.com/components/material-icons/
 */
import * as React from "react"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Accordion from "@mui/material/Accordion"
import AppBar from "@mui/material/AppBar"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import Button from "@mui/material/Button"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"

import Paper from "@mui/material/Paper"
import SoeMenuIcon from "../../icons/SoeMenuIcon"

const bull = (
  <Box component="span" sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}>
    •
  </Box>
)

export default function Surface() {
  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" mt={8}>
            Paper
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 128,
                height: 128,
              },
            }}
          >
            <Paper />
            <Paper elevation={0} />
            <Paper elevation={1} />
            <Paper variant="outlined" />
            <Paper variant="outlined" square />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" mt={8}>
            Card
          </Typography>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Word of the Day
              </Typography>
              <Typography variant="h5" component="div">
                be{bull}nev{bull}o{bull}lent
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                adjective
              </Typography>
              <Typography variant="body2">
                well meaning and kindly.
                <br />
                &quot;a benevolent smile&quot;
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" mt={8}>
            Accordion
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Accordion 1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                <Typography>Accordion 2</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion disabled>
              <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
                <Typography>Disabled Accordion</Typography>
              </AccordionSummary>
            </Accordion>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" mt={8}>
            AppBar
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" mb="4">
              <Toolbar>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                  <SoeMenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  News
                </Typography>
                <Button color="inherit">Login</Button>
              </Toolbar>
            </AppBar>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
