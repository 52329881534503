/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/api/slider/
 */

export default function Slider() {
  return {
    MuiSlider: {
      defaultProps: {},
      styleOverrides: {},
    },
  }
}
