// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"

// Formik
import { useFormik } from "formik"

// Theme
import FullAddressForm from "../../../../../../../../full-address-form"

import { getAddressFormat, isRequiredField } from "../../../../../../../../../services/address-form"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../../../../services/address-form/slice"
import { useUpdateCompanyMutation } from "../../../../../../../../../services/company/slice"

function CompanyBillingContactDrawer({ company, onSubmitEvent, setShowCompanyBillingContactDrawer, refetchCompany }) {
  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()
  const [setCompanyDetails] = useUpdateCompanyMutation()

  const { t } = useTranslation("companies")

  const formik = useFormik({
    initialValues: {
      companyName: company?.billing?.address?.companyName || "",
      personName: company?.billing?.address?.personName || "",
      addressLine1: company?.billing?.address?.addressLine1 || "",
      addressLine2: company?.billing?.address?.addressLine2 || "",
      city: company?.billing?.address?.city || "",
      provinceName: company?.billing?.address?.provinceName || "",
      provinceCode: company?.billing?.address?.provinceCode || "",
      countryCode: company?.billing?.address?.countryCode || "CA",
      postalCode: company?.billing?.address?.postalCode || "",
      phoneNumber: company?.billing?.address?.phoneNumber || "",
      emailAddress: company?.billing?.address?.emailAddress || "",
    },
    validate: (formValues) => {
      const errors = {}

      if (!formValues.personName) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.personName) {
        errors.personName = t("fields.personName.error.required")
      }

      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      await setCompanyDetails({ companyId: company.id, billing: { address: formValues } })
      formik.resetForm()
      setShowCompanyBillingContactDrawer(false)
      setTimeout(() => refetchCompany(), 200)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <FullAddressForm formik={formik} displaySearchAddressBar displaySaveAddressCheckbox={false} displaySaveDefaultAddressCheckbox={false} displayResidentialToggle={false} displayEmailAddressField />
    </Box>
  )
}

CompanyBillingContactDrawer.defaultProps = {
  onSubmitEvent: undefined,
  company: undefined,
}

CompanyBillingContactDrawer.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    pk: PropTypes.string,
    emailAddress: PropTypes.string,
    billing: PropTypes.shape({
      address: PropTypes.shape({
        personName: PropTypes.string,
        companyName: PropTypes.string,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        provinceName: PropTypes.string,
        provinceCode: PropTypes.string,
        countryCode: PropTypes.string,
        postalCode: PropTypes.string,
        phoneNumber: PropTypes.string,
        emailAddress: PropTypes.string,
      }),
    }),
  }),
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowCompanyBillingContactDrawer: PropTypes.func.isRequired,
  refetchCompany: PropTypes.func.isRequired,
}

export default CompanyBillingContactDrawer
