import restApiSlice from "../../../../services/rest-api/slice"

export const attachmentsRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAttachments: builder.query({
      query: ({ orderId }) => ({ url: `/orders/${orderId}/attachments`, method: "GET" }),
      providesTags: ["Attachments"],
      transformResponse: (response) => response.data,
    }),
    addAttachmentFile: builder.mutation({
      query: ({ payload, orderId }) => ({ url: `/orders/${orderId}/attachments`, method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetAttachmentsQuery, useAddAttachmentFileMutation } = attachmentsRestApiSlice
