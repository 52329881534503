// React
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Custom style
import { CustomCard, CustomCardContent, MuiWrapper } from "./style"

function RateRequestSummaryCard({ title, children, disabled, backgroundColor, ...muiBoxProps }) {
  return (
    <MuiWrapper {...muiBoxProps}>
      <CustomCard disabled={disabled} sx={{ height: 1, backgroundColor }}>
        <CustomCardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="bodyMedium600">{title}</Typography>
          </Box>
          {children}
        </CustomCardContent>
      </CustomCard>
    </MuiWrapper>
  )
}

RateRequestSummaryCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
}

RateRequestSummaryCard.defaultProps = {
  title: undefined,
  children: undefined,
  disabled: false,
  backgroundColor: undefined,
}

export default RateRequestSummaryCard
