import restApiSlice from "../rest-api/slice"

export const bulkUploadRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBulkUploads: builder.query({
      query: () => ({ url: "/bulk/shipments", method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    addNewBulkFile: builder.mutation({
      query: (payload) => ({ url: "/bulk/shipments/file", method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetBulkUploadsQuery, useAddNewBulkFileMutation } = bulkUploadRestApiSlice
