import React from "react"
import { Alert, AlertTitle, Card, CardHeader, CardContent, Container, Grid, Link, List, ListItem, Typography } from "@mui/material"

import "./styles.scss"

export default function TypographyExample() {
  return (
    <Container maxWidth="fit-content">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Material UI SOE Typography component" />
            <CardContent>
              <Alert sx={{ marginBottom: 2 }} severity="success">
                <AlertTitle>PRIORITY #1 : USE MUI SOE THEME</AlertTitle>
                <AlertTitle>(with the same name as found in Figma)</AlertTitle>
                <List>
                  <ListItem style={{ fontWeight: "bold" }}>{"<Typography /> :"}</ListItem>
                  <ListItem>
                    <Link href="https://mui.com/components/typography/" target="_blank">
                      {"MUI component <Typography />"}
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="https://mui.com/api/typography/" target="_blank">
                      {"MUI API <Typography />"}
                    </Link>
                  </ListItem>
                  <ListItem style={{ fontWeight: "bold" }}>{"<Divider /> :"}</ListItem>
                  <ListItem>
                    <Link href="https://mui.com/components/dividers/" target="_blank">
                      {"MUI component <Divider />"}
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="https://mui.com/api/dividers/" target="_blank">
                      {"MUI API <Divider />"}
                    </Link>
                  </ListItem>
                  <ListItem style={{ fontWeight: "bold" }}>{"<List /> :"}</ListItem>
                  <ListItem>
                    <Link href="https://mui.com/components/lists/" target="_blank">
                      {"MUI component <List />"}
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="https://mui.com/api/lists/" target="_blank">
                      {"MUI API <List />"}
                    </Link>
                  </ListItem>
                </List>
              </Alert>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="HeadingH1:" />
                <pre>
                  <code>
                    {`<Typography variant="headingH1" gutterBottom>
  Heading 1
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="headingH1" gutterBottom>
                    Heading 1
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="HeadingH2:" />
                <pre>
                  <code>
                    {`<Typography variant="headingH2" gutterBottom>
  Heading 2
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="headingH2" gutterBottom>
                    Heading 2
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="HeadingH3:" />
                <pre>
                  <code>
                    {`<Typography variant="headingH3" gutterBottom>
  Heading 3
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="headingH3" gutterBottom>
                    Heading 3
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="HeadingH4:" />
                <pre>
                  <code>
                    {`<Typography variant="headingH4" gutterBottom>
  Heading 4
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="headingH4" gutterBottom>
                    Heading 4
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="HeadingH5:" />
                <pre>
                  <code>
                    {`<Typography variant="headingH5" gutterBottom>
  Heading 5
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="headingH5" gutterBottom>
                    Heading 5
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="HeadingH6:" />
                <pre>
                  <code>
                    {`<Typography variant="headingH6" gutterBottom>
  Heading 6
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="headingH6" gutterBottom>
                    Heading 6
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2, paddingBottom: 2.85 }} variant="outlined">
                <CardHeader title="bodyMedium400:" />
                <pre>
                  <code>
                    {`<Typography variant="bodyMedium400" gutterBottom>
  bodyMedium400
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="bodyMedium400" gutterBottom>
                    bodyMedium400. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="bodyMedium600:" />
                <pre>
                  <code>
                    {`<Typography variant="bodyMedium600" gutterBottom>
  bodyMedium600
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="bodyMedium600" gutterBottom>
                    bodyMedium600. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="bodySmall400:" />
                <pre>
                  <code>
                    {`<Typography variant="bodySmall400" gutterBottom>
  bodySmall400
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="bodySmall400" gutterBottom>
                    bodySmall400. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="bodySmall600:" />
                <pre>
                  <code>
                    {`<Typography variant="bodySmall600" gutterBottom>
  bodySmall600
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="bodySmall600" gutterBottom>
                    bodySmall600. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="bodySmall200:" />
                <pre>
                  <code>
                    {`<Typography variant="bodySmall200" gutterBottom>
  bodySmall200
</Typography>`}
                  </code>
                </pre>
                <CardContent>
                  <Typography variant="bodySmall200" gutterBottom>
                    bodySmall200. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                  </Typography>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Material UI default Typography component" />
            <CardContent>
              <Alert sx={{ marginBottom: 2 }} severity="warning">
                <AlertTitle>PRIORITY #2 : USE MUI DEFAULT THEME</AlertTitle>
                <AlertTitle>(if not possible to include in MUI project theme)</AlertTitle>
                <List>
                  <ListItem style={{ fontWeight: "bold" }}>{"<Typography /> :"}</ListItem>
                  <ListItem>
                    <Link href="https://mui.com/components/typography/" target="_blank">
                      {"MUI component <Typography />"}
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="https://mui.com/api/typography/" target="_blank">
                      {"MUI API <Typography />"}
                    </Link>
                  </ListItem>
                  <ListItem style={{ fontWeight: "bold" }}>{"<Divider /> :"}</ListItem>
                  <ListItem>
                    <Link href="https://mui.com/components/dividers/" target="_blank">
                      {"MUI component <Divider />"}
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="https://mui.com/api/dividers/" target="_blank">
                      {"MUI API <Divider />"}
                    </Link>
                  </ListItem>
                  <ListItem style={{ fontWeight: "bold" }}>{"<List /> :"}</ListItem>
                  <ListItem>
                    <Link href="https://mui.com/components/lists/" target="_blank">
                      {"MUI component <List />"}
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="https://mui.com/api/lists/" target="_blank">
                      {"MUI API <List />"}
                    </Link>
                  </ListItem>
                </List>
              </Alert>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h1:" />
                <CardContent>
                  <Typography variant="h1" gutterBottom>
                    h1. Heading
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h2:" />
                <CardContent>
                  <Typography variant="h2" gutterBottom>
                    h2. Heading
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h3:" />
                <CardContent>
                  <Typography variant="h3" gutterBottom>
                    h3. Heading
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h4:" />
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    h4. Heading
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h5:" />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    h5. Heading
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h6:" />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    h6. Heading
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2, paddingBottom: 2.85 }} variant="outlined">
                <CardHeader title="body1:" />
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi
                    quidem quibusdam.
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="body2:" />
                <CardContent>
                  <Typography variant="body2" gutterBottom>
                    body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi
                    quidem quibusdam.
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="subtitle1:" typographyprops={({ variant: "subtitle1" }, { component: "subtitle1" })} />
                <CardContent>
                  <Typography gutterBottom>subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur</Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="subtitle2:" typographyprops={({ variant: "subtitle2" }, { component: "subtitle2" })} />
                <CardContent>
                  <Typography gutterBottom>subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur</Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="button:" />
                <CardContent>
                  <Typography variant="button" gutterBottom>
                    button text
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="caption:" typographyprops={({ variant: "caption" }, { component: "caption" })} />
                <CardContent>
                  {/* display="block" ? */}
                  <Typography gutterBottom>caption text</Typography>
                </CardContent>
              </Card>
              <Card variant="outlined">
                <CardHeader title="overline:" typographyprops={({ variant: "overline" }, { component: "overline" })} />
                <CardContent>
                  {/* display="block" ? */}
                  <Typography gutterBottom>overline text</Typography>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Base HTML Typography with GlobalStyle" />
            <CardContent>
              <Alert sx={{ marginBottom: 2, paddingBottom: 20.75 }} severity="error">
                <AlertTitle>NEVER USE HTML BASE TAGS, USE MUI INSTEAD</AlertTitle>
                <AlertTitle>(listing below shows the comparaison between MUI and base HTML)</AlertTitle>
                <List>
                  <ListItem>
                    {"<p>, <span>, <b>, <strong>, <h1> - <h6> : "}&nbsp;
                    <span style={{ fontWeight: "bold" }}>{"use MUI <Typography />"}</span>
                  </ListItem>
                  <ListItem>
                    {"<button>, <caption> : "}&nbsp;
                    <span style={{ fontWeight: "bold" }}>{"use MUI <Typography />"}</span>
                  </ListItem>
                  <ListItem>
                    {"<hr> : "}&nbsp;
                    <span style={{ fontWeight: "bold" }}>{"use MUI <Divider />"}</span>
                  </ListItem>
                  <ListItem>
                    {"<li> : "}&nbsp;
                    <span style={{ fontWeight: "bold" }}>{"use MUI <List />"}</span>
                  </ListItem>
                </List>
              </Alert>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h1:" />
                <CardContent>
                  <h1>h1. Heading</h1>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h2:" />
                <CardContent>
                  <h2>h2. Heading</h2>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h3:" />
                <CardContent>
                  <h3>h3. Heading</h3>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h4:" />
                <CardContent>
                  <h4>h4. Heading</h4>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h5:" />
                <CardContent>
                  <h5>h5. Heading</h5>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2 }} variant="outlined">
                <CardHeader title="h6:" />
                <CardContent>
                  <h6>h6. Heading</h6>
                </CardContent>
              </Card>
              <Card sx={{ marginBottom: 2, paddingBottom: 21.75 }} variant="outlined">
                <CardHeader title="p:" />
                <CardContent>
                  <p>
                    Paragraph. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                  </p>
                </CardContent>
              </Card>
              <Card variant="outlined">
                <CardHeader title="title:" />
                <CardContent>
                  <title>title. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur</title>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
