/**
 * stripe appearance
 * @link: https://stripe.com/docs/stripe-js/appearance-api#theme
 */

import palette from "./palette"
import shape from "./shape"
import typography from "./typography"

/**
 * @todo : choose a theme and use variables from theme to fill all default here
 * @link : https://stripe.com/docs/stripe-js/appearance-api#variables
 * Note: if we want to style exactly like our form, we need to set theme: "none" and use rules: {}
 */
const appearance = {
  theme: "stripe",
  variables: {
    borderRadius: `${shape.borderRadius}px`,
    colorBackground: palette.contentNeutralPrimaryReverse.main,
    colorDanger: palette.contentAlertError.main,
    colorPrimary: palette.backgroundNeutralBluecorporate.main,
    colorText: palette.contentNeutralPrimary.main,
    fontFamily: typography.fontFamily,
    fontSizeBase: typography.fontSize,
    spacingUnit: "4px",
  },
  rules: {}, // @link: https://stripe.com/docs/stripe-js/appearance-api#detail-rules
}

const stripe = { appearance }

export default stripe
