/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/drawers/
 * @link https://mui.com/api/drawer/
 */
export default function Drawer(theme) {
  return {
    MuiDrawer: {
      defaultProps: {
        anchor: "left",
        variant: "temporary",
        PaperProps: {},
      },
      styleOverrides: {
        root: {
          "& .MuiDrawer-paper": {
            width: theme.utils.pxToRem(400),
          },
        },
      },
    },
  }
}
