import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"
import DateTimeService from "../../services/date-time"

function AuditInfoCard({ audit, disabled }) {
  const { t } = useTranslation("auditInfoCard")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard disabled={disabled}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={2} textAlign="left">
          <Typography variant="bodySmall200" component="div" sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 16) }}>
            {t("auditFilename")}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography noWrap ml={theme.spacing(1)} component="div" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {audit.filename}
          </Typography>
        </Grid>
      </Grid>
      <Grid mt={1} container justifyContent="center" alignItems="center">
        <Grid item xs={2} textAlign="left">
          <Typography variant="bodySmall200" component="div" sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 16) }}>
            {t("auditDate")}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography noWrap ml={theme.spacing(1)} component="div" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {DateTimeService.toLocaleDateString(audit.date)}
          </Typography>
        </Grid>
      </Grid>
      <Grid mt={1} container justifyContent="center" alignItems="center">
        <Grid item xs={2} textAlign="left">
          <Typography variant="bodySmall200" component="div" sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 16) }}>
            {t("createdByAudit")}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography noWrap ml={theme.spacing(1)} component="div" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {audit.createdByAudit === true ? "Yes" : "No"}
          </Typography>
        </Grid>
      </Grid>
    </RateRequestSummaryCard>
  )
}

AuditInfoCard.propTypes = {
  audit: PropTypes.shape({
    filename: PropTypes.string,
    date: PropTypes.string,
    createdByAudit: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
}

AuditInfoCard.defaultProps = {
  audit: {
    filename: "",
    date: "",
    createdByAudit: false,
  },
  disabled: false,
}

export default AuditInfoCard
