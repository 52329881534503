/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/cards/
 * @link https://mui.com/api/card/
 *
 * @link https://mui.com/api/card-header/
 *
 * @link https://mui.com/api/card-content/
 *
 * @link https://mui.com/api/card-action-area/
 *
 * @link https://mui.com/api/card-actions/
 *
 * @link https://mui.com/api/card-action-area/
 */
export default function Card(theme) {
  return {
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        borderColor: theme.palette.strokeDefault,
        backgroundColor: theme.palette.backgroundNeutralGround,
      },
    },
    MuiCardHeader: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiCardContent: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
        },
      },
    },
    MuiCardMedia: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiCardActions: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiCardActionArea: {
      defaultProps: {},
      styleOverrides: {},
    },
  }
}
