import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import React from "react"

import { useTranslation } from "react-i18next"

import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"

function NoMatchCard() {
  const { t } = useTranslation("audits")
  return (
    <Card variant="outlined" square sx={{ height: 1 }}>
      <Box display="flex" alignItems="center" height={1} pl={pxToThemeSpacing(20)} sx={{ backgroundColor: "#FFEEEE" }}>
        <b>{t("auditDetails.noMatch")}</b>
      </Box>
    </Card>
  )
}

export default NoMatchCard
