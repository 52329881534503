// REACT
import React from "react"

// MUI
import Box from "@mui/material/Box"

// Others
import Logo from "../../../../assets/images/business-logo.svg"

function BusinessLogo() {
  return (
    <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
      <Box
        component="img"
        src={Logo}
        sx={(theme) => ({
          height: theme.utils.pxToRem(28),
          maxWidth: "none",
          mr: theme.spacing(1),
        })}
        alt="business logo"
      />
    </Box>
  )
}

export default BusinessLogo
