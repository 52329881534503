import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import TranslationService from "../../../../../../../../services/translation"

import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"
import RateRequestSummaryCard from "../../../../../../../rate-request-summary-card"

function ServiceCard({ service }) {
  const { i18n, t } = useTranslation("audits")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard title={t("auditDetails.auditItemDetails.carrierDetails.service.title")} sx={{ height: 1 }}>
      <Box mt={pxToThemeSpacing(10)}>
        {service?.fullName && (
          <Typography noWrap sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
            <b>{TranslationService.getLabelFromLocale(i18n.language, service.fullName)}</b>
          </Typography>
        )}
      </Box>
    </RateRequestSummaryCard>
  )
}

ServiceCard.propTypes = {
  service: PropTypes.shape({
    fullName: PropTypes.shape({}),
  }),
}

ServiceCard.defaultProps = {
  service: undefined,
}

export default ServiceCard
