// PROP-TYPES
import PropTypes from "prop-types"

// REACT
import React from "react"

// MUI
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { Formik, FieldArray } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

function CarrierNamesLanguagesForm({ formik, nameLabel, nameLabelPrefix, languages }) {
  const customTheme = useTheme()

  return (
    <Box component="div">
      <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
        {nameLabel}
      </Typography>
      <Box component="div" p={1} />
      <Formik initialValues={{ languages }}>
        {({ values }) => (
          <Box component="div">
            <FieldArray
              name="languages"
              render={() => (
                <Box component="div">
                  {values.languages &&
                    values.languages.length &&
                    values.languages.map((language, index) => (
                      <Box component="div" key={index.toString().concat(language)} mb={customTheme.utils.pxToThemeSpacing(16)} sx={{ display: "flex", flexDirection: "row" }}>
                        <TextField
                          key={`${index.toString().concat({ nameLabelPrefix }).concat([language.code])}`}
                          name={nameLabelPrefix.concat([language.code.toLocaleUpperCase()])}
                          id={nameLabelPrefix.concat([language.code.toLocaleUpperCase()])}
                          inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular } }}
                          InputLabelProps={{ style: { lineHeight: customTheme.utils.pxToLineHeight(16, 24) } }}
                          variant="outlined"
                          size="small"
                          label={language.name}
                          onChange={(event) => {
                            formik.setFieldValue(`${nameLabelPrefix}[${language.code}]`, event.target.value)
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[nameLabelPrefix][language.code] || ""}
                          fullWidth
                        />
                      </Box>
                    ))}
                </Box>
              )}
            />
          </Box>
        )}
      </Formik>
    </Box>
  )
}

CarrierNamesLanguagesForm.defaultProps = {
  languages: undefined,
}

CarrierNamesLanguagesForm.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  nameLabel: PropTypes.string.isRequired,
  nameLabelPrefix: PropTypes.string.isRequired,
  languages: PropTypes.instanceOf(Object),
}

export default CarrierNamesLanguagesForm
