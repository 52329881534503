// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import ClientDetailsSummaryCard from "../../../client-details-summary-card"
import { SummaryStatusVariantsEnum } from "../../../client-details-summary-card/components/summary-status"

function ClientBillingCard({ billing, defaultToSuccessStatus, handleClientBillingCardClick }) {
  const { t } = useTranslation("clients")
  const customTheme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToSuccessStatus) return SummaryStatusVariantsEnum.success
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <ClientDetailsSummaryCard title={t("clientDetails.clientDetailsInformations.billingCardTitle")} handleClick={handleClientBillingCardClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={customTheme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {billing?.type && (
          <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
            {t(`clientDetails.clientDetailsInformations.billing.type`)} {t(`clientDetails.clientDetailsInformations.billing.${billing?.type}`)}
          </Typography>
        )}
        {billing?.account?.number && (
          <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
            {t(`clientDetails.clientDetailsInformations.billing.number`)} {billing?.account?.number}
          </Typography>
        )}
        {billing?.account?.paymentTermsDays !== undefined && (
          <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
            {t(`clientDetails.clientDetailsInformations.billing.paymentTermsDays`)} {billing?.account?.paymentTermsDays}
          </Typography>
        )}
        {billing?.currency && (
          <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
            {t(`clientDetails.clientDetailsInformations.billing.currency`)} {billing?.currency}
          </Typography>
        )}
      </Box>
    </ClientDetailsSummaryCard>
  )
}

ClientBillingCard.propTypes = {
  billing: PropTypes.shape({
    type: PropTypes.string,
    currency: PropTypes.string,
    account: PropTypes.shape({
      number: PropTypes.string,
      paymentTermsDays: PropTypes.number,
    }),
  }).isRequired,
  defaultToSuccessStatus: PropTypes.bool,
  handleClientBillingCardClick: PropTypes.func,
}

ClientBillingCard.defaultProps = {
  handleClientBillingCardClick: () => {},
  defaultToSuccessStatus: false,
}

export default ClientBillingCard
