// REACT
import React from "react"

// MUI
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

// Theme
import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"

// Others
import Currency from "../../../../../../../currency"
import DateTimeService from "../../../../../../../../services/date-time"

import { AuditItemProps, AuditItemSectionProps } from "../../../../../../../../models/audit"

function AuditItemAccountCard({ rate, auditItem }) {
  return (
    <Card variant="outlined" square sx={{ height: 1 }}>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Stack m={pxToThemeSpacing(20)}>
            {auditItem.order?.number && (
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {auditItem.order.number}
              </Typography>
            )}
            {auditItem.shippingOptions?.account?.declared?.shippingDate && (
              <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {DateTimeService.toLocaleDateString(auditItem.shippingOptions.account.declared.shippingDate)}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {rate.amount !== undefined && (
            <Typography variant="bodySmall400" mr={pxToThemeSpacing(20)} sx={(theme) => ({ fontSize: theme.utils.pxToRem(14), fontWeight: theme.typography.fontWeightBold, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              <Currency amount={rate.amount} currency={rate.currency} currencyPosition="left" showSymbol />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

AuditItemAccountCard.propTypes = {
  rate: AuditItemSectionProps.isRequired,
  auditItem: AuditItemProps.isRequired,
}

export default AuditItemAccountCard
