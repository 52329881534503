import React from "react"
import { Box, Button, Card, CardHeader, CardContent, Container, Grid, IconButton, List, Link, ListItem } from "@mui/material"

import SoeCropSquareIcon from "../../icons/SoeCropSquareIcon"

import "./styles.scss"

export default function ButtonExample() {
  return (
    <Container maxWidth="fit-content">
      <List>
        <ListItem>
          <Link href="https://mui.com/components/Buttons/" target="_blank">
            {"MUI component <Button />"}
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://mui.com/api/Button/" target="_blank">
            {"MUI API <Button />"}
          </Link>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardHeader title="Material UI SOE Button component" />
            <Box sx={{ width: "100%" }}>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="contained - medium" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="contained" size="medium">
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" size="medium" disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="medium">
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="medium" disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="medium">
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="medium" disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="medium">
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="medium" disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="text - medium" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="text" size="medium">
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="text" size="medium" disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="medium">
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="medium" disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="contained - small" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="contained" size="small">
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" size="small" disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="small">
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="small" disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="small">
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="small" disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="small">
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>

                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="small" disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="text - small" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="text" size="small">
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="text" size="small" disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="small">
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="small" disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="contained - medium - endIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="contained" size="medium" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" size="medium" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="medium" endIcon={<SoeCropSquareIcon />}>
  action (contained medium with endIcon)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="medium" endIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - endIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="medium" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="medium" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="medium" endIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="medium" endIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="text - medium - endIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="text" size="medium" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="text" size="medium" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="medium" endIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="medium" endIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="contained small - endIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="contained" size="small" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" size="small" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="small" endIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="small" endIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined -small - endIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="small" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="small" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="small" endIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="small" endIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="text - small - endIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="text" size="small" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="text" size="small" endIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="small" endIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="small" endIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="contained - medium - startIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="contained" size="medium" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" size="medium" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="medium" startIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="medium" startIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - startIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="medium" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="medium" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="medium" startIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="medium" startIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="text - medium - startIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="text" size="medium" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="text" size="medium" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="medium" startIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="medium" startIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="contained - small - startIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="contained" size="small" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" size="small" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="small" startIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="small" startIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - startIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="small" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="small" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="small" startIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="small" startIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="text - small - startIcon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="text" size="small" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />}>
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="text" size="small" startIcon={<SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />} disabled>
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="small" startIcon={<SoeCropSquareIcon />}>
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="small" startIcon={<SoeCropSquareIcon />} disabled>
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>

              <CardContent />
              <CardContent />
              <CardContent />

              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="contained - medium - icon at center" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="contained" size="medium">
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" size="medium" disabled>
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="medium">
  <SoeCropSquareIcon />
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="medium" disabled>
  <SoeCropSquareIcon /> // (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>

              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - medium - icon at center" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="medium">
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="medium" disabled>
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="medium">
  <SoeCropSquareIcon />
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="medium" disabled>
  <SoeCropSquareIcon /> // (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>

              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="text - medium - icon at center" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="text" size="medium">
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="text" size="medium" disabled>
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="medium">
  <SoeCropSquareIcon />
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="medium" disabled>
  <SoeCropSquareIcon /> // (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>

              <CardContent />
              <CardContent />
              <CardContent />

              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="contained - small - icon at center" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="contained" size="small">
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" size="small" disabled>
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="small">
  <SoeCropSquareIcon />
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" size="small" disabled>
  <SoeCropSquareIcon /> // (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>

              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="outlined - small - icon at center" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="small">
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="outlined" size="small" disabled>
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="small">
  <SoeCropSquareIcon />
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" size="small" disabled>
  <SoeCropSquareIcon /> // (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>

              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="text - small - icon at center" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="text" size="small">
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="text" size="small" disabled>
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="small">
  <SoeCropSquareIcon />
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="text" size="small" disabled>
  <SoeCropSquareIcon /> // (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent />
              <CardContent />
              <CardContent />
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="medium - only Icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <IconButton size="medium">
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6}>
                        <IconButton size="medium" disabled>
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<IconButton size="medium">
  <SoeCropSquareIcon />
</IconButton>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<IconButton size="medium" disabled>
  <SoeCropSquareIcon /> // (disabled)
</IconButton>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>

              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="small - only Icon" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <IconButton size="small">
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6}>
                        <IconButton size="small" disabled>
                          <SoeCropSquareIcon sx={{ transform: "rotateY(0deg) rotate(45deg)" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<IconButton size="small">
  <SoeCropSquareIcon />
</IconButton>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<IconButton size="small" disabled>
  <SoeCropSquareIcon /> // (disabled)
</IconButton>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardContent>
                <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                  <CardHeader title="contained / outlined - medium - error" style={{ textAlign: "center" }} />
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Button variant="contained" color="error" size="medium">
                          action
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="outlined" color="error" size="medium">
                          action
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="contained" color="error" size="medium">
  action
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                      <Grid item xs={6}>
                        <pre>
                          <code>
                            {`<Button variant="outlined" color="error" size="medium">
  action (disabled)
</Button>`}
                          </code>
                        </pre>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
