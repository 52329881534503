import { createSlice } from "@reduxjs/toolkit"

import restApiSlice from "../services/rest-api/slice"
import TranslationService from "../services/translation"

import { getAddressSearchText, getPackagesSearchText } from "../utils/search"

export const AuditsSlice = createSlice({
  name: "audits",
  initialState: {
    order: "desc",
    orderBy: "createdDate",
  },
  reducers: {
    setOrder: (state, action) => {
      state.order = action.payload
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload
    },
  },
})

export const auditsRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAudits: builder.mutation({
      query: () => ({ url: "/audits", method: "GET" }),
      providesTags: ["Audits"],
      transformResponse: (response) =>
        response.data.map((audit) => {
          return {
            ...audit,
            carrierSearchText: audit?.carrierAccount?.name || "",
            accountNumberSearchText: audit?.invoice?.accountNumber || "",
          }
        }),
    }),
    getAuditFile: builder.mutation({
      query: (auditId) => ({ url: `/audits/${auditId}/file`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    addAuditFile: builder.mutation({
      query: ({ payload }) => ({ url: "/audits/file", method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
    redoAuditFile: builder.mutation({
      query: (auditId) => ({ url: `/audits/reprocess/${auditId}`, method: "GET" }),
    }),
    deleteAuditFile: builder.mutation({
      query: (auditId) => ({ url: `/audits/${auditId}`, method: "DELETE" }),
      invalidatesTags: ["Audits"],
    }),
    getAuditDetails: builder.query({
      query: (auditId) => ({ url: `/audits/${auditId}`, method: "GET" }),
      providesTags: ["Audit"],
      transformResponse: (response) => response.data,
    }),
    getAuditDetailsItems: builder.query({
      query: (auditId) => ({ url: `/audits/${auditId}/items`, method: "GET" }),
      providesTags: ["AuditItems"],
      transformResponse: (response) => response.data,
    }),
    getAuditItemDetails: builder.query({
      query: ({ auditId, auditItemId }) => ({ url: `/audits/${auditId}/items/${auditItemId}`, method: "GET" }),
      providesTags: ["AuditItemDetails"],
      transformResponse: (response) => response.data,
    }),
    validateAuditItemDetails: builder.mutation({
      query: ({ auditId, auditItemId, orderId, payload }) => ({ url: `/audits/${auditId}/items/${auditItemId}/orders/${orderId}/validate`, method: "POST", body: payload }),
      invalidatesTags: ["Audits", "AuditItems", "AuditItemDetails", "InvoicesCandidates"],
    }),
    unvalidateAuditItemDetails: builder.mutation({
      query: ({ auditId, auditItemId, orderId }) => ({ url: `/audits/${auditId}/items/${auditItemId}/orders/${orderId}/unvalidate`, method: "POST" }),
      invalidatesTags: ["Audits", "AuditItems", "AuditItemDetails", "InvoicesCandidates"],
    }),
    getAuditItemsOrders: builder.query({
      query: ({ auditId, auditItemId }) => ({ url: `/audits/${auditId}/items/${auditItemId}/orders`, method: "GET" }),
      providesTags: ["AuditItemsOrders"],
      transformResponse: (response, meta, arg) =>
        response.data.map((auditItemsOrder) => {
          return {
            ...auditItemsOrder,
            shipperSearchText: getAddressSearchText(auditItemsOrder.quotation?.request?.shipper),
            recipientSearchText: getAddressSearchText(auditItemsOrder.quotation?.request?.recipient),
            packagesSearchText: getPackagesSearchText(auditItemsOrder.quotation?.request?.packages),
            serviceSearchText: TranslationService.getLabelFromLocale(arg.language, auditItemsOrder.quotation?.response?.service?.fullName).concat(` ${auditItemsOrder?.tracking?.masterId}`) || "",
            amountSearchText: auditItemsOrder.quotation?.response?.rate?.totals?.account?.total?.amount || "",
          }
        }),
    }),
    addAuditItemsOrderOrAddlCharge: builder.mutation({
      query: ({ auditId, auditItemId, clientId, companyId }) => ({ url: `/audits/${auditId}/items/${auditItemId}/assign/${clientId}/company/${companyId}`, method: "POST" }),
      invalidatesTags: ["Audits", "AuditItems", "AuditItemDetails"],
    }),
    addAuditItemsManualMatchOrder: builder.mutation({
      query: ({ auditId, auditItemId, orderId }) => ({ url: `/audits/${auditId}/items/${auditItemId}/orders/${orderId}/match`, method: "POST" }),
      invalidatesTags: ["Audits", "AuditItems", "AuditItemDetails", "AuditItemsOrders", "InvoicesCandidates"],
    }),
    deleteAuditItemsManualUnmatchOrder: builder.mutation({
      query: ({ auditId, auditItemId, orderId }) => ({ url: `/audits/${auditId}/items/${auditItemId}/orders/${orderId}/unmatch`, method: "POST" }),
      invalidatesTags: ["Audits", "AuditItems", "AuditItemDetails", "AuditItemsOrders", "InvoicesCandidates"],
    }),
  }),
})

export const {
  useAddAuditItemsManualMatchOrderMutation,
  useAddAuditItemsOrderOrAddlChargeMutation,
  useAddAuditFileMutation,
  useDeleteAuditFileMutation,
  useDeleteAuditItemsManualUnmatchOrderMutation,
  useGetAuditsMutation,
  useGetAuditDetailsQuery,
  useGetAuditDetailsItemsQuery,
  useGetAuditFileMutation,
  useGetAuditItemDetailsQuery,
  useGetAuditItemsOrdersQuery,
  useRedoAuditFileMutation,
  useUnvalidateAuditItemDetailsMutation,
  useValidateAuditItemDetailsMutation,
} = auditsRestApiSlice

export const { setOrder, setOrderBy } = AuditsSlice.actions

export default AuditsSlice.reducer
