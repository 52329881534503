import restApiSlice from "../rest-api/slice"

const userRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => ({ url: "/users/me", method: "GET" }),
      providesTags: ["UserProfile"],
      transformResponse: (response) => response.data,
    }),
    getUsers: builder.query({
      query: () => ({ url: "/users", method: "GET" }),
      providesTags: ["users"],
      transformResponse: (response) => response.data,
    }),
    editUserProfile: builder.mutation({
      query: (payload) => ({ url: "/users/me", method: "PATCH", body: payload }),
      invalidatesTags: ["UserProfile"],
    }),
    disableUser: builder.mutation({
      query: (username) => ({ url: `/users/${username}/disable`, method: "PUT" }),
      invalidatesTags: ["users", "Clients"],
      transformResponse: (response) => response.data,
    }),
    enableUser: builder.mutation({
      query: (username) => ({ url: `/users/${username}/enable`, method: "PUT" }),
      invalidatesTags: ["users", "Clients"],
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetUserProfileQuery, useGetUsersQuery, useEditUserProfileMutation, useEnableUserMutation, useDisableUserMutation } = userRestApiSlice

export default userRestApiSlice.reducer
