import React from "react"
import PropTypes from "prop-types"
import { Box, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import SummaryCard from "../../../../../summary-card"
import SummaryStatusVariantsEnum from "../../../../../summary-card/components/summary-status/contant"

function StripeSettingsCard({ handleStripeSettingsCardClick, stripeSettings }) {
  const theme = useTheme()
  const { t } = useTranslation("settings")

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <SummaryCard title={t("settings.stripeSettingsCard.title.label")} handleClick={handleStripeSettingsCardClick} summaryStatusProps={summaryStatusProps} isDisabled={false}>
      {stripeSettings?.settings?.length > 0 && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {stripeSettings?.settings?.map((setting, index) => (
            <Typography key={"".concat(index)} sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {setting.name}
              {t("settings.keyValueSeparator")}
              {setting.value}
            </Typography>
          ))}
        </Box>
      )}
    </SummaryCard>
  )
}

StripeSettingsCard.defaultProps = {
  handleStripeSettingsCardClick: () => {},
  stripeSettings: undefined,
}

StripeSettingsCard.propTypes = {
  handleStripeSettingsCardClick: PropTypes.func,
  stripeSettings: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
}

export default StripeSettingsCard
