// REACT
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import RateRequestSummaryCard from "../rate-request-summary-card"

function OrderPaymentsCard({ order }) {
  const { t } = useTranslation("orderPaymentsCard")
  const customTheme = useTheme()

  return (
    <RateRequestSummaryCard title={t("orderPaymentsTitle")}>
      {order?.paymentId && (
        <Box mt={customTheme.spacing(1)} sx={{ display: "flex", flexDirection: "column" }}>
          {order.paymentId && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("paymentId.label")} {order.paymentId}
            </Typography>
          )}
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

OrderPaymentsCard.propTypes = {
  order: PropTypes.instanceOf(Object),
}

OrderPaymentsCard.defaultProps = {
  order: undefined,
}

export default OrderPaymentsCard
