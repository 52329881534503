// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"
import SoeEditIcon from "../../../../../../../../../soe-theme/src/icons/SoeEditIcon"

export const SummaryStatusVariantsEnum = {
  success: "success",
  warning: "warning",
  disabled: "disabled",
  icon: "icon",
}

function SummaryStatus({ variant, text, sx }) {
  const theme = useTheme()
  const { t } = useTranslation("clients")

  if (!Object.values(SummaryStatusVariantsEnum).includes(variant)) throw new Error("Invalid variant")

  let color
  let contentToDisplay

  if (variant === SummaryStatusVariantsEnum.success) {
    color = theme.palette.contentAlertPositive.main
    contentToDisplay = t("summaryStatus.toEdit.label") || text
  }
  if (variant === SummaryStatusVariantsEnum.warning) {
    color = theme.palette.contentAlertWarning.main
    contentToDisplay = t("summaryStatus.toComplete.label") || text
  }
  if (variant === SummaryStatusVariantsEnum.disabled) {
    color = theme.palette.contentNeutralSecondary.main
    contentToDisplay = t("summaryStatus.disabled.label") || text
  }

  if (variant === SummaryStatusVariantsEnum.icon) {
    color = theme.palette.contentNeutralSecondary.main
    contentToDisplay = <SoeEditIcon style={{ width: 25 }} /> || text
  }

  return (
    <Typography noWrap variant="bodySmall600" sx={{ color, ...sx }}>
      {contentToDisplay}
    </Typography>
  )
}

SummaryStatus.propTypes = {
  variant: PropTypes.string.isRequired,
  text: PropTypes.string,
  sx: PropTypes.instanceOf(Object),
}

SummaryStatus.defaultProps = {
  text: undefined,
  sx: undefined,
}

export default SummaryStatus
