import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Typography } from "@mui/material"
import PropTypes from "prop-types"
import SoeConfirmationDialog from "../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogActions from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import SoeConfirmationDialogContainer from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogContent from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogHeader from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"

import { useDisableUserMutation, useEnableUserMutation } from "../../../../services/user/slice"
import { useEditClientDetailsMutation } from "../../../clients/slice"
import MoreMenu from "../../../more-menu"

function User({ user }) {
  const { t } = useTranslation("users")
  const [enableUser] = useEnableUserMutation()
  const [disableUser] = useDisableUserMutation()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showRemoveAdminConfirmationDialog, setShowRemoveAdminConfirmationDialog] = useState(false)
  const [setClientDetails] = useEditClientDetailsMutation()

  const handleChangeUserStatus = () => {
    setShowConfirmationDialog(true)
  }

  const handleRemoveAdmin = () => {
    setShowRemoveAdminConfirmationDialog(true)
  }
  const sideMenuOptionList = [
    {
      label: user?.disabled ? t("sideMenu.enable") : t("sideMenu.disable"),
      handleClick: handleChangeUserStatus,
    },
    {
      label: t("sideMenu.removeAdmin"),
      handleClick: handleRemoveAdmin,
    },
  ]

  const handleClose = () => {
    setShowConfirmationDialog(false)
  }

  const handleConfirm = () => {
    if (user.disabled) enableUser(user.pk.slice(2))
    else disableUser(user.pk.slice(2))
    setShowConfirmationDialog(false)
  }

  const handleCloseRemoveAdminConfirmationDialog = () => {
    setShowRemoveAdminConfirmationDialog(false)
  }

  const handleConfirmationDialogConfirm = async () => {
    await setClientDetails({ clientId: user.pk.slice(2), payload: { isAdmin: false } })
  }

  return (
    <>
      <SoeConfirmationDialog showConfirmationDialog={showRemoveAdminConfirmationDialog} setShowConfirmationDialog={setShowRemoveAdminConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={t("confirmationDialog.title.label.removeAdmin")}
            setShowConfirmationDialog={() => {
              setShowRemoveAdminConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("confirmationDialog.content.label.removeAdmin")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleCloseRemoveAdminConfirmationDialog,
                label: t("confirmationDialog.action.cancel.label"),
                variant: "outlined",
              },
              {
                action: handleConfirmationDialogConfirm,
                label: t("confirmationDialog.action.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={user.disabled ? t("confirmationDialog.title.label.enable") : t("confirmationDialog.title.label.disable")}
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {user.disabled ? t("confirmationDialog.content.label.enable") : t("confirmationDialog.content.label.disable")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleClose,
                label: t("confirmationDialog.action.cancel.label"),
                variant: "outlined",
              },
              {
                action: handleConfirm,
                label: t("confirmationDialog.action.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
      <TableRow hover component="tr" sx={(theme) => ({ backgroundColor: theme.palette.contentNeutralPrimaryReverse.main })}>
        <TableCell component="td" scope="row">
          {user.clientAddress?.personName || user.personName}
        </TableCell>
        <TableCell component="td" scope="row" colSpan={7}>
          {user.emailAddress}
        </TableCell>
        <TableCell component="td" scope="row" align="left">
          <Chip label={user.disabled ? t("userStatus.inactive") : t("userStatus.active")} size="small" color={user.disabled ? "error" : "success"} sx={(theme) => ({ color: theme.palette.contentNeutralPrimaryReverse.main })} />
        </TableCell>
        <TableCell component="td" scope="row" align="right">
          <MoreMenu optionsList={sideMenuOptionList} />
        </TableCell>
      </TableRow>
    </>
  )
}

User.propTypes = {
  user: PropTypes.shape({
    pk: PropTypes.string,
    disabled: PropTypes.bool,
    clientAddress: PropTypes.instanceOf(Object),
    personName: PropTypes.string,
    emailAddress: PropTypes.string,
    legacy: PropTypes.shape({
      customer_recstat: PropTypes.string,
    }),
  }).isRequired,
}

export default User
