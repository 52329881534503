// REACT
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Custom theme
import { Grid, useTheme } from "@mui/material"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"

// Others

import GeneralInfoPasswordCard from "./components/general-info-password-card"
import GeneralInfoPasswordDrawer from "./components/general-info-password-drawer"
import GeneralInfoLanguageCard from "./components/general-info-language-card"
import GeneralInfoLanguageDrawer from "./components/general-info-language-drawer"

function UserProfileGeneralInfo() {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const [showGeneralInfoPasswordDrawer, setShowGeneralInfoPasswordDrawer] = useState(false)
  const [showGeneralInfoLanguageDrawer, setShowGeneralInfoLanguageDrawer] = useState(false)

  const [onSubmitEvent, setSubmitEvent] = useState()

  const handleGeneralInfoPasswordCardClick = () => {
    setSubmitEvent()
    setShowGeneralInfoPasswordDrawer(true)
  }

  const handleGeneralInfoLanguageCardClick = () => {
    setSubmitEvent()
    setShowGeneralInfoLanguageDrawer(true)
  }

  return (
    <Box component="div">
      <Box display="flex" pt={customTheme.utils.pxToThemeSpacing(20)} pr={customTheme.utils.pxToThemeSpacing(10)} pb={customTheme.utils.pxToThemeSpacing(20)} pl={customTheme.utils.pxToThemeSpacing(10)}>
        <Box component="h5">
          <Typography sx={(theme) => ({ fontSize: customTheme.utils.pxToRem(18), fontWeight: theme.typography.fontWeightBold })}>{t("userProfile.generalInformation.title.label")}</Typography>
        </Box>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <GeneralInfoPasswordCard handleGeneralInfoPasswordCardClick={() => handleGeneralInfoPasswordCardClick()} />
        </Grid>
        <Grid item xs={3}>
          <GeneralInfoLanguageCard handleGeneralInfoLanguageCardClick={() => handleGeneralInfoLanguageCardClick()} />
        </Grid>
      </Grid>

      <SoeDrawer showDrawer={showGeneralInfoPasswordDrawer} setShowDrawer={setShowGeneralInfoPasswordDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.generalInfoPasswordDrawer.changePassword.title")}
            setShowDrawer={() => {
              setShowGeneralInfoPasswordDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <GeneralInfoPasswordDrawer onSubmitEvent={onSubmitEvent} setShowGeneralInfoPasswordDrawer={setShowGeneralInfoPasswordDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.generalInfoPasswordDrawer.changePassword.actions.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showGeneralInfoLanguageDrawer} setShowDrawer={setShowGeneralInfoLanguageDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.languageDrawer.title")}
            setShowDrawer={() => {
              setShowGeneralInfoLanguageDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <GeneralInfoLanguageDrawer onSubmitEvent={onSubmitEvent} setShowGeneralInfoLanguageDrawer={setShowGeneralInfoLanguageDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.languageDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Box>
  )
}

export default UserProfileGeneralInfo
