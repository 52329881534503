import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CurrencyService from "../../services/currency"

const POSITION = {
  LEFT: "left",
  RIGHT: "right",
  NONE: "none",
}

function Currency({ amount, currency, color, showSymbol, currencyPosition }) {
  const { i18n } = useTranslation()
  const theme = useTheme()

  if (!color) color = theme.palette.contentNeutralPrimary.main

  return (
    <Typography variant="inherit" component="span" sx={{ color }}>
      {currencyPosition === POSITION.LEFT && currency} {CurrencyService.toLocaleString(i18n.language, amount, currency, showSymbol)} {currencyPosition === POSITION.RIGHT && currency}
    </Typography>
  )
}

Currency.defaultProps = {
  color: undefined,
  showSymbol: true,
  currencyPosition: POSITION.NONE,
}

Currency.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  color: PropTypes.string,
  showSymbol: PropTypes.bool,
  currencyPosition: PropTypes.string,
}

export default Currency
