import Chip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

const CustomChip = styled(Chip, { name: "Chip", slot: "Root" })(({ theme }) => ({
  backgroundColor: theme.palette.contentAlertInformativeDarker.main,
  color: theme.palette.backgroundNeutralGround.main,
  textTransform: "none",
  borderRadius: 12,
  marginRight: theme.spacing(theme.utils.pxToThemeSpacing(8)),
  marginTop: theme.spacing(theme.utils.pxToThemeSpacing(6)),
}))

export default CustomChip
