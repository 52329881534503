import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import { Skeleton, TableCell, TableRow } from "@mui/material"
import CustomPagination from "../../../../../../custom-pagination"
import EnhancedTableHead from "../../../../../../enhanced-table-head"
import getComparator from "../../../../../../../utils/sorting"
import CompanyDetailsInvoice from "../company-details-invoice"
import { useGetClientDetailsInvoicesQuery } from "../../../../../../clients/slice"

function CompanyDetailsInvoiceList({ maxRows, companyId, dataRangeFilterValues }) {
  const { t } = useTranslation("clients")
  const theme = useTheme()
  const { data: invoices, isFetching } = useGetClientDetailsInvoicesQuery({ companyId, from: dataRangeFilterValues?.from, to: dataRangeFilterValues?.to }, { skip: !companyId || !dataRangeFilterValues })

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("invoiceNumber")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(maxRows)

  const headCells = [
    {
      id: "invoiceNumber",
      disablePadding: false,
      label: t("invoiceList.headers.invoiceNumber"),
    },
    {
      id: "createdDate",
      disablePadding: false,
      label: t("invoiceList.headers.createdDate"),
    },
    {
      id: "user.billing.currency",
      disablePadding: false,
      label: t("invoiceList.headers.currency"),
    },
    {
      id: "dueDate",
      disablePadding: false,
      label: t("invoiceList.headers.dueDate"),
    },
  ]

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Box sx={{ width: "auto" }} className="manage-companies-table">
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table size="small" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1.main }}>
            <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {!invoices || isFetching ? (
                <>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <TableRow key={"".concat(index)}>
                          <TableCell colSpan={12}>
                            <Skeleton variant="rectangular" height={50} width="100%" />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </>
              ) : (
                invoices
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((invoice, index) => {
                    return <CompanyDetailsInvoice key={"".concat(index)} invoice={invoice} />
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {invoices && <CustomPagination count={Math.ceil(invoices.length / rowsPerPage)} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />}
      </Paper>
    </Box>
  )
}
CompanyDetailsInvoiceList.propTypes = {
  companyId: PropTypes.string.isRequired,
  dataRangeFilterValues: PropTypes.instanceOf(Object),
  maxRows: PropTypes.number,
}

CompanyDetailsInvoiceList.defaultProps = {
  maxRows: Infinity,
  dataRangeFilterValues: undefined,
}

export default CompanyDetailsInvoiceList
