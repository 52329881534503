import Card from "@mui/material/Card"
import React from "react"
import Skeleton from "@mui/material/Skeleton"

import UserGroup from "./components/user-group"

import { useGetInvoicesCandidatesCompaniesQuery } from "../../slice"

function InvoiceRows() {
  const { data: companyCandidates, isFetching, refetch } = useGetInvoicesCandidatesCompaniesQuery()
  return (
    <Card variant="elevation" square>
      {!companyCandidates || isFetching ? (
        <Skeleton variant="rectangular" width="100%" height={100} />
      ) : (
        <>
          {companyCandidates.map((compCandidate, index) => {
            return <UserGroup key={"".concat(index)} company={compCandidate.company} candidates={compCandidate.candidates} currency={compCandidate.currency} refetchCandidates={refetch} />
          })}
        </>
      )}
    </Card>
  )
}

export default InvoiceRows
