import { getLastMonthRange, getLastTwoWeeksRange, getLastWeekRange, getThisWeekRange, getTodayRange, getYesterdayRange } from "./utils"

const DateRangeEnum = {
  TODAY: getTodayRange(),
  YESTERDAY: getYesterdayRange(),
  THIS_WEEK: getThisWeekRange(),
  LAST_WEEK: getLastWeekRange(),
  LAST_TWO_WEEKS: getLastTwoWeeksRange(),
  LAST_MONTH: getLastMonthRange(),
  CUSTOM: {},
}

export default DateRangeEnum
