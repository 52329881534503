// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import TranslationService from "../../../../../../services/translation"

function Input({ formik, fieldFormat }) {
  const { i18n } = useTranslation("province")

  const theme = useTheme()

  const SPACING = 10
  const size = fieldFormat?.grid || 12
  const label = fieldFormat?.label && TranslationService.getLabelFromLocale(i18n.language, fieldFormat.label)
  const name = fieldFormat?.name
  const inputProps = {
    inputtype: fieldFormat?.form?.attributes?.inputType,
    maxLength: fieldFormat?.form?.attributes?.maxlength,
  }

  return (
    <Grid item xs={12} md={size} my={theme.utils.pxToThemeSpacing(SPACING)}>
      <TextField
        name={name}
        id={name}
        label={label}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name] || ""}
        error={formik.touched[name] && formik.errors[name] !== undefined}
        type="text"
        variant="outlined"
        fullWidth
        size="small"
        inputProps={inputProps}
      />
    </Grid>
  )
}

Input.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  fieldFormat: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string.isRequired,
      fr: PropTypes.string,
    }).isRequired,
    name: PropTypes.string.isRequired,
    grid: PropTypes.number.isRequired,
    form: PropTypes.shape({
      attributes: PropTypes.shape({
        inputType: PropTypes.string,
        maxlength: PropTypes.string,
      }),
    }),
    validations: PropTypes.shape({
      isRequired: PropTypes.bool,
      maxLength: PropTypes.number,
      minLength: PropTypes.number,
      regex: PropTypes.shape({
        error: PropTypes.shape({
          code: PropTypes.string,
          messages: PropTypes.shape({
            en: PropTypes.string.isRequired,
            fr: PropTypes.string,
          }),
        }),
        test: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default Input
