// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import { Grid } from "@mui/material"
import Box from "@mui/material/Box"
import Masonry from "@mui/lab/Masonry"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom theme
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"

// Others
import ClientEmailDrawer from "./components/client-email-drawer"
import ClientBillingCard from "./components/client-billing-card"
import ClientBillingDrawer from "./components/client-billing-drawer"
import ClientBillingContactCard from "./components/client-billing-contact-card"
import ClientBillingContactDrawer from "./components/client-billing-contact-drawer"
import ClientContactInformationCard from "./components/client-contact-information-card"
import ClientContactInformationDrawer from "./components/client-contact-information-drawer"
import ClientPricingGroupCard from "./components/client-pricing-group-card"
import ClientPricingGroupDrawer from "./components/client-pricing-group-drawer"

import { useGetPricingGroupsQuery } from "../../../../../pricing/slice"

function ClientDetailsInformations({ client }) {
  const { t } = useTranslation("clients")
  const theme = useTheme()

  const [showClientContactInformationDrawer, setShowClientContactInformationDrawer] = useState(false)
  const [showClientPricingGroupDrawer, setShowClientPricingGroupDrawer] = useState(false)
  const [showClientEmailDrawer, setShowClientEmailDrawer] = useState(false)
  const [showClientBillingContactDrawer, setShowClientBillingContactDrawer] = useState(false)
  const [showClientBillingDrawer, setShowClientBillingDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()

  const { data: pricingGroups } = useGetPricingGroupsQuery()

  const handleClientContactInformationCardClick = () => {
    setSubmitEvent()
    setShowClientContactInformationDrawer(true)
  }

  const handleClientPricingGroupCardClick = () => {
    setSubmitEvent()
    setShowClientPricingGroupDrawer(true)
  }

  const handleClientBillingContactCardClick = () => {
    setSubmitEvent()
    setShowClientBillingContactDrawer(true)
  }

  const handleClientBillingCardClick = () => {
    setSubmitEvent()
    setShowClientBillingDrawer(true)
  }

  const handleSetDefaultTag = () => {
    let clientPricingGroup
    let clientPricingGroupDefault
    if (pricingGroups) {
      const customerPricingGroup = client?.company?.pricingGroupCode || client?.pricingGroupCode
      if (customerPricingGroup) clientPricingGroup = pricingGroups?.find((pricingGroup) => pricingGroup?.code === customerPricingGroup)
      else clientPricingGroupDefault = `${t("clientDetails.clientDetailsInformations.clientPricingGroup.default.label")}${" ("}${pricingGroups?.find((pricingGroup) => pricingGroup?.isDefault === true)?.name}${")"}`
      if (clientPricingGroupDefault) return <Typography>{clientPricingGroupDefault}</Typography>
      return <Typography>{clientPricingGroup?.name}</Typography>
    }
    return undefined
  }

  return (
    <Box component="div">
      <Box display="flex" pt={theme.utils.pxToThemeSpacing(20)} pr={theme.utils.pxToThemeSpacing(10)} pb={theme.utils.pxToThemeSpacing(20)} pl={theme.utils.pxToThemeSpacing(10)}>
        <Box component="div">
          <Typography variant="headingH5" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {t("clientDetails.clientDetailsInformations.title")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" pl={theme.utils.pxToThemeSpacing(6)}>
        <Masonry columns={4} spacing={theme.utils.pxToThemeSpacing(6)}>
          <ClientContactInformationCard clientAddress={client.clientAddress} clientCreatedDate={client.createdDate} defaultToSuccessStatus handleClientContactInformationCardClick={() => handleClientContactInformationCardClick()} />
          <Grid>
            <ClientPricingGroupCard
              title={t("clientDetails.clientDetailsInformations.pricingGroupCardTitle")}
              pricingGroupCode={client.company?.pricingGroupCode || client.pricingGroupCode}
              pricingGroups={pricingGroups}
              pricingGroupName={handleSetDefaultTag()}
              defaultToSuccessStatus
              handleClientPricingGroupCardClick={() => handleClientPricingGroupCardClick()}
            />
            <div style={{ height: "10px" }}> </div>
          </Grid>
          <ClientBillingContactCard address={client?.billing.address} defaultToSuccessStatus handleClientBillingContactCardClick={handleClientBillingContactCardClick} />
          <ClientBillingCard billing={client.billing} defaultToSuccessStatus handleClientBillingCardClick={() => handleClientBillingCardClick()} />
        </Masonry>
      </Box>
      <SoeDrawer showDrawer={showClientContactInformationDrawer} setShowDrawer={setShowClientContactInformationDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("clientDetails.clientDetailsInformations.clientContactInformationDrawer.title")}
            setShowDrawer={() => {
              setShowClientContactInformationDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <ClientContactInformationDrawer client={client} onSubmitEvent={onSubmitEvent} setSubmitEvent={setSubmitEvent} setShowClientContactInformationDrawer={setShowClientContactInformationDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("clientDetails.clientDetailsInformations.clientContactInformationDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <SoeDrawer showDrawer={showClientPricingGroupDrawer} setShowDrawer={setShowClientPricingGroupDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("clientDetails.clientDetailsInformations.clientPricingGroupDrawer.title")}
            setShowDrawer={() => {
              setShowClientPricingGroupDrawer(false)
            }}
          />
          <SoeDrawerContent>
            {pricingGroups && (
              <ClientPricingGroupDrawer
                client={client}
                pricingGroups={pricingGroups}
                selectedPricingGroup={pricingGroups?.find((pricingGroup) => pricingGroup.code === (client.company?.pricingGroupCode || client?.pricingGroupCode))}
                onSubmitEvent={onSubmitEvent}
                setSubmitEvent={setSubmitEvent}
                setShowClientPricingGroupDrawer={setShowClientPricingGroupDrawer}
              />
            )}
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("clientDetails.clientDetailsInformations.clientPricingGroupDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showClientEmailDrawer} setShowDrawer={setShowClientEmailDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("clientDetails.clientDetailsInformations.clientPricingGroupDrawer.title")}
            setShowDrawer={() => {
              setShowClientEmailDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <ClientEmailDrawer
              email={client.emailAddress}
              setShowClientEmailDrawer={setShowClientEmailDrawer}
              onSubmitEvent={onSubmitEvent}
            />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("clientDetails.clientDetailsInformations.clientPricingGroupDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showClientBillingContactDrawer} setShowDrawer={setShowClientBillingContactDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("clientDetails.clientDetailsInformations.clientBillingContactDrawer.title")}
            setShowDrawer={() => {
              setShowClientBillingContactDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <ClientBillingContactDrawer client={client} onSubmitEvent={onSubmitEvent} setShowClientBillingContactDrawer={setShowClientBillingContactDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("clientDetails.clientDetailsInformations.clientBillingContactDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <SoeDrawer showDrawer={showClientBillingDrawer} setShowDrawer={setShowClientBillingDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("clientDetails.clientDetailsInformations.clientBillingDrawer.title")}
            setShowDrawer={() => {
              setShowClientBillingDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <ClientBillingDrawer client={client} onSubmitEvent={onSubmitEvent} setSubmitEvent={setSubmitEvent} setShowClientBillingDrawer={setShowClientBillingDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("clientDetails.clientDetailsInformations.clientBillingDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Box>
  )
}

ClientDetailsInformations.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
}

export default ClientDetailsInformations
