/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/lists/
 * @link https://mui.com/api/list/
 *
 * @link https://mui.com/api/list-item/
 *
 * @link https://mui.com/api/list-item-avatar/
 *
 * @link https://mui.com/api/list-item-button/
 *
 * @link https://mui.com/api/list-item-icon/
 *
 * @link https://mui.com/api/list-item-secondary-action/
 *
 * @link https://mui.com/api/list-item-text/
 *
 * @link https://mui.com/api/list-subheader/
 */
export default function Lists(theme) {
  return {
    MuiList: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.palette.contentNeutralPrimary.main,
        },
      },
    },
    MuiListItem: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiListItemAvatar: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiListItemButton: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.palette.contentNeutralPrimary.main,
        },
      },
    },
    MuiListItemIcon: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.palette.contentNeutralPrimary.main,
        },
      },
    },
    MuiListItemSecondaryAction: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiListItemText: {
      defaultProps: {},
      styleOverrides: {
        root: {
          fontWeight: "inherit",
          fontSize: "inherit",
          lineHeight: "inherit",
          color: theme.palette.contentActionDefault.main,
          "& .MuiListItemText-secondary": {
            color: theme.palette.contentNeutralPrimary.main,
          },
        },
      },
    },
    MuiListSubheader: {
      defaultProps: {},
      styleOverrides: {
        root: {
          fontSize: theme.typography.bodyMedium600.fontSize,
          fontWeight: theme.typography.bodyMedium600.fontWeight,
          lineHeight: theme.typography.bodyMedium600.lineHeight,
          color: theme.palette.contentNeutralPrimary.main,
        },
      },
    },
  }
}
