import AppConfig from "../../../../utils/app-config"

export const groupInvoicesByCompanyIdAndToEmail = (invoices) => {
  return invoices.reduce((acc, invoice) => {
    const invoiceOwner = invoice.company || invoice.user
    const { companyId } = invoice
    const emailAddress = invoiceOwner.billing?.address?.emailAddress || invoiceOwner.emailAddress

    if (!acc[companyId]) {
      acc[companyId] = {}
    }

    if (!acc[companyId][emailAddress]) {
      acc[companyId][emailAddress] = {
        invoices: [],
        cc: AppConfig.features.emailInvoiceCCList,
      }
    }

    acc[companyId][emailAddress].invoices.push(invoice)

    return acc
  }, {})
}

export const transformGroupedInvoicesForRequestBody = (invoices) => {
  const final = Object.keys(invoices).flatMap((clientId) => {
    return Object.keys(invoices[clientId]).flatMap((emailAddress) => {
      return invoices[clientId][emailAddress].invoices.map((invoice) => {
        return {
          to: emailAddress,
          cc: invoices[clientId][emailAddress].cc,
          invoiceId: invoice.id,
        }
      })
    })
  })
  return final
}
