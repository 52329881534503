// REACT
import { useTheme } from "@emotion/react"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import UserContext from "../../../../../../services/user/context"

import { LANGUAGES } from "../../../../../../constant"
import SummaryCard from "../../../../../summary-card"
import SummaryStatusVariantsEnum from "../../../../../summary-card/components/summary-status/contant"

function GeneralInfoLanguageCard({ handleGeneralInfoLanguageCardClick }) {
  const { t } = useTranslation("userProfile")
  const { currentUser } = useContext(UserContext)
  const theme = useTheme()

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <SummaryCard title={t("userProfile.generalInfoLanguageCard.title")} handleClick={handleGeneralInfoLanguageCardClick} summaryStatusProps={summaryStatusProps}>
      {currentUser?.defaultLanguage && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {LANGUAGES.find((language) => language.code === currentUser?.defaultLanguage).name}
          </Typography>
        </Box>
      )}
    </SummaryCard>
  )
}

GeneralInfoLanguageCard.propTypes = {
  handleGeneralInfoLanguageCardClick: PropTypes.func,
}

GeneralInfoLanguageCard.defaultProps = {
  handleGeneralInfoLanguageCardClick: () => {},
}

export default GeneralInfoLanguageCard
