import restApiSlice from "../rest-api/slice"

export const inexFeeRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInexFeesByCompanyId: builder.query({
      query: (companyId) => ({ url: `/inex-fees/${companyId}`, method: "GET" }),
      transformResponse: (response) => response.data[0],
    }),
    createInexFee: builder.mutation({
      query: (payload) => ({ url: "/inex-fees", method: "POST", body: payload }),
      transformResponse: (response) => response.data[0],
    }),
    updateInexFee: builder.mutation({
      query: ({ inexFeeId, payload }) => ({ url: `/inex-fees/${inexFeeId}`, method: "PATCH", body: payload }),
      transformResponse: (response) => response.data[0],
    }),
  }),
})

export const { useGetInexFeesByCompanyIdQuery, useCreateInexFeeMutation, useUpdateInexFeeMutation } = inexFeeRestApiSlice
