// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CarrierAccounts from "../carrier-accounts"
import CarrierCharges from "../carrier-charges"
import CarrierGeneralInfoDrawer from "../carrier-general-info-drawer"
import CarrierServiceLogo from "../../../carrier-service-logo"
import CarrierServices from "../carrier-services"
import TranslationService from "../../../../services/translation"
import MoreMenu from "../../../more-menu"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useGetLanguagesQuery } from "../../../../services/rest-api/slice"

function CarrierManagement({ carrier }) {
  const customTheme = useTheme()
  const { t, i18n } = useTranslation("carriers")

  const [showCarrierGeneralInfoDrawer, setShowCarrierGeneralInfoDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()

  const { data: languages } = useGetLanguagesQuery()

  const handleDrawerClickCarrierGeneralInfo = (event) => {
    event.stopPropagation()
    setSubmitEvent()
    setShowCarrierGeneralInfoDrawer(true)
  }

  const sideMenuOptionList = [
    {
      label: t("carriersManagement.sideMenu.modify"),
      handleClick: handleDrawerClickCarrierGeneralInfo,
    },
  ]

  return (
    <Accordion sx={(theme) => ({ border: 0, borderTop: `1px solid ${theme.palette.strokeDefault.main}`, borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
      <AccordionSummary>
        <Grid container>
          <Grid container item alignItems="center" xs={10}>
            <Box sx={(theme) => ({ width: theme.utils.pxToRem(210) })}>
              <CarrierServiceLogo logo={carrier.logo} align="center" />
            </Box>
            <Typography component="div" ml={customTheme.utils.pxToThemeSpacing(14)}>
              {`${t("carriersManagement.carrier.label")}: `}
              <Box fontWeight="fontWeightBold" display="inline">
                {TranslationService.getLabelFromLocale(i18n.language, carrier.fullName)}
              </Box>
            </Typography>
            {(carrier.services.new > 0 || carrier.charges.new > 0) && <Chip label={t("carriersManagement.chipStatus.newItems.label")} size="small" color="error" sx={(theme) => ({ ml: theme.utils.pxToThemeSpacing(14) })} />}
          </Grid>
          <Grid container item justifyContent="flex-end" alignItems="center" xs={2}>
            {carrier.isDisabled && <Chip label={t("carriersManagement.chipStatus.inactive.label")} size="small" color="primary" sx={(theme) => ({ ml: theme.utils.pxToThemeSpacing(14) })} />}
            <MoreMenu optionsList={sideMenuOptionList} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {carrier && <CarrierAccounts carrier={carrier} />}
        {carrier && <CarrierServices carrier={carrier} />}
        {carrier && <CarrierCharges carrier={carrier} />}
      </AccordionDetails>
      <SoeDrawer showDrawer={showCarrierGeneralInfoDrawer} setShowDrawer={setShowCarrierGeneralInfoDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("carrierDetails.carrierGeneralInfoDrawer.title")}
            setShowDrawer={() => {
              setShowCarrierGeneralInfoDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <CarrierGeneralInfoDrawer carrierGeneralInfo={carrier} languages={languages} onSubmitEvent={onSubmitEvent} setShowCarrierGeneralInfoDrawer={setShowCarrierGeneralInfoDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: "Save",
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Accordion>
  )
}

CarrierManagement.propTypes = {
  carrier: PropTypes.shape({
    accountFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    charges: PropTypes.shape({
      count: PropTypes.number,
      new: PropTypes.number,
    }),
    code: PropTypes.string,
    fullName: PropTypes.instanceOf(Object),

    logo: PropTypes.string,
    services: PropTypes.shape({
      count: PropTypes.number,
      new: PropTypes.number,
    }),
    shortName: PropTypes.instanceOf(Object),
    isDisabled: PropTypes.bool,
  }).isRequired,
}

export default CarrierManagement
