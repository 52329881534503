import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storageSession from "redux-persist/lib/storage/session"
import alertReducer from "./reducers/alertReducer"
import auditsReducer from "./reducers/auditsReducer"
import authenticatorReducer from "./reducers/authenticatorReducer"
import restApiSlice from "./services/rest-api/slice"
import drawerAlertReducer from "./reducers/drawerAlertReducer"
import rtkQueryActionHandler from "./middleware/rtk-query-action-handler"
import selectedInvoicesReducer from "./reducers/invoicesReducer"


const persistConfig = {
  key: "root",
  version: 1,
  storage: storageSession,
  blacklist: [restApiSlice.reducerPath],
}

const combinedReducers = combineReducers({
  drawer: drawerAlertReducer,
  alert: alertReducer,
  audits: auditsReducer,
  authenticator: authenticatorReducer,
  restApi: restApiSlice.reducer,
  selectedInvoices: selectedInvoicesReducer,
})

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return combinedReducers(undefined, action)
  }
  return combinedReducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(restApiSlice.middleware, rtkQueryActionHandler),
})

export default store
