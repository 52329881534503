// REACT
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"

// Others
import GeneralInformation from "./components/general-information"

function Settings() {
  const { t } = useTranslation("settings")

  return (
    <Box flexDirection="column" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1 })}>
      <Card variant="elevation" square>
        <CardHeader title={t("settings.title.label")} />
      </Card>
      <Card variant="elevation" square sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1.main, borderTop: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <Box component="div">
          <GeneralInformation />
        </Box>
      </Card>
    </Box>
  )
}

export default Settings
