const getAddressSearchText = (address) => {
  let addressText = ""
  if (address)
    addressText = `${address.companyName || ""} ${address.personName || ""} ${address.addressLine1 || ""} ${address.addressLine2 || ""} ${address.city || ""} ${address.provinceCode || ""} ${address.countryCode || ""} ${address.postalCode || ""}`
  return addressText.toLowerCase().trim()
}

const getPackagesSearchText = (packages) => {
  const totalPackages = packages.pieces?.length > 0 ? packages.pieces.reduce((accumulator, { quantity }) => accumulator + quantity, 0) : 0

  const totalWeight =
    packages.pieces?.length > 0
      ? packages.pieces.reduce((accumulator, piece) => {
          return accumulator + piece.weight * piece.quantity
        }, 0)
      : 0

  const packagesText = `${totalPackages + totalWeight / 1000}`
  return parseFloat(packagesText.trim())
}

const getBillingSearchText = (billing) => {
  let billingText = ""
  if (billing) billingText = `${billing.type || ""}`
  return billingText
}

export { getAddressSearchText, getPackagesSearchText, getBillingSearchText }
