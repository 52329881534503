/* eslint-disable react/destructuring-assignment */

/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/image-list/
 * @link https://mui.com/api/image-list/
 * @link https://mui.com/api/image-list-item/
 * @link https://mui.com/api/image-list-item-bar/
 */

export default function ImageList() {
  return {
    MuiImageList: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiImageListItem: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiImageListItemBar: {
      defaultProps: {},
      styleOverrides: {},
    },
  }
}
