// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

// Others
import PricePlan from "../pricing-plan"
import PricingPlanAdd from "../pricing-plan-add"
import PricingPlanEdit from "../pricing-plan-edit"
import MoreMenu from "../../../more-menu"
import SoeConfirmationDialog from "../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogActions from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import SoeConfirmationDialogContainer from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogContent from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogHeader from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useDeletePricingGroupMutation, useGetPricingPlansQuery } from "../../slice"

function PricingGroup({ pricingGroup, setSelectedPricingGroup, setShowPricingGroupAddDrawer, setShowPricingGroupEditDrawer }) {
  const { t } = useTranslation("pricing")

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  const [selectedPricingPlan, setSelectedPricingPlan] = useState()
  const [showPricingPlanAddDrawer, setShowPricingPlanAddDrawer] = useState(false)
  const [showPricingPlanEditDrawer, setShowPricingPlanEditDrawer] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const [deletePricingGroup] = useDeletePricingGroupMutation()
  const { data: pricingPlans, isFetching } = useGetPricingPlansQuery(pricingGroup.code, { skip: !isAccordionExpanded })

  const handleAddPricingPlanClick = (event) => {
    event.stopPropagation()
    setShowPricingPlanAddDrawer(true)
  }

  const handleEditClick = (event) => {
    event.stopPropagation()
    setSelectedPricingGroup(pricingGroup)
    setShowPricingGroupEditDrawer(true)
  }

  const handleCopyClick = (event) => {
    event.stopPropagation()
    setSelectedPricingGroup(pricingGroup)
    setShowPricingGroupAddDrawer(true)
  }

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    setShowConfirmationDialog(true)
  }

  const handleAccordionChange = (_, expanded) => {
    setIsAccordionExpanded(expanded)
  }

  const sideMenuOptionList = [
    {
      label: t("sideMenu.addPlan"),
      handleClick: handleAddPricingPlanClick,
    },
    {
      label: t("sideMenu.edit"),
      handleClick: handleEditClick,
    },
    {
      label: t("sideMenu.copy"),
      handleClick: handleCopyClick,
    },
    {
      label: t("sideMenu.delete"),
      handleClick: handleDeleteClick,
      disabled: pricingGroup.usedBy !== 0 || pricingGroup.isDefault,
    },
  ]

  const handleClose = () => {
    setShowConfirmationDialog(false)
  }

  const handleConfirm = () => {
    setShowConfirmationDialog(false)
    if (pricingGroup.usedBy === 0 && !pricingGroup.isDefault) deletePricingGroup(pricingGroup.code)
  }

  return (
    <>
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={t("pricingGroup.confirmationDialog.title")}
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("pricingGroup.confirmationDialog.content.label")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleClose,
                label: t("pricingGroup.confirmationDialog.action.cancel.label"),
                variant: "outlined",
              },
              {
                action: handleConfirm,
                label: t("pricingGroup.confirmationDialog.action.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
      <Accordion onChange={handleAccordionChange} sx={(theme) => ({ border: 0, borderTop: `1px solid ${theme.palette.strokeDefault.main}`, borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <AccordionSummary>
          <Grid container alignItems="center">
            <Grid container item alignItems="center" xs={8}>
              <Typography component="div">
                {`${t("pricingGroup.fields.label")}: `}
                <Box fontWeight="fontWeightBold" display="inline">
                  {pricingGroup.name}
                </Box>
              </Typography>
              {pricingGroup.isDefault && <Chip label={t("pricingGroup.chips.default.label")} size="small" color="secondary" sx={(theme) => ({ ml: theme.utils.pxToThemeSpacing(16) })} />}
            </Grid>
            <Grid container item justifyContent="flex-end" alignItems="center" xs={4}>
              {pricingGroup.usedBy === 0 ? <Chip label={t("pricingGroup.chips.noUser.label")} size="small" color="error" /> : <Chip label={`${pricingGroup.usedBy}${" "}${t("pricingGroup.chips.users.label")}`} size="small" color="secondary" />}
              <MoreMenu optionsList={sideMenuOptionList} />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {!pricingPlans || isFetching ? (
            <Skeleton variant="rectangular" width="100%" height={100} />
          ) : (
            <>
              {pricingPlans.map((pricingPlan, index) => {
                return <PricePlan key={"".concat(index)} pricingGroupId={pricingGroup.code} pricingPlan={pricingPlan} setSelectedPricingPlan={setSelectedPricingPlan} setShowPricingPlanEditDrawer={setShowPricingPlanEditDrawer} />
              })}
            </>
          )}
        </AccordionDetails>
        <SoeDrawer showDrawer={showPricingPlanAddDrawer} setShowDrawer={setShowPricingPlanAddDrawer} anchor="right" keepMounted={false}>
          <SoeDrawerContainer>
            <SoeDrawerHeader
              title={t("pricingPlanDrawer.title.add.label")}
              setShowDrawer={() => {
                setShowPricingPlanAddDrawer(false)
              }}
            />
            <SoeDrawerContent>
              <PricingPlanAdd selectedPricingGroupId={pricingGroup.code} onSubmitEvent={onSubmitEvent} setSubmitEvent={setSubmitEvent} setShowPricingPlanAddDrawer={setShowPricingPlanAddDrawer} />
            </SoeDrawerContent>
            <SoeDrawerActions
              buttons={[
                {
                  action: setSubmitEvent,
                  label: t("pricingManagement.actions.save.label"),
                  variant: "contained",
                },
              ]}
            />
          </SoeDrawerContainer>
        </SoeDrawer>
        <SoeDrawer showDrawer={showPricingPlanEditDrawer} setShowDrawer={setShowPricingPlanEditDrawer} anchor="right" keepMounted={false}>
          <SoeDrawerContainer>
            <SoeDrawerHeader
              title={t("pricingPlanDrawer.title.edit.label")}
              setShowDrawer={() => {
                setShowPricingPlanEditDrawer(false)
              }}
            />
            <SoeDrawerContent>
              {selectedPricingPlan && (
                <PricingPlanEdit selectedPricingGroupId={pricingGroup.code} selectedPricingPlan={selectedPricingPlan} onSubmitEvent={onSubmitEvent} setSubmitEvent={setSubmitEvent} setShowPricingPlanEditDrawer={setShowPricingPlanEditDrawer} />
              )}
            </SoeDrawerContent>
            <SoeDrawerActions
              buttons={[
                {
                  action: setSubmitEvent,
                  label: t("pricingManagement.actions.save.label"),
                  variant: "contained",
                },
              ]}
            />
          </SoeDrawerContainer>
        </SoeDrawer>
      </Accordion>
    </>
  )
}

PricingGroup.propTypes = {
  pricingGroup: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    isDefault: PropTypes.bool,
    usedBy: PropTypes.number,
  }).isRequired,
  setSelectedPricingGroup: PropTypes.func,
  setShowPricingGroupAddDrawer: PropTypes.func,
  setShowPricingGroupEditDrawer: PropTypes.func,
}

PricingGroup.defaultProps = {
  setSelectedPricingGroup: undefined,
  setShowPricingGroupAddDrawer: undefined,
  setShowPricingGroupEditDrawer: undefined,
}

export default PricingGroup
