import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"

import { useDispatch, useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useSendEmailInvoiceMutation } from "../../slice"
import restApiSlice from "../../../../services/rest-api/slice"
import { selectSelectedInvoices } from "../../../../reducers/invoicesReducer"
import { groupInvoicesByCompanyIdAndToEmail, transformGroupedInvoicesForRequestBody } from "./utils"
import GroupedInvoicesSummary from "./components/GroupedInvoicesSummary"
import { createAlert } from "../../../../reducers/alertReducer"
import { getFailedEmailInvoiceIds } from "../../utils"

function BulkEmailDrawer({ showBulkActionsDrawer, setShowBulkActionsDrawer }) {
  const { t } = useTranslation("invoices")

  const dispatch = useDispatch()
  const selectedInvoices = useSelector(selectSelectedInvoices)

  const [sendEmailInvoice, { isLoading }] = useSendEmailInvoiceMutation()

  const groupedSelectedInvoices = groupInvoicesByCompanyIdAndToEmail(selectedInvoices)
  const handleSendClick = async () => {
    const payload = transformGroupedInvoicesForRequestBody(groupedSelectedInvoices)

    try {
      const response = await sendEmailInvoice({ payload })
      if (response?.data?.status === "mixed") {
        // some of the emails failed to send
        const failedInvoiceNumbers = getFailedEmailInvoiceIds(response)
        const errorMessage = `${t("bulkEmailDrawer.errorMessages.someEmailsFailed")} ${failedInvoiceNumbers}`
        dispatch(createAlert(errorMessage))
      }
      dispatch(restApiSlice.util.invalidateTags(["UnpaidInvoices", "PaidInvoices"]))
      setShowBulkActionsDrawer(false)
    } catch (error) {
      console.error("Failed to send email", error)
    }
  }

  const handleCloseClick = () => {
    setShowBulkActionsDrawer(false)
  }

  return (
    <SoeDrawer showDrawer={showBulkActionsDrawer} setShowDrawer={setShowBulkActionsDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("bulkEmailDrawer.title")}
          setShowDrawer={() => {
            setShowBulkActionsDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <Box component="div">
            {Object.keys(groupedSelectedInvoices).map((companyId) => (
              <GroupedInvoicesSummary key={companyId} groupedInvoices={groupedSelectedInvoices[companyId]} />
            ))}
          </Box>
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleCloseClick,
              label: t("emailInvoiceDrawer.actions.cancel.label"),
              variant: "outlined",
              disabled: isLoading,
            },
            {
              action: handleSendClick,
              label: t("emailInvoiceDrawer.actions.send.label"),
              variant: "contained",
              loading: isLoading,
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

BulkEmailDrawer.propTypes = {
  showBulkActionsDrawer: PropTypes.bool.isRequired,
  setShowBulkActionsDrawer: PropTypes.func.isRequired,
}

export default BulkEmailDrawer
