import React from "react"
import PropTypes from "prop-types"
import { MuiWrapper, SoeStepWizard } from "./style"

function SoeDrawerWizard({ children, setStepWizardInstance, isLazyMount, ...muiBoxProps }) {
  return (
    <MuiWrapper {...muiBoxProps}>
      <SoeStepWizard instance={setStepWizardInstance} isLazyMount={isLazyMount}>
        {children}
      </SoeStepWizard>
    </MuiWrapper>
  )
}

SoeDrawerWizard.propTypes = {
  children: PropTypes.node,
  setStepWizardInstance: PropTypes.func.isRequired,
  isLazyMount: PropTypes.bool,
}

SoeDrawerWizard.defaultProps = {
  children: undefined,
  isLazyMount: false,
}

export default SoeDrawerWizard
