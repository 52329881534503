import restApiSlice from "../rest-api/slice"

const carriersRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarriers: builder.query({
      query: () => ({ url: `/carriers`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetCarriersQuery } = carriersRestApiSlice

export default carriersRestApiSlice.reducer
