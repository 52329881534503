import React from "react"

function Home() {
  return (
    <div className="home">
      <h1>HOME</h1>
    </div>
  )
}

export default Home
