import PropTypes from "prop-types"
import React from "react"
import Stack from "@mui/material/Stack"

import { useTranslation } from "react-i18next"

import AuditError from "../audit-error"

import { pxToThemeSpacing } from "../../../../soe-theme/src/utils"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"

function AuditErrorsDrawer({ errors, carrierCode, showAuditErrorsDrawer, setShowAuditErrorsDrawer }) {
  const { t } = useTranslation("audits")

  return (
    <SoeDrawer showDrawer={showAuditErrorsDrawer} setShowDrawer={setShowAuditErrorsDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("auditErrorsDrawer.title")}
          setShowDrawer={() => {
            setShowAuditErrorsDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <Stack spacing={pxToThemeSpacing(10)}>
            {errors.map((error, index) => {
              return <AuditError key={error.type.concat(error.billingCode || index)} error={error} carrierCode={carrierCode} />
            })}
          </Stack>
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: () => setShowAuditErrorsDrawer(false),
              label: t("auditErrorsDrawer.actions.close"),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

AuditErrorsDrawer.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  carrierCode: PropTypes.string,
  showAuditErrorsDrawer: PropTypes.bool.isRequired,
  setShowAuditErrorsDrawer: PropTypes.func.isRequired,
}

AuditErrorsDrawer.defaultProps = {
  carrierCode: undefined,
}

export default AuditErrorsDrawer
