import spacing from "./spacing"
import { pxToRem as typographyPxToRem } from "./typography"

/**
 * A scale factor between 1.2 (120%) and 1.5 (150%) is recommended.
 */
export const pxToLineHeight = (fontSize, lineHeight) => {
  if (!Number.isInteger(fontSize)) {
    throw new Error(`pxToLineHeight(${fontSize}, y) fontSize is not an Integer.`)
  }
  if (!Number.isInteger(lineHeight)) {
    throw new Error(`pxToLineHeight(x, ${lineHeight}) lineHeight is not an Integer.`)
  }

  return (lineHeight / fontSize).toFixed(2)
}

/**
 * MUI uses a recommended 8px scaling factor by default.
 *
 * When possible try to respect the scaling factor
 * If spacing is 8 : 8, 16, 24, 32, 40, 48, ...
 *
 * Example :
 *  theme.spacing(2) => `${8 * 2}px` = '16px'
 *  theme.spacing(pxToThemeSpacing(16)) = '16px'
 *  mt: pxToThemeSpacing(16) (mt: 2)
 */
export const pxToThemeSpacing = (value, scaling = spacing) => {
  if (!Number.isInteger(value)) {
    throw new Error(`pxToThemeSpacing(${value}) value is not an whole number.`)
  }
  return value / scaling
}

/**
 *
 * 16px is the standard htmlFontSize
 * Use the htmlFontSize properties defined in the theme
 *
 * Thumb rule : it's a good practice to use an even number, especially on box properties like padding, margin, width and height.
 * Please use 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, ...
 * If you need to use a odd number, simply put the REM directly instead of using this function
 *
 * Exemple :
 *  h1: {
 *    fontWeight: 700,
 *    lineHeight: 80 / 64,
 *    fontSize: pxToRem(40),
 *  },
 */
export const pxToRem = (value) => {
  if (!Number.isInteger(value)) {
    throw new Error(`pxToRem(${value}) value is not an whole number`)
  }
  return typographyPxToRem(value)
}

/**
 * Exemple :
 *  h1: {
 *    fontWeight: 700,
 *    lineHeight: 80 / 64,
 *    fontSize: pxToRem(40),
 *    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 })
 *  },
 */
export const responsiveFontSizes = ({ sm, md, lg }) => {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  }
}

const utils = { pxToLineHeight, pxToThemeSpacing, pxToRem, responsiveFontSizes }

export default utils
