// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CompanyDetailsSummaryCard from "../../../company-details-summary-card"
import { SummaryStatusVariantsEnum } from "../../../company-details-summary-card/components/summary-status"

function CompanyPricingGroupCard({ pricingGroupName, defaultToIconSuccessStatus, handleCompanyPricingGroupCardClick }) {
  const { t } = useTranslation("companies")
  const customTheme = useTheme()

  const getSummaryStatus = () => {
    if (defaultToIconSuccessStatus) return SummaryStatusVariantsEnum.icon
    return SummaryStatusVariantsEnum.warning
  }

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <CompanyDetailsSummaryCard title={t("companyDetails.companyDetailsInformations.companyPricingGroupCard.title")} handleClick={handleCompanyPricingGroupCardClick} summaryStatusProps={summaryStatusProps}>
      <Box
        mt={customTheme.spacing(1)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography noWrap variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(12, 16) })}>
          {pricingGroupName}
        </Typography>
      </Box>
    </CompanyDetailsSummaryCard>
  )
}

CompanyPricingGroupCard.propTypes = {
  pricingGroupName: PropTypes.instanceOf(Object),
  defaultToIconSuccessStatus: PropTypes.bool,
  handleCompanyPricingGroupCardClick: PropTypes.func,
}

CompanyPricingGroupCard.defaultProps = {
  handleCompanyPricingGroupCardClick: () => {},
  defaultToIconSuccessStatus: false,
  pricingGroupName: undefined,
}

export default CompanyPricingGroupCard
