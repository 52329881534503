// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import isEmpty from "lodash/isEmpty"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AutoCompletePaper from "../../../../../../soe-theme/src/components/auto-complete-paper"
import TranslationService from "../../../../../../services/translation"

import { useGetCarrierServicesQuery, useEditCarrierServicesMutation } from "../../../../../carriers/slice"

function ServiceMappingError({ error, carrierCode }) {
  const { i18n, t } = useTranslation("audits")
  const theme = useTheme()

  const { data: services } = useGetCarrierServicesQuery({ code: carrierCode, addAdditionalCharge: true }, { skip: !carrierCode })
  const [editCarrierServices] = useEditCarrierServicesMutation()

  const formik = useFormik({
    initialValues: {
      service: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (formValues.service === "") {
        errors.service = t("auditError.serviceMappingError.fields.service.error.required")
      }
      return errors
    },
    validateOnChange: false,
    onSubmit: async (formValues) => {
      const payload = { ...formValues.service, billingCode: isEmpty(formValues.service.billingCode) ? error.billingCode : formValues.service.billingCode.concat(`,${error.billingCode}`) }
      editCarrierServices({ code: carrierCode, serviceCode: payload.code, payload })
    },
  })

  return (
    <Box
      mt={theme.utils.pxToThemeSpacing(20)}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
      }}
    >
      {error.billingCode && (
        <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
          {t("auditError.serviceMappingError.codeLabel")} <b>{error.billingCode}</b>
        </Typography>
      )}
      {error.billingDescription && (
        <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
          {t("auditError.serviceMappingError.descriptionLabel")} <b>{error.billingDescription}</b>
        </Typography>
      )}
      <Box
        mt={theme.utils.pxToThemeSpacing(20)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
        }}
      >
        <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
          {t("auditError.serviceMappingError.mapTo")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: 1,
          }}
        >
          <Autocomplete
            name="serviceAutocomplete"
            disablePortal
            value={formik.values.service || null}
            onChange={(event, value) => formik.setFieldValue("service", value)}
            onBlur={formik.handleBlur}
            getOptionLabel={(option) => option && TranslationService.getLabelFromLocale(i18n.language, option.fullName)}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.code}>
                  <div>
                    <div>
                      <b>{option.code}</b>
                    </div>
                    <div>{TranslationService.getLabelFromLocale(i18n.language, option.fullName)}</div>
                  </div>
                </li>
              )
            }}
            isOptionEqualToValue={(option, value) => {
              return option.code === value.code
            }}
            options={services || []}
            PaperComponent={AutoCompletePaper}
            renderInput={(params) => <TextField {...params} name="service" sx={{ backgroundColor: "white", color: "black" }} required error={formik.touched.service && formik.errors.service !== undefined} />}
            noOptionsText={t("auditError.serviceMappingError.fields.service.noOptionsText")}
            disableClearable
            size="small"
            fullWidth
          />
          <Button size="small" onClick={formik.handleSubmit} sx={{ ml: theme.utils.pxToThemeSpacing(10) }}>
            {t("auditError.serviceMappingError.buttons.map.label")}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

ServiceMappingError.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string,
    billingCode: PropTypes.string,
    billingDescription: PropTypes.string,
    message: PropTypes.shape({}),
    severity: PropTypes.string,
  }).isRequired,
  carrierCode: PropTypes.string,
}

ServiceMappingError.defaultProps = {
  carrierCode: undefined,
}

export default ServiceMappingError
