import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"

// Custom theme
import { Skeleton, TableCell, TableRow, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

// Others
import Client from "./components/client"
import CustomPagination from "../custom-pagination"
import EnhancedTableHead from "../enhanced-table-head"
import getComparator from "../../utils/sorting"
import SearchInput from "../search-input"
import { CustomButton } from "../app-bar/styled"
import SoeCancelIcon from "../../soe-theme/src/icons/SoeCancelIcon"
import SoeAddIcon from "../../soe-theme/src/icons/SoeAddIcon"

import { useGetClientsQuery } from "./slice"

function ClientList({ externalHandleClientClick, showMoreMenu, nbRowsPerPage }) {
  const { t } = useTranslation("clients")
  const customTheme = useTheme()

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("personName")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(nbRowsPerPage)
  const [allClients, setAllClients] = useState([])
  const [isArchievedMode, setIsArchievedMode] = useState(false)
  const [filteredClients, setFilteredClients] = useState()

  const { data: clients, isFetching, refetch: refetchClients } = useGetClientsQuery()

  useEffect(() => {
    setFilteredClients((allClients?.length > 0 ? allClients : clients)?.filter((c) => (isArchievedMode ? c.disabled : !c.disabled)))
    if (!allClients?.length) setAllClients(clients)
  }, [clients, isArchievedMode])

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const headCells = [
    {
      id: "clientAddress.personName",
      disablePadding: false,
      label: t("headers.name"),
    },
    {
      id: "emailAddress",
      disablePadding: false,
      label: t("headers.emailAddress"),
    },
    {
      id: "clientAddress.companyName",
      disablePadding: false,
      label: t("headers.company"),
    },
    {
      id: "companyId",
      disablePadding: false,
      label: t("headers.hasCompany"),
    },
    {
      id: "clientAddress.addressLine1",
      disablePadding: false,
      label: t("headers.address"),
    },
    {
      id: "pricingGroupName",
      disablePadding: false,
      label: t("headers.pricingGroup"),
    },
    {
      id: "billingCurrency",
      disablePadding: false,
      label: t("headers.billingCurrency"),
    },
    {
      id: "createdDate",
      disablePadding: false,
      label: t("headers.createdDate"),
    },
    {
      id: "billing.type",
      disablePadding: false,
      label: t("headers.billingType"),
    },
    {
      id: "company.paymentMethods",
      disablePadding: false,
      label: t("headers.ccAdded"),
    },
    {
      id: "disabled",
      disablePadding: false,
      label: t("headers.status"),
    },
    ...(showMoreMenu ? [{ id: "menu" }] : []),
  ]

  const handleShowDisabledUsers = () => {
    setIsArchievedMode(!isArchievedMode)
    setPage(1)
  }

  return (
    <>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <Box my={customTheme.utils.pxToThemeSpacing(20)} mx={customTheme.spacing(2)}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <SearchInput
                data={clients}
                setFilteredData={setFilteredClients}
                excludeFields={["pk", "sk", "createdDate", "defaultLanguage", "entityType", "modifiedDate", "personName", "stripeCustomerId", "termsAcceptedDate", "pricingGroupCode", "defaultShipperId", "defaultBrokerId", "defaultShipper"]}
              />
              <CustomButton onClick={handleShowDisabledUsers} variant={isArchievedMode ? "contained" : "outlined"} size="medium" endIcon={isArchievedMode ? <SoeCancelIcon /> : <SoeAddIcon />} style={{ marginLeft: 20 }}>
                {t("headers.disabledUsers")}
              </CustomButton>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card variant="elevation" square>
        <Box sx={{ width: "auto" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table size="small" sx={{ backgroundColor: customTheme.palette.backgroundNeutralUnderground1.main }}>
                <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {!filteredClients || isFetching ? (
                    <>
                      {Array(3)
                        .fill()
                        .map((_, index) => {
                          return (
                            <TableRow key={"".concat(index)}>
                              <TableCell colSpan={12}>
                                <Skeleton variant="rectangular" height={50} width="100%" />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </>
                  ) : (
                    filteredClients
                      .slice()
                      .sort(getComparator(order, orderBy))
                      .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                      .map((client, index) => {
                        return <Client key={"".concat(index)} client={client} externalHandleClientClick={externalHandleClientClick} showMoreMenu={showMoreMenu} refetchClients={refetchClients} />
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination count={filteredClients ? Math.ceil(filteredClients.length / rowsPerPage) : 1} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />
          </Paper>
        </Box>
      </Card>
    </>
  )
}

ClientList.propTypes = {
  externalHandleClientClick: PropTypes.func,
  showMoreMenu: PropTypes.bool,
  nbRowsPerPage: PropTypes.number,
}

ClientList.defaultProps = {
  externalHandleClientClick: undefined,
  showMoreMenu: true,
  nbRowsPerPage: 15,
}

export default ClientList
