import Box from "@mui/material/Box"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Link from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import React from "react"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

import { useNavigate, useParams } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import AdditionalChargeDisplay from "./components/additional-charge-display"
import AuditItemDetailsDiplay from "./components/audit-item-details-display"
import NoMatchDisplay from "./components/no-match-display"

import { AuditItemTypeEnum } from "../../../../constant"
import { ROUTES } from "../../../../../../constant"
import { pxToThemeSpacing } from "../../../../../../soe-theme/src/utils"
import { useGetAuditDetailsQuery, useGetAuditItemDetailsQuery } from "../../../../../../reducers/auditsReducer"

function AuditItemDetails() {
  const { t } = useTranslation("audits")
  const navigate = useNavigate()
  const theme = useTheme()

  const { auditId, auditItemId } = useParams()

  const { data: audit } = useGetAuditDetailsQuery(auditId, { skip: !auditId })
  const { data: auditItemDetails, isFetching } = useGetAuditItemDetailsQuery({ auditId, auditItemId }, { skip: !auditId || !auditItemId })

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(ROUTES.AUDITS)}>
      {t("auditDetails.auditItemDetails.breadcrumbs.audits")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(`${ROUTES.AUDITS}/${auditId}`)}>
      {t("auditDetails.auditItemDetails.breadcrumbs.details")}
    </Link>,
    <Typography key="3" color="text.primary">
      {t("auditDetails.auditItemDetails.breadcrumbs.itemDetails")}
    </Typography>,
  ]

  return (
    <Box flexDirection="column" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1 }}>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <CardHeader title={t("auditDetails.auditItemDetails.title")} style={{ paddingLeft: theme.spacing(4), paddingBottom: theme.spacing(2) }} />
      </Card>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <Box mb={pxToThemeSpacing(10)} ml={pxToThemeSpacing(34)}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
      </Card>
      <Card variant="outlined" />
      {!auditItemDetails || isFetching ? (
        <Skeleton variant="rectangular" width="100%" height={100} />
      ) : (
        <Box m={pxToThemeSpacing(20)}>
          {audit && auditItemDetails?.order?.id && <AuditItemDetailsDiplay audit={audit} auditItemDetails={auditItemDetails} />}
          {audit && auditItemDetails && auditItemDetails.auditItemType === AuditItemTypeEnum.ORDER && !auditItemDetails.order?.id && <NoMatchDisplay audit={audit} auditItemDetails={auditItemDetails} />}
          {audit && auditItemDetails && auditItemDetails.auditItemType === AuditItemTypeEnum.ADDITIONAL_CHARGE && !auditItemDetails.order?.id && <AdditionalChargeDisplay audit={audit} auditItemDetails={auditItemDetails} />}
        </Box>
      )}
    </Box>
  )
}

export default AuditItemDetails
