// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CarrierAccountFieldsForm from "./components/carrier-account-fields-form"
import { useAddCarrierAccountsMutation, useEditCarrierAccountsMutation } from "../../slice"

function CarrierAccountDrawer({ carrierGeneralInfoCode, carrierAccounts, carrierAccountIndex, carrierGeneralInfoSorted, onSubmitEvent, onDisableEvent, setShowCarrierAcountDrawer, setDisableEvent }) {
  const customTheme = useTheme()
  const { t } = useTranslation("carriers")

  const [addCarrierAccounts] = useAddCarrierAccountsMutation()
  const [editCarrierAccounts] = useEditCarrierAccountsMutation()

  const formik = useFormik({
    initialValues: {
      code: (carrierAccounts && carrierAccounts[carrierAccountIndex] && carrierAccounts[carrierAccountIndex].code) || "",
      name: (carrierAccounts && carrierAccounts[carrierAccountIndex] && carrierAccounts[carrierAccountIndex].name) || "",
      accountDetails: (carrierAccounts && carrierAccounts[carrierAccountIndex] && carrierAccounts[carrierAccountIndex].accountDetails) || [],
      isTest: (carrierAccounts && carrierAccounts[carrierAccountIndex] && carrierAccounts[carrierAccountIndex].isTest) || false,
      isDisabled: (carrierAccounts && carrierAccounts[carrierAccountIndex] && carrierAccounts[carrierAccountIndex].isDisabled) || false,
    },
    validate: (values) => {
      const errors = {}

      if (!values.name) {
        errors.name = true
      }

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      if (carrierAccounts) {
        if (carrierAccountIndex === undefined) {
          await addCarrierAccounts({ code: carrierGeneralInfoCode, payload: formValues })
        } else {
          await editCarrierAccounts({ code: carrierGeneralInfoCode, accountCode: formValues.code, payload: formValues })
        }
      }
      formik.resetForm()
      setShowCarrierAcountDrawer(false)
      setDisableEvent()
    },
  })

  useEffect(() => {
    if (onDisableEvent) {
      if (formik.values && formik.values.isDisabled && formik.values.isDisabled === true) formik.setFieldValue("isDisabled", false)
      else formik.setFieldValue("isDisabled", true)
      formik.handleSubmit()
    }
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent, onDisableEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {t("carrierDetails.carrierAccountsDrawer.name.label")}
        </Typography>
        <Box component="div" p={1} />
        <TextField
          id="name"
          name="name"
          inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular } }}
          variant="outlined"
          size="small"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name || ""}
          error={formik.touched.name && formik.errors.name !== undefined}
          fullWidth
        />
        <Box component="div" p={1} pt={2} />
        <Divider orientation="horizontal" style={{ minHeight: 0 }} />
        <Box component="div" p={1} />
        <CarrierAccountFieldsForm formik={formik} nameLabel={t("carrierDetails.carrierAccountsDrawer.detail.label")} formikCarrierAccountFields="accountDetails" carrierGeneralInfoSorted={carrierGeneralInfoSorted} />
        <Box component="div" p={1} />
        <FormControlLabel
          onChange={() => (formik.values && formik.values.isTest ? formik.setFieldValue("isTest", false) : formik.setFieldValue("isTest", true))}
          value={formik.values.isTest}
          control={<Checkbox checked={!!(formik.values && formik.values.isTest)} />}
          label={t("carrierDetails.carrierAccountsDrawer.actions.test.label")}
        />
        <Box component="div" p={1} />
      </form>
    </Box>
  )
}

CarrierAccountDrawer.defaultProps = {
  carrierGeneralInfoCode: "",
  carrierAccounts: undefined,
  carrierAccountIndex: undefined,
  onSubmitEvent: undefined,
  onDisableEvent: undefined,
}

CarrierAccountDrawer.propTypes = {
  carrierGeneralInfoCode: PropTypes.string,
  carrierAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountDetails: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          name: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
          ordinal: PropTypes.number,
        })
      ),
      isDisabled: PropTypes.bool,
      isTest: PropTypes.bool,
      name: PropTypes.string,
      code: PropTypes.string,
    })
  ),
  carrierAccountIndex: PropTypes.number,
  carrierGeneralInfoSorted: PropTypes.instanceOf(Object).isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  onDisableEvent: PropTypes.instanceOf(Object),
  setShowCarrierAcountDrawer: PropTypes.func.isRequired,
  setDisableEvent: PropTypes.func.isRequired,
}

export default CarrierAccountDrawer
