import React from "react"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"

import { useTranslation } from "react-i18next"
import CompaniesList from "./components/companies-list"

function Companies() {
  const { t } = useTranslation("companies")

  return (
    <>
      <Card variant="elevation" square sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <CardHeader title={t("title")} />
      </Card>
      <CompaniesList />
    </>
  )
}

export default Companies
