/**
 * @link https://mui.com/customization/theme-components/#default-props
 *
 * @link https://mui.com/components/skeleton/
 * @link https://mui.com/api/skeleton/
 */
export default function Skeleton(theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: "pulse",
      },
      styleOverrides: {
        root: {
          color: theme.palette.backgroundNeutralUnderground1, // @todo: temporary color, check with UI/UX
          backgroundColor: theme.palette.backgroundNeutralTransparent, // @todo: temporary color, check with UI/UX
        },
      },
    },
  }
}
