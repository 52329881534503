import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CarrierServiceLogo from "../../../../../../../carrier-service-logo"
import DateTimeService from "../../../../../../../../services/date-time"
import RateRequestSummaryCard from "../../../../../../../rate-request-summary-card"
import TranslationService from "../../../../../../../../services/translation"

import { AuditItemSectionProps } from "../../../../../../../../models/audit"
import { pxToThemeSpacing } from "../../../../../../../../soe-theme/src/utils"

function CarrierDetailsHeader({ carrier, tracking, service, shippingOptions }) {
  const { i18n, t } = useTranslation("audits")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard sx={{ height: 1 }}>
      <CarrierServiceLogo logo={carrier.logo} align="left" />
      <Box mt={pxToThemeSpacing(10)}>
        <Stack direction="column">
          {tracking.masterId && (
            <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
              {t("auditDetails.auditItemDetails.carrierDetails.carrierInvoice.trackingNumber")} <b>{tracking.masterId}</b>
            </Typography>
          )}
          {service.fullName && (
            <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
              {t("auditDetails.auditItemDetails.carrierDetails.carrierInvoice.service")} <b>{TranslationService.getLabelFromLocale(i18n.language, service.fullName)}</b>
            </Typography>
          )}
          {shippingOptions.shippingDate && (
            <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400">
              {t("auditDetails.auditItemDetails.carrierDetails.carrierInvoice.shippingDate")} <b>{DateTimeService.toLocaleDateString(shippingOptions.shippingDate)}</b>
            </Typography>
          )}
        </Stack>
      </Box>
    </RateRequestSummaryCard>
  )
}

CarrierDetailsHeader.propTypes = {
  carrier: PropTypes.shape({
    logo: PropTypes.string,
  }).isRequired,
  tracking: AuditItemSectionProps.isRequired,
  service: AuditItemSectionProps.isRequired,
  shippingOptions: AuditItemSectionProps.isRequired,
}

export default CarrierDetailsHeader
