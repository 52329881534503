// PROP-TYPES
import PropTypes from "prop-types"

// REACT
import React from "react"

// MUI
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

function CarrierCodeForm({ formik, nameLabel, carrierCodeLabel, carrierCode, carrierIndex }) {
  const customTheme = useTheme()

  const getIsCodeEditable = (value) => {
    return !!value
  }

  return (
    <Box component="div">
      {carrierCodeLabel && (
        <Typography variant="bodyMedium600" gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {carrierCodeLabel}
        </Typography>
      )}
      <Box component="div" p={1} />
      <Box component="div">
        <TextField
          id="code"
          name="code"
          inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular } }}
          variant="outlined"
          size="small"
          label={nameLabel}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.code || ""}
          error={formik.touched.code && formik.errors.code !== undefined}
          helperText={(formik.touched.code && formik.errors.code) || " "}
          fullWidth
          disabled={getIsCodeEditable(carrierCode && carrierCode[carrierIndex] && carrierCode[carrierIndex].code)}
        />
      </Box>
    </Box>
  )
}

CarrierCodeForm.defaultProps = {
  carrierCodeLabel: undefined,
  carrierCode: undefined,
  carrierIndex: undefined,
}

CarrierCodeForm.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  nameLabel: PropTypes.string.isRequired,
  carrierCodeLabel: PropTypes.string,
  carrierCode: PropTypes.instanceOf(Object),
  carrierIndex: PropTypes.number,
}

export default CarrierCodeForm
