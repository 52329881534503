const CARRIERS_STATUS = {
  PENDING: "pending",
  INTRANSIT: "intransit",
  COMPLETED: "delivered",
  CANCELED: "canceled",
  EXPIRED: "expired",
}

const ORDER_STATUS = {
  MATCHED: "matched",
  VALIDATED: "validated",
  INVOICED: "invoiced",
  DISPUTED: "disputed",
  PREPAID: "prepaid",
  CANCELED: "canceled",
}

export { CARRIERS_STATUS, ORDER_STATUS }
