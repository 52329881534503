// REACT
import React, { useContext } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"

// Custom theme
import { useTheme } from "@mui/material"

// Others
import UserContext from "../../services/user/context"
import UserProfileGeneralInformation from "./components/user-profile-general-info"

function UserProfile() {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const { currentUser } = useContext(UserContext)

  return (
    <Box flexDirection="column" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1 })}>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <CardHeader title={t("userProfile.title.label")} style={{ pl: customTheme.spacing(4), pb: customTheme.spacing(2) }} />
      </Card>
      <Card variant="outlined" />
      <Box p={customTheme.utils.pxToThemeSpacing(20)} pt={0}>
        {currentUser && <UserProfileGeneralInformation />}
      </Box>
    </Box>
  )
}

export default UserProfile
