export const AuditStatusEnum = {
  MAPPING_ERRORS: "mappingErrors",
  READY: "ready",
  VALIDATED: "validated",
  INVOICED: "invoiced",
  FAILED: "failed",
}

export const AuditErrorEnum = {
  PARSE_ERROR: "parseError",
  SERVICE_MAPPING_ERROR: "serviceMappingError",
  CHARGE_MAPPING_ERROR: "chargeMappingError",
}

export const AuditItemTypeEnum = {
  ORDER: "order",
  ADDITIONAL_CHARGE: "additionalCharge",
}
