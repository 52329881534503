export const getTodayRange = () => {
  const range = {
    from: new Date(new Date().setHours(0, 0, 0, 0)),
    to: new Date(new Date().setHours(23, 59, 59, 999)),
  }
  return range
}

export const getYesterdayRange = () => {
  const currentDate = new Date()
  const yesterday = new Date().setDate(currentDate.getDate() - 1)
  const range = {
    from: new Date(new Date(yesterday).setHours(0, 0, 0, 0)),
    to: new Date(new Date(yesterday).setHours(23, 59, 59, 999)),
  }
  return range
}

export const getThisWeekRange = () => {
  const currentDate = new Date()
  return {
    from: new Date(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay()).setHours(0, 0, 0, 0)),
    to: new Date(currentDate.setHours(23, 59, 59, 999)),
  }
}

export const getLastWeekRange = () => {
  const currentDate = new Date()
  return {
    from: new Date(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() - 7).setHours(0, 0, 0, 0)),
    to: new Date(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() - 1).setHours(23, 59, 59, 999)),
  }
}

export const getLastTwoWeeksRange = () => {
  const currentDate = new Date()
  return {
    from: new Date(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() - 14).setHours(0, 0, 0, 0)),
    to: new Date(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() - 1).setHours(23, 59, 59, 999)),
  }
}

export const getLastMonthRange = () => {
  const currentDate = new Date()
  return {
    from: new Date(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate()).setHours(0, 0, 0, 0)),
    to: new Date(currentDate.setHours(23, 59, 59, 999)),
  }
}
