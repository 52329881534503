import Box from "@mui/material/Box"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Link from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import React, { useState } from "react"
import Typography from "@mui/material/Typography"

import { useNavigate, useParams } from "react-router-dom"
import { useTheme } from "@emotion/react"
import { useTranslation } from "react-i18next"

import CompanyDetailsActivities from "./components/company-details-activities"
import CompanyDetailsInformations from "./components/company-details-informations"
import CompanyDetailsStatistics from "./components/company-details-statistics"

import { pxToThemeSpacing } from "../../../../../soe-theme/src/utils"
import { ROUTES } from "../../../../../constant"
import { useGetCompanyQuery } from "../../../../../services/company/slice"

function CompanyDetails() {
  const { t } = useTranslation("companies")
  const navigate = useNavigate()
  const theme = useTheme()

  const { companyId } = useParams()
  const [dataRangeFilterValues, setDataRangeFilterValues] = useState()

  const { data: company, refetch: refetchCompany } = useGetCompanyQuery(companyId, { skip: !companyId })

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(ROUTES.COMPANIES)}>
      {t("companyDetails.breadcrumbs.companies")}
    </Link>,
    <Typography key="3" color="text.primary">
      {t("companyDetails.breadcrumbs.details")}
    </Typography>,
  ]

  const pageTitle = company?.companyName ? `${t("companyDetails.title")} | ${company?.companyName}` : t("companyDetails.title")
  return (
    <Box flexDirection="column" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1 }}>
      <Card variant="standard" square sx={{ height: "auto", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <CardHeader title={pageTitle} style={{ paddingLeft: theme.spacing(4), paddingBottom: theme.spacing(2) }} />
        {company && <CardHeader title={`ID: ${company?.accountId}`} style={{ paddingLeft: theme.spacing(4), paddingBottom: theme.spacing(2) }} />}
      </Card>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <Box mb={pxToThemeSpacing(10)} ml={pxToThemeSpacing(34)}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
      </Card>
      <Card variant="outlined" />
      <Box p={pxToThemeSpacing(20)} pt={0}>
        {company && <CompanyDetailsStatistics company={company} dataRangeFilterValues={dataRangeFilterValues} setDataRangeFilterValues={setDataRangeFilterValues} />}
        {company && <CompanyDetailsInformations company={company} refetchCompany={refetchCompany} />}
        {company && <CompanyDetailsActivities company={company} dataRangeFilterValues={dataRangeFilterValues} />}
      </Box>
    </Box>
  )
}

export default CompanyDetails
