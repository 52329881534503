import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import TextField from "@mui/material/TextField"

import { useTranslation } from "react-i18next"

import AutoCompletePaper from "../../soe-theme/src/components/auto-complete-paper"
import ClientAutocompleteOption from "./components/client-autocomplete-option"

function ClientsAutocomplete({ formik, clients }) {
  const { t } = useTranslation("clientsAutoComplete")
  return (
    <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
      <Autocomplete
        value={clients?.find((element) => formik.values.client?.emailAddress === element.emailAddress) || null}
        size="small"
        disablePortal
        onChange={(_, value) => {
          formik.setFieldValue("client", value)
        }}
        isOptionEqualToValue={(option, value) => {
          return option?.emailAddress === value?.emailAddress
        }}
        name="client"
        onBlur={formik.handleBlur}
        getOptionLabel={(option) => {
          return option.emailAddress
        }}
        renderOption={(props, option) => {
          return <ClientAutocompleteOption key={"".concat(option?.emailAddress)} renderOptionProps={props} client={option} />
        }}
        options={clients}
        PaperComponent={AutoCompletePaper}
        renderInput={(params) => <TextField {...params} label={t("label")} error={formik.touched.client && formik.errors.client !== undefined} />}
        noOptionsText={t("noClients")}
        label={t("label")}
        filterOptions={(options, state) => {
          return options.filter(
            (option) =>
              option.emailAddress?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
              option.clientAddress?.personName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
              option.clientAddress?.companyName?.toLowerCase().includes(state.inputValue.toLowerCase())
          )
        }}
      />
    </Box>
  )
}

ClientsAutocomplete.defaultProps = {
  clients: [],
}

ClientsAutocomplete.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  clients: PropTypes.instanceOf(Array),
}

export default ClientsAutocomplete
